import React, { FC } from "react"
import {
  ArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  NumberInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleFormProps,
  TextField,
  TextInput,
} from "react-admin"

import { SearchFilter } from "./utils"

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="code" />
      <TextInput source="name" />
      <BooleanInput source="supported" />
      <ArrayInput source="sublocations">
        <SimpleFormIterator>
          <TextInput source={""} />
        </SimpleFormIterator>
      </ArrayInput>
      <NumberInput source="latitude" />
      <NumberInput source="longitude" />
      {props.children}
    </SimpleForm>
  )
}

export const LocationCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const LocationEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm>
        <TextField source="users.length" />
        <TextField source="ubiqFeedItems.length" />
      </CreateEditForm>
    </Edit>
  )
}

export const LocationList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <BooleanField source="supported" />
      <TextField source="users.length" />
      <TextField source="ubiqFeedItems.length" />
    </Datagrid>
  </List>
)
