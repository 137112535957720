import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"
import { JsonInput } from "react-admin-json-view"

import { EditToolbar, SearchFilter } from "./utils"

export const FaqItemCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <NumberInput source="order" />
      <TextInput source="question" />
      <MarkdownInput source="answer" />
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <ReferenceInput reference="FaqCategory" source="category">
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const FaqItemEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source="id" />
        <NumberInput source="order" />
        <TextInput source="question" />
        <MarkdownInput source="answer" />
        <ReferenceInput reference="Community" source="parentCommunity">
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceInput reference="FaqCategory" source="category">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <JsonInput
          jsonString={false}
          reactJsonOptions={{
            collapsed: true,
            displayDataTypes: false,
            enableClipboard: false,
            name: null,
          }}
          source="styleOverride"
        />
      </SimpleForm>
    </Edit>
  )
}

export const FaqItemList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField reference="FaqCategory" source="category">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="question" />
      <TextField source="answer" />
    </Datagrid>
  </List>
)

export const FaqItemShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <NumberField source="order" />
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField reference="FaqCategory" source="category">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="question" />
      <TextField source="answer" />
    </SimpleShowLayout>
  </Show>
)
