import React, { FC } from "react"
import {
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"

import { TalksMessageType } from "./generated/graphql"

const talksMessageTypeChoices = [
  ...Object.keys(TalksMessageType).map((k) => ({
    id: k,
    name: k,
  })),
]

const CreateEditForm = ({ children, ...props }: { children?: any }) => (
  <SimpleForm {...props}>
    <ReferenceInput reference="Community" source="community">
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput label="Sender" reference="User" source="sender">
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <ReferenceInput label="Recipient" reference="User" source="recipient">
      <AutocompleteInput optionText="username" />
    </ReferenceInput>
    <SelectInput choices={talksMessageTypeChoices} label="Type" source="type" />
    <TextInput source="senderTitle" />
    <TextInput multiline source="message" />
    <BooleanInput source="read" />
    {children}
  </SimpleForm>
)

export const TalksMessageCreate: FC = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const TalksMessageEdit: FC = (props) => (
  <Edit {...props}>
    <CreateEditForm />
  </Edit>
)

export const TalksMessageList: FC = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <ReferenceField label="Community" reference="Community" source="community">
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceField label="Sender" reference="User" source="sender">
        <ChipField source="username" />
      </ReferenceField>
      <ReferenceField label="Recipient" reference="User" source="recipient">
        <ChipField source="username" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="message" />
      <DateField showTime source="createdAt" />
    </Datagrid>
  </List>
)
