import React, { FC } from "react"
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"

import { SearchFilter } from "./utils"

export const CommunityMemberInfoList: FC<any> = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="Community" source="community.name" />
        <TextInput label="User" source="user.username" />
      </SearchFilter>
    }
  >
    <Datagrid>
      <ReferenceField label="Community" reference="Community" source="community">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="User" reference="User" source="user">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="memberSince" />
    </Datagrid>
  </List>
)

export const CommunityMemberInfoShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ReferenceField label="Community" reference="Community" source="community">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="User" reference="User" source="user">
        <TextField source="username" />
      </ReferenceField>
      <DateField source="memberSince" />
    </SimpleShowLayout>
  </Show>
)
