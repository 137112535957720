import React, { FC } from "react"
import { ChipField, Datagrid, List, ReferenceField, TextField } from "react-admin"

import { SearchFilter } from "./utils"

export const KarmaChangesList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <ReferenceField reference="User" source="user">
        <ChipField source="username" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="amount" />
      <TextField source="createdAt" />
    </Datagrid>
  </List>
)
