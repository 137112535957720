import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleFormProps,
  SingleFieldList,
  TextInput,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { TruncatedTextField } from "./components/TruncatedTextField"
import { ForumPostPublishingStatus } from "./generated/graphql"
import { SearchFilter } from "./utils"

const forumPostStatusChoices = [
  ...Object.keys(ForumPostPublishingStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput reference="ForumTag" source="tags">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceInput reference="User" source="author">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <SelectInput choices={forumPostStatusChoices} source="publishingStatus" />
      <TextInput source="headline" />
      <MarkdownInput source="content" />
      <ArrayInput source="media">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ formData, scopedFormData }) => {
              const { media, id: forumItemId } = formData as {
                media: string[]
                id: string
              }
              const url = scopedFormData as string
              let index = media.findIndex((m) => m === url)
              if (index === -1) index = media.length
              return (
                <MediaWithUpload
                  path={`forum/media/${forumItemId}_${index}`}
                  source={`media.${index}`}
                />
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      {props.children}
    </SimpleForm>
  )
}

export const ForumPostCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const ForumPostEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm>
        <NumberField source="likesCount" />
        <NumberField source="commentsCount" />
        <NumberField source="commentsAndRepliesCount" />
        <DateTimeInput showTime source="createdAt" />
      </CreateEditForm>
    </Edit>
  )
}

export const ForumPostList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TruncatedTextField source="content" />
      <ReferenceField reference="Community" source="community">
        <ChipField source="name" />
      </ReferenceField>
      <ReferenceArrayField reference="ForumTag" source="tags">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ChipField source="publishingStatus" />
      <NumberField source="likesCount" />
      <NumberField source="commentsCount" />
      <NumberField source="commentsAndRepliesCount" />
      <DateField showTime source="createdAt" />
    </Datagrid>
  </List>
)
