import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  ArrayInput,
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  FormDataConsumer,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleFormProps,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { TruncatedTextField } from "./components/TruncatedTextField"
import { ForumCommentStatus } from "./generated/graphql"
import { SearchFilter } from "./utils"

const forumCommentPostChoices = [
  ...Object.keys(ForumCommentStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <ReferenceField reference="ForumPost" source="post">
        <ChipField source="id" />
      </ReferenceField>
      <ReferenceInput reference="User" source="author">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <SelectInput choices={forumCommentPostChoices} source="status" />
      <MarkdownInput source="content" />
      <ArrayInput source="media">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ formData, scopedFormData }) => {
              const { media, id: forumItemId } = formData as {
                media: string[]
                id: string
              }
              const url = scopedFormData as string
              let index = media.findIndex((m) => m === url)
              if (index === -1) index = media.length
              return (
                <MediaWithUpload
                  path={`forum/media/${forumItemId}_${index}`}
                  source={`media.${index}`}
                />
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      {props.children}
    </SimpleForm>
  )
}

export const ForumCommentCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const ForumCommentEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm>
        <NumberField source="likesCount" />
        <DateField showTime source="updatedAt" />
        <DateField showTime source="createdAt" />
      </CreateEditForm>
    </Edit>
  )
}

export const ForumCommentList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <ReferenceField reference="ForumPost" source="post">
        <ChipField source="id" />
      </ReferenceField>
      <ReferenceField reference="User" source="author">
        <ChipField source="username" />
      </ReferenceField>
      <TruncatedTextField source="content" />
      <NumberField source="likesCount" />
    </Datagrid>
  </List>
)
