import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: any;
  Json: any;
};

export type ActiveProductsReturn = {
  __typename?: 'ActiveProductsReturn';
  active: Scalars['Int'];
};

export type ActivityEvent = {
  __typename?: 'ActivityEvent';
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  eventId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location: Scalars['String'];
  platform: Scalars['String'];
  properties?: Maybe<Scalars['Json']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type ActivityEventCreateInput = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['Json']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutActivityEventsInput;
};

export type ActivityEventCreateManyUserInput = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['Json']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityEventCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<ActivityEventCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityEventCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ActivityEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityEventCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ActivityEventCreateWithoutUserInput>>;
  createMany?: InputMaybe<ActivityEventCreateManyUserInputEnvelope>;
};

export type ActivityEventCreateOrConnectWithoutUserInput = {
  create: ActivityEventCreateWithoutUserInput;
  where: ActivityEventWhereUniqueInput;
};

export type ActivityEventCreateWithoutUserInput = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
  properties?: InputMaybe<Scalars['Json']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityEventListRelationFilter = {
  every?: InputMaybe<ActivityEventWhereInput>;
  none?: InputMaybe<ActivityEventWhereInput>;
  some?: InputMaybe<ActivityEventWhereInput>;
};

export type ActivityEventOrderByInput = {
  communityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  properties?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type ActivityEventScalarWhereInput = {
  AND?: InputMaybe<Array<ActivityEventScalarWhereInput>>;
  NOT?: InputMaybe<Array<ActivityEventScalarWhereInput>>;
  OR?: InputMaybe<Array<ActivityEventScalarWhereInput>>;
  communityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringFilter>;
  platform?: InputMaybe<StringFilter>;
  properties?: InputMaybe<JsonNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ActivityEventUpdateInput = {
  communityId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<StringFieldUpdateOperationsInput>;
  platform?: InputMaybe<StringFieldUpdateOperationsInput>;
  properties?: InputMaybe<Scalars['Json']>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutActivityEventsInput>;
};

export type ActivityEventUpdateManyMutationInput = {
  communityId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<StringFieldUpdateOperationsInput>;
  platform?: InputMaybe<StringFieldUpdateOperationsInput>;
  properties?: InputMaybe<Scalars['Json']>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityEventUpdateManyWithWhereWithoutUserInput = {
  data: ActivityEventUpdateManyMutationInput;
  where: ActivityEventScalarWhereInput;
};

export type ActivityEventUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<ActivityEventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityEventCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ActivityEventCreateWithoutUserInput>>;
  createMany?: InputMaybe<ActivityEventCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<ActivityEventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ActivityEventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ActivityEventWhereUniqueInput>>;
  set?: InputMaybe<Array<ActivityEventWhereUniqueInput>>;
  update?: InputMaybe<Array<ActivityEventUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<ActivityEventUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<ActivityEventUpsertWithWhereUniqueWithoutUserInput>>;
};

export type ActivityEventUpdateWithWhereUniqueWithoutUserInput = {
  data: ActivityEventUpdateWithoutUserInput;
  where: ActivityEventWhereUniqueInput;
};

export type ActivityEventUpdateWithoutUserInput = {
  communityId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  eventId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  location?: InputMaybe<StringFieldUpdateOperationsInput>;
  platform?: InputMaybe<StringFieldUpdateOperationsInput>;
  properties?: InputMaybe<Scalars['Json']>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ActivityEventUpsertWithWhereUniqueWithoutUserInput = {
  create: ActivityEventCreateWithoutUserInput;
  update: ActivityEventUpdateWithoutUserInput;
  where: ActivityEventWhereUniqueInput;
};

export type ActivityEventWhereInput = {
  AND?: InputMaybe<Array<ActivityEventWhereInput>>;
  NOT?: InputMaybe<Array<ActivityEventWhereInput>>;
  OR?: InputMaybe<Array<ActivityEventWhereInput>>;
  communityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  location?: InputMaybe<StringFilter>;
  platform?: InputMaybe<StringFilter>;
  properties?: InputMaybe<JsonNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilter>;
};

export type ActivityEventWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type AdminChat = {
  __typename?: 'AdminChat';
  id?: Maybe<Scalars['String']>;
  lastMessage?: Maybe<TalksMessage>;
  title?: Maybe<Scalars['String']>;
};

export type AdminListEventAttendee = {
  __typename?: 'AdminListEventAttendee';
  purchases: Array<Purchase>;
  user: User;
};

export enum AdminRolesType {
  CheckIn = 'CheckIn',
  Super = 'Super'
}

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AllUserIDsExistResponse = {
  __typename?: 'AllUserIDsExistResponse';
  allExist?: Maybe<Scalars['Boolean']>;
  missingIDs: Array<Scalars['String']>;
};

export type AnnouncementsReturn = {
  __typename?: 'AnnouncementsReturn';
  items: Array<FeedItem>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  communities?: Maybe<Array<Maybe<Community>>>;
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AuthV2Response = {
  __typename?: 'AuthV2Response';
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  verificationCodeSent?: Maybe<Scalars['Boolean']>;
};

export enum BackgroundType {
  Color = 'Color',
  Picture = 'Picture',
  Pictures = 'Pictures',
  Video = 'Video',
  VideoAlways = 'VideoAlways'
}

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type ByDateBreakdownData = {
  __typename?: 'ByDateBreakdownData';
  byNameBreakdown?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  totalFaceValue?: Maybe<Scalars['Float']>;
  totalTickets?: Maybe<Scalars['Float']>;
};

export type ByTypeBreakdownData = {
  __typename?: 'ByTypeBreakdownData';
  data?: Maybe<Array<Maybe<Scalars['Float']>>>;
  label?: Maybe<Scalars['String']>;
};

export type CaAdmin = {
  __typename?: 'CAAdmin';
  DOB?: Maybe<Scalars['DateTime']>;
  avatar: Scalars['String'];
  displayRole: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  joinedOn: Scalars['DateTime'];
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type CaAdminsResponse = {
  __typename?: 'CAAdminsResponse';
  admins: Array<CaAdmin>;
};

export type CaAnnouncementCreateOrUpdateInput = {
  ctaButtonTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  text?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  watchLink?: InputMaybe<Scalars['String']>;
};

export type CaCommunityThemeCreateOrUpdateInput = {
  buttonStyle?: InputMaybe<Scalars['Json']>;
  colorSet?: InputMaybe<Array<Scalars['String']>>;
  enabledModules?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  fonts?: InputMaybe<Array<Scalars['String']>>;
  media?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  name: Scalars['String'];
  style: Scalars['Json'];
};

export type CaDashboardResponse = {
  __typename?: 'CADashboardResponse';
  advocates?: Maybe<Array<Maybe<User>>>;
  contributors?: Maybe<Array<Maybe<User>>>;
  observers?: Maybe<Array<Maybe<User>>>;
  participants?: Maybe<Array<Maybe<User>>>;
  sleepers?: Maybe<Array<Maybe<User>>>;
};

export type CaEventActivityEvent = {
  __typename?: 'CAEventActivityEvent';
  action?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CaEventInformation = {
  __typename?: 'CAEventInformation';
  grossRevenue?: Maybe<Scalars['Float']>;
  ticketsSold?: Maybe<Scalars['Float']>;
  usersCheckedIn?: Maybe<Scalars['Float']>;
  usersInvited?: Maybe<Scalars['Float']>;
  usersRegistered?: Maybe<Scalars['Float']>;
};

export type CaEventResponse = {
  __typename?: 'CAEventResponse';
  activityEvents?: Maybe<Array<Maybe<CaEventActivityEvent>>>;
  event?: Maybe<Event>;
  information?: Maybe<CaEventInformation>;
};

export type CaEventTicketsActivityResponse = {
  __typename?: 'CAEventTicketsActivityResponse';
  byDateBreakdown?: Maybe<Array<Maybe<ByDateBreakdownData>>>;
  byTypeBreakdown?: Maybe<Array<Maybe<ByTypeBreakdownData>>>;
};

export type CaEventTraction = {
  __typename?: 'CAEventTraction';
  metrics: CaEventTractionMetrics;
  ticketsDetails: Array<CaEventTractionTicketsDetails>;
};

export type CaEventTractionMetrics = {
  __typename?: 'CAEventTractionMetrics';
  potentialRevenue: Scalars['String'];
  ticketsSold: Scalars['String'];
  timePoint: Scalars['DateTime'];
  timeSubtitle: Scalars['String'];
  totalRevenue: Scalars['String'];
};

export type CaEventTractionTicketsDetails = {
  __typename?: 'CAEventTractionTicketsDetails';
  description: Scalars['String'];
  discountedPrice?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  initialPrice: Scalars['String'];
  medialUrl: Scalars['String'];
  name: Scalars['String'];
  salesDates: Scalars['String'];
  soldByInitialPrice: Scalars['String'];
  soldWithDiscount?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  statusColor: Scalars['String'];
  totalRevenue: Scalars['String'];
  totalSold: Scalars['String'];
};

export type CaFinancesOverviewResponse = {
  __typename?: 'CAFinancesOverviewResponse';
  salesBreakdown?: Maybe<Array<Maybe<SalesData>>>;
  selectedRangeTotal?: Maybe<Scalars['Float']>;
  totalSales?: Maybe<Scalars['Float']>;
};

export type CaFinancesPayoutsResponse = {
  __typename?: 'CAFinancesPayoutsResponse';
  availablePayouts?: Maybe<Scalars['Float']>;
  payoutsBreakdown?: Maybe<Array<Maybe<Scalars['Float']>>>;
  totalPayouts?: Maybe<Scalars['Float']>;
};

export type CaForumItemsReturn = {
  __typename?: 'CAForumItemsReturn';
  nextPosts: Scalars['Int'];
  nextReported: Scalars['Int'];
  posts: Array<ForumPost>;
  reports: Array<ForumContentReport>;
  totalPending: Scalars['Int'];
  totalPosts: Scalars['Int'];
  totalReported: Scalars['Int'];
};

export type CaLinkStripeAccountPayload = {
  __typename?: 'CALinkStripeAccountPayload';
  url?: Maybe<Scalars['String']>;
};

export type CaLoginResponse = {
  __typename?: 'CALoginResponse';
  communities: Array<Community>;
  token?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type CaMassMessageUpdateInput = {
  eventId?: InputMaybe<Scalars['String']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<Scalars['String']>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CaProductCreateOrUpdateInput = {
  badgeMediaUrl?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type CaProductsReturn = {
  __typename?: 'CAProductsReturn';
  items: Array<Product>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaScheduleItemCreateOrUpdateInput = {
  end?: InputMaybe<Scalars['DateTime']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  stageName?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type CaStripeAccountInfoPayload = {
  __typename?: 'CAStripeAccountInfoPayload';
  chargesEnabled?: Maybe<Scalars['String']>;
  payoutsEnabled?: Maybe<Scalars['String']>;
  requirements?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CaTalksChat = {
  __typename?: 'CATalksChat';
  avatar: Scalars['String'];
  fullName?: Maybe<Scalars['String']>;
  lastMessage: Scalars['String'];
  lastMessageTime: Scalars['DateTime'];
  lastMine: Scalars['Boolean'];
  numberUnread: Scalars['Int'];
  userId: Scalars['String'];
  username: Scalars['String'];
};

export type CaTalksChatResponse = {
  __typename?: 'CATalksChatResponse';
  avatar: Scalars['String'];
  items: Array<TalksMessage>;
  next: Scalars['Int'];
  total: Scalars['Int'];
  username: Scalars['String'];
};

export type CaTalksChatsResponse = {
  __typename?: 'CATalksChatsResponse';
  chats?: Maybe<Array<Maybe<CaTalksChat>>>;
  unreadCount: Scalars['Int'];
};

export type CaUnlinkStripeAccountPayload = {
  __typename?: 'CAUnlinkStripeAccountPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type Cta = {
  __typename?: 'CTA';
  frequency: Scalars['String'];
  link: Scalars['String'];
  style: Scalars['String'];
  text: Scalars['String'];
};

export type CaPromocodeUpdateInput = {
  code?: InputMaybe<Scalars['String']>;
  discountFixed?: InputMaybe<Scalars['Int']>;
  discountOverridePrice?: InputMaybe<Scalars['Int']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['String']>;
  limitPerUser?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type CalendarEventsResponse = {
  __typename?: 'CalendarEventsResponse';
  items: Array<Event>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CatalogItem = {
  __typename?: 'CatalogItem';
  categories: Array<CatalogItemCategory>;
  description: Scalars['String'];
  id: Scalars['String'];
  instagramUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity?: Maybe<Community>;
  parentCommunityId?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  schedule: Array<CatalogItemSchedule>;
  soundcloudUrl?: Maybe<Scalars['String']>;
  spotifyUrl?: Maybe<Scalars['String']>;
  twitterUrl?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
  youtubeUrl?: Maybe<Scalars['String']>;
};


export type CatalogItemCategoriesArgs = {
  cursor?: InputMaybe<CatalogItemCategoryWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type CatalogItemScheduleArgs = {
  cursor?: InputMaybe<CatalogItemScheduleWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CatalogItemScheduleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type CatalogItemCategory = {
  __typename?: 'CatalogItemCategory';
  catalogItems: Array<CatalogItem>;
  id: Scalars['String'];
  name: Scalars['String'];
};


export type CatalogItemCategoryCatalogItemsArgs = {
  cursor?: InputMaybe<CatalogItemWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type CatalogItemCategoryCreateInput = {
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutCategoriesInput>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CatalogItemCategoryCreateNestedManyWithoutCatalogItemsInput = {
  connect?: InputMaybe<Array<CatalogItemCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemCategoryCreateOrConnectWithoutCatalogItemsInput>>;
  create?: InputMaybe<Array<CatalogItemCategoryCreateWithoutCatalogItemsInput>>;
};

export type CatalogItemCategoryCreateOrConnectWithoutCatalogItemsInput = {
  create: CatalogItemCategoryCreateWithoutCatalogItemsInput;
  where: CatalogItemCategoryWhereUniqueInput;
};

export type CatalogItemCategoryCreateWithoutCatalogItemsInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CatalogItemCategoryListRelationFilter = {
  every?: InputMaybe<CatalogItemCategoryWhereInput>;
  none?: InputMaybe<CatalogItemCategoryWhereInput>;
  some?: InputMaybe<CatalogItemCategoryWhereInput>;
};

export type CatalogItemCategoryOrderByInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type CatalogItemCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<CatalogItemCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<CatalogItemCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<CatalogItemCategoryScalarWhereInput>>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type CatalogItemCategoryUpdateInput = {
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutCategoriesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CatalogItemCategoryUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CatalogItemCategoryUpdateManyWithWhereWithoutCatalogItemsInput = {
  data: CatalogItemCategoryUpdateManyMutationInput;
  where: CatalogItemCategoryScalarWhereInput;
};

export type CatalogItemCategoryUpdateManyWithoutCatalogItemsInput = {
  connect?: InputMaybe<Array<CatalogItemCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemCategoryCreateOrConnectWithoutCatalogItemsInput>>;
  create?: InputMaybe<Array<CatalogItemCategoryCreateWithoutCatalogItemsInput>>;
  delete?: InputMaybe<Array<CatalogItemCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CatalogItemCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CatalogItemCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<CatalogItemCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<CatalogItemCategoryUpdateWithWhereUniqueWithoutCatalogItemsInput>>;
  updateMany?: InputMaybe<Array<CatalogItemCategoryUpdateManyWithWhereWithoutCatalogItemsInput>>;
  upsert?: InputMaybe<Array<CatalogItemCategoryUpsertWithWhereUniqueWithoutCatalogItemsInput>>;
};

export type CatalogItemCategoryUpdateWithWhereUniqueWithoutCatalogItemsInput = {
  data: CatalogItemCategoryUpdateWithoutCatalogItemsInput;
  where: CatalogItemCategoryWhereUniqueInput;
};

export type CatalogItemCategoryUpdateWithoutCatalogItemsInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type CatalogItemCategoryUpsertWithWhereUniqueWithoutCatalogItemsInput = {
  create: CatalogItemCategoryCreateWithoutCatalogItemsInput;
  update: CatalogItemCategoryUpdateWithoutCatalogItemsInput;
  where: CatalogItemCategoryWhereUniqueInput;
};

export type CatalogItemCategoryWhereInput = {
  AND?: InputMaybe<Array<CatalogItemCategoryWhereInput>>;
  NOT?: InputMaybe<Array<CatalogItemCategoryWhereInput>>;
  OR?: InputMaybe<Array<CatalogItemCategoryWhereInput>>;
  catalogItems?: InputMaybe<CatalogItemListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type CatalogItemCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CatalogItemCreateInput = {
  categories?: InputMaybe<CatalogItemCategoryCreateNestedManyWithoutCatalogItemsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutCatalogItemsInput>;
  photoUrl?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutArtistInput>;
  soundcloudUrl?: InputMaybe<Scalars['String']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

export type CatalogItemCreateManyParentCommunityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  soundcloudUrl?: InputMaybe<Scalars['String']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

export type CatalogItemCreateManyParentCommunityInputEnvelope = {
  data?: InputMaybe<Array<CatalogItemCreateManyParentCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CatalogItemCreateNestedManyWithoutCategoriesInput = {
  connect?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemCreateOrConnectWithoutCategoriesInput>>;
  create?: InputMaybe<Array<CatalogItemCreateWithoutCategoriesInput>>;
};

export type CatalogItemCreateNestedManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<CatalogItemCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<CatalogItemCreateManyParentCommunityInputEnvelope>;
};

export type CatalogItemCreateNestedOneWithoutScheduleInput = {
  connect?: InputMaybe<CatalogItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CatalogItemCreateOrConnectWithoutScheduleInput>;
  create?: InputMaybe<CatalogItemCreateWithoutScheduleInput>;
};

export type CatalogItemCreateOrConnectWithoutCategoriesInput = {
  create: CatalogItemCreateWithoutCategoriesInput;
  where: CatalogItemWhereUniqueInput;
};

export type CatalogItemCreateOrConnectWithoutParentCommunityInput = {
  create: CatalogItemCreateWithoutParentCommunityInput;
  where: CatalogItemWhereUniqueInput;
};

export type CatalogItemCreateOrConnectWithoutScheduleInput = {
  create: CatalogItemCreateWithoutScheduleInput;
  where: CatalogItemWhereUniqueInput;
};

export type CatalogItemCreateWithoutCategoriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutCatalogItemsInput>;
  photoUrl?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutArtistInput>;
  soundcloudUrl?: InputMaybe<Scalars['String']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

export type CatalogItemCreateWithoutParentCommunityInput = {
  categories?: InputMaybe<CatalogItemCategoryCreateNestedManyWithoutCatalogItemsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  photoUrl?: InputMaybe<Scalars['String']>;
  schedule?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutArtistInput>;
  soundcloudUrl?: InputMaybe<Scalars['String']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

export type CatalogItemCreateWithoutScheduleInput = {
  categories?: InputMaybe<CatalogItemCategoryCreateNestedManyWithoutCatalogItemsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  instagramUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutCatalogItemsInput>;
  photoUrl?: InputMaybe<Scalars['String']>;
  soundcloudUrl?: InputMaybe<Scalars['String']>;
  spotifyUrl?: InputMaybe<Scalars['String']>;
  twitterUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  videoUrl?: InputMaybe<Scalars['String']>;
  youtubeUrl?: InputMaybe<Scalars['String']>;
};

export type CatalogItemListRelationFilter = {
  every?: InputMaybe<CatalogItemWhereInput>;
  none?: InputMaybe<CatalogItemWhereInput>;
  some?: InputMaybe<CatalogItemWhereInput>;
};

export type CatalogItemOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  instagramUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentCommunityId?: InputMaybe<SortOrder>;
  photoUrl?: InputMaybe<SortOrder>;
  soundcloudUrl?: InputMaybe<SortOrder>;
  spotifyUrl?: InputMaybe<SortOrder>;
  twitterUrl?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  videoUrl?: InputMaybe<SortOrder>;
  youtubeUrl?: InputMaybe<SortOrder>;
};

export type CatalogItemScalarWhereInput = {
  AND?: InputMaybe<Array<CatalogItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<CatalogItemScalarWhereInput>>;
  OR?: InputMaybe<Array<CatalogItemScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  instagramUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCommunityId?: InputMaybe<StringNullableFilter>;
  photoUrl?: InputMaybe<StringNullableFilter>;
  soundcloudUrl?: InputMaybe<StringNullableFilter>;
  spotifyUrl?: InputMaybe<StringNullableFilter>;
  twitterUrl?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoUrl?: InputMaybe<StringNullableFilter>;
  youtubeUrl?: InputMaybe<StringNullableFilter>;
};

export type CatalogItemSchedule = {
  __typename?: 'CatalogItemSchedule';
  artist?: Maybe<CatalogItem>;
  artistId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity?: Maybe<Community>;
  parentCommunityId?: Maybe<Scalars['String']>;
  publishingStatus: CatalogItemSchedulePublishingStatus;
  stageName: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
};

export type CatalogItemScheduleCreateInput = {
  artist?: InputMaybe<CatalogItemCreateNestedOneWithoutScheduleInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutCatalogSchedulesInput>;
  publishingStatus?: InputMaybe<CatalogItemSchedulePublishingStatus>;
  stageName?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CatalogItemScheduleCreateManyArtistInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunityId?: InputMaybe<Scalars['String']>;
  publishingStatus?: InputMaybe<CatalogItemSchedulePublishingStatus>;
  stageName?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CatalogItemScheduleCreateManyArtistInputEnvelope = {
  data?: InputMaybe<Array<CatalogItemScheduleCreateManyArtistInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CatalogItemScheduleCreateManyParentCommunityInput = {
  artistId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishingStatus?: InputMaybe<CatalogItemSchedulePublishingStatus>;
  stageName?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CatalogItemScheduleCreateManyParentCommunityInputEnvelope = {
  data?: InputMaybe<Array<CatalogItemScheduleCreateManyParentCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CatalogItemScheduleCreateNestedManyWithoutArtistInput = {
  connect?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemScheduleCreateOrConnectWithoutArtistInput>>;
  create?: InputMaybe<Array<CatalogItemScheduleCreateWithoutArtistInput>>;
  createMany?: InputMaybe<CatalogItemScheduleCreateManyArtistInputEnvelope>;
};

export type CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemScheduleCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<CatalogItemScheduleCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<CatalogItemScheduleCreateManyParentCommunityInputEnvelope>;
};

export type CatalogItemScheduleCreateOrConnectWithoutArtistInput = {
  create: CatalogItemScheduleCreateWithoutArtistInput;
  where: CatalogItemScheduleWhereUniqueInput;
};

export type CatalogItemScheduleCreateOrConnectWithoutParentCommunityInput = {
  create: CatalogItemScheduleCreateWithoutParentCommunityInput;
  where: CatalogItemScheduleWhereUniqueInput;
};

export type CatalogItemScheduleCreateWithoutArtistInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutCatalogSchedulesInput>;
  publishingStatus?: InputMaybe<CatalogItemSchedulePublishingStatus>;
  stageName?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CatalogItemScheduleCreateWithoutParentCommunityInput = {
  artist?: InputMaybe<CatalogItemCreateNestedOneWithoutScheduleInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishingStatus?: InputMaybe<CatalogItemSchedulePublishingStatus>;
  stageName?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CatalogItemScheduleListRelationFilter = {
  every?: InputMaybe<CatalogItemScheduleWhereInput>;
  none?: InputMaybe<CatalogItemScheduleWhereInput>;
  some?: InputMaybe<CatalogItemScheduleWhereInput>;
};

export type CatalogItemScheduleOrderByInput = {
  artistId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mediaUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentCommunityId?: InputMaybe<SortOrder>;
  publishingStatus?: InputMaybe<SortOrder>;
  stageName?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum CatalogItemSchedulePublishingStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Draft = 'Draft',
  UneditedDraft = 'UneditedDraft'
}

export type CatalogItemScheduleScalarWhereInput = {
  AND?: InputMaybe<Array<CatalogItemScheduleScalarWhereInput>>;
  NOT?: InputMaybe<Array<CatalogItemScheduleScalarWhereInput>>;
  OR?: InputMaybe<Array<CatalogItemScheduleScalarWhereInput>>;
  artistId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCommunityId?: InputMaybe<StringNullableFilter>;
  publishingStatus?: InputMaybe<EnumCatalogItemSchedulePublishingStatusFilter>;
  stageName?: InputMaybe<StringFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CatalogItemScheduleUpdateInput = {
  artist?: InputMaybe<CatalogItemUpdateOneWithoutScheduleInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutCatalogSchedulesInput>;
  publishingStatus?: InputMaybe<EnumCatalogItemSchedulePublishingStatusFieldUpdateOperationsInput>;
  stageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CatalogItemScheduleUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumCatalogItemSchedulePublishingStatusFieldUpdateOperationsInput>;
  stageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CatalogItemScheduleUpdateManyWithWhereWithoutArtistInput = {
  data: CatalogItemScheduleUpdateManyMutationInput;
  where: CatalogItemScheduleScalarWhereInput;
};

export type CatalogItemScheduleUpdateManyWithWhereWithoutParentCommunityInput = {
  data: CatalogItemScheduleUpdateManyMutationInput;
  where: CatalogItemScheduleScalarWhereInput;
};

export type CatalogItemScheduleUpdateManyWithoutArtistInput = {
  connect?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemScheduleCreateOrConnectWithoutArtistInput>>;
  create?: InputMaybe<Array<CatalogItemScheduleCreateWithoutArtistInput>>;
  createMany?: InputMaybe<CatalogItemScheduleCreateManyArtistInputEnvelope>;
  delete?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CatalogItemScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  update?: InputMaybe<Array<CatalogItemScheduleUpdateWithWhereUniqueWithoutArtistInput>>;
  updateMany?: InputMaybe<Array<CatalogItemScheduleUpdateManyWithWhereWithoutArtistInput>>;
  upsert?: InputMaybe<Array<CatalogItemScheduleUpsertWithWhereUniqueWithoutArtistInput>>;
};

export type CatalogItemScheduleUpdateManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemScheduleCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<CatalogItemScheduleCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<CatalogItemScheduleCreateManyParentCommunityInputEnvelope>;
  delete?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CatalogItemScheduleScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  set?: InputMaybe<Array<CatalogItemScheduleWhereUniqueInput>>;
  update?: InputMaybe<Array<CatalogItemScheduleUpdateWithWhereUniqueWithoutParentCommunityInput>>;
  updateMany?: InputMaybe<Array<CatalogItemScheduleUpdateManyWithWhereWithoutParentCommunityInput>>;
  upsert?: InputMaybe<Array<CatalogItemScheduleUpsertWithWhereUniqueWithoutParentCommunityInput>>;
};

export type CatalogItemScheduleUpdateWithWhereUniqueWithoutArtistInput = {
  data: CatalogItemScheduleUpdateWithoutArtistInput;
  where: CatalogItemScheduleWhereUniqueInput;
};

export type CatalogItemScheduleUpdateWithWhereUniqueWithoutParentCommunityInput = {
  data: CatalogItemScheduleUpdateWithoutParentCommunityInput;
  where: CatalogItemScheduleWhereUniqueInput;
};

export type CatalogItemScheduleUpdateWithoutArtistInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutCatalogSchedulesInput>;
  publishingStatus?: InputMaybe<EnumCatalogItemSchedulePublishingStatusFieldUpdateOperationsInput>;
  stageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CatalogItemScheduleUpdateWithoutParentCommunityInput = {
  artist?: InputMaybe<CatalogItemUpdateOneWithoutScheduleInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumCatalogItemSchedulePublishingStatusFieldUpdateOperationsInput>;
  stageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CatalogItemScheduleUpsertWithWhereUniqueWithoutArtistInput = {
  create: CatalogItemScheduleCreateWithoutArtistInput;
  update: CatalogItemScheduleUpdateWithoutArtistInput;
  where: CatalogItemScheduleWhereUniqueInput;
};

export type CatalogItemScheduleUpsertWithWhereUniqueWithoutParentCommunityInput = {
  create: CatalogItemScheduleCreateWithoutParentCommunityInput;
  update: CatalogItemScheduleUpdateWithoutParentCommunityInput;
  where: CatalogItemScheduleWhereUniqueInput;
};

export type CatalogItemScheduleWhereInput = {
  AND?: InputMaybe<Array<CatalogItemScheduleWhereInput>>;
  NOT?: InputMaybe<Array<CatalogItemScheduleWhereInput>>;
  OR?: InputMaybe<Array<CatalogItemScheduleWhereInput>>;
  artist?: InputMaybe<CatalogItemWhereInput>;
  artistId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCommunity?: InputMaybe<CommunityWhereInput>;
  parentCommunityId?: InputMaybe<StringNullableFilter>;
  publishingStatus?: InputMaybe<EnumCatalogItemSchedulePublishingStatusFilter>;
  stageName?: InputMaybe<StringFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CatalogItemScheduleWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CatalogItemUpdateInput = {
  categories?: InputMaybe<CatalogItemCategoryUpdateManyWithoutCatalogItemsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagramUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutCatalogItemsInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schedule?: InputMaybe<CatalogItemScheduleUpdateManyWithoutArtistInput>;
  soundcloudUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spotifyUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twitterUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  youtubeUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CatalogItemUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagramUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  soundcloudUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spotifyUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twitterUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  youtubeUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CatalogItemUpdateManyWithWhereWithoutCategoriesInput = {
  data: CatalogItemUpdateManyMutationInput;
  where: CatalogItemScalarWhereInput;
};

export type CatalogItemUpdateManyWithWhereWithoutParentCommunityInput = {
  data: CatalogItemUpdateManyMutationInput;
  where: CatalogItemScalarWhereInput;
};

export type CatalogItemUpdateManyWithoutCategoriesInput = {
  connect?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemCreateOrConnectWithoutCategoriesInput>>;
  create?: InputMaybe<Array<CatalogItemCreateWithoutCategoriesInput>>;
  delete?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CatalogItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CatalogItemUpdateWithWhereUniqueWithoutCategoriesInput>>;
  updateMany?: InputMaybe<Array<CatalogItemUpdateManyWithWhereWithoutCategoriesInput>>;
  upsert?: InputMaybe<Array<CatalogItemUpsertWithWhereUniqueWithoutCategoriesInput>>;
};

export type CatalogItemUpdateManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CatalogItemCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<CatalogItemCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<CatalogItemCreateManyParentCommunityInputEnvelope>;
  delete?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CatalogItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  set?: InputMaybe<Array<CatalogItemWhereUniqueInput>>;
  update?: InputMaybe<Array<CatalogItemUpdateWithWhereUniqueWithoutParentCommunityInput>>;
  updateMany?: InputMaybe<Array<CatalogItemUpdateManyWithWhereWithoutParentCommunityInput>>;
  upsert?: InputMaybe<Array<CatalogItemUpsertWithWhereUniqueWithoutParentCommunityInput>>;
};

export type CatalogItemUpdateOneWithoutScheduleInput = {
  connect?: InputMaybe<CatalogItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CatalogItemCreateOrConnectWithoutScheduleInput>;
  create?: InputMaybe<CatalogItemCreateWithoutScheduleInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CatalogItemUpdateWithoutScheduleInput>;
  upsert?: InputMaybe<CatalogItemUpsertWithoutScheduleInput>;
};

export type CatalogItemUpdateWithWhereUniqueWithoutCategoriesInput = {
  data: CatalogItemUpdateWithoutCategoriesInput;
  where: CatalogItemWhereUniqueInput;
};

export type CatalogItemUpdateWithWhereUniqueWithoutParentCommunityInput = {
  data: CatalogItemUpdateWithoutParentCommunityInput;
  where: CatalogItemWhereUniqueInput;
};

export type CatalogItemUpdateWithoutCategoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagramUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutCatalogItemsInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schedule?: InputMaybe<CatalogItemScheduleUpdateManyWithoutArtistInput>;
  soundcloudUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spotifyUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twitterUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  youtubeUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CatalogItemUpdateWithoutParentCommunityInput = {
  categories?: InputMaybe<CatalogItemCategoryUpdateManyWithoutCatalogItemsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagramUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  schedule?: InputMaybe<CatalogItemScheduleUpdateManyWithoutArtistInput>;
  soundcloudUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spotifyUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twitterUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  youtubeUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CatalogItemUpdateWithoutScheduleInput = {
  categories?: InputMaybe<CatalogItemCategoryUpdateManyWithoutCatalogItemsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  instagramUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutCatalogItemsInput>;
  photoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  soundcloudUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  spotifyUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  twitterUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  videoUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  youtubeUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CatalogItemUpsertWithWhereUniqueWithoutCategoriesInput = {
  create: CatalogItemCreateWithoutCategoriesInput;
  update: CatalogItemUpdateWithoutCategoriesInput;
  where: CatalogItemWhereUniqueInput;
};

export type CatalogItemUpsertWithWhereUniqueWithoutParentCommunityInput = {
  create: CatalogItemCreateWithoutParentCommunityInput;
  update: CatalogItemUpdateWithoutParentCommunityInput;
  where: CatalogItemWhereUniqueInput;
};

export type CatalogItemUpsertWithoutScheduleInput = {
  create: CatalogItemCreateWithoutScheduleInput;
  update: CatalogItemUpdateWithoutScheduleInput;
};

export type CatalogItemWhereInput = {
  AND?: InputMaybe<Array<CatalogItemWhereInput>>;
  NOT?: InputMaybe<Array<CatalogItemWhereInput>>;
  OR?: InputMaybe<Array<CatalogItemWhereInput>>;
  categories?: InputMaybe<CatalogItemCategoryListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  instagramUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCommunity?: InputMaybe<CommunityWhereInput>;
  parentCommunityId?: InputMaybe<StringNullableFilter>;
  photoUrl?: InputMaybe<StringNullableFilter>;
  schedule?: InputMaybe<CatalogItemScheduleListRelationFilter>;
  soundcloudUrl?: InputMaybe<StringNullableFilter>;
  spotifyUrl?: InputMaybe<StringNullableFilter>;
  twitterUrl?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  videoUrl?: InputMaybe<StringNullableFilter>;
  youtubeUrl?: InputMaybe<StringNullableFilter>;
};

export type CatalogItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Chat = {
  __typename?: 'Chat';
  id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ClaimedBadge = {
  __typename?: 'ClaimedBadge';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  owner: User;
  ownerId: Scalars['String'];
  purchase?: Maybe<Purchase>;
  purchaseId?: Maybe<Scalars['String']>;
  template: TemplateBadge;
  templateId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ClaimedBadgeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutBadgesInput;
  purchase?: InputMaybe<PurchaseCreateNestedOneWithoutBadgeInput>;
  template: TemplateBadgeCreateNestedOneWithoutClaimedBadgesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClaimedBadgeCreateManyOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  purchaseId?: InputMaybe<Scalars['String']>;
  templateId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClaimedBadgeCreateManyOwnerInputEnvelope = {
  data?: InputMaybe<Array<ClaimedBadgeCreateManyOwnerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClaimedBadgeCreateManyTemplateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  purchaseId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClaimedBadgeCreateManyTemplateInputEnvelope = {
  data?: InputMaybe<Array<ClaimedBadgeCreateManyTemplateInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ClaimedBadgeCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClaimedBadgeCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<ClaimedBadgeCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<ClaimedBadgeCreateManyOwnerInputEnvelope>;
};

export type ClaimedBadgeCreateNestedManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClaimedBadgeCreateOrConnectWithoutTemplateInput>>;
  create?: InputMaybe<Array<ClaimedBadgeCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<ClaimedBadgeCreateManyTemplateInputEnvelope>;
};

export type ClaimedBadgeCreateNestedOneWithoutPurchaseInput = {
  connect?: InputMaybe<ClaimedBadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClaimedBadgeCreateOrConnectWithoutPurchaseInput>;
  create?: InputMaybe<ClaimedBadgeCreateWithoutPurchaseInput>;
};

export type ClaimedBadgeCreateOrConnectWithoutOwnerInput = {
  create: ClaimedBadgeCreateWithoutOwnerInput;
  where: ClaimedBadgeWhereUniqueInput;
};

export type ClaimedBadgeCreateOrConnectWithoutPurchaseInput = {
  create: ClaimedBadgeCreateWithoutPurchaseInput;
  where: ClaimedBadgeWhereUniqueInput;
};

export type ClaimedBadgeCreateOrConnectWithoutTemplateInput = {
  create: ClaimedBadgeCreateWithoutTemplateInput;
  where: ClaimedBadgeWhereUniqueInput;
};

export type ClaimedBadgeCreateWithoutOwnerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  purchase?: InputMaybe<PurchaseCreateNestedOneWithoutBadgeInput>;
  template: TemplateBadgeCreateNestedOneWithoutClaimedBadgesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClaimedBadgeCreateWithoutPurchaseInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutBadgesInput;
  template: TemplateBadgeCreateNestedOneWithoutClaimedBadgesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClaimedBadgeCreateWithoutTemplateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  owner: UserCreateNestedOneWithoutBadgesInput;
  purchase?: InputMaybe<PurchaseCreateNestedOneWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ClaimedBadgeListRelationFilter = {
  every?: InputMaybe<ClaimedBadgeWhereInput>;
  none?: InputMaybe<ClaimedBadgeWhereInput>;
  some?: InputMaybe<ClaimedBadgeWhereInput>;
};

export type ClaimedBadgeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  purchaseId?: InputMaybe<SortOrder>;
  templateId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ClaimedBadgeScalarWhereInput = {
  AND?: InputMaybe<Array<ClaimedBadgeScalarWhereInput>>;
  NOT?: InputMaybe<Array<ClaimedBadgeScalarWhereInput>>;
  OR?: InputMaybe<Array<ClaimedBadgeScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringFilter>;
  purchaseId?: InputMaybe<StringNullableFilter>;
  templateId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ClaimedBadgeUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutBadgesInput>;
  purchase?: InputMaybe<PurchaseUpdateOneWithoutBadgeInput>;
  template?: InputMaybe<TemplateBadgeUpdateOneRequiredWithoutClaimedBadgesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClaimedBadgeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClaimedBadgeUpdateManyWithWhereWithoutOwnerInput = {
  data: ClaimedBadgeUpdateManyMutationInput;
  where: ClaimedBadgeScalarWhereInput;
};

export type ClaimedBadgeUpdateManyWithWhereWithoutTemplateInput = {
  data: ClaimedBadgeUpdateManyMutationInput;
  where: ClaimedBadgeScalarWhereInput;
};

export type ClaimedBadgeUpdateManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClaimedBadgeCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<ClaimedBadgeCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<ClaimedBadgeCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClaimedBadgeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  set?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  update?: InputMaybe<Array<ClaimedBadgeUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<ClaimedBadgeUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<ClaimedBadgeUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type ClaimedBadgeUpdateManyWithoutTemplateInput = {
  connect?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ClaimedBadgeCreateOrConnectWithoutTemplateInput>>;
  create?: InputMaybe<Array<ClaimedBadgeCreateWithoutTemplateInput>>;
  createMany?: InputMaybe<ClaimedBadgeCreateManyTemplateInputEnvelope>;
  delete?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ClaimedBadgeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  set?: InputMaybe<Array<ClaimedBadgeWhereUniqueInput>>;
  update?: InputMaybe<Array<ClaimedBadgeUpdateWithWhereUniqueWithoutTemplateInput>>;
  updateMany?: InputMaybe<Array<ClaimedBadgeUpdateManyWithWhereWithoutTemplateInput>>;
  upsert?: InputMaybe<Array<ClaimedBadgeUpsertWithWhereUniqueWithoutTemplateInput>>;
};

export type ClaimedBadgeUpdateOneWithoutPurchaseInput = {
  connect?: InputMaybe<ClaimedBadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ClaimedBadgeCreateOrConnectWithoutPurchaseInput>;
  create?: InputMaybe<ClaimedBadgeCreateWithoutPurchaseInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ClaimedBadgeUpdateWithoutPurchaseInput>;
  upsert?: InputMaybe<ClaimedBadgeUpsertWithoutPurchaseInput>;
};

export type ClaimedBadgeUpdateWithWhereUniqueWithoutOwnerInput = {
  data: ClaimedBadgeUpdateWithoutOwnerInput;
  where: ClaimedBadgeWhereUniqueInput;
};

export type ClaimedBadgeUpdateWithWhereUniqueWithoutTemplateInput = {
  data: ClaimedBadgeUpdateWithoutTemplateInput;
  where: ClaimedBadgeWhereUniqueInput;
};

export type ClaimedBadgeUpdateWithoutOwnerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  purchase?: InputMaybe<PurchaseUpdateOneWithoutBadgeInput>;
  template?: InputMaybe<TemplateBadgeUpdateOneRequiredWithoutClaimedBadgesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClaimedBadgeUpdateWithoutPurchaseInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutBadgesInput>;
  template?: InputMaybe<TemplateBadgeUpdateOneRequiredWithoutClaimedBadgesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClaimedBadgeUpdateWithoutTemplateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutBadgesInput>;
  purchase?: InputMaybe<PurchaseUpdateOneWithoutBadgeInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ClaimedBadgeUpsertWithWhereUniqueWithoutOwnerInput = {
  create: ClaimedBadgeCreateWithoutOwnerInput;
  update: ClaimedBadgeUpdateWithoutOwnerInput;
  where: ClaimedBadgeWhereUniqueInput;
};

export type ClaimedBadgeUpsertWithWhereUniqueWithoutTemplateInput = {
  create: ClaimedBadgeCreateWithoutTemplateInput;
  update: ClaimedBadgeUpdateWithoutTemplateInput;
  where: ClaimedBadgeWhereUniqueInput;
};

export type ClaimedBadgeUpsertWithoutPurchaseInput = {
  create: ClaimedBadgeCreateWithoutPurchaseInput;
  update: ClaimedBadgeUpdateWithoutPurchaseInput;
};

export type ClaimedBadgeWhereInput = {
  AND?: InputMaybe<Array<ClaimedBadgeWhereInput>>;
  NOT?: InputMaybe<Array<ClaimedBadgeWhereInput>>;
  OR?: InputMaybe<Array<ClaimedBadgeWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserWhereInput>;
  ownerId?: InputMaybe<StringFilter>;
  purchase?: InputMaybe<PurchaseWhereInput>;
  purchaseId?: InputMaybe<StringNullableFilter>;
  template?: InputMaybe<TemplateBadgeWhereInput>;
  templateId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ClaimedBadgeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  purchaseId?: InputMaybe<Scalars['String']>;
};

export type Community = {
  __typename?: 'Community';
  adminRole?: Maybe<Scalars['String']>;
  admins: Array<User>;
  adminsCount: Scalars['Int'];
  availableModules?: Maybe<Array<Maybe<Scalars['String']>>>;
  availablePaymentMethods: Array<Scalars['String']>;
  backgroundColor: Scalars['String'];
  backgroundColorAndroid: Scalars['String'];
  backgroundLength: Scalars['Int'];
  backgroundLengthAndroid: Scalars['Int'];
  backgroundType: BackgroundType;
  backgroundTypeAndroid?: Maybe<BackgroundType>;
  code?: Maybe<Scalars['String']>;
  configs?: Maybe<Scalars['Json']>;
  country: SupportedCountries;
  createdAt: Scalars['DateTime'];
  currency: SupportedCurrencies;
  description?: Maybe<Scalars['String']>;
  enableModulesV2: Scalars['Boolean'];
  enableStylePolling: Scalars['Boolean'];
  events: Array<Event>;
  globalInviteCode?: Maybe<Scalars['String']>;
  globalTicketsLink?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  landingCode?: Maybe<Scalars['String']>;
  landingLink?: Maybe<Scalars['String']>;
  links?: Maybe<Scalars['Json']>;
  location?: Maybe<Scalars['String']>;
  memberSince?: Maybe<Scalars['Date']>;
  members: Array<User>;
  membersCount?: Maybe<Scalars['Int']>;
  minAppVersion: Scalars['String'];
  modulesDesign?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  numPersonalizedInviteCodes: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
  paymentFees: ProductFees;
  requestToJoin: Scalars['Boolean'];
  requireVerifiedEmailForShopAccess: Scalars['Boolean'];
  requiredUserFields: Array<UserInfoField>;
  sawDisclaimer?: Maybe<Scalars['Boolean']>;
  stripeConnectedAccountId?: Maybe<Scalars['String']>;
  styleOverride?: Maybe<Scalars['JSON']>;
  swishPayees?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  visibleInLocations: Array<Location>;
};


export type CommunityAdminsArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type CommunityEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type CommunityMembersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type CommunityVisibleInLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type CommunityCreateInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateNestedManyWithoutAdminsInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutAdminsInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutAdminsInput>>;
};

export type CommunityCreateNestedManyWithoutMembersInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutMembersInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutMembersInput>>;
};

export type CommunityCreateNestedManyWithoutRelatedEventsInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutRelatedEventsInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutRelatedEventsInput>>;
};

export type CommunityCreateNestedManyWithoutRelatedFeedItemsInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutRelatedFeedItemsInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutRelatedFeedItemsInput>>;
};

export type CommunityCreateNestedManyWithoutRequestedMembersInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutRequestedMembersInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutRequestedMembersInput>>;
};

export type CommunityCreateNestedManyWithoutVisibleInLocationsInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutVisibleInLocationsInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutVisibleInLocationsInput>>;
};

export type CommunityCreateNestedOneWithoutBadgesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutBadgesInput>;
  create?: InputMaybe<CommunityCreateWithoutBadgesInput>;
};

export type CommunityCreateNestedOneWithoutCatalogItemsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutCatalogItemsInput>;
  create?: InputMaybe<CommunityCreateWithoutCatalogItemsInput>;
};

export type CommunityCreateNestedOneWithoutCatalogSchedulesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutCatalogSchedulesInput>;
  create?: InputMaybe<CommunityCreateWithoutCatalogSchedulesInput>;
};

export type CommunityCreateNestedOneWithoutEventsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutEventsInput>;
  create?: InputMaybe<CommunityCreateWithoutEventsInput>;
};

export type CommunityCreateNestedOneWithoutFaqItemsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutFaqItemsInput>;
  create?: InputMaybe<CommunityCreateWithoutFaqItemsInput>;
};

export type CommunityCreateNestedOneWithoutFeedItemInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutFeedItemInput>;
  create?: InputMaybe<CommunityCreateWithoutFeedItemInput>;
};

export type CommunityCreateNestedOneWithoutForumPostsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutForumPostsInput>;
  create?: InputMaybe<CommunityCreateWithoutForumPostsInput>;
};

export type CommunityCreateNestedOneWithoutForumTagsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutForumTagsInput>;
  create?: InputMaybe<CommunityCreateWithoutForumTagsInput>;
};

export type CommunityCreateNestedOneWithoutMembershipInfoInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutMembershipInfoInput>;
  create?: InputMaybe<CommunityCreateWithoutMembershipInfoInput>;
};

export type CommunityCreateNestedOneWithoutModuleConfigurationsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutModuleConfigurationsInput>;
  create?: InputMaybe<CommunityCreateWithoutModuleConfigurationsInput>;
};

export type CommunityCreateNestedOneWithoutOpenCallsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutOpenCallsInput>;
  create?: InputMaybe<CommunityCreateWithoutOpenCallsInput>;
};

export type CommunityCreateNestedOneWithoutPersonalizedInviteCodesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutPersonalizedInviteCodesInput>;
  create?: InputMaybe<CommunityCreateWithoutPersonalizedInviteCodesInput>;
};

export type CommunityCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<CommunityCreateWithoutProductsInput>;
};

export type CommunityCreateNestedOneWithoutPromocodesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutPromocodesInput>;
  create?: InputMaybe<CommunityCreateWithoutPromocodesInput>;
};

export type CommunityCreateNestedOneWithoutRelatedHighlightsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutRelatedHighlightsInput>;
  create?: InputMaybe<CommunityCreateWithoutRelatedHighlightsInput>;
};

export type CommunityCreateNestedOneWithoutRelatedMasssMessagesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutRelatedMasssMessagesInput>;
  create?: InputMaybe<CommunityCreateWithoutRelatedMasssMessagesInput>;
};

export type CommunityCreateNestedOneWithoutRelatedTalksMessagesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutRelatedTalksMessagesInput>;
  create?: InputMaybe<CommunityCreateWithoutRelatedTalksMessagesInput>;
};

export type CommunityCreateNestedOneWithoutTrackInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutTrackInput>;
  create?: InputMaybe<CommunityCreateWithoutTrackInput>;
};

export type CommunityCreateOrConnectWithoutAdminsInput = {
  create: CommunityCreateWithoutAdminsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutBadgesInput = {
  create: CommunityCreateWithoutBadgesInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutCatalogItemsInput = {
  create: CommunityCreateWithoutCatalogItemsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutCatalogSchedulesInput = {
  create: CommunityCreateWithoutCatalogSchedulesInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutEventsInput = {
  create: CommunityCreateWithoutEventsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutFaqItemsInput = {
  create: CommunityCreateWithoutFaqItemsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutFeedItemInput = {
  create: CommunityCreateWithoutFeedItemInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutForumPostsInput = {
  create: CommunityCreateWithoutForumPostsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutForumTagsInput = {
  create: CommunityCreateWithoutForumTagsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutMembersInput = {
  create: CommunityCreateWithoutMembersInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutMembershipInfoInput = {
  create: CommunityCreateWithoutMembershipInfoInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutModuleConfigurationsInput = {
  create: CommunityCreateWithoutModuleConfigurationsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutOpenCallsInput = {
  create: CommunityCreateWithoutOpenCallsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutPersonalizedInviteCodesInput = {
  create: CommunityCreateWithoutPersonalizedInviteCodesInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutProductsInput = {
  create: CommunityCreateWithoutProductsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutPromocodesInput = {
  create: CommunityCreateWithoutPromocodesInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutRelatedEventsInput = {
  create: CommunityCreateWithoutRelatedEventsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutRelatedFeedItemsInput = {
  create: CommunityCreateWithoutRelatedFeedItemsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutRelatedHighlightsInput = {
  create: CommunityCreateWithoutRelatedHighlightsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutRelatedMasssMessagesInput = {
  create: CommunityCreateWithoutRelatedMasssMessagesInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutRelatedTalksMessagesInput = {
  create: CommunityCreateWithoutRelatedTalksMessagesInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutRequestedMembersInput = {
  create: CommunityCreateWithoutRequestedMembersInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutTrackInput = {
  create: CommunityCreateWithoutTrackInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateOrConnectWithoutVisibleInLocationsInput = {
  create: CommunityCreateWithoutVisibleInLocationsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityCreateWithoutAdminsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutBadgesInput = {
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutCatalogItemsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutCatalogSchedulesInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutEventsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutFaqItemsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutFeedItemInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutForumPostsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutForumTagsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutMembersInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutMembershipInfoInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutModuleConfigurationsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutOpenCallsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutPersonalizedInviteCodesInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutProductsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutPromocodesInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutRelatedEventsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutRelatedFeedItemsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutRelatedHighlightsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutRelatedMasssMessagesInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutRelatedTalksMessagesInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutRequestedMembersInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutTrackInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  visibleInLocations?: InputMaybe<LocationCreateNestedManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateWithoutVisibleInLocationsInput = {
  Badges?: InputMaybe<TemplateBadgeCreateNestedManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemCreateNestedManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackCreateNestedManyWithoutCommunityInput>;
  admins?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityCreateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityCreateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<Scalars['String']>;
  backgroundColorAndroid?: InputMaybe<Scalars['String']>;
  backgroundLength?: InputMaybe<Scalars['Int']>;
  backgroundLengthAndroid?: InputMaybe<Scalars['Int']>;
  backgroundType?: InputMaybe<BackgroundType>;
  backgroundTypeAndroid?: InputMaybe<BackgroundType>;
  catalogItems?: InputMaybe<CatalogItemCreateNestedManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleCreateNestedManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityCreatecheckInAdminIdsInput>;
  code?: InputMaybe<Scalars['String']>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<SupportedCountries>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  description?: InputMaybe<Scalars['String']>;
  enableModulesV2?: InputMaybe<Scalars['Boolean']>;
  enableStylePolling?: InputMaybe<Scalars['Boolean']>;
  events?: InputMaybe<EventCreateNestedManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagCreateNestedManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<Scalars['String']>;
  globalTicketsLink?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  landingCode?: InputMaybe<Scalars['String']>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<Scalars['String']>;
  logoLandingUrl?: InputMaybe<Scalars['String']>;
  logoSmallUrl?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<Scalars['String']>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationCreateNestedManyWithoutCommunityInput>;
  name: Scalars['String'];
  numPersonalizedInviteCodes?: InputMaybe<Scalars['Int']>;
  openCalls?: InputMaybe<OpenCallCreateNestedManyWithoutCommunityInput>;
  order?: InputMaybe<Scalars['Int']>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutCommunityInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventCreateNestedManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageCreateNestedManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<Scalars['Boolean']>;
  requestedMembers?: InputMaybe<UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<Scalars['Boolean']>;
  requiredUserFields?: InputMaybe<CommunityCreaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<Scalars['String']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<Scalars['String']>;
  themes?: InputMaybe<CommunityThemeCreateNestedManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<Scalars['String']>;
};

export type CommunityCreateavailableModulesInput = {
  set?: InputMaybe<Array<Module>>;
};

export type CommunityCreateavailablePaymentMethodsInput = {
  set?: InputMaybe<Array<PurchasePaymentMethod>>;
};

export type CommunityCreatecheckInAdminIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityCreaterequiredUserFieldsInput = {
  set?: InputMaybe<Array<UserInfoField>>;
};

export type CommunityDailyRevenueData = {
  __typename?: 'CommunityDailyRevenueData';
  amount?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['Date']>;
  formattedDates?: Maybe<Scalars['String']>;
};

export type CommunityListRelationFilter = {
  every?: InputMaybe<CommunityWhereInput>;
  none?: InputMaybe<CommunityWhereInput>;
  some?: InputMaybe<CommunityWhereInput>;
};

export type CommunityMemberInfo = {
  __typename?: 'CommunityMemberInfo';
  community: Community;
  id?: Maybe<Scalars['String']>;
  memberSince: Scalars['DateTime'];
  sawDisclaimer: Scalars['Boolean'];
  user: User;
};

export type CommunityMemberInfoCreateInput = {
  community: CommunityCreateNestedOneWithoutMembershipInfoInput;
  memberSince?: InputMaybe<Scalars['DateTime']>;
  sawDisclaimer?: InputMaybe<Scalars['Boolean']>;
  user: UserCreateNestedOneWithoutCommunitiesMembershipInfoInput;
};

export type CommunityMemberInfoCreateManyCommunityInput = {
  memberSince?: InputMaybe<Scalars['DateTime']>;
  sawDisclaimer?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['String'];
};

export type CommunityMemberInfoCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<CommunityMemberInfoCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CommunityMemberInfoCreateManyUserInput = {
  communityId: Scalars['String'];
  memberSince?: InputMaybe<Scalars['DateTime']>;
  sawDisclaimer?: InputMaybe<Scalars['Boolean']>;
};

export type CommunityMemberInfoCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<CommunityMemberInfoCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CommunityMemberInfoCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityMemberInfoCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<CommunityMemberInfoCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<CommunityMemberInfoCreateManyCommunityInputEnvelope>;
};

export type CommunityMemberInfoCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityMemberInfoCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommunityMemberInfoCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommunityMemberInfoCreateManyUserInputEnvelope>;
};

export type CommunityMemberInfoCreateOrConnectWithoutCommunityInput = {
  create: CommunityMemberInfoCreateWithoutCommunityInput;
  where: CommunityMemberInfoWhereUniqueInput;
};

export type CommunityMemberInfoCreateOrConnectWithoutUserInput = {
  create: CommunityMemberInfoCreateWithoutUserInput;
  where: CommunityMemberInfoWhereUniqueInput;
};

export type CommunityMemberInfoCreateWithoutCommunityInput = {
  memberSince?: InputMaybe<Scalars['DateTime']>;
  sawDisclaimer?: InputMaybe<Scalars['Boolean']>;
  user: UserCreateNestedOneWithoutCommunitiesMembershipInfoInput;
};

export type CommunityMemberInfoCreateWithoutUserInput = {
  community: CommunityCreateNestedOneWithoutMembershipInfoInput;
  memberSince?: InputMaybe<Scalars['DateTime']>;
  sawDisclaimer?: InputMaybe<Scalars['Boolean']>;
};

export type CommunityMemberInfoListRelationFilter = {
  every?: InputMaybe<CommunityMemberInfoWhereInput>;
  none?: InputMaybe<CommunityMemberInfoWhereInput>;
  some?: InputMaybe<CommunityMemberInfoWhereInput>;
};

export type CommunityMemberInfoOrderByInput = {
  communityId?: InputMaybe<SortOrder>;
  memberSince?: InputMaybe<SortOrder>;
  sawDisclaimer?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CommunityMemberInfoScalarWhereInput = {
  AND?: InputMaybe<Array<CommunityMemberInfoScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommunityMemberInfoScalarWhereInput>>;
  OR?: InputMaybe<Array<CommunityMemberInfoScalarWhereInput>>;
  communityId?: InputMaybe<StringFilter>;
  memberSince?: InputMaybe<DateTimeFilter>;
  sawDisclaimer?: InputMaybe<BoolFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type CommunityMemberInfoUpdateInput = {
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutMembershipInfoInput>;
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sawDisclaimer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommunitiesMembershipInfoInput>;
};

export type CommunityMemberInfoUpdateManyMutationInput = {
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sawDisclaimer?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CommunityMemberInfoUpdateManyWithWhereWithoutCommunityInput = {
  data: CommunityMemberInfoUpdateManyMutationInput;
  where: CommunityMemberInfoScalarWhereInput;
};

export type CommunityMemberInfoUpdateManyWithWhereWithoutUserInput = {
  data: CommunityMemberInfoUpdateManyMutationInput;
  where: CommunityMemberInfoScalarWhereInput;
};

export type CommunityMemberInfoUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityMemberInfoCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<CommunityMemberInfoCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<CommunityMemberInfoCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityMemberInfoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityMemberInfoUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<CommunityMemberInfoUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<CommunityMemberInfoUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type CommunityMemberInfoUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityMemberInfoCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<CommunityMemberInfoCreateWithoutUserInput>>;
  createMany?: InputMaybe<CommunityMemberInfoCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityMemberInfoScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityMemberInfoWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityMemberInfoUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<CommunityMemberInfoUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<CommunityMemberInfoUpsertWithWhereUniqueWithoutUserInput>>;
};

export type CommunityMemberInfoUpdateWithWhereUniqueWithoutCommunityInput = {
  data: CommunityMemberInfoUpdateWithoutCommunityInput;
  where: CommunityMemberInfoWhereUniqueInput;
};

export type CommunityMemberInfoUpdateWithWhereUniqueWithoutUserInput = {
  data: CommunityMemberInfoUpdateWithoutUserInput;
  where: CommunityMemberInfoWhereUniqueInput;
};

export type CommunityMemberInfoUpdateWithoutCommunityInput = {
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sawDisclaimer?: InputMaybe<BoolFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutCommunitiesMembershipInfoInput>;
};

export type CommunityMemberInfoUpdateWithoutUserInput = {
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutMembershipInfoInput>;
  memberSince?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  sawDisclaimer?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type CommunityMemberInfoUpsertWithWhereUniqueWithoutCommunityInput = {
  create: CommunityMemberInfoCreateWithoutCommunityInput;
  update: CommunityMemberInfoUpdateWithoutCommunityInput;
  where: CommunityMemberInfoWhereUniqueInput;
};

export type CommunityMemberInfoUpsertWithWhereUniqueWithoutUserInput = {
  create: CommunityMemberInfoCreateWithoutUserInput;
  update: CommunityMemberInfoUpdateWithoutUserInput;
  where: CommunityMemberInfoWhereUniqueInput;
};

export type CommunityMemberInfoUserIdCommunityIdCompoundUniqueInput = {
  communityId: Scalars['String'];
  userId: Scalars['String'];
};

export type CommunityMemberInfoWhereInput = {
  AND?: InputMaybe<Array<CommunityMemberInfoWhereInput>>;
  NOT?: InputMaybe<Array<CommunityMemberInfoWhereInput>>;
  OR?: InputMaybe<Array<CommunityMemberInfoWhereInput>>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringFilter>;
  memberSince?: InputMaybe<DateTimeFilter>;
  sawDisclaimer?: InputMaybe<BoolFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilter>;
};

export type CommunityMemberInfoWhereUniqueInput = {
  userId_communityId?: InputMaybe<CommunityMemberInfoUserIdCommunityIdCompoundUniqueInput>;
};

export type CommunityOrderByInput = {
  availableModules?: InputMaybe<SortOrder>;
  availablePaymentMethods?: InputMaybe<SortOrder>;
  backgroundColor?: InputMaybe<SortOrder>;
  backgroundColorAndroid?: InputMaybe<SortOrder>;
  backgroundLength?: InputMaybe<SortOrder>;
  backgroundLengthAndroid?: InputMaybe<SortOrder>;
  backgroundType?: InputMaybe<SortOrder>;
  backgroundTypeAndroid?: InputMaybe<SortOrder>;
  checkInAdminIds?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  configs?: InputMaybe<SortOrder>;
  country?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  enableModulesV2?: InputMaybe<SortOrder>;
  enableStylePolling?: InputMaybe<SortOrder>;
  globalInviteCode?: InputMaybe<SortOrder>;
  globalTicketsLink?: InputMaybe<SortOrder>;
  iconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  landingCode?: InputMaybe<SortOrder>;
  links?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  logoLandingUrl?: InputMaybe<SortOrder>;
  logoSmallUrl?: InputMaybe<SortOrder>;
  minAppVersion?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  numPersonalizedInviteCodes?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  paymentFees?: InputMaybe<SortOrder>;
  requestToJoin?: InputMaybe<SortOrder>;
  requireVerifiedEmailForShopAccess?: InputMaybe<SortOrder>;
  requiredUserFields?: InputMaybe<SortOrder>;
  stripeConnectedAccountId?: InputMaybe<SortOrder>;
  styleOverride?: InputMaybe<SortOrder>;
  styleOverrideBackup?: InputMaybe<SortOrder>;
  swishPayees?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<SortOrder>;
};

export type CommunityPayload = {
  __typename?: 'CommunityPayload';
  communities?: Maybe<Array<Maybe<Community>>>;
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
};

export type CommunityScalarWhereInput = {
  AND?: InputMaybe<Array<CommunityScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommunityScalarWhereInput>>;
  OR?: InputMaybe<Array<CommunityScalarWhereInput>>;
  availableModules?: InputMaybe<EnumModuleNullableListFilter>;
  availablePaymentMethods?: InputMaybe<EnumPurchasePaymentMethodNullableListFilter>;
  backgroundColor?: InputMaybe<StringFilter>;
  backgroundColorAndroid?: InputMaybe<StringFilter>;
  backgroundLength?: InputMaybe<IntFilter>;
  backgroundLengthAndroid?: InputMaybe<IntFilter>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFilter>;
  backgroundTypeAndroid?: InputMaybe<EnumBackgroundTypeNullableFilter>;
  checkInAdminIds?: InputMaybe<StringNullableListFilter>;
  code?: InputMaybe<StringNullableFilter>;
  configs?: InputMaybe<JsonNullableFilter>;
  country?: InputMaybe<EnumSupportedCountriesFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumSupportedCurrenciesFilter>;
  description?: InputMaybe<StringNullableFilter>;
  enableModulesV2?: InputMaybe<BoolFilter>;
  enableStylePolling?: InputMaybe<BoolFilter>;
  globalInviteCode?: InputMaybe<StringNullableFilter>;
  globalTicketsLink?: InputMaybe<StringNullableFilter>;
  iconUrl?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  landingCode?: InputMaybe<StringNullableFilter>;
  links?: InputMaybe<JsonNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  logoLandingUrl?: InputMaybe<StringNullableFilter>;
  logoSmallUrl?: InputMaybe<StringNullableFilter>;
  minAppVersion?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  numPersonalizedInviteCodes?: InputMaybe<IntFilter>;
  order?: InputMaybe<IntNullableFilter>;
  paymentFees?: InputMaybe<JsonNullableFilter>;
  requestToJoin?: InputMaybe<BoolFilter>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFilter>;
  requiredUserFields?: InputMaybe<EnumUserInfoFieldNullableListFilter>;
  stripeConnectedAccountId?: InputMaybe<StringNullableFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  styleOverrideBackup?: InputMaybe<JsonNullableFilter>;
  swishPayees?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<StringNullableFilter>;
};

export type CommunityShop = {
  __typename?: 'CommunityShop';
  code: Scalars['String'];
  iconUrl: Scalars['String'];
  id: Scalars['String'];
  inAppLink: Scalars['String'];
};

export type CommunityTheme = {
  __typename?: 'CommunityTheme';
  buttonStyle?: Maybe<Scalars['Json']>;
  colorSet: Array<Scalars['String']>;
  community?: Maybe<Community>;
  createdAt: Scalars['DateTime'];
  enabledModules: Array<Module>;
  fonts: Array<Scalars['String']>;
  id: Scalars['String'];
  media: Array<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  status: CommunityThemeStatus;
  style?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};

export type CommunityThemeCreateManyCommunityInput = {
  buttonStyle?: InputMaybe<Scalars['Json']>;
  colorSet?: InputMaybe<CommunityThemeCreateManycolorSetInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  enabledModules?: InputMaybe<CommunityThemeCreateManyenabledModulesInput>;
  fonts?: InputMaybe<CommunityThemeCreateManyfontsInput>;
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<CommunityThemeCreateManymediaInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  status?: InputMaybe<CommunityThemeStatus>;
  style?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CommunityThemeCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<CommunityThemeCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type CommunityThemeCreateManycolorSetInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeCreateManyenabledModulesInput = {
  set?: InputMaybe<Array<Module>>;
};

export type CommunityThemeCreateManyfontsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeCreateManymediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<CommunityThemeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityThemeCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<CommunityThemeCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<CommunityThemeCreateManyCommunityInputEnvelope>;
};

export type CommunityThemeCreateOrConnectWithoutCommunityInput = {
  create: CommunityThemeCreateWithoutCommunityInput;
  where: CommunityThemeWhereUniqueInput;
};

export type CommunityThemeCreateWithoutCommunityInput = {
  buttonStyle?: InputMaybe<Scalars['Json']>;
  colorSet?: InputMaybe<CommunityThemeCreatecolorSetInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  enabledModules?: InputMaybe<CommunityThemeCreateenabledModulesInput>;
  fonts?: InputMaybe<CommunityThemeCreatefontsInput>;
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<CommunityThemeCreatemediaInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  status?: InputMaybe<CommunityThemeStatus>;
  style?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CommunityThemeCreatecolorSetInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeCreateenabledModulesInput = {
  set?: InputMaybe<Array<Module>>;
};

export type CommunityThemeCreatefontsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeCreatemediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeListRelationFilter = {
  every?: InputMaybe<CommunityThemeWhereInput>;
  none?: InputMaybe<CommunityThemeWhereInput>;
  some?: InputMaybe<CommunityThemeWhereInput>;
};

export type CommunityThemeScalarWhereInput = {
  AND?: InputMaybe<Array<CommunityThemeScalarWhereInput>>;
  NOT?: InputMaybe<Array<CommunityThemeScalarWhereInput>>;
  OR?: InputMaybe<Array<CommunityThemeScalarWhereInput>>;
  buttonStyle?: InputMaybe<JsonNullableFilter>;
  colorSet?: InputMaybe<StringNullableListFilter>;
  communityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  enabledModules?: InputMaybe<EnumModuleNullableListFilter>;
  fonts?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCommunityThemeStatusFilter>;
  style?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum CommunityThemeStatus {
  Active = 'Active',
  Deleted = 'Deleted',
  Draft = 'Draft'
}

export type CommunityThemeUpdateManyMutationInput = {
  buttonStyle?: InputMaybe<Scalars['Json']>;
  colorSet?: InputMaybe<CommunityThemeUpdatecolorSetInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  enabledModules?: InputMaybe<CommunityThemeUpdateenabledModulesInput>;
  fonts?: InputMaybe<CommunityThemeUpdatefontsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  media?: InputMaybe<CommunityThemeUpdatemediaInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumCommunityThemeStatusFieldUpdateOperationsInput>;
  style?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommunityThemeUpdateManyWithWhereWithoutCommunityInput = {
  data: CommunityThemeUpdateManyMutationInput;
  where: CommunityThemeScalarWhereInput;
};

export type CommunityThemeUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<CommunityThemeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityThemeCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<CommunityThemeCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<CommunityThemeCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<CommunityThemeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityThemeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityThemeWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityThemeWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityThemeUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<CommunityThemeUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<CommunityThemeUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type CommunityThemeUpdateWithWhereUniqueWithoutCommunityInput = {
  data: CommunityThemeUpdateWithoutCommunityInput;
  where: CommunityThemeWhereUniqueInput;
};

export type CommunityThemeUpdateWithoutCommunityInput = {
  buttonStyle?: InputMaybe<Scalars['Json']>;
  colorSet?: InputMaybe<CommunityThemeUpdatecolorSetInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  enabledModules?: InputMaybe<CommunityThemeUpdateenabledModulesInput>;
  fonts?: InputMaybe<CommunityThemeUpdatefontsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  media?: InputMaybe<CommunityThemeUpdatemediaInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  slug?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumCommunityThemeStatusFieldUpdateOperationsInput>;
  style?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type CommunityThemeUpdatecolorSetInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeUpdateenabledModulesInput = {
  push?: InputMaybe<Module>;
  set?: InputMaybe<Array<Module>>;
};

export type CommunityThemeUpdatefontsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeUpdatemediaInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityThemeUpsertWithWhereUniqueWithoutCommunityInput = {
  create: CommunityThemeCreateWithoutCommunityInput;
  update: CommunityThemeUpdateWithoutCommunityInput;
  where: CommunityThemeWhereUniqueInput;
};

export type CommunityThemeWhereInput = {
  AND?: InputMaybe<Array<CommunityThemeWhereInput>>;
  NOT?: InputMaybe<Array<CommunityThemeWhereInput>>;
  OR?: InputMaybe<Array<CommunityThemeWhereInput>>;
  buttonStyle?: InputMaybe<JsonNullableFilter>;
  colorSet?: InputMaybe<StringNullableListFilter>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  enabledModules?: InputMaybe<EnumModuleNullableListFilter>;
  fonts?: InputMaybe<StringNullableListFilter>;
  id?: InputMaybe<StringFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumCommunityThemeStatusFilter>;
  style?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CommunityThemeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CommunityUpdateInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateManyMutationInput = {
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateManyWithWhereWithoutAdminsInput = {
  data: CommunityUpdateManyMutationInput;
  where: CommunityScalarWhereInput;
};

export type CommunityUpdateManyWithWhereWithoutMembersInput = {
  data: CommunityUpdateManyMutationInput;
  where: CommunityScalarWhereInput;
};

export type CommunityUpdateManyWithWhereWithoutRelatedEventsInput = {
  data: CommunityUpdateManyMutationInput;
  where: CommunityScalarWhereInput;
};

export type CommunityUpdateManyWithWhereWithoutRelatedFeedItemsInput = {
  data: CommunityUpdateManyMutationInput;
  where: CommunityScalarWhereInput;
};

export type CommunityUpdateManyWithWhereWithoutRequestedMembersInput = {
  data: CommunityUpdateManyMutationInput;
  where: CommunityScalarWhereInput;
};

export type CommunityUpdateManyWithWhereWithoutVisibleInLocationsInput = {
  data: CommunityUpdateManyMutationInput;
  where: CommunityScalarWhereInput;
};

export type CommunityUpdateManyWithoutAdminsInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutAdminsInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutAdminsInput>>;
  delete?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityUpdateWithWhereUniqueWithoutAdminsInput>>;
  updateMany?: InputMaybe<Array<CommunityUpdateManyWithWhereWithoutAdminsInput>>;
  upsert?: InputMaybe<Array<CommunityUpsertWithWhereUniqueWithoutAdminsInput>>;
};

export type CommunityUpdateManyWithoutMembersInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutMembersInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutMembersInput>>;
  delete?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityUpdateWithWhereUniqueWithoutMembersInput>>;
  updateMany?: InputMaybe<Array<CommunityUpdateManyWithWhereWithoutMembersInput>>;
  upsert?: InputMaybe<Array<CommunityUpsertWithWhereUniqueWithoutMembersInput>>;
};

export type CommunityUpdateManyWithoutRelatedEventsInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutRelatedEventsInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutRelatedEventsInput>>;
  delete?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityUpdateWithWhereUniqueWithoutRelatedEventsInput>>;
  updateMany?: InputMaybe<Array<CommunityUpdateManyWithWhereWithoutRelatedEventsInput>>;
  upsert?: InputMaybe<Array<CommunityUpsertWithWhereUniqueWithoutRelatedEventsInput>>;
};

export type CommunityUpdateManyWithoutRelatedFeedItemsInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutRelatedFeedItemsInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutRelatedFeedItemsInput>>;
  delete?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityUpdateWithWhereUniqueWithoutRelatedFeedItemsInput>>;
  updateMany?: InputMaybe<Array<CommunityUpdateManyWithWhereWithoutRelatedFeedItemsInput>>;
  upsert?: InputMaybe<Array<CommunityUpsertWithWhereUniqueWithoutRelatedFeedItemsInput>>;
};

export type CommunityUpdateManyWithoutRequestedMembersInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutRequestedMembersInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutRequestedMembersInput>>;
  delete?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityUpdateWithWhereUniqueWithoutRequestedMembersInput>>;
  updateMany?: InputMaybe<Array<CommunityUpdateManyWithWhereWithoutRequestedMembersInput>>;
  upsert?: InputMaybe<Array<CommunityUpsertWithWhereUniqueWithoutRequestedMembersInput>>;
};

export type CommunityUpdateManyWithoutVisibleInLocationsInput = {
  connect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<CommunityCreateOrConnectWithoutVisibleInLocationsInput>>;
  create?: InputMaybe<Array<CommunityCreateWithoutVisibleInLocationsInput>>;
  delete?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<CommunityScalarWhereInput>>;
  disconnect?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  set?: InputMaybe<Array<CommunityWhereUniqueInput>>;
  update?: InputMaybe<Array<CommunityUpdateWithWhereUniqueWithoutVisibleInLocationsInput>>;
  updateMany?: InputMaybe<Array<CommunityUpdateManyWithWhereWithoutVisibleInLocationsInput>>;
  upsert?: InputMaybe<Array<CommunityUpsertWithWhereUniqueWithoutVisibleInLocationsInput>>;
};

export type CommunityUpdateOneRequiredWithoutBadgesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutBadgesInput>;
  create?: InputMaybe<CommunityCreateWithoutBadgesInput>;
  update?: InputMaybe<CommunityUpdateWithoutBadgesInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutBadgesInput>;
};

export type CommunityUpdateOneRequiredWithoutEventsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutEventsInput>;
  create?: InputMaybe<CommunityCreateWithoutEventsInput>;
  update?: InputMaybe<CommunityUpdateWithoutEventsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutEventsInput>;
};

export type CommunityUpdateOneRequiredWithoutFeedItemInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutFeedItemInput>;
  create?: InputMaybe<CommunityCreateWithoutFeedItemInput>;
  update?: InputMaybe<CommunityUpdateWithoutFeedItemInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutFeedItemInput>;
};

export type CommunityUpdateOneRequiredWithoutForumPostsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutForumPostsInput>;
  create?: InputMaybe<CommunityCreateWithoutForumPostsInput>;
  update?: InputMaybe<CommunityUpdateWithoutForumPostsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutForumPostsInput>;
};

export type CommunityUpdateOneRequiredWithoutForumTagsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutForumTagsInput>;
  create?: InputMaybe<CommunityCreateWithoutForumTagsInput>;
  update?: InputMaybe<CommunityUpdateWithoutForumTagsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutForumTagsInput>;
};

export type CommunityUpdateOneRequiredWithoutMembershipInfoInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutMembershipInfoInput>;
  create?: InputMaybe<CommunityCreateWithoutMembershipInfoInput>;
  update?: InputMaybe<CommunityUpdateWithoutMembershipInfoInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutMembershipInfoInput>;
};

export type CommunityUpdateOneRequiredWithoutModuleConfigurationsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutModuleConfigurationsInput>;
  create?: InputMaybe<CommunityCreateWithoutModuleConfigurationsInput>;
  update?: InputMaybe<CommunityUpdateWithoutModuleConfigurationsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutModuleConfigurationsInput>;
};

export type CommunityUpdateOneRequiredWithoutOpenCallsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutOpenCallsInput>;
  create?: InputMaybe<CommunityCreateWithoutOpenCallsInput>;
  update?: InputMaybe<CommunityUpdateWithoutOpenCallsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutOpenCallsInput>;
};

export type CommunityUpdateOneRequiredWithoutPersonalizedInviteCodesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutPersonalizedInviteCodesInput>;
  create?: InputMaybe<CommunityCreateWithoutPersonalizedInviteCodesInput>;
  update?: InputMaybe<CommunityUpdateWithoutPersonalizedInviteCodesInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutPersonalizedInviteCodesInput>;
};

export type CommunityUpdateOneRequiredWithoutProductsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<CommunityCreateWithoutProductsInput>;
  update?: InputMaybe<CommunityUpdateWithoutProductsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutProductsInput>;
};

export type CommunityUpdateOneRequiredWithoutTrackInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutTrackInput>;
  create?: InputMaybe<CommunityCreateWithoutTrackInput>;
  update?: InputMaybe<CommunityUpdateWithoutTrackInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutTrackInput>;
};

export type CommunityUpdateOneWithoutCatalogItemsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutCatalogItemsInput>;
  create?: InputMaybe<CommunityCreateWithoutCatalogItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommunityUpdateWithoutCatalogItemsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutCatalogItemsInput>;
};

export type CommunityUpdateOneWithoutCatalogSchedulesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutCatalogSchedulesInput>;
  create?: InputMaybe<CommunityCreateWithoutCatalogSchedulesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommunityUpdateWithoutCatalogSchedulesInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutCatalogSchedulesInput>;
};

export type CommunityUpdateOneWithoutFaqItemsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutFaqItemsInput>;
  create?: InputMaybe<CommunityCreateWithoutFaqItemsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommunityUpdateWithoutFaqItemsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutFaqItemsInput>;
};

export type CommunityUpdateOneWithoutPromocodesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutPromocodesInput>;
  create?: InputMaybe<CommunityCreateWithoutPromocodesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommunityUpdateWithoutPromocodesInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutPromocodesInput>;
};

export type CommunityUpdateOneWithoutRelatedHighlightsInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutRelatedHighlightsInput>;
  create?: InputMaybe<CommunityCreateWithoutRelatedHighlightsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommunityUpdateWithoutRelatedHighlightsInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutRelatedHighlightsInput>;
};

export type CommunityUpdateOneWithoutRelatedMasssMessagesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutRelatedMasssMessagesInput>;
  create?: InputMaybe<CommunityCreateWithoutRelatedMasssMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommunityUpdateWithoutRelatedMasssMessagesInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutRelatedMasssMessagesInput>;
};

export type CommunityUpdateOneWithoutRelatedTalksMessagesInput = {
  connect?: InputMaybe<CommunityWhereUniqueInput>;
  connectOrCreate?: InputMaybe<CommunityCreateOrConnectWithoutRelatedTalksMessagesInput>;
  create?: InputMaybe<CommunityCreateWithoutRelatedTalksMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<CommunityUpdateWithoutRelatedTalksMessagesInput>;
  upsert?: InputMaybe<CommunityUpsertWithoutRelatedTalksMessagesInput>;
};

export type CommunityUpdateWithWhereUniqueWithoutAdminsInput = {
  data: CommunityUpdateWithoutAdminsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpdateWithWhereUniqueWithoutMembersInput = {
  data: CommunityUpdateWithoutMembersInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpdateWithWhereUniqueWithoutRelatedEventsInput = {
  data: CommunityUpdateWithoutRelatedEventsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpdateWithWhereUniqueWithoutRelatedFeedItemsInput = {
  data: CommunityUpdateWithoutRelatedFeedItemsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpdateWithWhereUniqueWithoutRequestedMembersInput = {
  data: CommunityUpdateWithoutRequestedMembersInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpdateWithWhereUniqueWithoutVisibleInLocationsInput = {
  data: CommunityUpdateWithoutVisibleInLocationsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpdateWithoutAdminsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutBadgesInput = {
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutCatalogItemsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutCatalogSchedulesInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutEventsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutFaqItemsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutFeedItemInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutForumPostsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutForumTagsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutMembersInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutMembershipInfoInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutModuleConfigurationsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutOpenCallsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutPersonalizedInviteCodesInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutProductsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutPromocodesInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutRelatedEventsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutRelatedFeedItemsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutRelatedHighlightsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutRelatedMasssMessagesInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutRelatedTalksMessagesInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutRequestedMembersInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutTrackInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  visibleInLocations?: InputMaybe<LocationUpdateManyWithoutCommunitiesToExploreInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateWithoutVisibleInLocationsInput = {
  Badges?: InputMaybe<TemplateBadgeUpdateManyWithoutParentCommunityInput>;
  FeedItem?: InputMaybe<FeedItemUpdateManyWithoutParentCommunityInput>;
  Track?: InputMaybe<TrackUpdateManyWithoutCommunityInput>;
  admins?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereAdminInput>;
  availableModules?: InputMaybe<CommunityUpdateavailableModulesInput>;
  availablePaymentMethods?: InputMaybe<CommunityUpdateavailablePaymentMethodsInput>;
  backgroundColor?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundColorAndroid?: InputMaybe<StringFieldUpdateOperationsInput>;
  backgroundLength?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundLengthAndroid?: InputMaybe<IntFieldUpdateOperationsInput>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFieldUpdateOperationsInput>;
  backgroundTypeAndroid?: InputMaybe<NullableEnumBackgroundTypeFieldUpdateOperationsInput>;
  catalogItems?: InputMaybe<CatalogItemUpdateManyWithoutParentCommunityInput>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleUpdateManyWithoutParentCommunityInput>;
  checkInAdminIds?: InputMaybe<CommunityUpdatecheckInAdminIdsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  configs?: InputMaybe<Scalars['Json']>;
  country?: InputMaybe<EnumSupportedCountriesFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  enableModulesV2?: InputMaybe<BoolFieldUpdateOperationsInput>;
  enableStylePolling?: InputMaybe<BoolFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutParentCommunityInput>;
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutParentCommunityInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutCommunityInput>;
  forumTags?: InputMaybe<ForumTagUpdateManyWithoutCommunityInput>;
  globalInviteCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  globalTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  landingCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  links?: InputMaybe<Scalars['Json']>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoLandingUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  logoSmallUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  members?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereMemberInput>;
  membershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutCommunityInput>;
  minAppVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationUpdateManyWithoutCommunityInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  numPersonalizedInviteCodes?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCalls?: InputMaybe<OpenCallUpdateManyWithoutCommunityInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  paymentFees?: InputMaybe<Scalars['Json']>;
  personalizedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutCommunityInput>;
  products?: InputMaybe<ProductUpdateManyWithoutCommunityInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutParentCommunityInput>;
  relatedEvents?: InputMaybe<EventUpdateManyWithoutRelatedCommunitiesInput>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutCommunityInput>;
  relatedMasssMessages?: InputMaybe<MassMessageUpdateManyWithoutCommunityInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutCommunityInput>;
  requestToJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requestedMembers?: InputMaybe<UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFieldUpdateOperationsInput>;
  requiredUserFields?: InputMaybe<CommunityUpdaterequiredUserFieldsInput>;
  stripeConnectedAccountId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  styleOverrideBackup?: InputMaybe<Scalars['Json']>;
  swishPayees?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  themes?: InputMaybe<CommunityThemeUpdateManyWithoutCommunityInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type CommunityUpdateavailableModulesInput = {
  push?: InputMaybe<Module>;
  set?: InputMaybe<Array<Module>>;
};

export type CommunityUpdateavailablePaymentMethodsInput = {
  push?: InputMaybe<PurchasePaymentMethod>;
  set?: InputMaybe<Array<PurchasePaymentMethod>>;
};

export type CommunityUpdatecheckInAdminIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type CommunityUpdaterequiredUserFieldsInput = {
  push?: InputMaybe<UserInfoField>;
  set?: InputMaybe<Array<UserInfoField>>;
};

export type CommunityUpsertWithWhereUniqueWithoutAdminsInput = {
  create: CommunityCreateWithoutAdminsInput;
  update: CommunityUpdateWithoutAdminsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpsertWithWhereUniqueWithoutMembersInput = {
  create: CommunityCreateWithoutMembersInput;
  update: CommunityUpdateWithoutMembersInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpsertWithWhereUniqueWithoutRelatedEventsInput = {
  create: CommunityCreateWithoutRelatedEventsInput;
  update: CommunityUpdateWithoutRelatedEventsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpsertWithWhereUniqueWithoutRelatedFeedItemsInput = {
  create: CommunityCreateWithoutRelatedFeedItemsInput;
  update: CommunityUpdateWithoutRelatedFeedItemsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpsertWithWhereUniqueWithoutRequestedMembersInput = {
  create: CommunityCreateWithoutRequestedMembersInput;
  update: CommunityUpdateWithoutRequestedMembersInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpsertWithWhereUniqueWithoutVisibleInLocationsInput = {
  create: CommunityCreateWithoutVisibleInLocationsInput;
  update: CommunityUpdateWithoutVisibleInLocationsInput;
  where: CommunityWhereUniqueInput;
};

export type CommunityUpsertWithoutBadgesInput = {
  create: CommunityCreateWithoutBadgesInput;
  update: CommunityUpdateWithoutBadgesInput;
};

export type CommunityUpsertWithoutCatalogItemsInput = {
  create: CommunityCreateWithoutCatalogItemsInput;
  update: CommunityUpdateWithoutCatalogItemsInput;
};

export type CommunityUpsertWithoutCatalogSchedulesInput = {
  create: CommunityCreateWithoutCatalogSchedulesInput;
  update: CommunityUpdateWithoutCatalogSchedulesInput;
};

export type CommunityUpsertWithoutEventsInput = {
  create: CommunityCreateWithoutEventsInput;
  update: CommunityUpdateWithoutEventsInput;
};

export type CommunityUpsertWithoutFaqItemsInput = {
  create: CommunityCreateWithoutFaqItemsInput;
  update: CommunityUpdateWithoutFaqItemsInput;
};

export type CommunityUpsertWithoutFeedItemInput = {
  create: CommunityCreateWithoutFeedItemInput;
  update: CommunityUpdateWithoutFeedItemInput;
};

export type CommunityUpsertWithoutForumPostsInput = {
  create: CommunityCreateWithoutForumPostsInput;
  update: CommunityUpdateWithoutForumPostsInput;
};

export type CommunityUpsertWithoutForumTagsInput = {
  create: CommunityCreateWithoutForumTagsInput;
  update: CommunityUpdateWithoutForumTagsInput;
};

export type CommunityUpsertWithoutMembershipInfoInput = {
  create: CommunityCreateWithoutMembershipInfoInput;
  update: CommunityUpdateWithoutMembershipInfoInput;
};

export type CommunityUpsertWithoutModuleConfigurationsInput = {
  create: CommunityCreateWithoutModuleConfigurationsInput;
  update: CommunityUpdateWithoutModuleConfigurationsInput;
};

export type CommunityUpsertWithoutOpenCallsInput = {
  create: CommunityCreateWithoutOpenCallsInput;
  update: CommunityUpdateWithoutOpenCallsInput;
};

export type CommunityUpsertWithoutPersonalizedInviteCodesInput = {
  create: CommunityCreateWithoutPersonalizedInviteCodesInput;
  update: CommunityUpdateWithoutPersonalizedInviteCodesInput;
};

export type CommunityUpsertWithoutProductsInput = {
  create: CommunityCreateWithoutProductsInput;
  update: CommunityUpdateWithoutProductsInput;
};

export type CommunityUpsertWithoutPromocodesInput = {
  create: CommunityCreateWithoutPromocodesInput;
  update: CommunityUpdateWithoutPromocodesInput;
};

export type CommunityUpsertWithoutRelatedHighlightsInput = {
  create: CommunityCreateWithoutRelatedHighlightsInput;
  update: CommunityUpdateWithoutRelatedHighlightsInput;
};

export type CommunityUpsertWithoutRelatedMasssMessagesInput = {
  create: CommunityCreateWithoutRelatedMasssMessagesInput;
  update: CommunityUpdateWithoutRelatedMasssMessagesInput;
};

export type CommunityUpsertWithoutRelatedTalksMessagesInput = {
  create: CommunityCreateWithoutRelatedTalksMessagesInput;
  update: CommunityUpdateWithoutRelatedTalksMessagesInput;
};

export type CommunityUpsertWithoutTrackInput = {
  create: CommunityCreateWithoutTrackInput;
  update: CommunityUpdateWithoutTrackInput;
};

export type CommunityWhereInput = {
  AND?: InputMaybe<Array<CommunityWhereInput>>;
  Badges?: InputMaybe<TemplateBadgeListRelationFilter>;
  FeedItem?: InputMaybe<FeedItemListRelationFilter>;
  NOT?: InputMaybe<Array<CommunityWhereInput>>;
  OR?: InputMaybe<Array<CommunityWhereInput>>;
  Track?: InputMaybe<TrackListRelationFilter>;
  admins?: InputMaybe<UserListRelationFilter>;
  availableModules?: InputMaybe<EnumModuleNullableListFilter>;
  availablePaymentMethods?: InputMaybe<EnumPurchasePaymentMethodNullableListFilter>;
  backgroundColor?: InputMaybe<StringFilter>;
  backgroundColorAndroid?: InputMaybe<StringFilter>;
  backgroundLength?: InputMaybe<IntFilter>;
  backgroundLengthAndroid?: InputMaybe<IntFilter>;
  backgroundType?: InputMaybe<EnumBackgroundTypeFilter>;
  backgroundTypeAndroid?: InputMaybe<EnumBackgroundTypeNullableFilter>;
  catalogItems?: InputMaybe<CatalogItemListRelationFilter>;
  catalogSchedules?: InputMaybe<CatalogItemScheduleListRelationFilter>;
  checkInAdminIds?: InputMaybe<StringNullableListFilter>;
  code?: InputMaybe<StringNullableFilter>;
  configs?: InputMaybe<JsonNullableFilter>;
  country?: InputMaybe<EnumSupportedCountriesFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumSupportedCurrenciesFilter>;
  description?: InputMaybe<StringNullableFilter>;
  enableModulesV2?: InputMaybe<BoolFilter>;
  enableStylePolling?: InputMaybe<BoolFilter>;
  events?: InputMaybe<EventListRelationFilter>;
  faqItems?: InputMaybe<FaqItemListRelationFilter>;
  forumPosts?: InputMaybe<ForumPostListRelationFilter>;
  forumTags?: InputMaybe<ForumTagListRelationFilter>;
  globalInviteCode?: InputMaybe<StringNullableFilter>;
  globalTicketsLink?: InputMaybe<StringNullableFilter>;
  iconUrl?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  landingCode?: InputMaybe<StringNullableFilter>;
  links?: InputMaybe<JsonNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  logoLandingUrl?: InputMaybe<StringNullableFilter>;
  logoSmallUrl?: InputMaybe<StringNullableFilter>;
  members?: InputMaybe<UserListRelationFilter>;
  membershipInfo?: InputMaybe<CommunityMemberInfoListRelationFilter>;
  minAppVersion?: InputMaybe<StringFilter>;
  moduleConfigurations?: InputMaybe<ModuleConfigurationListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  numPersonalizedInviteCodes?: InputMaybe<IntFilter>;
  openCalls?: InputMaybe<OpenCallListRelationFilter>;
  order?: InputMaybe<IntNullableFilter>;
  paymentFees?: InputMaybe<JsonNullableFilter>;
  personalizedInviteCodes?: InputMaybe<InviteCodeListRelationFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  promocodes?: InputMaybe<PromocodeListRelationFilter>;
  relatedEvents?: InputMaybe<EventListRelationFilter>;
  relatedFeedItems?: InputMaybe<UbiqFeedItemListRelationFilter>;
  relatedHighlights?: InputMaybe<HighlightListRelationFilter>;
  relatedMasssMessages?: InputMaybe<MassMessageListRelationFilter>;
  relatedTalksMessages?: InputMaybe<TalksMessageListRelationFilter>;
  requestToJoin?: InputMaybe<BoolFilter>;
  requestedMembers?: InputMaybe<UserListRelationFilter>;
  requireVerifiedEmailForShopAccess?: InputMaybe<BoolFilter>;
  requiredUserFields?: InputMaybe<EnumUserInfoFieldNullableListFilter>;
  stripeConnectedAccountId?: InputMaybe<StringNullableFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  styleOverrideBackup?: InputMaybe<JsonNullableFilter>;
  swishPayees?: InputMaybe<StringNullableFilter>;
  themes?: InputMaybe<CommunityThemeListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  visibleInLocations?: InputMaybe<LocationListRelationFilter>;
  welcomeDisclaimerBackgroundUrl?: InputMaybe<StringNullableFilter>;
};

export type CommunityWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Configuration = {
  __typename?: 'Configuration';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  type: ConfigurationType;
  updatedAt: Scalars['DateTime'];
  userIds: Scalars['String'];
  value: Scalars['Json'];
};

export type ConfigurationCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: ConfigurationType;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userIds?: InputMaybe<Scalars['String']>;
  value: Scalars['Json'];
};

export type ConfigurationOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userIds?: InputMaybe<SortOrder>;
  value?: InputMaybe<SortOrder>;
};

export enum ConfigurationType {
  NotificationReprompt = 'NotificationReprompt'
}

export type ConfigurationUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumConfigurationTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars['Json']>;
};

export type ConfigurationUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumConfigurationTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  userIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  value?: InputMaybe<Scalars['Json']>;
};

export type ConfigurationWhereInput = {
  AND?: InputMaybe<Array<ConfigurationWhereInput>>;
  NOT?: InputMaybe<Array<ConfigurationWhereInput>>;
  OR?: InputMaybe<Array<ConfigurationWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumConfigurationTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userIds?: InputMaybe<StringFilter>;
};

export type ConfigurationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type CreateMediaResponse = {
  __typename?: 'CreateMediaResponse';
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  uploadURL?: Maybe<Scalars['String']>;
};

export type DailyMembersData = {
  __typename?: 'DailyMembersData';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DiscountAndFees = {
  __typename?: 'DiscountAndFees';
  discount?: Maybe<Scalars['Float']>;
  fees?: Maybe<Scalars['Float']>;
  vat?: Maybe<Scalars['Float']>;
};

export type EnumBackgroundTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BackgroundType>;
};

export type EnumBackgroundTypeFilter = {
  equals?: InputMaybe<BackgroundType>;
  in?: InputMaybe<Array<BackgroundType>>;
  not?: InputMaybe<NestedEnumBackgroundTypeFilter>;
  notIn?: InputMaybe<Array<BackgroundType>>;
};

export type EnumBackgroundTypeNullableFilter = {
  equals?: InputMaybe<BackgroundType>;
  in?: InputMaybe<Array<BackgroundType>>;
  not?: InputMaybe<NestedEnumBackgroundTypeNullableFilter>;
  notIn?: InputMaybe<Array<BackgroundType>>;
};

export type EnumCatalogItemSchedulePublishingStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<CatalogItemSchedulePublishingStatus>;
};

export type EnumCatalogItemSchedulePublishingStatusFilter = {
  equals?: InputMaybe<CatalogItemSchedulePublishingStatus>;
  in?: InputMaybe<Array<CatalogItemSchedulePublishingStatus>>;
  not?: InputMaybe<NestedEnumCatalogItemSchedulePublishingStatusFilter>;
  notIn?: InputMaybe<Array<CatalogItemSchedulePublishingStatus>>;
};

export type EnumCommunityThemeStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<CommunityThemeStatus>;
};

export type EnumCommunityThemeStatusFilter = {
  equals?: InputMaybe<CommunityThemeStatus>;
  in?: InputMaybe<Array<CommunityThemeStatus>>;
  not?: InputMaybe<NestedEnumCommunityThemeStatusFilter>;
  notIn?: InputMaybe<Array<CommunityThemeStatus>>;
};

export type EnumConfigurationTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ConfigurationType>;
};

export type EnumConfigurationTypeFilter = {
  equals?: InputMaybe<ConfigurationType>;
  in?: InputMaybe<Array<ConfigurationType>>;
  not?: InputMaybe<NestedEnumConfigurationTypeFilter>;
  notIn?: InputMaybe<Array<ConfigurationType>>;
};

export type EnumEventLocationAvailabilityFieldUpdateOperationsInput = {
  set?: InputMaybe<EventLocationAvailability>;
};

export type EnumEventLocationAvailabilityFilter = {
  equals?: InputMaybe<EventLocationAvailability>;
  in?: InputMaybe<Array<EventLocationAvailability>>;
  not?: InputMaybe<NestedEnumEventLocationAvailabilityFilter>;
  notIn?: InputMaybe<Array<EventLocationAvailability>>;
};

export type EnumEventStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<EventStatus>;
};

export type EnumEventStatusFilter = {
  equals?: InputMaybe<EventStatus>;
  in?: InputMaybe<Array<EventStatus>>;
  not?: InputMaybe<NestedEnumEventStatusFilter>;
  notIn?: InputMaybe<Array<EventStatus>>;
};

export type EnumFeedItemCommentReportStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<FeedItemCommentReportStatus>;
};

export type EnumFeedItemCommentReportStatusFilter = {
  equals?: InputMaybe<FeedItemCommentReportStatus>;
  in?: InputMaybe<Array<FeedItemCommentReportStatus>>;
  not?: InputMaybe<NestedEnumFeedItemCommentReportStatusFilter>;
  notIn?: InputMaybe<Array<FeedItemCommentReportStatus>>;
};

export type EnumFeedItemCommentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<FeedItemCommentStatus>;
};

export type EnumFeedItemCommentStatusFilter = {
  equals?: InputMaybe<FeedItemCommentStatus>;
  in?: InputMaybe<Array<FeedItemCommentStatus>>;
  not?: InputMaybe<NestedEnumFeedItemCommentStatusFilter>;
  notIn?: InputMaybe<Array<FeedItemCommentStatus>>;
};

export type EnumFeedItemPublishingStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<FeedItemPublishingStatus>;
};

export type EnumFeedItemPublishingStatusFilter = {
  equals?: InputMaybe<FeedItemPublishingStatus>;
  in?: InputMaybe<Array<FeedItemPublishingStatus>>;
  not?: InputMaybe<NestedEnumFeedItemPublishingStatusFilter>;
  notIn?: InputMaybe<Array<FeedItemPublishingStatus>>;
};

export type EnumForumCommentStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ForumCommentStatus>;
};

export type EnumForumCommentStatusFilter = {
  equals?: InputMaybe<ForumCommentStatus>;
  in?: InputMaybe<Array<ForumCommentStatus>>;
  not?: InputMaybe<NestedEnumForumCommentStatusFilter>;
  notIn?: InputMaybe<Array<ForumCommentStatus>>;
};

export type EnumForumContentReportStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ForumContentReportStatus>;
};

export type EnumForumContentReportStatusFilter = {
  equals?: InputMaybe<ForumContentReportStatus>;
  in?: InputMaybe<Array<ForumContentReportStatus>>;
  not?: InputMaybe<NestedEnumForumContentReportStatusFilter>;
  notIn?: InputMaybe<Array<ForumContentReportStatus>>;
};

export type EnumForumPostPublishingStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ForumPostPublishingStatus>;
};

export type EnumForumPostPublishingStatusFilter = {
  equals?: InputMaybe<ForumPostPublishingStatus>;
  in?: InputMaybe<Array<ForumPostPublishingStatus>>;
  not?: InputMaybe<NestedEnumForumPostPublishingStatusFilter>;
  notIn?: InputMaybe<Array<ForumPostPublishingStatus>>;
};

export type EnumHeadlineTypeNullableFilter = {
  equals?: InputMaybe<HeadlineType>;
  in?: InputMaybe<Array<HeadlineType>>;
  not?: InputMaybe<NestedEnumHeadlineTypeNullableFilter>;
  notIn?: InputMaybe<Array<HeadlineType>>;
};

export type EnumHighlightTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<HighlightType>;
};

export type EnumHighlightTypeFilter = {
  equals?: InputMaybe<HighlightType>;
  in?: InputMaybe<Array<HighlightType>>;
  not?: InputMaybe<NestedEnumHighlightTypeFilter>;
  notIn?: InputMaybe<Array<HighlightType>>;
};

export type EnumInviteCodeTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<InviteCodeType>;
};

export type EnumInviteCodeTypeFilter = {
  equals?: InputMaybe<InviteCodeType>;
  in?: InputMaybe<Array<InviteCodeType>>;
  not?: InputMaybe<NestedEnumInviteCodeTypeFilter>;
  notIn?: InputMaybe<Array<InviteCodeType>>;
};

export type EnumMassMessageSegmentNullableFilter = {
  equals?: InputMaybe<MassMessageSegment>;
  in?: InputMaybe<Array<MassMessageSegment>>;
  not?: InputMaybe<NestedEnumMassMessageSegmentNullableFilter>;
  notIn?: InputMaybe<Array<MassMessageSegment>>;
};

export type EnumMassMessageStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<MassMessageStatus>;
};

export type EnumMassMessageStatusFilter = {
  equals?: InputMaybe<MassMessageStatus>;
  in?: InputMaybe<Array<MassMessageStatus>>;
  not?: InputMaybe<NestedEnumMassMessageStatusFilter>;
  notIn?: InputMaybe<Array<MassMessageStatus>>;
};

export type EnumMassMessageTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<MassMessageType>;
};

export type EnumMassMessageTypeFilter = {
  equals?: InputMaybe<MassMessageType>;
  in?: InputMaybe<Array<MassMessageType>>;
  not?: InputMaybe<NestedEnumMassMessageTypeFilter>;
  notIn?: InputMaybe<Array<MassMessageType>>;
};

export type EnumMessageHighlightStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<MessageHighlightStatus>;
};

export type EnumMessageHighlightStatusFilter = {
  equals?: InputMaybe<MessageHighlightStatus>;
  in?: InputMaybe<Array<MessageHighlightStatus>>;
  not?: InputMaybe<NestedEnumMessageHighlightStatusFilter>;
  notIn?: InputMaybe<Array<MessageHighlightStatus>>;
};

export type EnumModuleFieldUpdateOperationsInput = {
  set?: InputMaybe<Module>;
};

export type EnumModuleFilter = {
  equals?: InputMaybe<Module>;
  in?: InputMaybe<Array<Module>>;
  not?: InputMaybe<NestedEnumModuleFilter>;
  notIn?: InputMaybe<Array<Module>>;
};

export type EnumModuleNullableFilter = {
  equals?: InputMaybe<Module>;
  in?: InputMaybe<Array<Module>>;
  not?: InputMaybe<NestedEnumModuleNullableFilter>;
  notIn?: InputMaybe<Array<Module>>;
};

export type EnumModuleNullableListFilter = {
  equals?: InputMaybe<Array<Module>>;
  has?: InputMaybe<Module>;
  hasEvery?: InputMaybe<Array<Module>>;
  hasSome?: InputMaybe<Array<Module>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type EnumOpenCallPublishingStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<OpenCallPublishingStatus>;
};

export type EnumOpenCallPublishingStatusFilter = {
  equals?: InputMaybe<OpenCallPublishingStatus>;
  in?: InputMaybe<Array<OpenCallPublishingStatus>>;
  not?: InputMaybe<NestedEnumOpenCallPublishingStatusFilter>;
  notIn?: InputMaybe<Array<OpenCallPublishingStatus>>;
};

export type EnumPayoutStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PayoutStatus>;
};

export type EnumPayoutStatusFilter = {
  equals?: InputMaybe<PayoutStatus>;
  in?: InputMaybe<Array<PayoutStatus>>;
  not?: InputMaybe<NestedEnumPayoutStatusFilter>;
  notIn?: InputMaybe<Array<PayoutStatus>>;
};

export type EnumProductStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductStatus>;
};

export type EnumProductStatusFilter = {
  equals?: InputMaybe<ProductStatus>;
  in?: InputMaybe<Array<ProductStatus>>;
  not?: InputMaybe<NestedEnumProductStatusFilter>;
  notIn?: InputMaybe<Array<ProductStatus>>;
};

export type EnumProductTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<ProductType>;
};

export type EnumProductTypeFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type EnumPromocodePublishingStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PromocodePublishingStatus>;
};

export type EnumPromocodePublishingStatusFilter = {
  equals?: InputMaybe<PromocodePublishingStatus>;
  in?: InputMaybe<Array<PromocodePublishingStatus>>;
  not?: InputMaybe<NestedEnumPromocodePublishingStatusFilter>;
  notIn?: InputMaybe<Array<PromocodePublishingStatus>>;
};

export type EnumPurchasePaymentMethodFieldUpdateOperationsInput = {
  set?: InputMaybe<PurchasePaymentMethod>;
};

export type EnumPurchasePaymentMethodFilter = {
  equals?: InputMaybe<PurchasePaymentMethod>;
  in?: InputMaybe<Array<PurchasePaymentMethod>>;
  not?: InputMaybe<NestedEnumPurchasePaymentMethodFilter>;
  notIn?: InputMaybe<Array<PurchasePaymentMethod>>;
};

export type EnumPurchasePaymentMethodNullableListFilter = {
  equals?: InputMaybe<Array<PurchasePaymentMethod>>;
  has?: InputMaybe<PurchasePaymentMethod>;
  hasEvery?: InputMaybe<Array<PurchasePaymentMethod>>;
  hasSome?: InputMaybe<Array<PurchasePaymentMethod>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type EnumPurchaseStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<PurchaseStatus>;
};

export type EnumPurchaseStatusFilter = {
  equals?: InputMaybe<PurchaseStatus>;
  in?: InputMaybe<Array<PurchaseStatus>>;
  not?: InputMaybe<NestedEnumPurchaseStatusFilter>;
  notIn?: InputMaybe<Array<PurchaseStatus>>;
};

export type EnumSupportedCountriesFieldUpdateOperationsInput = {
  set?: InputMaybe<SupportedCountries>;
};

export type EnumSupportedCountriesFilter = {
  equals?: InputMaybe<SupportedCountries>;
  in?: InputMaybe<Array<SupportedCountries>>;
  not?: InputMaybe<NestedEnumSupportedCountriesFilter>;
  notIn?: InputMaybe<Array<SupportedCountries>>;
};

export type EnumSupportedCurrenciesFieldUpdateOperationsInput = {
  set?: InputMaybe<SupportedCurrencies>;
};

export type EnumSupportedCurrenciesFilter = {
  equals?: InputMaybe<SupportedCurrencies>;
  in?: InputMaybe<Array<SupportedCurrencies>>;
  not?: InputMaybe<NestedEnumSupportedCurrenciesFilter>;
  notIn?: InputMaybe<Array<SupportedCurrencies>>;
};

export type EnumTalksMessageTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TalksMessageType>;
};

export type EnumTalksMessageTypeFilter = {
  equals?: InputMaybe<TalksMessageType>;
  in?: InputMaybe<Array<TalksMessageType>>;
  not?: InputMaybe<NestedEnumTalksMessageTypeFilter>;
  notIn?: InputMaybe<Array<TalksMessageType>>;
};

export type EnumTalksMessageTypeNullableFilter = {
  equals?: InputMaybe<TalksMessageType>;
  in?: InputMaybe<Array<TalksMessageType>>;
  not?: InputMaybe<NestedEnumTalksMessageTypeNullableFilter>;
  notIn?: InputMaybe<Array<TalksMessageType>>;
};

export type EnumTrackPublishingStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<TrackPublishingStatus>;
};

export type EnumTrackPublishingStatusFilter = {
  equals?: InputMaybe<TrackPublishingStatus>;
  in?: InputMaybe<Array<TrackPublishingStatus>>;
  not?: InputMaybe<NestedEnumTrackPublishingStatusFilter>;
  notIn?: InputMaybe<Array<TrackPublishingStatus>>;
};

export type EnumTrackTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TrackType>;
};

export type EnumTrackTypeFilter = {
  equals?: InputMaybe<TrackType>;
  in?: InputMaybe<Array<TrackType>>;
  not?: InputMaybe<NestedEnumTrackTypeFilter>;
  notIn?: InputMaybe<Array<TrackType>>;
};

export type EnumUbiqFeedItemStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<UbiqFeedItemStatus>;
};

export type EnumUbiqFeedItemStatusFilter = {
  equals?: InputMaybe<UbiqFeedItemStatus>;
  in?: InputMaybe<Array<UbiqFeedItemStatus>>;
  not?: InputMaybe<NestedEnumUbiqFeedItemStatusFilter>;
  notIn?: InputMaybe<Array<UbiqFeedItemStatus>>;
};

export type EnumUbiqFeedItemTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<UbiqFeedItemType>;
};

export type EnumUbiqFeedItemTypeFilter = {
  equals?: InputMaybe<UbiqFeedItemType>;
  in?: InputMaybe<Array<UbiqFeedItemType>>;
  not?: InputMaybe<NestedEnumUbiqFeedItemTypeFilter>;
  notIn?: InputMaybe<Array<UbiqFeedItemType>>;
};

export type EnumUserInfoFieldNullableListFilter = {
  equals?: InputMaybe<Array<UserInfoField>>;
  has?: InputMaybe<UserInfoField>;
  hasEvery?: InputMaybe<Array<UserInfoField>>;
  hasSome?: InputMaybe<Array<UserInfoField>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type EnumUserRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<UserRole>;
};

export type EnumUserRoleFilter = {
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<NestedEnumUserRoleFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type EnumUserStatusFieldUpdateOperationsInput = {
  set?: InputMaybe<UserStatus>;
};

export type EnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type EnumWaitlistSourceFieldUpdateOperationsInput = {
  set?: InputMaybe<WaitlistSource>;
};

export type EnumWaitlistSourceFilter = {
  equals?: InputMaybe<WaitlistSource>;
  in?: InputMaybe<Array<WaitlistSource>>;
  not?: InputMaybe<NestedEnumWaitlistSourceFilter>;
  notIn?: InputMaybe<Array<WaitlistSource>>;
};

export type Event = {
  __typename?: 'Event';
  additionalDetails?: Maybe<Scalars['String']>;
  allowFreeGuestList: Scalars['Boolean'];
  buyTicketsLink?: Maybe<Scalars['String']>;
  calendarSeen: Scalars['Boolean'];
  categories: Array<EventCategory>;
  checkedInUsers: Array<User>;
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  eventDetails: Array<EventDetail>;
  feed: Array<FeedItem>;
  freeGuestListProductId?: Maybe<Scalars['String']>;
  grossRevenue?: Maybe<Scalars['Float']>;
  hasTicketsOrLink: Scalars['Boolean'];
  host?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  infoStepsComplete: Scalars['Int'];
  invitedUsers: Array<User>;
  isLocked: Scalars['Boolean'];
  isMaster: Scalars['Boolean'];
  isPrivate: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Float']>;
  location: Scalars['String'];
  locationAvailability: EventLocationAvailability;
  locationAvailable: Scalars['Boolean'];
  locationDescription?: Maybe<Scalars['String']>;
  locationDropNotificationSent?: Maybe<Scalars['Boolean']>;
  locationDropTime?: Maybe<Scalars['DateTime']>;
  locationSubtitle: Scalars['String'];
  longitude?: Maybe<Scalars['Float']>;
  meRegistered?: Maybe<Scalars['Boolean']>;
  meTicketed?: Maybe<Scalars['Boolean']>;
  mediaUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: Community;
  parentCommunityId: Scalars['String'];
  potentialRevenue?: Maybe<Scalars['Float']>;
  prePurchaseCode?: Maybe<Scalars['String']>;
  products: Array<Product>;
  publishingStatus: EventStatus;
  registerLink?: Maybe<Scalars['String']>;
  registeredUsers: Array<User>;
  registrationRequired: Scalars['Boolean'];
  registrationState: Scalars['String'];
  relatedCommunities: Array<Community>;
  shouldInviteCommunityMembersAutomatically: Scalars['Boolean'];
  start?: Maybe<Scalars['DateTime']>;
  styleOverride?: Maybe<Scalars['Json']>;
  swapMinPrice?: Maybe<Scalars['String']>;
  ticketState: Scalars['String'];
  ticketedUsers: Array<User>;
  ticketsSold: Scalars['Int'];
  ticketsTotal?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  venueName?: Maybe<Scalars['String']>;
};


export type EventCategoriesArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type EventCheckedInUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type EventEventDetailsArgs = {
  cursor?: InputMaybe<EventDetailWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type EventFeedArgs = {
  cursor?: InputMaybe<FeedItemWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type EventInvitedUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type EventProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type EventRegisteredUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type EventRelatedCommunitiesArgs = {
  cursor?: InputMaybe<CommunityWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type EventTicketedUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export type EventAttendees = {
  __typename?: 'EventAttendees';
  attendees: Array<User>;
  next: Scalars['Int'];
  totalAttendees: Scalars['Int'];
};

export type EventCategory = {
  __typename?: 'EventCategory';
  createdAt: Scalars['DateTime'];
  events: Array<Event>;
  id: Scalars['String'];
  name: Scalars['String'];
  styleOverride?: Maybe<Scalars['Json']>;
  updatedAt: Scalars['DateTime'];
};


export type EventCategoryEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type EventCategoryCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  events?: InputMaybe<EventCreateNestedManyWithoutCategoriesInput>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventCategoryCreateNestedManyWithoutEventsInput = {
  connect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCategoryCreateOrConnectWithoutEventsInput>>;
  create?: InputMaybe<Array<EventCategoryCreateWithoutEventsInput>>;
};

export type EventCategoryCreateOrConnectWithoutEventsInput = {
  create: EventCategoryCreateWithoutEventsInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryCreateWithoutEventsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventCategoryListRelationFilter = {
  every?: InputMaybe<EventCategoryWhereInput>;
  none?: InputMaybe<EventCategoryWhereInput>;
  some?: InputMaybe<EventCategoryWhereInput>;
};

export type EventCategoryOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  styleOverride?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EventCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventCategoryUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutCategoriesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventCategoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventCategoryUpdateManyWithWhereWithoutEventsInput = {
  data: EventCategoryUpdateManyMutationInput;
  where: EventCategoryScalarWhereInput;
};

export type EventCategoryUpdateManyWithoutEventsInput = {
  connect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCategoryCreateOrConnectWithoutEventsInput>>;
  create?: InputMaybe<Array<EventCategoryCreateWithoutEventsInput>>;
  delete?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<EventCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<EventCategoryUpdateWithWhereUniqueWithoutEventsInput>>;
  updateMany?: InputMaybe<Array<EventCategoryUpdateManyWithWhereWithoutEventsInput>>;
  upsert?: InputMaybe<Array<EventCategoryUpsertWithWhereUniqueWithoutEventsInput>>;
};

export type EventCategoryUpdateWithWhereUniqueWithoutEventsInput = {
  data: EventCategoryUpdateWithoutEventsInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryUpdateWithoutEventsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventCategoryUpsertWithWhereUniqueWithoutEventsInput = {
  create: EventCategoryCreateWithoutEventsInput;
  update: EventCategoryUpdateWithoutEventsInput;
  where: EventCategoryWhereUniqueInput;
};

export type EventCategoryWhereInput = {
  AND?: InputMaybe<Array<EventCategoryWhereInput>>;
  NOT?: InputMaybe<Array<EventCategoryWhereInput>>;
  OR?: InputMaybe<Array<EventCategoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  events?: InputMaybe<EventListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type EventCreateInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateManyCreatorInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunityId: Scalars['String'];
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateManyCreatorInputEnvelope = {
  data?: InputMaybe<Array<EventCreateManyCreatorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EventCreateManyParentCommunityInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creatorId?: InputMaybe<Scalars['String']>;
  end?: InputMaybe<Scalars['DateTime']>;
  externalId?: InputMaybe<Scalars['String']>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateManyParentCommunityInputEnvelope = {
  data?: InputMaybe<Array<EventCreateManyParentCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EventCreateNestedManyWithoutCategoriesInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutCategoriesInput>>;
  create?: InputMaybe<Array<EventCreateWithoutCategoriesInput>>;
};

export type EventCreateNestedManyWithoutCheckedInUsersInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutCheckedInUsersInput>>;
  create?: InputMaybe<Array<EventCreateWithoutCheckedInUsersInput>>;
};

export type EventCreateNestedManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<EventCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<EventCreateManyCreatorInputEnvelope>;
};

export type EventCreateNestedManyWithoutFeedInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutFeedInput>>;
  create?: InputMaybe<Array<EventCreateWithoutFeedInput>>;
};

export type EventCreateNestedManyWithoutInvitedUsersInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutInvitedUsersInput>>;
  create?: InputMaybe<Array<EventCreateWithoutInvitedUsersInput>>;
};

export type EventCreateNestedManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<EventCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<EventCreateManyParentCommunityInputEnvelope>;
};

export type EventCreateNestedManyWithoutRegisteredUsersInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutRegisteredUsersInput>>;
  create?: InputMaybe<Array<EventCreateWithoutRegisteredUsersInput>>;
};

export type EventCreateNestedManyWithoutRelatedCommunitiesInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutRelatedCommunitiesInput>>;
  create?: InputMaybe<Array<EventCreateWithoutRelatedCommunitiesInput>>;
};

export type EventCreateNestedManyWithoutTicketedUsersInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutTicketedUsersInput>>;
  create?: InputMaybe<Array<EventCreateWithoutTicketedUsersInput>>;
};

export type EventCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<EventCreateWithoutProductsInput>;
};

export type EventCreateNestedOneWithoutRelatedHighlightsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutRelatedHighlightsInput>;
  create?: InputMaybe<EventCreateWithoutRelatedHighlightsInput>;
};

export type EventCreateNestedOneWithoutRelatedMassMessagesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutRelatedMassMessagesInput>;
  create?: InputMaybe<EventCreateWithoutRelatedMassMessagesInput>;
};

export type EventCreateNestedOneWithoutRelatedTalksMessagesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutRelatedTalksMessagesInput>;
  create?: InputMaybe<EventCreateWithoutRelatedTalksMessagesInput>;
};

export type EventCreateOrConnectWithoutCategoriesInput = {
  create: EventCreateWithoutCategoriesInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutCheckedInUsersInput = {
  create: EventCreateWithoutCheckedInUsersInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutCreatorInput = {
  create: EventCreateWithoutCreatorInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutFeedInput = {
  create: EventCreateWithoutFeedInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutInvitedUsersInput = {
  create: EventCreateWithoutInvitedUsersInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutParentCommunityInput = {
  create: EventCreateWithoutParentCommunityInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutProductsInput = {
  create: EventCreateWithoutProductsInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutRegisteredUsersInput = {
  create: EventCreateWithoutRegisteredUsersInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutRelatedCommunitiesInput = {
  create: EventCreateWithoutRelatedCommunitiesInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutRelatedHighlightsInput = {
  create: EventCreateWithoutRelatedHighlightsInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutRelatedMassMessagesInput = {
  create: EventCreateWithoutRelatedMassMessagesInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutRelatedTalksMessagesInput = {
  create: EventCreateWithoutRelatedTalksMessagesInput;
  where: EventWhereUniqueInput;
};

export type EventCreateOrConnectWithoutTicketedUsersInput = {
  create: EventCreateWithoutTicketedUsersInput;
  where: EventWhereUniqueInput;
};

export type EventCreateWithoutCategoriesInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutCheckedInUsersInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutCreatorInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutFeedInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutInvitedUsersInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutParentCommunityInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutProductsInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutRegisteredUsersInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutRelatedCommunitiesInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutRelatedHighlightsInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutRelatedMassMessagesInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutRelatedTalksMessagesInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserCreateNestedManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventCreateWithoutTicketedUsersInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  calendarSeenBy?: InputMaybe<Scalars['String']>;
  calenderHideFrom?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<EventCategoryCreateNestedManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserCreateNestedManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  creator?: InputMaybe<UserCreateNestedOneWithoutCreatedEventsInput>;
  end?: InputMaybe<Scalars['DateTime']>;
  eventDetails?: InputMaybe<EventDetailCreateNestedManyWithoutEventInput>;
  externalId?: InputMaybe<Scalars['String']>;
  feed?: InputMaybe<FeedItemCreateNestedManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  iconUrl?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  infoStepsComplete?: InputMaybe<Scalars['Int']>;
  invitedUsers?: InputMaybe<UserCreateNestedManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isMaster?: InputMaybe<Scalars['Boolean']>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<EventLocationAvailability>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropNotificationSent?: InputMaybe<Scalars['Boolean']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutEventsInput;
  prePurchaseCode?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EventStatus>;
  registerLink?: InputMaybe<Scalars['String']>;
  registeredUsers?: InputMaybe<UserCreateNestedManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  relatedCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightCreateNestedManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageCreateNestedManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageCreateNestedManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketsSold?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type EventDetail = {
  __typename?: 'EventDetail';
  createdAt: Scalars['DateTime'];
  event: Event;
  id: Scalars['String'];
  shouldSendNotification: Scalars['Boolean'];
  text?: Maybe<Scalars['String']>;
};

export type EventDetailCreateManyEventInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  notificationsSent?: InputMaybe<Scalars['Boolean']>;
  shouldSendNotification?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventDetailCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<EventDetailCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type EventDetailCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<EventDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventDetailCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<EventDetailCreateWithoutEventInput>>;
  createMany?: InputMaybe<EventDetailCreateManyEventInputEnvelope>;
};

export type EventDetailCreateOrConnectWithoutEventInput = {
  create: EventDetailCreateWithoutEventInput;
  where: EventDetailWhereUniqueInput;
};

export type EventDetailCreateWithoutEventInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  notificationsSent?: InputMaybe<Scalars['Boolean']>;
  shouldSendNotification?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type EventDetailListRelationFilter = {
  every?: InputMaybe<EventDetailWhereInput>;
  none?: InputMaybe<EventDetailWhereInput>;
  some?: InputMaybe<EventDetailWhereInput>;
};

export type EventDetailScalarWhereInput = {
  AND?: InputMaybe<Array<EventDetailScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventDetailScalarWhereInput>>;
  OR?: InputMaybe<Array<EventDetailScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notificationsSent?: InputMaybe<BoolFilter>;
  shouldSendNotification?: InputMaybe<BoolFilter>;
  text?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventDetailUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationsSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shouldSendNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventDetailUpdateManyWithWhereWithoutEventInput = {
  data: EventDetailUpdateManyMutationInput;
  where: EventDetailScalarWhereInput;
};

export type EventDetailUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<EventDetailWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventDetailCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<EventDetailCreateWithoutEventInput>>;
  createMany?: InputMaybe<EventDetailCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<EventDetailWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventDetailScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventDetailWhereUniqueInput>>;
  set?: InputMaybe<Array<EventDetailWhereUniqueInput>>;
  update?: InputMaybe<Array<EventDetailUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<EventDetailUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<EventDetailUpsertWithWhereUniqueWithoutEventInput>>;
};

export type EventDetailUpdateWithWhereUniqueWithoutEventInput = {
  data: EventDetailUpdateWithoutEventInput;
  where: EventDetailWhereUniqueInput;
};

export type EventDetailUpdateWithoutEventInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  notificationsSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shouldSendNotification?: InputMaybe<BoolFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type EventDetailUpsertWithWhereUniqueWithoutEventInput = {
  create: EventDetailCreateWithoutEventInput;
  update: EventDetailUpdateWithoutEventInput;
  where: EventDetailWhereUniqueInput;
};

export type EventDetailWhereInput = {
  AND?: InputMaybe<Array<EventDetailWhereInput>>;
  NOT?: InputMaybe<Array<EventDetailWhereInput>>;
  OR?: InputMaybe<Array<EventDetailWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  notificationsSent?: InputMaybe<BoolFilter>;
  shouldSendNotification?: InputMaybe<BoolFilter>;
  text?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type EventDetailWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type EventInfoSection = {
  __typename?: 'EventInfoSection';
  items?: Maybe<Array<Maybe<EventInfoSectionItem>>>;
  name: Scalars['String'];
  productId?: Maybe<Scalars['String']>;
};

export type EventInfoSectionItem = {
  __typename?: 'EventInfoSectionItem';
  color: Scalars['String'];
  count: Scalars['Float'];
  desc: Scalars['String'];
  name: Scalars['String'];
  type: EventInfoSectionItemType;
};

export enum EventInfoSectionItemType {
  CheckedIn = 'CheckedIn',
  Invited = 'Invited',
  Purchased = 'Purchased',
  Registered = 'Registered'
}

export type EventListRelationFilter = {
  every?: InputMaybe<EventWhereInput>;
  none?: InputMaybe<EventWhereInput>;
  some?: InputMaybe<EventWhereInput>;
};

export enum EventLocationAvailability {
  Public = 'Public',
  Registered = 'Registered',
  Ticketed = 'Ticketed'
}

export type EventOrderByInput = {
  additionalDetails?: InputMaybe<SortOrder>;
  allowFreeGuestList?: InputMaybe<SortOrder>;
  buyTicketsLink?: InputMaybe<SortOrder>;
  calendarSeenBy?: InputMaybe<SortOrder>;
  calenderHideFrom?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  freeGuestListProductId?: InputMaybe<SortOrder>;
  host?: InputMaybe<SortOrder>;
  iconUrl?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  infoStepsComplete?: InputMaybe<SortOrder>;
  isLocked?: InputMaybe<SortOrder>;
  isMaster?: InputMaybe<SortOrder>;
  isPrivate?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  location?: InputMaybe<SortOrder>;
  locationAvailability?: InputMaybe<SortOrder>;
  locationDescription?: InputMaybe<SortOrder>;
  locationDropNotificationSent?: InputMaybe<SortOrder>;
  locationDropTime?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  mediaUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentCommunityId?: InputMaybe<SortOrder>;
  prePurchaseCode?: InputMaybe<SortOrder>;
  publishingStatus?: InputMaybe<SortOrder>;
  registerLink?: InputMaybe<SortOrder>;
  registrationRequired?: InputMaybe<SortOrder>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  styleOverride?: InputMaybe<SortOrder>;
  ticketsSold?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  venueName?: InputMaybe<SortOrder>;
};

export type EventScalarWhereInput = {
  AND?: InputMaybe<Array<EventScalarWhereInput>>;
  NOT?: InputMaybe<Array<EventScalarWhereInput>>;
  OR?: InputMaybe<Array<EventScalarWhereInput>>;
  additionalDetails?: InputMaybe<StringNullableFilter>;
  allowFreeGuestList?: InputMaybe<BoolFilter>;
  buyTicketsLink?: InputMaybe<StringNullableFilter>;
  calendarSeenBy?: InputMaybe<StringNullableFilter>;
  calenderHideFrom?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  freeGuestListProductId?: InputMaybe<StringNullableFilter>;
  host?: InputMaybe<StringNullableFilter>;
  iconUrl?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  infoStepsComplete?: InputMaybe<IntFilter>;
  isLocked?: InputMaybe<BoolFilter>;
  isMaster?: InputMaybe<BoolFilter>;
  isPrivate?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFilter>;
  locationDescription?: InputMaybe<StringNullableFilter>;
  locationDropNotificationSent?: InputMaybe<BoolNullableFilter>;
  locationDropTime?: InputMaybe<DateTimeNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCommunityId?: InputMaybe<StringFilter>;
  prePurchaseCode?: InputMaybe<StringNullableFilter>;
  publishingStatus?: InputMaybe<EnumEventStatusFilter>;
  registerLink?: InputMaybe<StringNullableFilter>;
  registrationRequired?: InputMaybe<BoolFilter>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  ticketsSold?: InputMaybe<IntFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  venueName?: InputMaybe<StringNullableFilter>;
};

export enum EventStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  CompletedDraft = 'CompletedDraft',
  Deleted = 'Deleted',
  Draft = 'Draft',
  UneditedDraft = 'UneditedDraft'
}

export type EventUpdateInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateManyMutationInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateManyWithWhereWithoutCategoriesInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutCheckedInUsersInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutCreatorInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutFeedInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutInvitedUsersInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutParentCommunityInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutRegisteredUsersInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutRelatedCommunitiesInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithWhereWithoutTicketedUsersInput = {
  data: EventUpdateManyMutationInput;
  where: EventScalarWhereInput;
};

export type EventUpdateManyWithoutCategoriesInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutCategoriesInput>>;
  create?: InputMaybe<Array<EventCreateWithoutCategoriesInput>>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutCategoriesInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutCategoriesInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutCategoriesInput>>;
};

export type EventUpdateManyWithoutCheckedInUsersInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutCheckedInUsersInput>>;
  create?: InputMaybe<Array<EventCreateWithoutCheckedInUsersInput>>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutCheckedInUsersInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutCheckedInUsersInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutCheckedInUsersInput>>;
};

export type EventUpdateManyWithoutCreatorInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutCreatorInput>>;
  create?: InputMaybe<Array<EventCreateWithoutCreatorInput>>;
  createMany?: InputMaybe<EventCreateManyCreatorInputEnvelope>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutCreatorInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutCreatorInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutCreatorInput>>;
};

export type EventUpdateManyWithoutFeedInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutFeedInput>>;
  create?: InputMaybe<Array<EventCreateWithoutFeedInput>>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutFeedInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutFeedInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutFeedInput>>;
};

export type EventUpdateManyWithoutInvitedUsersInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutInvitedUsersInput>>;
  create?: InputMaybe<Array<EventCreateWithoutInvitedUsersInput>>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutInvitedUsersInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutInvitedUsersInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutInvitedUsersInput>>;
};

export type EventUpdateManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<EventCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<EventCreateManyParentCommunityInputEnvelope>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutParentCommunityInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutParentCommunityInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutParentCommunityInput>>;
};

export type EventUpdateManyWithoutRegisteredUsersInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutRegisteredUsersInput>>;
  create?: InputMaybe<Array<EventCreateWithoutRegisteredUsersInput>>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutRegisteredUsersInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutRegisteredUsersInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutRegisteredUsersInput>>;
};

export type EventUpdateManyWithoutRelatedCommunitiesInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutRelatedCommunitiesInput>>;
  create?: InputMaybe<Array<EventCreateWithoutRelatedCommunitiesInput>>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutRelatedCommunitiesInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutRelatedCommunitiesInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutRelatedCommunitiesInput>>;
};

export type EventUpdateManyWithoutTicketedUsersInput = {
  connect?: InputMaybe<Array<EventWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<EventCreateOrConnectWithoutTicketedUsersInput>>;
  create?: InputMaybe<Array<EventCreateWithoutTicketedUsersInput>>;
  delete?: InputMaybe<Array<EventWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<EventScalarWhereInput>>;
  disconnect?: InputMaybe<Array<EventWhereUniqueInput>>;
  set?: InputMaybe<Array<EventWhereUniqueInput>>;
  update?: InputMaybe<Array<EventUpdateWithWhereUniqueWithoutTicketedUsersInput>>;
  updateMany?: InputMaybe<Array<EventUpdateManyWithWhereWithoutTicketedUsersInput>>;
  upsert?: InputMaybe<Array<EventUpsertWithWhereUniqueWithoutTicketedUsersInput>>;
};

export type EventUpdateOneWithoutProductsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<EventCreateWithoutProductsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<EventUpdateWithoutProductsInput>;
  upsert?: InputMaybe<EventUpsertWithoutProductsInput>;
};

export type EventUpdateOneWithoutRelatedHighlightsInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutRelatedHighlightsInput>;
  create?: InputMaybe<EventCreateWithoutRelatedHighlightsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<EventUpdateWithoutRelatedHighlightsInput>;
  upsert?: InputMaybe<EventUpsertWithoutRelatedHighlightsInput>;
};

export type EventUpdateOneWithoutRelatedMassMessagesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutRelatedMassMessagesInput>;
  create?: InputMaybe<EventCreateWithoutRelatedMassMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<EventUpdateWithoutRelatedMassMessagesInput>;
  upsert?: InputMaybe<EventUpsertWithoutRelatedMassMessagesInput>;
};

export type EventUpdateOneWithoutRelatedTalksMessagesInput = {
  connect?: InputMaybe<EventWhereUniqueInput>;
  connectOrCreate?: InputMaybe<EventCreateOrConnectWithoutRelatedTalksMessagesInput>;
  create?: InputMaybe<EventCreateWithoutRelatedTalksMessagesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<EventUpdateWithoutRelatedTalksMessagesInput>;
  upsert?: InputMaybe<EventUpsertWithoutRelatedTalksMessagesInput>;
};

export type EventUpdateWithWhereUniqueWithoutCategoriesInput = {
  data: EventUpdateWithoutCategoriesInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutCheckedInUsersInput = {
  data: EventUpdateWithoutCheckedInUsersInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutCreatorInput = {
  data: EventUpdateWithoutCreatorInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutFeedInput = {
  data: EventUpdateWithoutFeedInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutInvitedUsersInput = {
  data: EventUpdateWithoutInvitedUsersInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutParentCommunityInput = {
  data: EventUpdateWithoutParentCommunityInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutRegisteredUsersInput = {
  data: EventUpdateWithoutRegisteredUsersInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutRelatedCommunitiesInput = {
  data: EventUpdateWithoutRelatedCommunitiesInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithWhereUniqueWithoutTicketedUsersInput = {
  data: EventUpdateWithoutTicketedUsersInput;
  where: EventWhereUniqueInput;
};

export type EventUpdateWithoutCategoriesInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutCheckedInUsersInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutCreatorInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutFeedInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutInvitedUsersInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutParentCommunityInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutProductsInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutRegisteredUsersInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutRelatedCommunitiesInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutRelatedHighlightsInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutRelatedMassMessagesInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutRelatedTalksMessagesInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketedUsers?: InputMaybe<UserUpdateManyWithoutTicketsEventsInput>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpdateWithoutTicketedUsersInput = {
  additionalDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  allowFreeGuestList?: InputMaybe<BoolFieldUpdateOperationsInput>;
  buyTicketsLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calendarSeenBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  calenderHideFrom?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<EventCategoryUpdateManyWithoutEventsInput>;
  checkedInUsers?: InputMaybe<UserUpdateManyWithoutCheckedInEventsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  creator?: InputMaybe<UserUpdateOneWithoutCreatedEventsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  eventDetails?: InputMaybe<EventDetailUpdateManyWithoutEventInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  feed?: InputMaybe<FeedItemUpdateManyWithoutEventsInput>;
  freeGuestListProductId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  host?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  iconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  infoStepsComplete?: InputMaybe<IntFieldUpdateOperationsInput>;
  invitedUsers?: InputMaybe<UserUpdateManyWithoutInvitedEventsInput>;
  isLocked?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isMaster?: InputMaybe<BoolFieldUpdateOperationsInput>;
  isPrivate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFieldUpdateOperationsInput>;
  locationDescription?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  locationDropNotificationSent?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  locationDropTime?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutEventsInput>;
  prePurchaseCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutEventInput>;
  publishingStatus?: InputMaybe<EnumEventStatusFieldUpdateOperationsInput>;
  registerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  registeredUsers?: InputMaybe<UserUpdateManyWithoutRegisteredEventsInput>;
  registrationRequired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  relatedCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedEventsInput>;
  relatedHighlights?: InputMaybe<HighlightUpdateManyWithoutEventInput>;
  relatedMassMessages?: InputMaybe<MassMessageUpdateManyWithoutEventInput>;
  relatedTalksMessages?: InputMaybe<TalksMessageUpdateManyWithoutEventInput>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFieldUpdateOperationsInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  ticketsSold?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  venueName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type EventUpsertWithWhereUniqueWithoutCategoriesInput = {
  create: EventCreateWithoutCategoriesInput;
  update: EventUpdateWithoutCategoriesInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutCheckedInUsersInput = {
  create: EventCreateWithoutCheckedInUsersInput;
  update: EventUpdateWithoutCheckedInUsersInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutCreatorInput = {
  create: EventCreateWithoutCreatorInput;
  update: EventUpdateWithoutCreatorInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutFeedInput = {
  create: EventCreateWithoutFeedInput;
  update: EventUpdateWithoutFeedInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutInvitedUsersInput = {
  create: EventCreateWithoutInvitedUsersInput;
  update: EventUpdateWithoutInvitedUsersInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutParentCommunityInput = {
  create: EventCreateWithoutParentCommunityInput;
  update: EventUpdateWithoutParentCommunityInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutRegisteredUsersInput = {
  create: EventCreateWithoutRegisteredUsersInput;
  update: EventUpdateWithoutRegisteredUsersInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutRelatedCommunitiesInput = {
  create: EventCreateWithoutRelatedCommunitiesInput;
  update: EventUpdateWithoutRelatedCommunitiesInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithWhereUniqueWithoutTicketedUsersInput = {
  create: EventCreateWithoutTicketedUsersInput;
  update: EventUpdateWithoutTicketedUsersInput;
  where: EventWhereUniqueInput;
};

export type EventUpsertWithoutProductsInput = {
  create: EventCreateWithoutProductsInput;
  update: EventUpdateWithoutProductsInput;
};

export type EventUpsertWithoutRelatedHighlightsInput = {
  create: EventCreateWithoutRelatedHighlightsInput;
  update: EventUpdateWithoutRelatedHighlightsInput;
};

export type EventUpsertWithoutRelatedMassMessagesInput = {
  create: EventCreateWithoutRelatedMassMessagesInput;
  update: EventUpdateWithoutRelatedMassMessagesInput;
};

export type EventUpsertWithoutRelatedTalksMessagesInput = {
  create: EventCreateWithoutRelatedTalksMessagesInput;
  update: EventUpdateWithoutRelatedTalksMessagesInput;
};

export type EventWhereInput = {
  AND?: InputMaybe<Array<EventWhereInput>>;
  NOT?: InputMaybe<Array<EventWhereInput>>;
  OR?: InputMaybe<Array<EventWhereInput>>;
  additionalDetails?: InputMaybe<StringNullableFilter>;
  allowFreeGuestList?: InputMaybe<BoolFilter>;
  buyTicketsLink?: InputMaybe<StringNullableFilter>;
  calendarSeenBy?: InputMaybe<StringNullableFilter>;
  calenderHideFrom?: InputMaybe<StringNullableFilter>;
  categories?: InputMaybe<EventCategoryListRelationFilter>;
  checkedInUsers?: InputMaybe<UserListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creator?: InputMaybe<UserWhereInput>;
  creatorId?: InputMaybe<StringNullableFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  eventDetails?: InputMaybe<EventDetailListRelationFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  feed?: InputMaybe<FeedItemListRelationFilter>;
  freeGuestListProductId?: InputMaybe<StringNullableFilter>;
  host?: InputMaybe<StringNullableFilter>;
  iconUrl?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  infoStepsComplete?: InputMaybe<IntFilter>;
  invitedUsers?: InputMaybe<UserListRelationFilter>;
  isLocked?: InputMaybe<BoolFilter>;
  isMaster?: InputMaybe<BoolFilter>;
  isPrivate?: InputMaybe<BoolFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  location?: InputMaybe<StringNullableFilter>;
  locationAvailability?: InputMaybe<EnumEventLocationAvailabilityFilter>;
  locationDescription?: InputMaybe<StringNullableFilter>;
  locationDropNotificationSent?: InputMaybe<BoolNullableFilter>;
  locationDropTime?: InputMaybe<DateTimeNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCommunity?: InputMaybe<CommunityWhereInput>;
  parentCommunityId?: InputMaybe<StringFilter>;
  prePurchaseCode?: InputMaybe<StringNullableFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  publishingStatus?: InputMaybe<EnumEventStatusFilter>;
  registerLink?: InputMaybe<StringNullableFilter>;
  registeredUsers?: InputMaybe<UserListRelationFilter>;
  registrationRequired?: InputMaybe<BoolFilter>;
  relatedCommunities?: InputMaybe<CommunityListRelationFilter>;
  relatedHighlights?: InputMaybe<HighlightListRelationFilter>;
  relatedMassMessages?: InputMaybe<MassMessageListRelationFilter>;
  relatedTalksMessages?: InputMaybe<TalksMessageListRelationFilter>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<BoolFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  ticketedUsers?: InputMaybe<UserListRelationFilter>;
  ticketsSold?: InputMaybe<IntFilter>;
  type?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  venueName?: InputMaybe<StringNullableFilter>;
};

export type EventWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type EventsV2Response = {
  __typename?: 'EventsV2Response';
  items: Array<Event>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type ExploreCommunitiesResponse = {
  __typename?: 'ExploreCommunitiesResponse';
  items: Array<ExploreCommunity>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type ExploreCommunity = {
  __typename?: 'ExploreCommunity';
  allowJoin: Scalars['Boolean'];
  allowOpen: Scalars['Boolean'];
  communityCode: Scalars['String'];
  communityDescription?: Maybe<Scalars['String']>;
  communityIconUrl: Scalars['String'];
  communityInviteCode: Scalars['String'];
  communityName: Scalars['String'];
  membershipStatus: Scalars['String'];
};

export type FaqCategory = {
  __typename?: 'FaqCategory';
  faqItems: Array<FaqItem>;
  id: Scalars['String'];
  name: Scalars['String'];
  order: Scalars['Int'];
};


export type FaqCategoryFaqItemsArgs = {
  cursor?: InputMaybe<FaqItemWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type FaqCategoryCreateInput = {
  faqItems?: InputMaybe<FaqItemCreateNestedManyWithoutCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type FaqCategoryCreateNestedOneWithoutFaqItemsInput = {
  connect?: InputMaybe<FaqCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FaqCategoryCreateOrConnectWithoutFaqItemsInput>;
  create?: InputMaybe<FaqCategoryCreateWithoutFaqItemsInput>;
};

export type FaqCategoryCreateOrConnectWithoutFaqItemsInput = {
  create: FaqCategoryCreateWithoutFaqItemsInput;
  where: FaqCategoryWhereUniqueInput;
};

export type FaqCategoryCreateWithoutFaqItemsInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
};

export type FaqCategoryOrderByInput = {
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
};

export type FaqCategoryUpdateInput = {
  faqItems?: InputMaybe<FaqItemUpdateManyWithoutCategoryInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type FaqCategoryUpdateManyMutationInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type FaqCategoryUpdateOneRequiredWithoutFaqItemsInput = {
  connect?: InputMaybe<FaqCategoryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FaqCategoryCreateOrConnectWithoutFaqItemsInput>;
  create?: InputMaybe<FaqCategoryCreateWithoutFaqItemsInput>;
  update?: InputMaybe<FaqCategoryUpdateWithoutFaqItemsInput>;
  upsert?: InputMaybe<FaqCategoryUpsertWithoutFaqItemsInput>;
};

export type FaqCategoryUpdateWithoutFaqItemsInput = {
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type FaqCategoryUpsertWithoutFaqItemsInput = {
  create: FaqCategoryCreateWithoutFaqItemsInput;
  update: FaqCategoryUpdateWithoutFaqItemsInput;
};

export type FaqCategoryWhereInput = {
  AND?: InputMaybe<Array<FaqCategoryWhereInput>>;
  NOT?: InputMaybe<Array<FaqCategoryWhereInput>>;
  OR?: InputMaybe<Array<FaqCategoryWhereInput>>;
  faqItems?: InputMaybe<FaqItemListRelationFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
};

export type FaqCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FaqItem = {
  __typename?: 'FaqItem';
  answer: Scalars['String'];
  category: FaqCategory;
  categoryId: Scalars['String'];
  id: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  parentCommunity?: Maybe<Community>;
  parentCommunityId?: Maybe<Scalars['String']>;
  question: Scalars['String'];
};

export type FaqItemCreateInput = {
  answer?: InputMaybe<Scalars['String']>;
  category: FaqCategoryCreateNestedOneWithoutFaqItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutFaqItemsInput>;
  question?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FaqItemCreateManyCategoryInput = {
  answer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCommunityId?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FaqItemCreateManyCategoryInputEnvelope = {
  data?: InputMaybe<Array<FaqItemCreateManyCategoryInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FaqItemCreateManyParentCommunityInput = {
  answer?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FaqItemCreateManyParentCommunityInputEnvelope = {
  data?: InputMaybe<Array<FaqItemCreateManyParentCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FaqItemCreateNestedManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FaqItemCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<FaqItemCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<FaqItemCreateManyCategoryInputEnvelope>;
};

export type FaqItemCreateNestedManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FaqItemCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<FaqItemCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<FaqItemCreateManyParentCommunityInputEnvelope>;
};

export type FaqItemCreateOrConnectWithoutCategoryInput = {
  create: FaqItemCreateWithoutCategoryInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemCreateOrConnectWithoutParentCommunityInput = {
  create: FaqItemCreateWithoutParentCommunityInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemCreateWithoutCategoryInput = {
  answer?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutFaqItemsInput>;
  question?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FaqItemCreateWithoutParentCommunityInput = {
  answer?: InputMaybe<Scalars['String']>;
  category: FaqCategoryCreateNestedOneWithoutFaqItemsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FaqItemListRelationFilter = {
  every?: InputMaybe<FaqItemWhereInput>;
  none?: InputMaybe<FaqItemWhereInput>;
  some?: InputMaybe<FaqItemWhereInput>;
};

export type FaqItemOrderByInput = {
  answer?: InputMaybe<SortOrder>;
  categoryId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  parentCommunityId?: InputMaybe<SortOrder>;
  question?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FaqItemScalarWhereInput = {
  AND?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  OR?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  answer?: InputMaybe<StringFilter>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntNullableFilter>;
  parentCommunityId?: InputMaybe<StringNullableFilter>;
  question?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FaqItemUpdateInput = {
  answer?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<FaqCategoryUpdateOneRequiredWithoutFaqItemsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutFaqItemsInput>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FaqItemUpdateManyMutationInput = {
  answer?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FaqItemUpdateManyWithWhereWithoutCategoryInput = {
  data: FaqItemUpdateManyMutationInput;
  where: FaqItemScalarWhereInput;
};

export type FaqItemUpdateManyWithWhereWithoutParentCommunityInput = {
  data: FaqItemUpdateManyMutationInput;
  where: FaqItemScalarWhereInput;
};

export type FaqItemUpdateManyWithoutCategoryInput = {
  connect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FaqItemCreateOrConnectWithoutCategoryInput>>;
  create?: InputMaybe<Array<FaqItemCreateWithoutCategoryInput>>;
  createMany?: InputMaybe<FaqItemCreateManyCategoryInputEnvelope>;
  delete?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  set?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  update?: InputMaybe<Array<FaqItemUpdateWithWhereUniqueWithoutCategoryInput>>;
  updateMany?: InputMaybe<Array<FaqItemUpdateManyWithWhereWithoutCategoryInput>>;
  upsert?: InputMaybe<Array<FaqItemUpsertWithWhereUniqueWithoutCategoryInput>>;
};

export type FaqItemUpdateManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FaqItemCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<FaqItemCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<FaqItemCreateManyParentCommunityInputEnvelope>;
  delete?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FaqItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  set?: InputMaybe<Array<FaqItemWhereUniqueInput>>;
  update?: InputMaybe<Array<FaqItemUpdateWithWhereUniqueWithoutParentCommunityInput>>;
  updateMany?: InputMaybe<Array<FaqItemUpdateManyWithWhereWithoutParentCommunityInput>>;
  upsert?: InputMaybe<Array<FaqItemUpsertWithWhereUniqueWithoutParentCommunityInput>>;
};

export type FaqItemUpdateWithWhereUniqueWithoutCategoryInput = {
  data: FaqItemUpdateWithoutCategoryInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemUpdateWithWhereUniqueWithoutParentCommunityInput = {
  data: FaqItemUpdateWithoutParentCommunityInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemUpdateWithoutCategoryInput = {
  answer?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutFaqItemsInput>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FaqItemUpdateWithoutParentCommunityInput = {
  answer?: InputMaybe<StringFieldUpdateOperationsInput>;
  category?: InputMaybe<FaqCategoryUpdateOneRequiredWithoutFaqItemsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  question?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FaqItemUpsertWithWhereUniqueWithoutCategoryInput = {
  create: FaqItemCreateWithoutCategoryInput;
  update: FaqItemUpdateWithoutCategoryInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemUpsertWithWhereUniqueWithoutParentCommunityInput = {
  create: FaqItemCreateWithoutParentCommunityInput;
  update: FaqItemUpdateWithoutParentCommunityInput;
  where: FaqItemWhereUniqueInput;
};

export type FaqItemWhereInput = {
  AND?: InputMaybe<Array<FaqItemWhereInput>>;
  NOT?: InputMaybe<Array<FaqItemWhereInput>>;
  OR?: InputMaybe<Array<FaqItemWhereInput>>;
  answer?: InputMaybe<StringFilter>;
  category?: InputMaybe<FaqCategoryWhereInput>;
  categoryId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntNullableFilter>;
  parentCommunity?: InputMaybe<CommunityWhereInput>;
  parentCommunityId?: InputMaybe<StringNullableFilter>;
  question?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FaqItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FeaturedItem = {
  __typename?: 'FeaturedItem';
  iconUrl: Scalars['String'];
  id: Scalars['String'];
  inAppLink: Scalars['String'];
  mediaUrl: Scalars['String'];
  price: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type FeedCategoryCreateNestedManyWithoutItemsInput = {
  connect?: InputMaybe<Array<FeedCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedCategoryCreateOrConnectWithoutItemsInput>>;
  create?: InputMaybe<Array<FeedCategoryCreateWithoutItemsInput>>;
};

export type FeedCategoryCreateOrConnectWithoutItemsInput = {
  create: FeedCategoryCreateWithoutItemsInput;
  where: FeedCategoryWhereUniqueInput;
};

export type FeedCategoryCreateWithoutItemsInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedCategoryListRelationFilter = {
  every?: InputMaybe<FeedCategoryWhereInput>;
  none?: InputMaybe<FeedCategoryWhereInput>;
  some?: InputMaybe<FeedCategoryWhereInput>;
};

export type FeedCategoryScalarWhereInput = {
  AND?: InputMaybe<Array<FeedCategoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<FeedCategoryScalarWhereInput>>;
  OR?: InputMaybe<Array<FeedCategoryScalarWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FeedCategoryUpdateManyMutationInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedCategoryUpdateManyWithWhereWithoutItemsInput = {
  data: FeedCategoryUpdateManyMutationInput;
  where: FeedCategoryScalarWhereInput;
};

export type FeedCategoryUpdateManyWithoutItemsInput = {
  connect?: InputMaybe<Array<FeedCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedCategoryCreateOrConnectWithoutItemsInput>>;
  create?: InputMaybe<Array<FeedCategoryCreateWithoutItemsInput>>;
  delete?: InputMaybe<Array<FeedCategoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedCategoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedCategoryWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedCategoryWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedCategoryUpdateWithWhereUniqueWithoutItemsInput>>;
  updateMany?: InputMaybe<Array<FeedCategoryUpdateManyWithWhereWithoutItemsInput>>;
  upsert?: InputMaybe<Array<FeedCategoryUpsertWithWhereUniqueWithoutItemsInput>>;
};

export type FeedCategoryUpdateWithWhereUniqueWithoutItemsInput = {
  data: FeedCategoryUpdateWithoutItemsInput;
  where: FeedCategoryWhereUniqueInput;
};

export type FeedCategoryUpdateWithoutItemsInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedCategoryUpsertWithWhereUniqueWithoutItemsInput = {
  create: FeedCategoryCreateWithoutItemsInput;
  update: FeedCategoryUpdateWithoutItemsInput;
  where: FeedCategoryWhereUniqueInput;
};

export type FeedCategoryWhereInput = {
  AND?: InputMaybe<Array<FeedCategoryWhereInput>>;
  NOT?: InputMaybe<Array<FeedCategoryWhereInput>>;
  OR?: InputMaybe<Array<FeedCategoryWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  items?: InputMaybe<FeedItemListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FeedCategoryWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type FeedItem = {
  __typename?: 'FeedItem';
  author?: Maybe<Scalars['String']>;
  commentsCount?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  ctaButtonTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  downvoted: Array<Event>;
  downvotes?: Maybe<Scalars['Int']>;
  events: Array<Event>;
  externalUrl?: Maybe<Scalars['String']>;
  headlineType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  liked?: Maybe<Scalars['Boolean']>;
  likesBoost: Scalars['Int'];
  likesCount?: Maybe<Scalars['Int']>;
  media: Array<Scalars['String']>;
  mediaPreviewUrl?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  missingFields: Array<Scalars['String']>;
  parentCommunity: Community;
  publishedAt?: Maybe<Scalars['DateTime']>;
  publishingStatus: FeedItemPublishingStatus;
  subtitle?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  trailerLink?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  upvoted: Array<Event>;
  upvotes?: Maybe<Scalars['Int']>;
  watchLink?: Maybe<Scalars['String']>;
};


export type FeedItemDownvotedArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type FeedItemEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type FeedItemUpvotedArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};

export type FeedItemComment = {
  __typename?: 'FeedItemComment';
  author: User;
  createdAt: Scalars['DateTime'];
  feedItem: FeedItem;
  id: Scalars['String'];
  liked?: Maybe<Scalars['Boolean']>;
  likesCount?: Maybe<Scalars['Int']>;
  media: Array<Scalars['String']>;
  parentCommentId?: Maybe<Scalars['String']>;
  replies: Array<FeedItemComment>;
  status: FeedItemCommentStatus;
  text: Scalars['String'];
};


export type FeedItemCommentRepliesArgs = {
  cursor?: InputMaybe<FeedItemCommentWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type FeedItemCommentCreateInput = {
  author: UserCreateNestedOneWithoutFeedItemCommentsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  feedItem: FeedItemCreateNestedOneWithoutCommentsInput;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentCreatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentCreateNestedOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateManyAuthorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  feedItemId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<FeedItemCommentCreateManymediaInput>;
  parentCommentId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateManyAuthorInputEnvelope = {
  data?: InputMaybe<Array<FeedItemCommentCreateManyAuthorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FeedItemCommentCreateManyFeedItemInput = {
  authorId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<FeedItemCommentCreateManymediaInput>;
  parentCommentId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateManyFeedItemInputEnvelope = {
  data?: InputMaybe<Array<FeedItemCommentCreateManyFeedItemInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FeedItemCommentCreateManyParentCommentInput = {
  authorId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  feedItemId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<FeedItemCommentCreateManymediaInput>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateManyParentCommentInputEnvelope = {
  data?: InputMaybe<Array<FeedItemCommentCreateManyParentCommentInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FeedItemCommentCreateManymediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedItemCommentCreateNestedManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<FeedItemCommentCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<FeedItemCommentCreateManyAuthorInputEnvelope>;
};

export type FeedItemCommentCreateNestedManyWithoutFeedItemInput = {
  connect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentCreateOrConnectWithoutFeedItemInput>>;
  create?: InputMaybe<Array<FeedItemCommentCreateWithoutFeedItemInput>>;
  createMany?: InputMaybe<FeedItemCommentCreateManyFeedItemInputEnvelope>;
};

export type FeedItemCommentCreateNestedManyWithoutLikesInput = {
  connect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentCreateOrConnectWithoutLikesInput>>;
  create?: InputMaybe<Array<FeedItemCommentCreateWithoutLikesInput>>;
};

export type FeedItemCommentCreateNestedManyWithoutParentCommentInput = {
  connect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentCreateOrConnectWithoutParentCommentInput>>;
  create?: InputMaybe<Array<FeedItemCommentCreateWithoutParentCommentInput>>;
  createMany?: InputMaybe<FeedItemCommentCreateManyParentCommentInputEnvelope>;
};

export type FeedItemCommentCreateNestedOneWithoutRepliesInput = {
  connect?: InputMaybe<FeedItemCommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedItemCommentCreateOrConnectWithoutRepliesInput>;
  create?: InputMaybe<FeedItemCommentCreateWithoutRepliesInput>;
};

export type FeedItemCommentCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<FeedItemCommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedItemCommentCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<FeedItemCommentCreateWithoutReportsInput>;
};

export type FeedItemCommentCreateOrConnectWithoutAuthorInput = {
  create: FeedItemCommentCreateWithoutAuthorInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentCreateOrConnectWithoutFeedItemInput = {
  create: FeedItemCommentCreateWithoutFeedItemInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentCreateOrConnectWithoutLikesInput = {
  create: FeedItemCommentCreateWithoutLikesInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentCreateOrConnectWithoutParentCommentInput = {
  create: FeedItemCommentCreateWithoutParentCommentInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentCreateOrConnectWithoutRepliesInput = {
  create: FeedItemCommentCreateWithoutRepliesInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentCreateOrConnectWithoutReportsInput = {
  create: FeedItemCommentCreateWithoutReportsInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentCreateWithoutAuthorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  feedItem: FeedItemCreateNestedOneWithoutCommentsInput;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentCreatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentCreateNestedOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateWithoutFeedItemInput = {
  author: UserCreateNestedOneWithoutFeedItemCommentsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentCreatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentCreateNestedOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateWithoutLikesInput = {
  author: UserCreateNestedOneWithoutFeedItemCommentsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  feedItem: FeedItemCreateNestedOneWithoutCommentsInput;
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<FeedItemCommentCreatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentCreateNestedOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateWithoutParentCommentInput = {
  author: UserCreateNestedOneWithoutFeedItemCommentsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  feedItem: FeedItemCreateNestedOneWithoutCommentsInput;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentCreatemediaInput>;
  replies?: InputMaybe<FeedItemCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateWithoutRepliesInput = {
  author: UserCreateNestedOneWithoutFeedItemCommentsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  feedItem: FeedItemCreateNestedOneWithoutCommentsInput;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentCreatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentCreateNestedOneWithoutRepliesInput>;
  reports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreateWithoutReportsInput = {
  author: UserCreateNestedOneWithoutFeedItemCommentsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  feedItem: FeedItemCreateNestedOneWithoutCommentsInput;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentCreatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentCreateNestedOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentCreateNestedManyWithoutParentCommentInput>;
  status?: InputMaybe<FeedItemCommentStatus>;
  text: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentCreatemediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedItemCommentListRelationFilter = {
  every?: InputMaybe<FeedItemCommentWhereInput>;
  none?: InputMaybe<FeedItemCommentWhereInput>;
  some?: InputMaybe<FeedItemCommentWhereInput>;
};

export type FeedItemCommentOrderByInput = {
  authorId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  feedItemId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  media?: InputMaybe<SortOrder>;
  parentCommentId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FeedItemCommentReport = {
  __typename?: 'FeedItemCommentReport';
  author: User;
  comment: FeedItemComment;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  reason: Scalars['String'];
  status: FeedItemCommentReportStatus;
};

export type FeedItemCommentReportCreateInput = {
  author: UserCreateNestedOneWithoutFeedItemCommentReportsInput;
  comment: FeedItemCommentCreateNestedOneWithoutReportsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  status?: InputMaybe<FeedItemCommentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentReportCreateManyAuthorInput = {
  commentId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  status?: InputMaybe<FeedItemCommentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentReportCreateManyAuthorInputEnvelope = {
  data?: InputMaybe<Array<FeedItemCommentReportCreateManyAuthorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FeedItemCommentReportCreateManyCommentInput = {
  authorId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  status?: InputMaybe<FeedItemCommentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentReportCreateManyCommentInputEnvelope = {
  data?: InputMaybe<Array<FeedItemCommentReportCreateManyCommentInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FeedItemCommentReportCreateNestedManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentReportCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<FeedItemCommentReportCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<FeedItemCommentReportCreateManyAuthorInputEnvelope>;
};

export type FeedItemCommentReportCreateNestedManyWithoutCommentInput = {
  connect?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentReportCreateOrConnectWithoutCommentInput>>;
  create?: InputMaybe<Array<FeedItemCommentReportCreateWithoutCommentInput>>;
  createMany?: InputMaybe<FeedItemCommentReportCreateManyCommentInputEnvelope>;
};

export type FeedItemCommentReportCreateOrConnectWithoutAuthorInput = {
  create: FeedItemCommentReportCreateWithoutAuthorInput;
  where: FeedItemCommentReportWhereUniqueInput;
};

export type FeedItemCommentReportCreateOrConnectWithoutCommentInput = {
  create: FeedItemCommentReportCreateWithoutCommentInput;
  where: FeedItemCommentReportWhereUniqueInput;
};

export type FeedItemCommentReportCreateWithoutAuthorInput = {
  comment: FeedItemCommentCreateNestedOneWithoutReportsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  status?: InputMaybe<FeedItemCommentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentReportCreateWithoutCommentInput = {
  author: UserCreateNestedOneWithoutFeedItemCommentReportsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  status?: InputMaybe<FeedItemCommentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FeedItemCommentReportListRelationFilter = {
  every?: InputMaybe<FeedItemCommentReportWhereInput>;
  none?: InputMaybe<FeedItemCommentReportWhereInput>;
  some?: InputMaybe<FeedItemCommentReportWhereInput>;
};

export type FeedItemCommentReportOrderByInput = {
  authorId?: InputMaybe<SortOrder>;
  commentId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FeedItemCommentReportScalarWhereInput = {
  AND?: InputMaybe<Array<FeedItemCommentReportScalarWhereInput>>;
  NOT?: InputMaybe<Array<FeedItemCommentReportScalarWhereInput>>;
  OR?: InputMaybe<Array<FeedItemCommentReportScalarWhereInput>>;
  authorId?: InputMaybe<StringFilter>;
  commentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumFeedItemCommentReportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum FeedItemCommentReportStatus {
  Approved = 'Approved',
  Declined = 'Declined',
  Submitted = 'Submitted'
}

export type FeedItemCommentReportUpdateInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutFeedItemCommentReportsInput>;
  comment?: InputMaybe<FeedItemCommentUpdateOneRequiredWithoutReportsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumFeedItemCommentReportStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentReportUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumFeedItemCommentReportStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentReportUpdateManyWithWhereWithoutAuthorInput = {
  data: FeedItemCommentReportUpdateManyMutationInput;
  where: FeedItemCommentReportScalarWhereInput;
};

export type FeedItemCommentReportUpdateManyWithWhereWithoutCommentInput = {
  data: FeedItemCommentReportUpdateManyMutationInput;
  where: FeedItemCommentReportScalarWhereInput;
};

export type FeedItemCommentReportUpdateManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentReportCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<FeedItemCommentReportCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<FeedItemCommentReportCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemCommentReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemCommentReportUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: InputMaybe<Array<FeedItemCommentReportUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: InputMaybe<Array<FeedItemCommentReportUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type FeedItemCommentReportUpdateManyWithoutCommentInput = {
  connect?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentReportCreateOrConnectWithoutCommentInput>>;
  create?: InputMaybe<Array<FeedItemCommentReportCreateWithoutCommentInput>>;
  createMany?: InputMaybe<FeedItemCommentReportCreateManyCommentInputEnvelope>;
  delete?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemCommentReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemCommentReportWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemCommentReportUpdateWithWhereUniqueWithoutCommentInput>>;
  updateMany?: InputMaybe<Array<FeedItemCommentReportUpdateManyWithWhereWithoutCommentInput>>;
  upsert?: InputMaybe<Array<FeedItemCommentReportUpsertWithWhereUniqueWithoutCommentInput>>;
};

export type FeedItemCommentReportUpdateWithWhereUniqueWithoutAuthorInput = {
  data: FeedItemCommentReportUpdateWithoutAuthorInput;
  where: FeedItemCommentReportWhereUniqueInput;
};

export type FeedItemCommentReportUpdateWithWhereUniqueWithoutCommentInput = {
  data: FeedItemCommentReportUpdateWithoutCommentInput;
  where: FeedItemCommentReportWhereUniqueInput;
};

export type FeedItemCommentReportUpdateWithoutAuthorInput = {
  comment?: InputMaybe<FeedItemCommentUpdateOneRequiredWithoutReportsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumFeedItemCommentReportStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentReportUpdateWithoutCommentInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutFeedItemCommentReportsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumFeedItemCommentReportStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentReportUpsertWithWhereUniqueWithoutAuthorInput = {
  create: FeedItemCommentReportCreateWithoutAuthorInput;
  update: FeedItemCommentReportUpdateWithoutAuthorInput;
  where: FeedItemCommentReportWhereUniqueInput;
};

export type FeedItemCommentReportUpsertWithWhereUniqueWithoutCommentInput = {
  create: FeedItemCommentReportCreateWithoutCommentInput;
  update: FeedItemCommentReportUpdateWithoutCommentInput;
  where: FeedItemCommentReportWhereUniqueInput;
};

export type FeedItemCommentReportWhereInput = {
  AND?: InputMaybe<Array<FeedItemCommentReportWhereInput>>;
  NOT?: InputMaybe<Array<FeedItemCommentReportWhereInput>>;
  OR?: InputMaybe<Array<FeedItemCommentReportWhereInput>>;
  author?: InputMaybe<UserWhereInput>;
  authorId?: InputMaybe<StringFilter>;
  comment?: InputMaybe<FeedItemCommentWhereInput>;
  commentId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumFeedItemCommentReportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FeedItemCommentReportWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FeedItemCommentScalarWhereInput = {
  AND?: InputMaybe<Array<FeedItemCommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<FeedItemCommentScalarWhereInput>>;
  OR?: InputMaybe<Array<FeedItemCommentScalarWhereInput>>;
  authorId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  feedItemId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  parentCommentId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumFeedItemCommentStatusFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum FeedItemCommentStatus {
  Deleted = 'Deleted',
  Hidden = 'Hidden',
  Published = 'Published'
}

export type FeedItemCommentUpdateInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutFeedItemCommentsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedItem?: InputMaybe<FeedItemUpdateOneRequiredWithoutCommentsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentUpdatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentUpdateOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumFeedItemCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  media?: InputMaybe<FeedItemCommentUpdatemediaInput>;
  status?: InputMaybe<EnumFeedItemCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentUpdateManyWithWhereWithoutAuthorInput = {
  data: FeedItemCommentUpdateManyMutationInput;
  where: FeedItemCommentScalarWhereInput;
};

export type FeedItemCommentUpdateManyWithWhereWithoutFeedItemInput = {
  data: FeedItemCommentUpdateManyMutationInput;
  where: FeedItemCommentScalarWhereInput;
};

export type FeedItemCommentUpdateManyWithWhereWithoutLikesInput = {
  data: FeedItemCommentUpdateManyMutationInput;
  where: FeedItemCommentScalarWhereInput;
};

export type FeedItemCommentUpdateManyWithWhereWithoutParentCommentInput = {
  data: FeedItemCommentUpdateManyMutationInput;
  where: FeedItemCommentScalarWhereInput;
};

export type FeedItemCommentUpdateManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<FeedItemCommentCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<FeedItemCommentCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemCommentUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: InputMaybe<Array<FeedItemCommentUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: InputMaybe<Array<FeedItemCommentUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type FeedItemCommentUpdateManyWithoutFeedItemInput = {
  connect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentCreateOrConnectWithoutFeedItemInput>>;
  create?: InputMaybe<Array<FeedItemCommentCreateWithoutFeedItemInput>>;
  createMany?: InputMaybe<FeedItemCommentCreateManyFeedItemInputEnvelope>;
  delete?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemCommentUpdateWithWhereUniqueWithoutFeedItemInput>>;
  updateMany?: InputMaybe<Array<FeedItemCommentUpdateManyWithWhereWithoutFeedItemInput>>;
  upsert?: InputMaybe<Array<FeedItemCommentUpsertWithWhereUniqueWithoutFeedItemInput>>;
};

export type FeedItemCommentUpdateManyWithoutLikesInput = {
  connect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentCreateOrConnectWithoutLikesInput>>;
  create?: InputMaybe<Array<FeedItemCommentCreateWithoutLikesInput>>;
  delete?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemCommentUpdateWithWhereUniqueWithoutLikesInput>>;
  updateMany?: InputMaybe<Array<FeedItemCommentUpdateManyWithWhereWithoutLikesInput>>;
  upsert?: InputMaybe<Array<FeedItemCommentUpsertWithWhereUniqueWithoutLikesInput>>;
};

export type FeedItemCommentUpdateManyWithoutParentCommentInput = {
  connect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCommentCreateOrConnectWithoutParentCommentInput>>;
  create?: InputMaybe<Array<FeedItemCommentCreateWithoutParentCommentInput>>;
  createMany?: InputMaybe<FeedItemCommentCreateManyParentCommentInputEnvelope>;
  delete?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemCommentUpdateWithWhereUniqueWithoutParentCommentInput>>;
  updateMany?: InputMaybe<Array<FeedItemCommentUpdateManyWithWhereWithoutParentCommentInput>>;
  upsert?: InputMaybe<Array<FeedItemCommentUpsertWithWhereUniqueWithoutParentCommentInput>>;
};

export type FeedItemCommentUpdateOneRequiredWithoutReportsInput = {
  connect?: InputMaybe<FeedItemCommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedItemCommentCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<FeedItemCommentCreateWithoutReportsInput>;
  update?: InputMaybe<FeedItemCommentUpdateWithoutReportsInput>;
  upsert?: InputMaybe<FeedItemCommentUpsertWithoutReportsInput>;
};

export type FeedItemCommentUpdateOneWithoutRepliesInput = {
  connect?: InputMaybe<FeedItemCommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedItemCommentCreateOrConnectWithoutRepliesInput>;
  create?: InputMaybe<FeedItemCommentCreateWithoutRepliesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<FeedItemCommentUpdateWithoutRepliesInput>;
  upsert?: InputMaybe<FeedItemCommentUpsertWithoutRepliesInput>;
};

export type FeedItemCommentUpdateWithWhereUniqueWithoutAuthorInput = {
  data: FeedItemCommentUpdateWithoutAuthorInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentUpdateWithWhereUniqueWithoutFeedItemInput = {
  data: FeedItemCommentUpdateWithoutFeedItemInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentUpdateWithWhereUniqueWithoutLikesInput = {
  data: FeedItemCommentUpdateWithoutLikesInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentUpdateWithWhereUniqueWithoutParentCommentInput = {
  data: FeedItemCommentUpdateWithoutParentCommentInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentUpdateWithoutAuthorInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedItem?: InputMaybe<FeedItemUpdateOneRequiredWithoutCommentsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentUpdatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentUpdateOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumFeedItemCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentUpdateWithoutFeedItemInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutFeedItemCommentsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentUpdatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentUpdateOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumFeedItemCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentUpdateWithoutLikesInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutFeedItemCommentsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedItem?: InputMaybe<FeedItemUpdateOneRequiredWithoutCommentsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  media?: InputMaybe<FeedItemCommentUpdatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentUpdateOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumFeedItemCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentUpdateWithoutParentCommentInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutFeedItemCommentsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedItem?: InputMaybe<FeedItemUpdateOneRequiredWithoutCommentsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentUpdatemediaInput>;
  replies?: InputMaybe<FeedItemCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumFeedItemCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentUpdateWithoutRepliesInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutFeedItemCommentsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedItem?: InputMaybe<FeedItemUpdateOneRequiredWithoutCommentsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentUpdatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentUpdateOneWithoutRepliesInput>;
  reports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumFeedItemCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentUpdateWithoutReportsInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutFeedItemCommentsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  feedItem?: InputMaybe<FeedItemUpdateOneRequiredWithoutCommentsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutFeedItemCommentLikesInput>;
  media?: InputMaybe<FeedItemCommentUpdatemediaInput>;
  parentComment?: InputMaybe<FeedItemCommentUpdateOneWithoutRepliesInput>;
  replies?: InputMaybe<FeedItemCommentUpdateManyWithoutParentCommentInput>;
  status?: InputMaybe<EnumFeedItemCommentStatusFieldUpdateOperationsInput>;
  text?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type FeedItemCommentUpdatemediaInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedItemCommentUpsertWithWhereUniqueWithoutAuthorInput = {
  create: FeedItemCommentCreateWithoutAuthorInput;
  update: FeedItemCommentUpdateWithoutAuthorInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentUpsertWithWhereUniqueWithoutFeedItemInput = {
  create: FeedItemCommentCreateWithoutFeedItemInput;
  update: FeedItemCommentUpdateWithoutFeedItemInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentUpsertWithWhereUniqueWithoutLikesInput = {
  create: FeedItemCommentCreateWithoutLikesInput;
  update: FeedItemCommentUpdateWithoutLikesInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentUpsertWithWhereUniqueWithoutParentCommentInput = {
  create: FeedItemCommentCreateWithoutParentCommentInput;
  update: FeedItemCommentUpdateWithoutParentCommentInput;
  where: FeedItemCommentWhereUniqueInput;
};

export type FeedItemCommentUpsertWithoutRepliesInput = {
  create: FeedItemCommentCreateWithoutRepliesInput;
  update: FeedItemCommentUpdateWithoutRepliesInput;
};

export type FeedItemCommentUpsertWithoutReportsInput = {
  create: FeedItemCommentCreateWithoutReportsInput;
  update: FeedItemCommentUpdateWithoutReportsInput;
};

export type FeedItemCommentWhereInput = {
  AND?: InputMaybe<Array<FeedItemCommentWhereInput>>;
  NOT?: InputMaybe<Array<FeedItemCommentWhereInput>>;
  OR?: InputMaybe<Array<FeedItemCommentWhereInput>>;
  author?: InputMaybe<UserWhereInput>;
  authorId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  feedItem?: InputMaybe<FeedItemWhereInput>;
  feedItemId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  likes?: InputMaybe<UserListRelationFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  parentComment?: InputMaybe<FeedItemCommentWhereInput>;
  parentCommentId?: InputMaybe<StringNullableFilter>;
  replies?: InputMaybe<FeedItemCommentListRelationFilter>;
  reports?: InputMaybe<FeedItemCommentReportListRelationFilter>;
  status?: InputMaybe<EnumFeedItemCommentStatusFilter>;
  text?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FeedItemCommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FeedItemComments = {
  __typename?: 'FeedItemComments';
  comments: Array<FeedItemComment>;
  next: Scalars['Int'];
  totalComments: Scalars['Int'];
};

export type FeedItemCreateInput = {
  author?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<FeedCategoryCreateNestedManyWithoutItemsInput>;
  comments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutFeedItemInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ctaButtonTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<EventCreateNestedManyWithoutFeedInput>;
  externalId?: InputMaybe<Scalars['String']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  headlineType?: InputMaybe<HeadlineType>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedFeedItemsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<FeedItemCreatemediaInput>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  parentCommunity: CommunityCreateNestedOneWithoutFeedItemInput;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishingStatus?: InputMaybe<FeedItemPublishingStatus>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  trailerLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  watchLink?: InputMaybe<Scalars['String']>;
};

export type FeedItemCreateManyParentCommunityInput = {
  author?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ctaButtonTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  headlineType?: InputMaybe<HeadlineType>;
  id?: InputMaybe<Scalars['String']>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<FeedItemCreateManymediaInput>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishingStatus?: InputMaybe<FeedItemPublishingStatus>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  trailerLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  watchLink?: InputMaybe<Scalars['String']>;
};

export type FeedItemCreateManyParentCommunityInputEnvelope = {
  data?: InputMaybe<Array<FeedItemCreateManyParentCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FeedItemCreateManymediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedItemCreateNestedManyWithoutEventsInput = {
  connect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCreateOrConnectWithoutEventsInput>>;
  create?: InputMaybe<Array<FeedItemCreateWithoutEventsInput>>;
};

export type FeedItemCreateNestedManyWithoutLikesInput = {
  connect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCreateOrConnectWithoutLikesInput>>;
  create?: InputMaybe<Array<FeedItemCreateWithoutLikesInput>>;
};

export type FeedItemCreateNestedManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<FeedItemCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<FeedItemCreateManyParentCommunityInputEnvelope>;
};

export type FeedItemCreateNestedOneWithoutCommentsInput = {
  connect?: InputMaybe<FeedItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedItemCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<FeedItemCreateWithoutCommentsInput>;
};

export type FeedItemCreateOrConnectWithoutCommentsInput = {
  create: FeedItemCreateWithoutCommentsInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemCreateOrConnectWithoutEventsInput = {
  create: FeedItemCreateWithoutEventsInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemCreateOrConnectWithoutLikesInput = {
  create: FeedItemCreateWithoutLikesInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemCreateOrConnectWithoutParentCommunityInput = {
  create: FeedItemCreateWithoutParentCommunityInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemCreateWithoutCommentsInput = {
  author?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<FeedCategoryCreateNestedManyWithoutItemsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ctaButtonTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<EventCreateNestedManyWithoutFeedInput>;
  externalId?: InputMaybe<Scalars['String']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  headlineType?: InputMaybe<HeadlineType>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedFeedItemsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<FeedItemCreatemediaInput>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  parentCommunity: CommunityCreateNestedOneWithoutFeedItemInput;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishingStatus?: InputMaybe<FeedItemPublishingStatus>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  trailerLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  watchLink?: InputMaybe<Scalars['String']>;
};

export type FeedItemCreateWithoutEventsInput = {
  author?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<FeedCategoryCreateNestedManyWithoutItemsInput>;
  comments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutFeedItemInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ctaButtonTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  headlineType?: InputMaybe<HeadlineType>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedFeedItemsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<FeedItemCreatemediaInput>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  parentCommunity: CommunityCreateNestedOneWithoutFeedItemInput;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishingStatus?: InputMaybe<FeedItemPublishingStatus>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  trailerLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  watchLink?: InputMaybe<Scalars['String']>;
};

export type FeedItemCreateWithoutLikesInput = {
  author?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<FeedCategoryCreateNestedManyWithoutItemsInput>;
  comments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutFeedItemInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ctaButtonTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<EventCreateNestedManyWithoutFeedInput>;
  externalId?: InputMaybe<Scalars['String']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  headlineType?: InputMaybe<HeadlineType>;
  id?: InputMaybe<Scalars['String']>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<FeedItemCreatemediaInput>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  parentCommunity: CommunityCreateNestedOneWithoutFeedItemInput;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishingStatus?: InputMaybe<FeedItemPublishingStatus>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  trailerLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  watchLink?: InputMaybe<Scalars['String']>;
};

export type FeedItemCreateWithoutParentCommunityInput = {
  author?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<FeedCategoryCreateNestedManyWithoutItemsInput>;
  comments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutFeedItemInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  ctaButtonTitle?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  events?: InputMaybe<EventCreateNestedManyWithoutFeedInput>;
  externalId?: InputMaybe<Scalars['String']>;
  externalUrl?: InputMaybe<Scalars['String']>;
  headlineType?: InputMaybe<HeadlineType>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedFeedItemsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<FeedItemCreatemediaInput>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  publishedAt?: InputMaybe<Scalars['DateTime']>;
  publishingStatus?: InputMaybe<FeedItemPublishingStatus>;
  subtitle?: InputMaybe<Scalars['String']>;
  text?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  trailerLink?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  watchLink?: InputMaybe<Scalars['String']>;
};

export type FeedItemCreatemediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedItemListRelationFilter = {
  every?: InputMaybe<FeedItemWhereInput>;
  none?: InputMaybe<FeedItemWhereInput>;
  some?: InputMaybe<FeedItemWhereInput>;
};

export type FeedItemOrderByInput = {
  author?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  ctaButtonTitle?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  externalId?: InputMaybe<SortOrder>;
  externalUrl?: InputMaybe<SortOrder>;
  headlineType?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  likesBoost?: InputMaybe<SortOrder>;
  media?: InputMaybe<SortOrder>;
  mediaPreviewUrl?: InputMaybe<SortOrder>;
  mediaUrl?: InputMaybe<SortOrder>;
  parentCommunityId?: InputMaybe<SortOrder>;
  publishedAt?: InputMaybe<SortOrder>;
  publishingStatus?: InputMaybe<SortOrder>;
  subtitle?: InputMaybe<SortOrder>;
  text?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  trailerLink?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  watchLink?: InputMaybe<SortOrder>;
};

export enum FeedItemPublishingStatus {
  Deleted = 'Deleted',
  Draft = 'Draft',
  Published = 'Published',
  UneditedDraft = 'UneditedDraft'
}

export type FeedItemScalarWhereInput = {
  AND?: InputMaybe<Array<FeedItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<FeedItemScalarWhereInput>>;
  OR?: InputMaybe<Array<FeedItemScalarWhereInput>>;
  author?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  ctaButtonTitle?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  externalUrl?: InputMaybe<StringNullableFilter>;
  headlineType?: InputMaybe<EnumHeadlineTypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  likesBoost?: InputMaybe<IntFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  mediaPreviewUrl?: InputMaybe<StringNullableFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  parentCommunityId?: InputMaybe<StringFilter>;
  publishedAt?: InputMaybe<DateTimeNullableFilter>;
  publishingStatus?: InputMaybe<EnumFeedItemPublishingStatusFilter>;
  subtitle?: InputMaybe<StringNullableFilter>;
  text?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  trailerLink?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  watchLink?: InputMaybe<StringNullableFilter>;
};

export type FeedItemUpdateInput = {
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<FeedCategoryUpdateManyWithoutItemsInput>;
  comments?: InputMaybe<FeedItemCommentUpdateManyWithoutFeedItemInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ctaButtonTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutFeedInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  externalUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headlineType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedFeedItemsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<FeedItemUpdatemediaInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutFeedItemInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumFeedItemPublishingStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trailerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  watchLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FeedItemUpdateManyMutationInput = {
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ctaButtonTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  externalUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headlineType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<FeedItemUpdatemediaInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumFeedItemPublishingStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trailerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  watchLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FeedItemUpdateManyWithWhereWithoutEventsInput = {
  data: FeedItemUpdateManyMutationInput;
  where: FeedItemScalarWhereInput;
};

export type FeedItemUpdateManyWithWhereWithoutLikesInput = {
  data: FeedItemUpdateManyMutationInput;
  where: FeedItemScalarWhereInput;
};

export type FeedItemUpdateManyWithWhereWithoutParentCommunityInput = {
  data: FeedItemUpdateManyMutationInput;
  where: FeedItemScalarWhereInput;
};

export type FeedItemUpdateManyWithoutEventsInput = {
  connect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCreateOrConnectWithoutEventsInput>>;
  create?: InputMaybe<Array<FeedItemCreateWithoutEventsInput>>;
  delete?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemUpdateWithWhereUniqueWithoutEventsInput>>;
  updateMany?: InputMaybe<Array<FeedItemUpdateManyWithWhereWithoutEventsInput>>;
  upsert?: InputMaybe<Array<FeedItemUpsertWithWhereUniqueWithoutEventsInput>>;
};

export type FeedItemUpdateManyWithoutLikesInput = {
  connect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCreateOrConnectWithoutLikesInput>>;
  create?: InputMaybe<Array<FeedItemCreateWithoutLikesInput>>;
  delete?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemUpdateWithWhereUniqueWithoutLikesInput>>;
  updateMany?: InputMaybe<Array<FeedItemUpdateManyWithWhereWithoutLikesInput>>;
  upsert?: InputMaybe<Array<FeedItemUpsertWithWhereUniqueWithoutLikesInput>>;
};

export type FeedItemUpdateManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FeedItemCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<FeedItemCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<FeedItemCreateManyParentCommunityInputEnvelope>;
  delete?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<FeedItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  set?: InputMaybe<Array<FeedItemWhereUniqueInput>>;
  update?: InputMaybe<Array<FeedItemUpdateWithWhereUniqueWithoutParentCommunityInput>>;
  updateMany?: InputMaybe<Array<FeedItemUpdateManyWithWhereWithoutParentCommunityInput>>;
  upsert?: InputMaybe<Array<FeedItemUpsertWithWhereUniqueWithoutParentCommunityInput>>;
};

export type FeedItemUpdateOneRequiredWithoutCommentsInput = {
  connect?: InputMaybe<FeedItemWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FeedItemCreateOrConnectWithoutCommentsInput>;
  create?: InputMaybe<FeedItemCreateWithoutCommentsInput>;
  update?: InputMaybe<FeedItemUpdateWithoutCommentsInput>;
  upsert?: InputMaybe<FeedItemUpsertWithoutCommentsInput>;
};

export type FeedItemUpdateWithWhereUniqueWithoutEventsInput = {
  data: FeedItemUpdateWithoutEventsInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemUpdateWithWhereUniqueWithoutLikesInput = {
  data: FeedItemUpdateWithoutLikesInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemUpdateWithWhereUniqueWithoutParentCommunityInput = {
  data: FeedItemUpdateWithoutParentCommunityInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemUpdateWithoutCommentsInput = {
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<FeedCategoryUpdateManyWithoutItemsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ctaButtonTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutFeedInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  externalUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headlineType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedFeedItemsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<FeedItemUpdatemediaInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutFeedItemInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumFeedItemPublishingStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trailerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  watchLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FeedItemUpdateWithoutEventsInput = {
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<FeedCategoryUpdateManyWithoutItemsInput>;
  comments?: InputMaybe<FeedItemCommentUpdateManyWithoutFeedItemInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ctaButtonTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  externalUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headlineType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedFeedItemsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<FeedItemUpdatemediaInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutFeedItemInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumFeedItemPublishingStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trailerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  watchLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FeedItemUpdateWithoutLikesInput = {
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<FeedCategoryUpdateManyWithoutItemsInput>;
  comments?: InputMaybe<FeedItemCommentUpdateManyWithoutFeedItemInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ctaButtonTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutFeedInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  externalUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headlineType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<FeedItemUpdatemediaInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutFeedItemInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumFeedItemPublishingStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trailerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  watchLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FeedItemUpdateWithoutParentCommunityInput = {
  author?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  categories?: InputMaybe<FeedCategoryUpdateManyWithoutItemsInput>;
  comments?: InputMaybe<FeedItemCommentUpdateManyWithoutFeedItemInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  ctaButtonTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  events?: InputMaybe<EventUpdateManyWithoutFeedInput>;
  externalId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  externalUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headlineType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedFeedItemsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<FeedItemUpdatemediaInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumFeedItemPublishingStatusFieldUpdateOperationsInput>;
  subtitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  text?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  trailerLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  watchLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type FeedItemUpdatemediaInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type FeedItemUpsertWithWhereUniqueWithoutEventsInput = {
  create: FeedItemCreateWithoutEventsInput;
  update: FeedItemUpdateWithoutEventsInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemUpsertWithWhereUniqueWithoutLikesInput = {
  create: FeedItemCreateWithoutLikesInput;
  update: FeedItemUpdateWithoutLikesInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemUpsertWithWhereUniqueWithoutParentCommunityInput = {
  create: FeedItemCreateWithoutParentCommunityInput;
  update: FeedItemUpdateWithoutParentCommunityInput;
  where: FeedItemWhereUniqueInput;
};

export type FeedItemUpsertWithoutCommentsInput = {
  create: FeedItemCreateWithoutCommentsInput;
  update: FeedItemUpdateWithoutCommentsInput;
};

export type FeedItemWhereInput = {
  AND?: InputMaybe<Array<FeedItemWhereInput>>;
  NOT?: InputMaybe<Array<FeedItemWhereInput>>;
  OR?: InputMaybe<Array<FeedItemWhereInput>>;
  author?: InputMaybe<StringNullableFilter>;
  categories?: InputMaybe<FeedCategoryListRelationFilter>;
  comments?: InputMaybe<FeedItemCommentListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  ctaButtonTitle?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  events?: InputMaybe<EventListRelationFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  externalUrl?: InputMaybe<StringNullableFilter>;
  headlineType?: InputMaybe<EnumHeadlineTypeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  likes?: InputMaybe<UserListRelationFilter>;
  likesBoost?: InputMaybe<IntFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  mediaPreviewUrl?: InputMaybe<StringNullableFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  parentCommunity?: InputMaybe<CommunityWhereInput>;
  parentCommunityId?: InputMaybe<StringFilter>;
  publishedAt?: InputMaybe<DateTimeNullableFilter>;
  publishingStatus?: InputMaybe<EnumFeedItemPublishingStatusFilter>;
  subtitle?: InputMaybe<StringNullableFilter>;
  text?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  trailerLink?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  watchLink?: InputMaybe<StringNullableFilter>;
};

export type FeedItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type FloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type ForumComment = {
  __typename?: 'ForumComment';
  author: User;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  liked: Scalars['Boolean'];
  likesCount: Scalars['Int'];
  media: Array<Scalars['String']>;
  post: ForumPost;
  replies: Array<ForumComment>;
  status: ForumCommentStatus;
  updatedAt: Scalars['DateTime'];
};


export type ForumCommentRepliesArgs = {
  cursor?: InputMaybe<ForumCommentWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type ForumCommentCreateInput = {
  author: UserCreateNestedOneWithoutForumCommentsInput;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentCreatemediaInput>;
  parentComment?: InputMaybe<ForumCommentCreateNestedOneWithoutRepliesInput>;
  post: ForumPostCreateNestedOneWithoutForumCommentInput;
  replies?: InputMaybe<ForumCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateManyAuthorInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<ForumCommentCreateManymediaInput>;
  parentCommentId?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateManyAuthorInputEnvelope = {
  data?: InputMaybe<Array<ForumCommentCreateManyAuthorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ForumCommentCreateManyParentCommentInput = {
  authorId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<ForumCommentCreateManymediaInput>;
  postId: Scalars['String'];
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateManyParentCommentInputEnvelope = {
  data?: InputMaybe<Array<ForumCommentCreateManyParentCommentInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ForumCommentCreateManyPostInput = {
  authorId: Scalars['String'];
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<ForumCommentCreateManymediaInput>;
  parentCommentId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateManyPostInputEnvelope = {
  data?: InputMaybe<Array<ForumCommentCreateManyPostInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ForumCommentCreateManymediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ForumCommentCreateNestedManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumCommentCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<ForumCommentCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<ForumCommentCreateManyAuthorInputEnvelope>;
};

export type ForumCommentCreateNestedManyWithoutLikesInput = {
  connect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumCommentCreateOrConnectWithoutLikesInput>>;
  create?: InputMaybe<Array<ForumCommentCreateWithoutLikesInput>>;
};

export type ForumCommentCreateNestedManyWithoutParentCommentInput = {
  connect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumCommentCreateOrConnectWithoutParentCommentInput>>;
  create?: InputMaybe<Array<ForumCommentCreateWithoutParentCommentInput>>;
  createMany?: InputMaybe<ForumCommentCreateManyParentCommentInputEnvelope>;
};

export type ForumCommentCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumCommentCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<ForumCommentCreateWithoutPostInput>>;
  createMany?: InputMaybe<ForumCommentCreateManyPostInputEnvelope>;
};

export type ForumCommentCreateNestedOneWithoutRepliesInput = {
  connect?: InputMaybe<ForumCommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ForumCommentCreateOrConnectWithoutRepliesInput>;
  create?: InputMaybe<ForumCommentCreateWithoutRepliesInput>;
};

export type ForumCommentCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<ForumCommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ForumCommentCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<ForumCommentCreateWithoutReportsInput>;
};

export type ForumCommentCreateOrConnectWithoutAuthorInput = {
  create: ForumCommentCreateWithoutAuthorInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentCreateOrConnectWithoutLikesInput = {
  create: ForumCommentCreateWithoutLikesInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentCreateOrConnectWithoutParentCommentInput = {
  create: ForumCommentCreateWithoutParentCommentInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentCreateOrConnectWithoutPostInput = {
  create: ForumCommentCreateWithoutPostInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentCreateOrConnectWithoutRepliesInput = {
  create: ForumCommentCreateWithoutRepliesInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentCreateOrConnectWithoutReportsInput = {
  create: ForumCommentCreateWithoutReportsInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentCreateWithoutAuthorInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentCreatemediaInput>;
  parentComment?: InputMaybe<ForumCommentCreateNestedOneWithoutRepliesInput>;
  post: ForumPostCreateNestedOneWithoutForumCommentInput;
  replies?: InputMaybe<ForumCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateWithoutLikesInput = {
  author: UserCreateNestedOneWithoutForumCommentsInput;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<ForumCommentCreatemediaInput>;
  parentComment?: InputMaybe<ForumCommentCreateNestedOneWithoutRepliesInput>;
  post: ForumPostCreateNestedOneWithoutForumCommentInput;
  replies?: InputMaybe<ForumCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateWithoutParentCommentInput = {
  author: UserCreateNestedOneWithoutForumCommentsInput;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentCreatemediaInput>;
  post: ForumPostCreateNestedOneWithoutForumCommentInput;
  replies?: InputMaybe<ForumCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateWithoutPostInput = {
  author: UserCreateNestedOneWithoutForumCommentsInput;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentCreatemediaInput>;
  parentComment?: InputMaybe<ForumCommentCreateNestedOneWithoutRepliesInput>;
  replies?: InputMaybe<ForumCommentCreateNestedManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateWithoutRepliesInput = {
  author: UserCreateNestedOneWithoutForumCommentsInput;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentCreatemediaInput>;
  parentComment?: InputMaybe<ForumCommentCreateNestedOneWithoutRepliesInput>;
  post: ForumPostCreateNestedOneWithoutForumCommentInput;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutCommentInput>;
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreateWithoutReportsInput = {
  author: UserCreateNestedOneWithoutForumCommentsInput;
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentCreatemediaInput>;
  parentComment?: InputMaybe<ForumCommentCreateNestedOneWithoutRepliesInput>;
  post: ForumPostCreateNestedOneWithoutForumCommentInput;
  replies?: InputMaybe<ForumCommentCreateNestedManyWithoutParentCommentInput>;
  status?: InputMaybe<ForumCommentStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumCommentCreatemediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ForumCommentListRelationFilter = {
  every?: InputMaybe<ForumCommentWhereInput>;
  none?: InputMaybe<ForumCommentWhereInput>;
  some?: InputMaybe<ForumCommentWhereInput>;
};

export type ForumCommentOrderByInput = {
  authorId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleteReason?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  media?: InputMaybe<SortOrder>;
  parentCommentId?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ForumCommentScalarWhereInput = {
  AND?: InputMaybe<Array<ForumCommentScalarWhereInput>>;
  NOT?: InputMaybe<Array<ForumCommentScalarWhereInput>>;
  OR?: InputMaybe<Array<ForumCommentScalarWhereInput>>;
  authorId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleteReason?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  parentCommentId?: InputMaybe<StringNullableFilter>;
  postId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumForumCommentStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ForumCommentStatus {
  Deleted = 'Deleted',
  Hidden = 'Hidden',
  Published = 'Published',
  Reported = 'Reported'
}

export type ForumCommentUpdateInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumCommentsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentUpdatemediaInput>;
  parentComment?: InputMaybe<ForumCommentUpdateOneWithoutRepliesInput>;
  post?: InputMaybe<ForumPostUpdateOneRequiredWithoutForumCommentInput>;
  replies?: InputMaybe<ForumCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumForumCommentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumCommentUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumCommentUpdatemediaInput>;
  status?: InputMaybe<EnumForumCommentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumCommentUpdateManyWithWhereWithoutAuthorInput = {
  data: ForumCommentUpdateManyMutationInput;
  where: ForumCommentScalarWhereInput;
};

export type ForumCommentUpdateManyWithWhereWithoutLikesInput = {
  data: ForumCommentUpdateManyMutationInput;
  where: ForumCommentScalarWhereInput;
};

export type ForumCommentUpdateManyWithWhereWithoutParentCommentInput = {
  data: ForumCommentUpdateManyMutationInput;
  where: ForumCommentScalarWhereInput;
};

export type ForumCommentUpdateManyWithWhereWithoutPostInput = {
  data: ForumCommentUpdateManyMutationInput;
  where: ForumCommentScalarWhereInput;
};

export type ForumCommentUpdateManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumCommentCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<ForumCommentCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<ForumCommentCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumCommentUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: InputMaybe<Array<ForumCommentUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: InputMaybe<Array<ForumCommentUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type ForumCommentUpdateManyWithoutLikesInput = {
  connect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumCommentCreateOrConnectWithoutLikesInput>>;
  create?: InputMaybe<Array<ForumCommentCreateWithoutLikesInput>>;
  delete?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumCommentUpdateWithWhereUniqueWithoutLikesInput>>;
  updateMany?: InputMaybe<Array<ForumCommentUpdateManyWithWhereWithoutLikesInput>>;
  upsert?: InputMaybe<Array<ForumCommentUpsertWithWhereUniqueWithoutLikesInput>>;
};

export type ForumCommentUpdateManyWithoutParentCommentInput = {
  connect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumCommentCreateOrConnectWithoutParentCommentInput>>;
  create?: InputMaybe<Array<ForumCommentCreateWithoutParentCommentInput>>;
  createMany?: InputMaybe<ForumCommentCreateManyParentCommentInputEnvelope>;
  delete?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumCommentUpdateWithWhereUniqueWithoutParentCommentInput>>;
  updateMany?: InputMaybe<Array<ForumCommentUpdateManyWithWhereWithoutParentCommentInput>>;
  upsert?: InputMaybe<Array<ForumCommentUpsertWithWhereUniqueWithoutParentCommentInput>>;
};

export type ForumCommentUpdateManyWithoutPostInput = {
  connect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumCommentCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<ForumCommentCreateWithoutPostInput>>;
  createMany?: InputMaybe<ForumCommentCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumCommentScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumCommentWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumCommentUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<ForumCommentUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<ForumCommentUpsertWithWhereUniqueWithoutPostInput>>;
};

export type ForumCommentUpdateOneWithoutRepliesInput = {
  connect?: InputMaybe<ForumCommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ForumCommentCreateOrConnectWithoutRepliesInput>;
  create?: InputMaybe<ForumCommentCreateWithoutRepliesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ForumCommentUpdateWithoutRepliesInput>;
  upsert?: InputMaybe<ForumCommentUpsertWithoutRepliesInput>;
};

export type ForumCommentUpdateOneWithoutReportsInput = {
  connect?: InputMaybe<ForumCommentWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ForumCommentCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<ForumCommentCreateWithoutReportsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ForumCommentUpdateWithoutReportsInput>;
  upsert?: InputMaybe<ForumCommentUpsertWithoutReportsInput>;
};

export type ForumCommentUpdateWithWhereUniqueWithoutAuthorInput = {
  data: ForumCommentUpdateWithoutAuthorInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentUpdateWithWhereUniqueWithoutLikesInput = {
  data: ForumCommentUpdateWithoutLikesInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentUpdateWithWhereUniqueWithoutParentCommentInput = {
  data: ForumCommentUpdateWithoutParentCommentInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentUpdateWithWhereUniqueWithoutPostInput = {
  data: ForumCommentUpdateWithoutPostInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentUpdateWithoutAuthorInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentUpdatemediaInput>;
  parentComment?: InputMaybe<ForumCommentUpdateOneWithoutRepliesInput>;
  post?: InputMaybe<ForumPostUpdateOneRequiredWithoutForumCommentInput>;
  replies?: InputMaybe<ForumCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumForumCommentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumCommentUpdateWithoutLikesInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumCommentsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumCommentUpdatemediaInput>;
  parentComment?: InputMaybe<ForumCommentUpdateOneWithoutRepliesInput>;
  post?: InputMaybe<ForumPostUpdateOneRequiredWithoutForumCommentInput>;
  replies?: InputMaybe<ForumCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumForumCommentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumCommentUpdateWithoutParentCommentInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumCommentsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentUpdatemediaInput>;
  post?: InputMaybe<ForumPostUpdateOneRequiredWithoutForumCommentInput>;
  replies?: InputMaybe<ForumCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumForumCommentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumCommentUpdateWithoutPostInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumCommentsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentUpdatemediaInput>;
  parentComment?: InputMaybe<ForumCommentUpdateOneWithoutRepliesInput>;
  replies?: InputMaybe<ForumCommentUpdateManyWithoutParentCommentInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumForumCommentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumCommentUpdateWithoutRepliesInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumCommentsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentUpdatemediaInput>;
  parentComment?: InputMaybe<ForumCommentUpdateOneWithoutRepliesInput>;
  post?: InputMaybe<ForumPostUpdateOneRequiredWithoutForumCommentInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutCommentInput>;
  status?: InputMaybe<EnumForumCommentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumCommentUpdateWithoutReportsInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumCommentsInput>;
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumCommentsInput>;
  media?: InputMaybe<ForumCommentUpdatemediaInput>;
  parentComment?: InputMaybe<ForumCommentUpdateOneWithoutRepliesInput>;
  post?: InputMaybe<ForumPostUpdateOneRequiredWithoutForumCommentInput>;
  replies?: InputMaybe<ForumCommentUpdateManyWithoutParentCommentInput>;
  status?: InputMaybe<EnumForumCommentStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumCommentUpdatemediaInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ForumCommentUpsertWithWhereUniqueWithoutAuthorInput = {
  create: ForumCommentCreateWithoutAuthorInput;
  update: ForumCommentUpdateWithoutAuthorInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentUpsertWithWhereUniqueWithoutLikesInput = {
  create: ForumCommentCreateWithoutLikesInput;
  update: ForumCommentUpdateWithoutLikesInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentUpsertWithWhereUniqueWithoutParentCommentInput = {
  create: ForumCommentCreateWithoutParentCommentInput;
  update: ForumCommentUpdateWithoutParentCommentInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentUpsertWithWhereUniqueWithoutPostInput = {
  create: ForumCommentCreateWithoutPostInput;
  update: ForumCommentUpdateWithoutPostInput;
  where: ForumCommentWhereUniqueInput;
};

export type ForumCommentUpsertWithoutRepliesInput = {
  create: ForumCommentCreateWithoutRepliesInput;
  update: ForumCommentUpdateWithoutRepliesInput;
};

export type ForumCommentUpsertWithoutReportsInput = {
  create: ForumCommentCreateWithoutReportsInput;
  update: ForumCommentUpdateWithoutReportsInput;
};

export type ForumCommentWhereInput = {
  AND?: InputMaybe<Array<ForumCommentWhereInput>>;
  NOT?: InputMaybe<Array<ForumCommentWhereInput>>;
  OR?: InputMaybe<Array<ForumCommentWhereInput>>;
  author?: InputMaybe<UserWhereInput>;
  authorId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleteReason?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  likes?: InputMaybe<UserListRelationFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  parentComment?: InputMaybe<ForumCommentWhereInput>;
  parentCommentId?: InputMaybe<StringNullableFilter>;
  post?: InputMaybe<ForumPostWhereInput>;
  postId?: InputMaybe<StringFilter>;
  replies?: InputMaybe<ForumCommentListRelationFilter>;
  reports?: InputMaybe<ForumContentReportListRelationFilter>;
  status?: InputMaybe<EnumForumCommentStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForumCommentWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ForumContentReport = {
  __typename?: 'ForumContentReport';
  authorId: Scalars['String'];
  comment?: Maybe<ForumComment>;
  commentId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  post?: Maybe<ForumPost>;
  postId?: Maybe<Scalars['String']>;
  reason: Scalars['String'];
  status: ForumContentReportStatus;
  updatedAt: Scalars['DateTime'];
};

export type ForumContentReportCreateInput = {
  adminRejectReason?: InputMaybe<Scalars['String']>;
  authorId: Scalars['String'];
  comment?: InputMaybe<ForumCommentCreateNestedOneWithoutReportsInput>;
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<ForumPostCreateNestedOneWithoutReportsInput>;
  reason: Scalars['String'];
  status?: InputMaybe<ForumContentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumContentReportCreateManyCommentInput = {
  adminRejectReason?: InputMaybe<Scalars['String']>;
  authorId: Scalars['String'];
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  postId?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  status?: InputMaybe<ForumContentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumContentReportCreateManyCommentInputEnvelope = {
  data?: InputMaybe<Array<ForumContentReportCreateManyCommentInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ForumContentReportCreateManyPostInput = {
  adminRejectReason?: InputMaybe<Scalars['String']>;
  authorId: Scalars['String'];
  commentId?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  status?: InputMaybe<ForumContentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumContentReportCreateManyPostInputEnvelope = {
  data?: InputMaybe<Array<ForumContentReportCreateManyPostInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ForumContentReportCreateNestedManyWithoutCommentInput = {
  connect?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumContentReportCreateOrConnectWithoutCommentInput>>;
  create?: InputMaybe<Array<ForumContentReportCreateWithoutCommentInput>>;
  createMany?: InputMaybe<ForumContentReportCreateManyCommentInputEnvelope>;
};

export type ForumContentReportCreateNestedManyWithoutPostInput = {
  connect?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumContentReportCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<ForumContentReportCreateWithoutPostInput>>;
  createMany?: InputMaybe<ForumContentReportCreateManyPostInputEnvelope>;
};

export type ForumContentReportCreateOrConnectWithoutCommentInput = {
  create: ForumContentReportCreateWithoutCommentInput;
  where: ForumContentReportWhereUniqueInput;
};

export type ForumContentReportCreateOrConnectWithoutPostInput = {
  create: ForumContentReportCreateWithoutPostInput;
  where: ForumContentReportWhereUniqueInput;
};

export type ForumContentReportCreateWithoutCommentInput = {
  adminRejectReason?: InputMaybe<Scalars['String']>;
  authorId: Scalars['String'];
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  post?: InputMaybe<ForumPostCreateNestedOneWithoutReportsInput>;
  reason: Scalars['String'];
  status?: InputMaybe<ForumContentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumContentReportCreateWithoutPostInput = {
  adminRejectReason?: InputMaybe<Scalars['String']>;
  authorId: Scalars['String'];
  comment?: InputMaybe<ForumCommentCreateNestedOneWithoutReportsInput>;
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
  status?: InputMaybe<ForumContentReportStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumContentReportListRelationFilter = {
  every?: InputMaybe<ForumContentReportWhereInput>;
  none?: InputMaybe<ForumContentReportWhereInput>;
  some?: InputMaybe<ForumContentReportWhereInput>;
};

export type ForumContentReportOrderByInput = {
  adminRejectReason?: InputMaybe<SortOrder>;
  authorId?: InputMaybe<SortOrder>;
  commentId?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  postId?: InputMaybe<SortOrder>;
  reason?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ForumContentReportScalarWhereInput = {
  AND?: InputMaybe<Array<ForumContentReportScalarWhereInput>>;
  NOT?: InputMaybe<Array<ForumContentReportScalarWhereInput>>;
  OR?: InputMaybe<Array<ForumContentReportScalarWhereInput>>;
  adminRejectReason?: InputMaybe<StringNullableFilter>;
  authorId?: InputMaybe<StringFilter>;
  commentId?: InputMaybe<StringNullableFilter>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  postId?: InputMaybe<StringNullableFilter>;
  reason?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumForumContentReportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ForumContentReportStatus {
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Submitted = 'Submitted'
}

export type ForumContentReportUpdateInput = {
  adminRejectReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  authorId?: InputMaybe<StringFieldUpdateOperationsInput>;
  comment?: InputMaybe<ForumCommentUpdateOneWithoutReportsInput>;
  communityId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<ForumPostUpdateOneWithoutReportsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumForumContentReportStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumContentReportUpdateManyMutationInput = {
  adminRejectReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  authorId?: InputMaybe<StringFieldUpdateOperationsInput>;
  communityId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumForumContentReportStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumContentReportUpdateManyWithWhereWithoutCommentInput = {
  data: ForumContentReportUpdateManyMutationInput;
  where: ForumContentReportScalarWhereInput;
};

export type ForumContentReportUpdateManyWithWhereWithoutPostInput = {
  data: ForumContentReportUpdateManyMutationInput;
  where: ForumContentReportScalarWhereInput;
};

export type ForumContentReportUpdateManyWithoutCommentInput = {
  connect?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumContentReportCreateOrConnectWithoutCommentInput>>;
  create?: InputMaybe<Array<ForumContentReportCreateWithoutCommentInput>>;
  createMany?: InputMaybe<ForumContentReportCreateManyCommentInputEnvelope>;
  delete?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumContentReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumContentReportUpdateWithWhereUniqueWithoutCommentInput>>;
  updateMany?: InputMaybe<Array<ForumContentReportUpdateManyWithWhereWithoutCommentInput>>;
  upsert?: InputMaybe<Array<ForumContentReportUpsertWithWhereUniqueWithoutCommentInput>>;
};

export type ForumContentReportUpdateManyWithoutPostInput = {
  connect?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumContentReportCreateOrConnectWithoutPostInput>>;
  create?: InputMaybe<Array<ForumContentReportCreateWithoutPostInput>>;
  createMany?: InputMaybe<ForumContentReportCreateManyPostInputEnvelope>;
  delete?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumContentReportScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumContentReportWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumContentReportUpdateWithWhereUniqueWithoutPostInput>>;
  updateMany?: InputMaybe<Array<ForumContentReportUpdateManyWithWhereWithoutPostInput>>;
  upsert?: InputMaybe<Array<ForumContentReportUpsertWithWhereUniqueWithoutPostInput>>;
};

export type ForumContentReportUpdateWithWhereUniqueWithoutCommentInput = {
  data: ForumContentReportUpdateWithoutCommentInput;
  where: ForumContentReportWhereUniqueInput;
};

export type ForumContentReportUpdateWithWhereUniqueWithoutPostInput = {
  data: ForumContentReportUpdateWithoutPostInput;
  where: ForumContentReportWhereUniqueInput;
};

export type ForumContentReportUpdateWithoutCommentInput = {
  adminRejectReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  authorId?: InputMaybe<StringFieldUpdateOperationsInput>;
  communityId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  post?: InputMaybe<ForumPostUpdateOneWithoutReportsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumForumContentReportStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumContentReportUpdateWithoutPostInput = {
  adminRejectReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  authorId?: InputMaybe<StringFieldUpdateOperationsInput>;
  comment?: InputMaybe<ForumCommentUpdateOneWithoutReportsInput>;
  communityId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reason?: InputMaybe<StringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumForumContentReportStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumContentReportUpsertWithWhereUniqueWithoutCommentInput = {
  create: ForumContentReportCreateWithoutCommentInput;
  update: ForumContentReportUpdateWithoutCommentInput;
  where: ForumContentReportWhereUniqueInput;
};

export type ForumContentReportUpsertWithWhereUniqueWithoutPostInput = {
  create: ForumContentReportCreateWithoutPostInput;
  update: ForumContentReportUpdateWithoutPostInput;
  where: ForumContentReportWhereUniqueInput;
};

export type ForumContentReportWhereInput = {
  AND?: InputMaybe<Array<ForumContentReportWhereInput>>;
  NOT?: InputMaybe<Array<ForumContentReportWhereInput>>;
  OR?: InputMaybe<Array<ForumContentReportWhereInput>>;
  adminRejectReason?: InputMaybe<StringNullableFilter>;
  authorId?: InputMaybe<StringFilter>;
  comment?: InputMaybe<ForumCommentWhereInput>;
  commentId?: InputMaybe<StringNullableFilter>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  post?: InputMaybe<ForumPostWhereInput>;
  postId?: InputMaybe<StringNullableFilter>;
  reason?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumForumContentReportStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForumContentReportWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ForumPost = {
  __typename?: 'ForumPost';
  author: User;
  commentsAndRepliesCount: Scalars['Int'];
  commentsCount: Scalars['Int'];
  community: Community;
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  headline?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  liked: Scalars['Boolean'];
  likesBoost: Scalars['Int'];
  likesCount: Scalars['Int'];
  media: Array<Scalars['String']>;
  publishingStatus: ForumPostPublishingStatus;
  tags: Array<ForumTag>;
  updatedAt: Scalars['DateTime'];
};


export type ForumPostTagsArgs = {
  cursor?: InputMaybe<ForumTagWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type ForumPostCommentsReturn = {
  __typename?: 'ForumPostCommentsReturn';
  comments: Array<ForumComment>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type ForumPostCreateInput = {
  ForumComment?: InputMaybe<ForumCommentCreateNestedManyWithoutPostInput>;
  author: UserCreateNestedOneWithoutForumPostsInput;
  community: CommunityCreateNestedOneWithoutForumPostsInput;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreatemediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreateManyAuthorInput = {
  communityId: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreateManymediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreateManyAuthorInputEnvelope = {
  data?: InputMaybe<Array<ForumPostCreateManyAuthorInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ForumPostCreateManyCommunityInput = {
  authorId: Scalars['String'];
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreateManymediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<ForumPostCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ForumPostCreateManymediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ForumPostCreateNestedManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumPostCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<ForumPostCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<ForumPostCreateManyAuthorInputEnvelope>;
};

export type ForumPostCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumPostCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<ForumPostCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<ForumPostCreateManyCommunityInputEnvelope>;
};

export type ForumPostCreateNestedManyWithoutLikesInput = {
  connect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumPostCreateOrConnectWithoutLikesInput>>;
  create?: InputMaybe<Array<ForumPostCreateWithoutLikesInput>>;
};

export type ForumPostCreateNestedManyWithoutTagsInput = {
  connect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumPostCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<ForumPostCreateWithoutTagsInput>>;
};

export type ForumPostCreateNestedOneWithoutForumCommentInput = {
  connect?: InputMaybe<ForumPostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ForumPostCreateOrConnectWithoutForumCommentInput>;
  create?: InputMaybe<ForumPostCreateWithoutForumCommentInput>;
};

export type ForumPostCreateNestedOneWithoutReportsInput = {
  connect?: InputMaybe<ForumPostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ForumPostCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<ForumPostCreateWithoutReportsInput>;
};

export type ForumPostCreateOrConnectWithoutAuthorInput = {
  create: ForumPostCreateWithoutAuthorInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostCreateOrConnectWithoutCommunityInput = {
  create: ForumPostCreateWithoutCommunityInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostCreateOrConnectWithoutForumCommentInput = {
  create: ForumPostCreateWithoutForumCommentInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostCreateOrConnectWithoutLikesInput = {
  create: ForumPostCreateWithoutLikesInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostCreateOrConnectWithoutReportsInput = {
  create: ForumPostCreateWithoutReportsInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostCreateOrConnectWithoutTagsInput = {
  create: ForumPostCreateWithoutTagsInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostCreateWithoutAuthorInput = {
  ForumComment?: InputMaybe<ForumCommentCreateNestedManyWithoutPostInput>;
  community: CommunityCreateNestedOneWithoutForumPostsInput;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreatemediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreateWithoutCommunityInput = {
  ForumComment?: InputMaybe<ForumCommentCreateNestedManyWithoutPostInput>;
  author: UserCreateNestedOneWithoutForumPostsInput;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreatemediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreateWithoutForumCommentInput = {
  author: UserCreateNestedOneWithoutForumPostsInput;
  community: CommunityCreateNestedOneWithoutForumPostsInput;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreatemediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreateWithoutLikesInput = {
  ForumComment?: InputMaybe<ForumCommentCreateNestedManyWithoutPostInput>;
  author: UserCreateNestedOneWithoutForumPostsInput;
  community: CommunityCreateNestedOneWithoutForumPostsInput;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreatemediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreateWithoutReportsInput = {
  ForumComment?: InputMaybe<ForumCommentCreateNestedManyWithoutPostInput>;
  author: UserCreateNestedOneWithoutForumPostsInput;
  community: CommunityCreateNestedOneWithoutForumPostsInput;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreatemediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  tags?: InputMaybe<ForumTagCreateNestedManyWithoutPostsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreateWithoutTagsInput = {
  ForumComment?: InputMaybe<ForumCommentCreateNestedManyWithoutPostInput>;
  author: UserCreateNestedOneWithoutForumPostsInput;
  community: CommunityCreateNestedOneWithoutForumPostsInput;
  content?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  deleteReason?: InputMaybe<Scalars['String']>;
  headline?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  likes?: InputMaybe<UserCreateNestedManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<Scalars['Int']>;
  media?: InputMaybe<ForumPostCreatemediaInput>;
  publishingStatus?: InputMaybe<ForumPostPublishingStatus>;
  reports?: InputMaybe<ForumContentReportCreateNestedManyWithoutPostInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumPostCreatemediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ForumPostListRelationFilter = {
  every?: InputMaybe<ForumPostWhereInput>;
  none?: InputMaybe<ForumPostWhereInput>;
  some?: InputMaybe<ForumPostWhereInput>;
};

export type ForumPostOrderByInput = {
  authorId?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  deleteReason?: InputMaybe<SortOrder>;
  headline?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  likesBoost?: InputMaybe<SortOrder>;
  media?: InputMaybe<SortOrder>;
  publishingStatus?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum ForumPostPublishingStatus {
  Deleted = 'Deleted',
  Hidden = 'Hidden',
  Pending = 'Pending',
  Published = 'Published',
  Reported = 'Reported',
  UneditedDraft = 'UneditedDraft'
}

export type ForumPostScalarWhereInput = {
  AND?: InputMaybe<Array<ForumPostScalarWhereInput>>;
  NOT?: InputMaybe<Array<ForumPostScalarWhereInput>>;
  OR?: InputMaybe<Array<ForumPostScalarWhereInput>>;
  authorId?: InputMaybe<StringFilter>;
  communityId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleteReason?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  likesBoost?: InputMaybe<IntFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForumPostUpdateInput = {
  ForumComment?: InputMaybe<ForumCommentUpdateManyWithoutPostInput>;
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumPostsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutForumPostsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumPostUpdatemediaInput>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFieldUpdateOperationsInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagUpdateManyWithoutPostsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumPostUpdateManyMutationInput = {
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumPostUpdatemediaInput>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumPostUpdateManyWithWhereWithoutAuthorInput = {
  data: ForumPostUpdateManyMutationInput;
  where: ForumPostScalarWhereInput;
};

export type ForumPostUpdateManyWithWhereWithoutCommunityInput = {
  data: ForumPostUpdateManyMutationInput;
  where: ForumPostScalarWhereInput;
};

export type ForumPostUpdateManyWithWhereWithoutLikesInput = {
  data: ForumPostUpdateManyMutationInput;
  where: ForumPostScalarWhereInput;
};

export type ForumPostUpdateManyWithWhereWithoutTagsInput = {
  data: ForumPostUpdateManyMutationInput;
  where: ForumPostScalarWhereInput;
};

export type ForumPostUpdateManyWithoutAuthorInput = {
  connect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumPostCreateOrConnectWithoutAuthorInput>>;
  create?: InputMaybe<Array<ForumPostCreateWithoutAuthorInput>>;
  createMany?: InputMaybe<ForumPostCreateManyAuthorInputEnvelope>;
  delete?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumPostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumPostUpdateWithWhereUniqueWithoutAuthorInput>>;
  updateMany?: InputMaybe<Array<ForumPostUpdateManyWithWhereWithoutAuthorInput>>;
  upsert?: InputMaybe<Array<ForumPostUpsertWithWhereUniqueWithoutAuthorInput>>;
};

export type ForumPostUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumPostCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<ForumPostCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<ForumPostCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumPostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumPostUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<ForumPostUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<ForumPostUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type ForumPostUpdateManyWithoutLikesInput = {
  connect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumPostCreateOrConnectWithoutLikesInput>>;
  create?: InputMaybe<Array<ForumPostCreateWithoutLikesInput>>;
  delete?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumPostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumPostUpdateWithWhereUniqueWithoutLikesInput>>;
  updateMany?: InputMaybe<Array<ForumPostUpdateManyWithWhereWithoutLikesInput>>;
  upsert?: InputMaybe<Array<ForumPostUpsertWithWhereUniqueWithoutLikesInput>>;
};

export type ForumPostUpdateManyWithoutTagsInput = {
  connect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumPostCreateOrConnectWithoutTagsInput>>;
  create?: InputMaybe<Array<ForumPostCreateWithoutTagsInput>>;
  delete?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumPostScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumPostWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumPostUpdateWithWhereUniqueWithoutTagsInput>>;
  updateMany?: InputMaybe<Array<ForumPostUpdateManyWithWhereWithoutTagsInput>>;
  upsert?: InputMaybe<Array<ForumPostUpsertWithWhereUniqueWithoutTagsInput>>;
};

export type ForumPostUpdateOneRequiredWithoutForumCommentInput = {
  connect?: InputMaybe<ForumPostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ForumPostCreateOrConnectWithoutForumCommentInput>;
  create?: InputMaybe<ForumPostCreateWithoutForumCommentInput>;
  update?: InputMaybe<ForumPostUpdateWithoutForumCommentInput>;
  upsert?: InputMaybe<ForumPostUpsertWithoutForumCommentInput>;
};

export type ForumPostUpdateOneWithoutReportsInput = {
  connect?: InputMaybe<ForumPostWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ForumPostCreateOrConnectWithoutReportsInput>;
  create?: InputMaybe<ForumPostCreateWithoutReportsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<ForumPostUpdateWithoutReportsInput>;
  upsert?: InputMaybe<ForumPostUpsertWithoutReportsInput>;
};

export type ForumPostUpdateWithWhereUniqueWithoutAuthorInput = {
  data: ForumPostUpdateWithoutAuthorInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostUpdateWithWhereUniqueWithoutCommunityInput = {
  data: ForumPostUpdateWithoutCommunityInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostUpdateWithWhereUniqueWithoutLikesInput = {
  data: ForumPostUpdateWithoutLikesInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostUpdateWithWhereUniqueWithoutTagsInput = {
  data: ForumPostUpdateWithoutTagsInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostUpdateWithoutAuthorInput = {
  ForumComment?: InputMaybe<ForumCommentUpdateManyWithoutPostInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutForumPostsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumPostUpdatemediaInput>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFieldUpdateOperationsInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagUpdateManyWithoutPostsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumPostUpdateWithoutCommunityInput = {
  ForumComment?: InputMaybe<ForumCommentUpdateManyWithoutPostInput>;
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumPostsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumPostUpdatemediaInput>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFieldUpdateOperationsInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagUpdateManyWithoutPostsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumPostUpdateWithoutForumCommentInput = {
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumPostsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutForumPostsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumPostUpdatemediaInput>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFieldUpdateOperationsInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagUpdateManyWithoutPostsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumPostUpdateWithoutLikesInput = {
  ForumComment?: InputMaybe<ForumCommentUpdateManyWithoutPostInput>;
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumPostsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutForumPostsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumPostUpdatemediaInput>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFieldUpdateOperationsInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutPostInput>;
  tags?: InputMaybe<ForumTagUpdateManyWithoutPostsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumPostUpdateWithoutReportsInput = {
  ForumComment?: InputMaybe<ForumCommentUpdateManyWithoutPostInput>;
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumPostsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutForumPostsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumPostUpdatemediaInput>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<ForumTagUpdateManyWithoutPostsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumPostUpdateWithoutTagsInput = {
  ForumComment?: InputMaybe<ForumCommentUpdateManyWithoutPostInput>;
  author?: InputMaybe<UserUpdateOneRequiredWithoutForumPostsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutForumPostsInput>;
  content?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  deleteReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headline?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  likes?: InputMaybe<UserUpdateManyWithoutLikedForumPostsInput>;
  likesBoost?: InputMaybe<IntFieldUpdateOperationsInput>;
  media?: InputMaybe<ForumPostUpdatemediaInput>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFieldUpdateOperationsInput>;
  reports?: InputMaybe<ForumContentReportUpdateManyWithoutPostInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumPostUpdatemediaInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type ForumPostUpsertWithWhereUniqueWithoutAuthorInput = {
  create: ForumPostCreateWithoutAuthorInput;
  update: ForumPostUpdateWithoutAuthorInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostUpsertWithWhereUniqueWithoutCommunityInput = {
  create: ForumPostCreateWithoutCommunityInput;
  update: ForumPostUpdateWithoutCommunityInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostUpsertWithWhereUniqueWithoutLikesInput = {
  create: ForumPostCreateWithoutLikesInput;
  update: ForumPostUpdateWithoutLikesInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostUpsertWithWhereUniqueWithoutTagsInput = {
  create: ForumPostCreateWithoutTagsInput;
  update: ForumPostUpdateWithoutTagsInput;
  where: ForumPostWhereUniqueInput;
};

export type ForumPostUpsertWithoutForumCommentInput = {
  create: ForumPostCreateWithoutForumCommentInput;
  update: ForumPostUpdateWithoutForumCommentInput;
};

export type ForumPostUpsertWithoutReportsInput = {
  create: ForumPostCreateWithoutReportsInput;
  update: ForumPostUpdateWithoutReportsInput;
};

export type ForumPostWhereInput = {
  AND?: InputMaybe<Array<ForumPostWhereInput>>;
  ForumComment?: InputMaybe<ForumCommentListRelationFilter>;
  NOT?: InputMaybe<Array<ForumPostWhereInput>>;
  OR?: InputMaybe<Array<ForumPostWhereInput>>;
  author?: InputMaybe<UserWhereInput>;
  authorId?: InputMaybe<StringFilter>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringFilter>;
  content?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  deleteReason?: InputMaybe<StringNullableFilter>;
  headline?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  likes?: InputMaybe<UserListRelationFilter>;
  likesBoost?: InputMaybe<IntFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  publishingStatus?: InputMaybe<EnumForumPostPublishingStatusFilter>;
  reports?: InputMaybe<ForumContentReportListRelationFilter>;
  tags?: InputMaybe<ForumTagListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForumPostWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ForumPostsReturn = {
  __typename?: 'ForumPostsReturn';
  allTags: Array<ForumTag>;
  next: Scalars['Int'];
  posts: Array<ForumPost>;
  total: Scalars['Int'];
};

export type ForumTag = {
  __typename?: 'ForumTag';
  community: Community;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  posts: Array<ForumPost>;
  updatedAt: Scalars['DateTime'];
};


export type ForumTagPostsArgs = {
  cursor?: InputMaybe<ForumPostWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type ForumTagCreateInput = {
  community: CommunityCreateNestedOneWithoutForumTagsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  posts?: InputMaybe<ForumPostCreateNestedManyWithoutTagsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumTagCreateManyCommunityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumTagCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<ForumTagCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ForumTagCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumTagCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<ForumTagCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<ForumTagCreateManyCommunityInputEnvelope>;
};

export type ForumTagCreateNestedManyWithoutPostsInput = {
  connect?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumTagCreateOrConnectWithoutPostsInput>>;
  create?: InputMaybe<Array<ForumTagCreateWithoutPostsInput>>;
};

export type ForumTagCreateOrConnectWithoutCommunityInput = {
  create: ForumTagCreateWithoutCommunityInput;
  where: ForumTagWhereUniqueInput;
};

export type ForumTagCreateOrConnectWithoutPostsInput = {
  create: ForumTagCreateWithoutPostsInput;
  where: ForumTagWhereUniqueInput;
};

export type ForumTagCreateWithoutCommunityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  posts?: InputMaybe<ForumPostCreateNestedManyWithoutTagsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumTagCreateWithoutPostsInput = {
  community: CommunityCreateNestedOneWithoutForumTagsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ForumTagListRelationFilter = {
  every?: InputMaybe<ForumTagWhereInput>;
  none?: InputMaybe<ForumTagWhereInput>;
  some?: InputMaybe<ForumTagWhereInput>;
};

export type ForumTagOrderByInput = {
  communityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ForumTagScalarWhereInput = {
  AND?: InputMaybe<Array<ForumTagScalarWhereInput>>;
  NOT?: InputMaybe<Array<ForumTagScalarWhereInput>>;
  OR?: InputMaybe<Array<ForumTagScalarWhereInput>>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForumTagUpdateInput = {
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutForumTagsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  posts?: InputMaybe<ForumPostUpdateManyWithoutTagsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumTagUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumTagUpdateManyWithWhereWithoutCommunityInput = {
  data: ForumTagUpdateManyMutationInput;
  where: ForumTagScalarWhereInput;
};

export type ForumTagUpdateManyWithWhereWithoutPostsInput = {
  data: ForumTagUpdateManyMutationInput;
  where: ForumTagScalarWhereInput;
};

export type ForumTagUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumTagCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<ForumTagCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<ForumTagCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumTagUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<ForumTagUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<ForumTagUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type ForumTagUpdateManyWithoutPostsInput = {
  connect?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ForumTagCreateOrConnectWithoutPostsInput>>;
  create?: InputMaybe<Array<ForumTagCreateWithoutPostsInput>>;
  delete?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ForumTagScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  set?: InputMaybe<Array<ForumTagWhereUniqueInput>>;
  update?: InputMaybe<Array<ForumTagUpdateWithWhereUniqueWithoutPostsInput>>;
  updateMany?: InputMaybe<Array<ForumTagUpdateManyWithWhereWithoutPostsInput>>;
  upsert?: InputMaybe<Array<ForumTagUpsertWithWhereUniqueWithoutPostsInput>>;
};

export type ForumTagUpdateWithWhereUniqueWithoutCommunityInput = {
  data: ForumTagUpdateWithoutCommunityInput;
  where: ForumTagWhereUniqueInput;
};

export type ForumTagUpdateWithWhereUniqueWithoutPostsInput = {
  data: ForumTagUpdateWithoutPostsInput;
  where: ForumTagWhereUniqueInput;
};

export type ForumTagUpdateWithoutCommunityInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  posts?: InputMaybe<ForumPostUpdateManyWithoutTagsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumTagUpdateWithoutPostsInput = {
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutForumTagsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ForumTagUpsertWithWhereUniqueWithoutCommunityInput = {
  create: ForumTagCreateWithoutCommunityInput;
  update: ForumTagUpdateWithoutCommunityInput;
  where: ForumTagWhereUniqueInput;
};

export type ForumTagUpsertWithWhereUniqueWithoutPostsInput = {
  create: ForumTagCreateWithoutPostsInput;
  update: ForumTagUpdateWithoutPostsInput;
  where: ForumTagWhereUniqueInput;
};

export type ForumTagWhereInput = {
  AND?: InputMaybe<Array<ForumTagWhereInput>>;
  NOT?: InputMaybe<Array<ForumTagWhereInput>>;
  OR?: InputMaybe<Array<ForumTagWhereInput>>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  posts?: InputMaybe<ForumPostListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ForumTagWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum HeadlineType {
  Picture = 'Picture',
  Video = 'Video'
}

export type HighlightCreateManyCommunityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaType?: InputMaybe<HeadlineType>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<HighlightType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HighlightCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<HighlightCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type HighlightCreateManyEventInput = {
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaType?: InputMaybe<HeadlineType>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<HighlightType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HighlightCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<HighlightCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type HighlightCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HighlightCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<HighlightCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<HighlightCreateManyCommunityInputEnvelope>;
};

export type HighlightCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HighlightCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<HighlightCreateWithoutEventInput>>;
  createMany?: InputMaybe<HighlightCreateManyEventInputEnvelope>;
};

export type HighlightCreateOrConnectWithoutCommunityInput = {
  create: HighlightCreateWithoutCommunityInput;
  where: HighlightWhereUniqueInput;
};

export type HighlightCreateOrConnectWithoutEventInput = {
  create: HighlightCreateWithoutEventInput;
  where: HighlightWhereUniqueInput;
};

export type HighlightCreateWithoutCommunityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutRelatedHighlightsInput>;
  id?: InputMaybe<Scalars['String']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaType?: InputMaybe<HeadlineType>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<HighlightType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HighlightCreateWithoutEventInput = {
  community?: InputMaybe<CommunityCreateNestedOneWithoutRelatedHighlightsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  mediaPreviewUrl?: InputMaybe<Scalars['String']>;
  mediaType?: InputMaybe<HeadlineType>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<HighlightType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type HighlightListRelationFilter = {
  every?: InputMaybe<HighlightWhereInput>;
  none?: InputMaybe<HighlightWhereInput>;
  some?: InputMaybe<HighlightWhereInput>;
};

export type HighlightScalarWhereInput = {
  AND?: InputMaybe<Array<HighlightScalarWhereInput>>;
  NOT?: InputMaybe<Array<HighlightScalarWhereInput>>;
  OR?: InputMaybe<Array<HighlightScalarWhereInput>>;
  communityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  linkedItemId?: InputMaybe<StringNullableFilter>;
  linkedModule?: InputMaybe<EnumModuleNullableFilter>;
  mediaPreviewUrl?: InputMaybe<StringNullableFilter>;
  mediaType?: InputMaybe<EnumHeadlineTypeNullableFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  message?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumMessageHighlightStatusFilter>;
  type?: InputMaybe<EnumHighlightTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum HighlightType {
  EventCreated = 'EventCreated',
  EventUpdated = 'EventUpdated',
  FeedItemCreated = 'FeedItemCreated',
  Generic = 'Generic',
  ProductCreated = 'ProductCreated',
  ProductOnlyHasFewLeft = 'ProductOnlyHasFewLeft',
  ProductSoldOut = 'ProductSoldOut'
}

export type HighlightUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumHighlightTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type HighlightUpdateManyWithWhereWithoutCommunityInput = {
  data: HighlightUpdateManyMutationInput;
  where: HighlightScalarWhereInput;
};

export type HighlightUpdateManyWithWhereWithoutEventInput = {
  data: HighlightUpdateManyMutationInput;
  where: HighlightScalarWhereInput;
};

export type HighlightUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HighlightCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<HighlightCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<HighlightCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HighlightScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  set?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  update?: InputMaybe<Array<HighlightUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<HighlightUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<HighlightUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type HighlightUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<HighlightCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<HighlightCreateWithoutEventInput>>;
  createMany?: InputMaybe<HighlightCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<HighlightScalarWhereInput>>;
  disconnect?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  set?: InputMaybe<Array<HighlightWhereUniqueInput>>;
  update?: InputMaybe<Array<HighlightUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<HighlightUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<HighlightUpsertWithWhereUniqueWithoutEventInput>>;
};

export type HighlightUpdateWithWhereUniqueWithoutCommunityInput = {
  data: HighlightUpdateWithoutCommunityInput;
  where: HighlightWhereUniqueInput;
};

export type HighlightUpdateWithWhereUniqueWithoutEventInput = {
  data: HighlightUpdateWithoutEventInput;
  where: HighlightWhereUniqueInput;
};

export type HighlightUpdateWithoutCommunityInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutRelatedHighlightsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumHighlightTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type HighlightUpdateWithoutEventInput = {
  community?: InputMaybe<CommunityUpdateOneWithoutRelatedHighlightsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  mediaPreviewUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mediaType?: InputMaybe<NullableEnumHeadlineTypeFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumHighlightTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type HighlightUpsertWithWhereUniqueWithoutCommunityInput = {
  create: HighlightCreateWithoutCommunityInput;
  update: HighlightUpdateWithoutCommunityInput;
  where: HighlightWhereUniqueInput;
};

export type HighlightUpsertWithWhereUniqueWithoutEventInput = {
  create: HighlightCreateWithoutEventInput;
  update: HighlightUpdateWithoutEventInput;
  where: HighlightWhereUniqueInput;
};

export type HighlightWhereInput = {
  AND?: InputMaybe<Array<HighlightWhereInput>>;
  NOT?: InputMaybe<Array<HighlightWhereInput>>;
  OR?: InputMaybe<Array<HighlightWhereInput>>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  linkedItemId?: InputMaybe<StringNullableFilter>;
  linkedModule?: InputMaybe<EnumModuleNullableFilter>;
  mediaPreviewUrl?: InputMaybe<StringNullableFilter>;
  mediaType?: InputMaybe<EnumHeadlineTypeNullableFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  message?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumMessageHighlightStatusFilter>;
  type?: InputMaybe<EnumHighlightTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type HighlightWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InventoryReturnType = {
  __typename?: 'InventoryReturnType';
  active?: Maybe<Array<Maybe<Purchase>>>;
  expired?: Maybe<Array<Maybe<Purchase>>>;
};

export type InventorySendInvite = {
  __typename?: 'InventorySendInvite';
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  success: Scalars['Boolean'];
};

export type InviteCode = {
  __typename?: 'InviteCode';
  additionalConfiguration?: Maybe<Scalars['Json']>;
  claimedAt?: Maybe<Scalars['DateTime']>;
  claimer?: Maybe<User>;
  claimerId?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  community: Community;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  owner?: Maybe<User>;
  ownerId?: Maybe<Scalars['String']>;
  reservedFor?: Maybe<Scalars['String']>;
  type: InviteCodeType;
  updatedAt: Scalars['DateTime'];
};

export type InviteCodeCreateInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<Scalars['DateTime']>;
  claimer?: InputMaybe<UserCreateNestedOneWithoutClaimedInviteCodeInput>;
  code: Scalars['String'];
  community: CommunityCreateNestedOneWithoutPersonalizedInviteCodesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserCreateNestedOneWithoutOwnedInviteCodesInput>;
  reservedFor?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InviteCodeType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InviteCodeCreateManyClaimerInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<Scalars['DateTime']>;
  code: Scalars['String'];
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  reservedFor?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InviteCodeType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InviteCodeCreateManyClaimerInputEnvelope = {
  data?: InputMaybe<Array<InviteCodeCreateManyClaimerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InviteCodeCreateManyCommunityInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<Scalars['DateTime']>;
  claimerId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['String']>;
  reservedFor?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InviteCodeType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InviteCodeCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<InviteCodeCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InviteCodeCreateManyOwnerInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<Scalars['DateTime']>;
  claimerId?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reservedFor?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InviteCodeType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InviteCodeCreateManyOwnerInputEnvelope = {
  data?: InputMaybe<Array<InviteCodeCreateManyOwnerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InviteCodeCreateNestedManyWithoutClaimerInput = {
  connect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCodeCreateOrConnectWithoutClaimerInput>>;
  create?: InputMaybe<Array<InviteCodeCreateWithoutClaimerInput>>;
  createMany?: InputMaybe<InviteCodeCreateManyClaimerInputEnvelope>;
};

export type InviteCodeCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCodeCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<InviteCodeCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<InviteCodeCreateManyCommunityInputEnvelope>;
};

export type InviteCodeCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCodeCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<InviteCodeCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<InviteCodeCreateManyOwnerInputEnvelope>;
};

export type InviteCodeCreateOrConnectWithoutClaimerInput = {
  create: InviteCodeCreateWithoutClaimerInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeCreateOrConnectWithoutCommunityInput = {
  create: InviteCodeCreateWithoutCommunityInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeCreateOrConnectWithoutOwnerInput = {
  create: InviteCodeCreateWithoutOwnerInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeCreateWithoutClaimerInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<Scalars['DateTime']>;
  code: Scalars['String'];
  community: CommunityCreateNestedOneWithoutPersonalizedInviteCodesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserCreateNestedOneWithoutOwnedInviteCodesInput>;
  reservedFor?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InviteCodeType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InviteCodeCreateWithoutCommunityInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<Scalars['DateTime']>;
  claimer?: InputMaybe<UserCreateNestedOneWithoutClaimedInviteCodeInput>;
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<UserCreateNestedOneWithoutOwnedInviteCodesInput>;
  reservedFor?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InviteCodeType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InviteCodeCreateWithoutOwnerInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<Scalars['DateTime']>;
  claimer?: InputMaybe<UserCreateNestedOneWithoutClaimedInviteCodeInput>;
  code: Scalars['String'];
  community: CommunityCreateNestedOneWithoutPersonalizedInviteCodesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  reservedFor?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<InviteCodeType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InviteCodeListRelationFilter = {
  every?: InputMaybe<InviteCodeWhereInput>;
  none?: InputMaybe<InviteCodeWhereInput>;
  some?: InputMaybe<InviteCodeWhereInput>;
};

export type InviteCodeOrderByInput = {
  additionalConfiguration?: InputMaybe<SortOrder>;
  claimedAt?: InputMaybe<SortOrder>;
  claimerId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  reservedFor?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type InviteCodeScalarWhereInput = {
  AND?: InputMaybe<Array<InviteCodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<InviteCodeScalarWhereInput>>;
  OR?: InputMaybe<Array<InviteCodeScalarWhereInput>>;
  additionalConfiguration?: InputMaybe<JsonNullableFilter>;
  claimedAt?: InputMaybe<DateTimeNullableFilter>;
  claimerId?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  ownerId?: InputMaybe<StringNullableFilter>;
  reservedFor?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumInviteCodeTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum InviteCodeType {
  Invalid = 'Invalid',
  Permanent = 'Permanent',
  Personalized = 'Personalized'
}

export type InviteCodeUpdateInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  claimer?: InputMaybe<UserUpdateOneWithoutClaimedInviteCodeInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutPersonalizedInviteCodesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutOwnedInviteCodesInput>;
  reservedFor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInviteCodeTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InviteCodeUpdateManyMutationInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reservedFor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInviteCodeTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InviteCodeUpdateManyWithWhereWithoutClaimerInput = {
  data: InviteCodeUpdateManyMutationInput;
  where: InviteCodeScalarWhereInput;
};

export type InviteCodeUpdateManyWithWhereWithoutCommunityInput = {
  data: InviteCodeUpdateManyMutationInput;
  where: InviteCodeScalarWhereInput;
};

export type InviteCodeUpdateManyWithWhereWithoutOwnerInput = {
  data: InviteCodeUpdateManyMutationInput;
  where: InviteCodeScalarWhereInput;
};

export type InviteCodeUpdateManyWithoutClaimerInput = {
  connect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCodeCreateOrConnectWithoutClaimerInput>>;
  create?: InputMaybe<Array<InviteCodeCreateWithoutClaimerInput>>;
  createMany?: InputMaybe<InviteCodeCreateManyClaimerInputEnvelope>;
  delete?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InviteCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<InviteCodeUpdateWithWhereUniqueWithoutClaimerInput>>;
  updateMany?: InputMaybe<Array<InviteCodeUpdateManyWithWhereWithoutClaimerInput>>;
  upsert?: InputMaybe<Array<InviteCodeUpsertWithWhereUniqueWithoutClaimerInput>>;
};

export type InviteCodeUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCodeCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<InviteCodeCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<InviteCodeCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InviteCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<InviteCodeUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<InviteCodeUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<InviteCodeUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type InviteCodeUpdateManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InviteCodeCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<InviteCodeCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<InviteCodeCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<InviteCodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  set?: InputMaybe<Array<InviteCodeWhereUniqueInput>>;
  update?: InputMaybe<Array<InviteCodeUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<InviteCodeUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<InviteCodeUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type InviteCodeUpdateWithWhereUniqueWithoutClaimerInput = {
  data: InviteCodeUpdateWithoutClaimerInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeUpdateWithWhereUniqueWithoutCommunityInput = {
  data: InviteCodeUpdateWithoutCommunityInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeUpdateWithWhereUniqueWithoutOwnerInput = {
  data: InviteCodeUpdateWithoutOwnerInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeUpdateWithoutClaimerInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutPersonalizedInviteCodesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutOwnedInviteCodesInput>;
  reservedFor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInviteCodeTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InviteCodeUpdateWithoutCommunityInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  claimer?: InputMaybe<UserUpdateOneWithoutClaimedInviteCodeInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneWithoutOwnedInviteCodesInput>;
  reservedFor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInviteCodeTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InviteCodeUpdateWithoutOwnerInput = {
  additionalConfiguration?: InputMaybe<Scalars['Json']>;
  claimedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  claimer?: InputMaybe<UserUpdateOneWithoutClaimedInviteCodeInput>;
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutPersonalizedInviteCodesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  reservedFor?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumInviteCodeTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type InviteCodeUpsertWithWhereUniqueWithoutClaimerInput = {
  create: InviteCodeCreateWithoutClaimerInput;
  update: InviteCodeUpdateWithoutClaimerInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeUpsertWithWhereUniqueWithoutCommunityInput = {
  create: InviteCodeCreateWithoutCommunityInput;
  update: InviteCodeUpdateWithoutCommunityInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeUpsertWithWhereUniqueWithoutOwnerInput = {
  create: InviteCodeCreateWithoutOwnerInput;
  update: InviteCodeUpdateWithoutOwnerInput;
  where: InviteCodeWhereUniqueInput;
};

export type InviteCodeWhereInput = {
  AND?: InputMaybe<Array<InviteCodeWhereInput>>;
  NOT?: InputMaybe<Array<InviteCodeWhereInput>>;
  OR?: InputMaybe<Array<InviteCodeWhereInput>>;
  additionalConfiguration?: InputMaybe<JsonNullableFilter>;
  claimedAt?: InputMaybe<DateTimeNullableFilter>;
  claimer?: InputMaybe<UserWhereInput>;
  claimerId?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringFilter>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  owner?: InputMaybe<UserWhereInput>;
  ownerId?: InputMaybe<StringNullableFilter>;
  reservedFor?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumInviteCodeTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InviteCodeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type JoinCommunityReturnType = {
  __typename?: 'JoinCommunityReturnType';
  communities?: Maybe<Array<Community>>;
  communityToJoin?: Maybe<Community>;
  missingInfo?: Maybe<Array<Maybe<UserInfoField>>>;
};

export type JsonNullableFilter = {
  equals?: InputMaybe<Scalars['Json']>;
  not?: InputMaybe<Scalars['Json']>;
};

export type KeyMetricsData = {
  __typename?: 'KeyMetricsData';
  totalPurchases?: Maybe<Scalars['Int']>;
  usedCount?: Maybe<Scalars['Int']>;
};

export type Location = {
  __typename?: 'Location';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  sublocations: Array<Scalars['String']>;
  supported: Scalars['Boolean'];
  ubiqFeedItems: Array<UbiqFeedItem>;
  updatedAt: Scalars['DateTime'];
  users: Array<User>;
};


export type LocationUbiqFeedItemsArgs = {
  cursor?: InputMaybe<UbiqFeedItemWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type LocationUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type LocationCreateInput = {
  code: Scalars['String'];
  communitiesToExplore?: InputMaybe<CommunityCreateNestedManyWithoutVisibleInLocationsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  sublocations?: InputMaybe<LocationCreatesublocationsInput>;
  supported?: InputMaybe<Scalars['Boolean']>;
  ubiqFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToLocationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutLocationInput>;
};

export type LocationCreateNestedManyWithoutCommunitiesToExploreInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutCommunitiesToExploreInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutCommunitiesToExploreInput>>;
};

export type LocationCreateNestedManyWithoutUbiqFeedItemsInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutUbiqFeedItemsInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutUbiqFeedItemsInput>>;
};

export type LocationCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<LocationCreateWithoutUsersInput>;
};

export type LocationCreateOrConnectWithoutCommunitiesToExploreInput = {
  create: LocationCreateWithoutCommunitiesToExploreInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutUbiqFeedItemsInput = {
  create: LocationCreateWithoutUbiqFeedItemsInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateOrConnectWithoutUsersInput = {
  create: LocationCreateWithoutUsersInput;
  where: LocationWhereUniqueInput;
};

export type LocationCreateWithoutCommunitiesToExploreInput = {
  code: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  sublocations?: InputMaybe<LocationCreatesublocationsInput>;
  supported?: InputMaybe<Scalars['Boolean']>;
  ubiqFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToLocationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutLocationInput>;
};

export type LocationCreateWithoutUbiqFeedItemsInput = {
  code: Scalars['String'];
  communitiesToExplore?: InputMaybe<CommunityCreateNestedManyWithoutVisibleInLocationsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  sublocations?: InputMaybe<LocationCreatesublocationsInput>;
  supported?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutLocationInput>;
};

export type LocationCreateWithoutUsersInput = {
  code: Scalars['String'];
  communitiesToExplore?: InputMaybe<CommunityCreateNestedManyWithoutVisibleInLocationsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  name: Scalars['String'];
  sublocations?: InputMaybe<LocationCreatesublocationsInput>;
  supported?: InputMaybe<Scalars['Boolean']>;
  ubiqFeedItems?: InputMaybe<UbiqFeedItemCreateNestedManyWithoutRestrictToLocationsInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type LocationCreatesublocationsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type LocationListRelationFilter = {
  every?: InputMaybe<LocationWhereInput>;
  none?: InputMaybe<LocationWhereInput>;
  some?: InputMaybe<LocationWhereInput>;
};

export type LocationOrderByInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  latitude?: InputMaybe<SortOrder>;
  longitude?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  sublocations?: InputMaybe<SortOrder>;
  supported?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type LocationScalarWhereInput = {
  AND?: InputMaybe<Array<LocationScalarWhereInput>>;
  NOT?: InputMaybe<Array<LocationScalarWhereInput>>;
  OR?: InputMaybe<Array<LocationScalarWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  name?: InputMaybe<StringFilter>;
  sublocations?: InputMaybe<StringNullableListFilter>;
  supported?: InputMaybe<BoolFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type LocationUpdateInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  communitiesToExplore?: InputMaybe<CommunityUpdateManyWithoutVisibleInLocationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sublocations?: InputMaybe<LocationUpdatesublocationsInput>;
  supported?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ubiqFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToLocationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutLocationInput>;
};

export type LocationUpdateManyMutationInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sublocations?: InputMaybe<LocationUpdatesublocationsInput>;
  supported?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LocationUpdateManyWithWhereWithoutCommunitiesToExploreInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithWhereWithoutUbiqFeedItemsInput = {
  data: LocationUpdateManyMutationInput;
  where: LocationScalarWhereInput;
};

export type LocationUpdateManyWithoutCommunitiesToExploreInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutCommunitiesToExploreInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutCommunitiesToExploreInput>>;
  delete?: InputMaybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationWhereUniqueInput>>;
  update?: InputMaybe<Array<LocationUpdateWithWhereUniqueWithoutCommunitiesToExploreInput>>;
  updateMany?: InputMaybe<Array<LocationUpdateManyWithWhereWithoutCommunitiesToExploreInput>>;
  upsert?: InputMaybe<Array<LocationUpsertWithWhereUniqueWithoutCommunitiesToExploreInput>>;
};

export type LocationUpdateManyWithoutUbiqFeedItemsInput = {
  connect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LocationCreateOrConnectWithoutUbiqFeedItemsInput>>;
  create?: InputMaybe<Array<LocationCreateWithoutUbiqFeedItemsInput>>;
  delete?: InputMaybe<Array<LocationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LocationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LocationWhereUniqueInput>>;
  set?: InputMaybe<Array<LocationWhereUniqueInput>>;
  update?: InputMaybe<Array<LocationUpdateWithWhereUniqueWithoutUbiqFeedItemsInput>>;
  updateMany?: InputMaybe<Array<LocationUpdateManyWithWhereWithoutUbiqFeedItemsInput>>;
  upsert?: InputMaybe<Array<LocationUpsertWithWhereUniqueWithoutUbiqFeedItemsInput>>;
};

export type LocationUpdateOneWithoutUsersInput = {
  connect?: InputMaybe<LocationWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LocationCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<LocationCreateWithoutUsersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<LocationUpdateWithoutUsersInput>;
  upsert?: InputMaybe<LocationUpsertWithoutUsersInput>;
};

export type LocationUpdateWithWhereUniqueWithoutCommunitiesToExploreInput = {
  data: LocationUpdateWithoutCommunitiesToExploreInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithWhereUniqueWithoutUbiqFeedItemsInput = {
  data: LocationUpdateWithoutUbiqFeedItemsInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpdateWithoutCommunitiesToExploreInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sublocations?: InputMaybe<LocationUpdatesublocationsInput>;
  supported?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ubiqFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToLocationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutLocationInput>;
};

export type LocationUpdateWithoutUbiqFeedItemsInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  communitiesToExplore?: InputMaybe<CommunityUpdateManyWithoutVisibleInLocationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sublocations?: InputMaybe<LocationUpdatesublocationsInput>;
  supported?: InputMaybe<BoolFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  users?: InputMaybe<UserUpdateManyWithoutLocationInput>;
};

export type LocationUpdateWithoutUsersInput = {
  code?: InputMaybe<StringFieldUpdateOperationsInput>;
  communitiesToExplore?: InputMaybe<CommunityUpdateManyWithoutVisibleInLocationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  latitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  longitude?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  sublocations?: InputMaybe<LocationUpdatesublocationsInput>;
  supported?: InputMaybe<BoolFieldUpdateOperationsInput>;
  ubiqFeedItems?: InputMaybe<UbiqFeedItemUpdateManyWithoutRestrictToLocationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type LocationUpdatesublocationsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type LocationUpsertWithWhereUniqueWithoutCommunitiesToExploreInput = {
  create: LocationCreateWithoutCommunitiesToExploreInput;
  update: LocationUpdateWithoutCommunitiesToExploreInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithWhereUniqueWithoutUbiqFeedItemsInput = {
  create: LocationCreateWithoutUbiqFeedItemsInput;
  update: LocationUpdateWithoutUbiqFeedItemsInput;
  where: LocationWhereUniqueInput;
};

export type LocationUpsertWithoutUsersInput = {
  create: LocationCreateWithoutUsersInput;
  update: LocationUpdateWithoutUsersInput;
};

export type LocationWhereInput = {
  AND?: InputMaybe<Array<LocationWhereInput>>;
  NOT?: InputMaybe<Array<LocationWhereInput>>;
  OR?: InputMaybe<Array<LocationWhereInput>>;
  code?: InputMaybe<StringFilter>;
  communitiesToExplore?: InputMaybe<CommunityListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  latitude?: InputMaybe<FloatNullableFilter>;
  longitude?: InputMaybe<FloatNullableFilter>;
  name?: InputMaybe<StringFilter>;
  sublocations?: InputMaybe<StringNullableListFilter>;
  supported?: InputMaybe<BoolFilter>;
  ubiqFeedItems?: InputMaybe<UbiqFeedItemListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type LocationWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type MassMessage = {
  __typename?: 'MassMessage';
  actionDeepLink: Scalars['String'];
  chunkInterval: Scalars['Int'];
  chunkSize: Scalars['Int'];
  community?: Maybe<Community>;
  createdAt: Scalars['DateTime'];
  customSegmentUserIds?: Maybe<Scalars['String']>;
  event?: Maybe<Event>;
  id: Scalars['String'];
  immediateSend: Scalars['Boolean'];
  linkedDisplayInfo: Scalars['String'];
  linkedItemId?: Maybe<Scalars['String']>;
  linkedModule?: Maybe<Module>;
  message: Scalars['String'];
  missingFields: Array<Scalars['String']>;
  name: Scalars['String'];
  onesignalIds: Array<Scalars['String']>;
  onesignalUrls: Array<Scalars['String']>;
  rejectionReason?: Maybe<Scalars['String']>;
  segment?: Maybe<MassMessageSegment>;
  sendAt?: Maybe<Scalars['DateTime']>;
  status: MassMessageStatus;
  talksMessageText?: Maybe<Scalars['String']>;
  talksMessageType?: Maybe<TalksMessageType>;
  title: Scalars['String'];
  totalClicks: Scalars['Int'];
  totalPushIds: Scalars['Int'];
  totalReceivers: Scalars['Int'];
  totalUsers: Scalars['Int'];
  type: MassMessageType;
  updatedAt: Scalars['DateTime'];
};

export type MassMessageCreateInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  chunkInterval?: InputMaybe<Scalars['Int']>;
  chunkSize?: InputMaybe<Scalars['Int']>;
  community?: InputMaybe<CommunityCreateNestedOneWithoutRelatedMasssMessagesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customSegmentUserIds?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventCreateNestedOneWithoutRelatedMassMessagesInput>;
  id?: InputMaybe<Scalars['String']>;
  immediateSend?: InputMaybe<Scalars['Boolean']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  onesignalIds?: InputMaybe<MassMessageCreateonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageCreateonesignalUrlsInput>;
  rejectionReason?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<MassMessageSegment>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<MassMessageStatus>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<Scalars['String']>;
  talksMessageType?: InputMaybe<TalksMessageType>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalPushIds?: InputMaybe<Scalars['Int']>;
  totalReceivers?: InputMaybe<Scalars['Int']>;
  totalUsers?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<MassMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MassMessageCreateManyCommunityInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  chunkInterval?: InputMaybe<Scalars['Int']>;
  chunkSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customSegmentUserIds?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  immediateSend?: InputMaybe<Scalars['Boolean']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  onesignalIds?: InputMaybe<MassMessageCreateManyonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageCreateManyonesignalUrlsInput>;
  rejectionReason?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<MassMessageSegment>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<MassMessageStatus>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<Scalars['String']>;
  talksMessageType?: InputMaybe<TalksMessageType>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalPushIds?: InputMaybe<Scalars['Int']>;
  totalReceivers?: InputMaybe<Scalars['Int']>;
  totalUsers?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<MassMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MassMessageCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<MassMessageCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MassMessageCreateManyEventInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  chunkInterval?: InputMaybe<Scalars['Int']>;
  chunkSize?: InputMaybe<Scalars['Int']>;
  communityId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customSegmentUserIds?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  immediateSend?: InputMaybe<Scalars['Boolean']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  onesignalIds?: InputMaybe<MassMessageCreateManyonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageCreateManyonesignalUrlsInput>;
  rejectionReason?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<MassMessageSegment>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<MassMessageStatus>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<Scalars['String']>;
  talksMessageType?: InputMaybe<TalksMessageType>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalPushIds?: InputMaybe<Scalars['Int']>;
  totalReceivers?: InputMaybe<Scalars['Int']>;
  totalUsers?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<MassMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MassMessageCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<MassMessageCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MassMessageCreateManyonesignalIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type MassMessageCreateManyonesignalUrlsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type MassMessageCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MassMessageCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<MassMessageCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<MassMessageCreateManyCommunityInputEnvelope>;
};

export type MassMessageCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MassMessageCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<MassMessageCreateWithoutEventInput>>;
  createMany?: InputMaybe<MassMessageCreateManyEventInputEnvelope>;
};

export type MassMessageCreateOrConnectWithoutCommunityInput = {
  create: MassMessageCreateWithoutCommunityInput;
  where: MassMessageWhereUniqueInput;
};

export type MassMessageCreateOrConnectWithoutEventInput = {
  create: MassMessageCreateWithoutEventInput;
  where: MassMessageWhereUniqueInput;
};

export type MassMessageCreateWithoutCommunityInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  chunkInterval?: InputMaybe<Scalars['Int']>;
  chunkSize?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customSegmentUserIds?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<EventCreateNestedOneWithoutRelatedMassMessagesInput>;
  id?: InputMaybe<Scalars['String']>;
  immediateSend?: InputMaybe<Scalars['Boolean']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  onesignalIds?: InputMaybe<MassMessageCreateonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageCreateonesignalUrlsInput>;
  rejectionReason?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<MassMessageSegment>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<MassMessageStatus>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<Scalars['String']>;
  talksMessageType?: InputMaybe<TalksMessageType>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalPushIds?: InputMaybe<Scalars['Int']>;
  totalReceivers?: InputMaybe<Scalars['Int']>;
  totalUsers?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<MassMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MassMessageCreateWithoutEventInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  chunkInterval?: InputMaybe<Scalars['Int']>;
  chunkSize?: InputMaybe<Scalars['Int']>;
  community?: InputMaybe<CommunityCreateNestedOneWithoutRelatedMasssMessagesInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customSegmentUserIds?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  immediateSend?: InputMaybe<Scalars['Boolean']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  onesignalIds?: InputMaybe<MassMessageCreateonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageCreateonesignalUrlsInput>;
  rejectionReason?: InputMaybe<Scalars['String']>;
  segment?: InputMaybe<MassMessageSegment>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<MassMessageStatus>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<Scalars['String']>;
  talksMessageType?: InputMaybe<TalksMessageType>;
  title?: InputMaybe<Scalars['String']>;
  totalClicks?: InputMaybe<Scalars['Int']>;
  totalPushIds?: InputMaybe<Scalars['Int']>;
  totalReceivers?: InputMaybe<Scalars['Int']>;
  totalUsers?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<MassMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MassMessageCreateonesignalIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type MassMessageCreateonesignalUrlsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type MassMessageListRelationFilter = {
  every?: InputMaybe<MassMessageWhereInput>;
  none?: InputMaybe<MassMessageWhereInput>;
  some?: InputMaybe<MassMessageWhereInput>;
};

export type MassMessageOrderByInput = {
  actionDeepLink?: InputMaybe<SortOrder>;
  chunkInterval?: InputMaybe<SortOrder>;
  chunkSize?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customSegmentUserIds?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  immediateSend?: InputMaybe<SortOrder>;
  linkedItemId?: InputMaybe<SortOrder>;
  linkedModule?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  onesignalIds?: InputMaybe<SortOrder>;
  onesignalUrls?: InputMaybe<SortOrder>;
  rejectionReason?: InputMaybe<SortOrder>;
  segment?: InputMaybe<SortOrder>;
  sendAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  talksMessageAdditionalData?: InputMaybe<SortOrder>;
  talksMessageText?: InputMaybe<SortOrder>;
  talksMessageType?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  totalClicks?: InputMaybe<SortOrder>;
  totalPushIds?: InputMaybe<SortOrder>;
  totalReceivers?: InputMaybe<SortOrder>;
  totalUsers?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MassMessageScalarWhereInput = {
  AND?: InputMaybe<Array<MassMessageScalarWhereInput>>;
  NOT?: InputMaybe<Array<MassMessageScalarWhereInput>>;
  OR?: InputMaybe<Array<MassMessageScalarWhereInput>>;
  actionDeepLink?: InputMaybe<StringFilter>;
  chunkInterval?: InputMaybe<IntFilter>;
  chunkSize?: InputMaybe<IntFilter>;
  communityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customSegmentUserIds?: InputMaybe<StringNullableFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  immediateSend?: InputMaybe<BoolFilter>;
  linkedItemId?: InputMaybe<StringNullableFilter>;
  linkedModule?: InputMaybe<EnumModuleNullableFilter>;
  message?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  onesignalIds?: InputMaybe<StringNullableListFilter>;
  onesignalUrls?: InputMaybe<StringNullableListFilter>;
  rejectionReason?: InputMaybe<StringNullableFilter>;
  segment?: InputMaybe<EnumMassMessageSegmentNullableFilter>;
  sendAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumMassMessageStatusFilter>;
  talksMessageAdditionalData?: InputMaybe<JsonNullableFilter>;
  talksMessageText?: InputMaybe<StringNullableFilter>;
  talksMessageType?: InputMaybe<EnumTalksMessageTypeNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalClicks?: InputMaybe<IntFilter>;
  totalPushIds?: InputMaybe<IntFilter>;
  totalReceivers?: InputMaybe<IntFilter>;
  totalUsers?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumMassMessageTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum MassMessageSegment {
  CommunityMembers = 'CommunityMembers',
  Custom = 'Custom',
  EventCheckedIn = 'EventCheckedIn',
  EventInvited = 'EventInvited',
  EventInvitedNoTicket = 'EventInvitedNoTicket',
  EventMultipleTickets = 'EventMultipleTickets',
  EventRegistered = 'EventRegistered',
  EventRegisteredNoTicket = 'EventRegisteredNoTicket',
  EventTicketed = 'EventTicketed'
}

export enum MassMessageStatus {
  Deleted = 'Deleted',
  Draft = 'Draft',
  FailedScheduling = 'FailedScheduling',
  PendingScheduling = 'PendingScheduling',
  Rejected = 'Rejected',
  Scheduled = 'Scheduled',
  Sent = 'Sent',
  UneditedDraft = 'UneditedDraft',
  WaitingForApproval = 'WaitingForApproval'
}

export enum MassMessageType {
  LocationDropped = 'LocationDropped',
  Manual = 'Manual'
}

export type MassMessageUpdateInput = {
  actionDeepLink?: InputMaybe<StringFieldUpdateOperationsInput>;
  chunkInterval?: InputMaybe<IntFieldUpdateOperationsInput>;
  chunkSize?: InputMaybe<IntFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneWithoutRelatedMasssMessagesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customSegmentUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutRelatedMassMessagesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  immediateSend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onesignalIds?: InputMaybe<MassMessageUpdateonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageUpdateonesignalUrlsInput>;
  rejectionReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  segment?: InputMaybe<NullableEnumMassMessageSegmentFieldUpdateOperationsInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMassMessageStatusFieldUpdateOperationsInput>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  talksMessageType?: InputMaybe<NullableEnumTalksMessageTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalClicks?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalPushIds?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReceivers?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalUsers?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumMassMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MassMessageUpdateManyMutationInput = {
  actionDeepLink?: InputMaybe<StringFieldUpdateOperationsInput>;
  chunkInterval?: InputMaybe<IntFieldUpdateOperationsInput>;
  chunkSize?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customSegmentUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  immediateSend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onesignalIds?: InputMaybe<MassMessageUpdateonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageUpdateonesignalUrlsInput>;
  rejectionReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  segment?: InputMaybe<NullableEnumMassMessageSegmentFieldUpdateOperationsInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMassMessageStatusFieldUpdateOperationsInput>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  talksMessageType?: InputMaybe<NullableEnumTalksMessageTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalClicks?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalPushIds?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReceivers?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalUsers?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumMassMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MassMessageUpdateManyWithWhereWithoutCommunityInput = {
  data: MassMessageUpdateManyMutationInput;
  where: MassMessageScalarWhereInput;
};

export type MassMessageUpdateManyWithWhereWithoutEventInput = {
  data: MassMessageUpdateManyMutationInput;
  where: MassMessageScalarWhereInput;
};

export type MassMessageUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MassMessageCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<MassMessageCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<MassMessageCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MassMessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  set?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  update?: InputMaybe<Array<MassMessageUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<MassMessageUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<MassMessageUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type MassMessageUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MassMessageCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<MassMessageCreateWithoutEventInput>>;
  createMany?: InputMaybe<MassMessageCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MassMessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  set?: InputMaybe<Array<MassMessageWhereUniqueInput>>;
  update?: InputMaybe<Array<MassMessageUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<MassMessageUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<MassMessageUpsertWithWhereUniqueWithoutEventInput>>;
};

export type MassMessageUpdateWithWhereUniqueWithoutCommunityInput = {
  data: MassMessageUpdateWithoutCommunityInput;
  where: MassMessageWhereUniqueInput;
};

export type MassMessageUpdateWithWhereUniqueWithoutEventInput = {
  data: MassMessageUpdateWithoutEventInput;
  where: MassMessageWhereUniqueInput;
};

export type MassMessageUpdateWithoutCommunityInput = {
  actionDeepLink?: InputMaybe<StringFieldUpdateOperationsInput>;
  chunkInterval?: InputMaybe<IntFieldUpdateOperationsInput>;
  chunkSize?: InputMaybe<IntFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customSegmentUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutRelatedMassMessagesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  immediateSend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onesignalIds?: InputMaybe<MassMessageUpdateonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageUpdateonesignalUrlsInput>;
  rejectionReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  segment?: InputMaybe<NullableEnumMassMessageSegmentFieldUpdateOperationsInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMassMessageStatusFieldUpdateOperationsInput>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  talksMessageType?: InputMaybe<NullableEnumTalksMessageTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalClicks?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalPushIds?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReceivers?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalUsers?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumMassMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MassMessageUpdateWithoutEventInput = {
  actionDeepLink?: InputMaybe<StringFieldUpdateOperationsInput>;
  chunkInterval?: InputMaybe<IntFieldUpdateOperationsInput>;
  chunkSize?: InputMaybe<IntFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneWithoutRelatedMasssMessagesInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customSegmentUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  immediateSend?: InputMaybe<BoolFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  onesignalIds?: InputMaybe<MassMessageUpdateonesignalIdsInput>;
  onesignalUrls?: InputMaybe<MassMessageUpdateonesignalUrlsInput>;
  rejectionReason?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  segment?: InputMaybe<NullableEnumMassMessageSegmentFieldUpdateOperationsInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMassMessageStatusFieldUpdateOperationsInput>;
  talksMessageAdditionalData?: InputMaybe<Scalars['Json']>;
  talksMessageText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  talksMessageType?: InputMaybe<NullableEnumTalksMessageTypeFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  totalClicks?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalPushIds?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalReceivers?: InputMaybe<IntFieldUpdateOperationsInput>;
  totalUsers?: InputMaybe<IntFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumMassMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MassMessageUpdateonesignalIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type MassMessageUpdateonesignalUrlsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type MassMessageUpsertWithWhereUniqueWithoutCommunityInput = {
  create: MassMessageCreateWithoutCommunityInput;
  update: MassMessageUpdateWithoutCommunityInput;
  where: MassMessageWhereUniqueInput;
};

export type MassMessageUpsertWithWhereUniqueWithoutEventInput = {
  create: MassMessageCreateWithoutEventInput;
  update: MassMessageUpdateWithoutEventInput;
  where: MassMessageWhereUniqueInput;
};

export type MassMessageWhereInput = {
  AND?: InputMaybe<Array<MassMessageWhereInput>>;
  NOT?: InputMaybe<Array<MassMessageWhereInput>>;
  OR?: InputMaybe<Array<MassMessageWhereInput>>;
  actionDeepLink?: InputMaybe<StringFilter>;
  chunkInterval?: InputMaybe<IntFilter>;
  chunkSize?: InputMaybe<IntFilter>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customSegmentUserIds?: InputMaybe<StringNullableFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  immediateSend?: InputMaybe<BoolFilter>;
  linkedItemId?: InputMaybe<StringNullableFilter>;
  linkedModule?: InputMaybe<EnumModuleNullableFilter>;
  message?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  onesignalIds?: InputMaybe<StringNullableListFilter>;
  onesignalUrls?: InputMaybe<StringNullableListFilter>;
  rejectionReason?: InputMaybe<StringNullableFilter>;
  segment?: InputMaybe<EnumMassMessageSegmentNullableFilter>;
  sendAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumMassMessageStatusFilter>;
  talksMessageAdditionalData?: InputMaybe<JsonNullableFilter>;
  talksMessageText?: InputMaybe<StringNullableFilter>;
  talksMessageType?: InputMaybe<EnumTalksMessageTypeNullableFilter>;
  title?: InputMaybe<StringFilter>;
  totalClicks?: InputMaybe<IntFilter>;
  totalPushIds?: InputMaybe<IntFilter>;
  totalReceivers?: InputMaybe<IntFilter>;
  totalUsers?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumMassMessageTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MassMessageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export enum MessageHighlightStatus {
  Hidden = 'Hidden',
  Visible = 'Visible'
}

export type Modal = {
  __typename?: 'Modal';
  CTAs: Array<Cta>;
  body: Scalars['String'];
  frequency: ModalFrequency;
  title: Scalars['String'];
  type: Scalars['String'];
};

export enum ModalFrequency {
  Day = 'day',
  Once = 'once'
}

export enum Module {
  Catalogue = 'Catalogue',
  EventList = 'EventList',
  Feed = 'Feed',
  Forum = 'Forum',
  IdCard = 'IDCard',
  Landing = 'Landing',
  OpenCalls = 'OpenCalls',
  PrivateEvent = 'PrivateEvent',
  Radio = 'Radio',
  Schedule = 'Schedule',
  Shop = 'Shop'
}

export type ModuleConfiguration = {
  __typename?: 'ModuleConfiguration';
  availableTo: Scalars['String'];
  community: Community;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  module: Module;
  name?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  styleOverride: Scalars['Json'];
  updatedAt: Scalars['DateTime'];
};

export type ModuleConfigurationCreateInput = {
  availableTo: Scalars['String'];
  community: CommunityCreateNestedOneWithoutModuleConfigurationsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  module: Module;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  styleOverride: Scalars['Json'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModuleConfigurationCreateManyCommunityInput = {
  availableTo: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  module: Module;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  styleOverride: Scalars['Json'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModuleConfigurationCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<ModuleConfigurationCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ModuleConfigurationCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<ModuleConfigurationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleConfigurationCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<ModuleConfigurationCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<ModuleConfigurationCreateManyCommunityInputEnvelope>;
};

export type ModuleConfigurationCreateOrConnectWithoutCommunityInput = {
  create: ModuleConfigurationCreateWithoutCommunityInput;
  where: ModuleConfigurationWhereUniqueInput;
};

export type ModuleConfigurationCreateWithoutCommunityInput = {
  availableTo: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  module: Module;
  name?: InputMaybe<Scalars['String']>;
  position?: InputMaybe<Scalars['Int']>;
  styleOverride: Scalars['Json'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ModuleConfigurationListRelationFilter = {
  every?: InputMaybe<ModuleConfigurationWhereInput>;
  none?: InputMaybe<ModuleConfigurationWhereInput>;
  some?: InputMaybe<ModuleConfigurationWhereInput>;
};

export type ModuleConfigurationOrderByInput = {
  availableTo?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  module?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  styleOverride?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type ModuleConfigurationScalarWhereInput = {
  AND?: InputMaybe<Array<ModuleConfigurationScalarWhereInput>>;
  NOT?: InputMaybe<Array<ModuleConfigurationScalarWhereInput>>;
  OR?: InputMaybe<Array<ModuleConfigurationScalarWhereInput>>;
  availableTo?: InputMaybe<StringFilter>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  module?: InputMaybe<EnumModuleFilter>;
  name?: InputMaybe<StringNullableFilter>;
  position?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModuleConfigurationUpdateInput = {
  availableTo?: InputMaybe<StringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutModuleConfigurationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  module?: InputMaybe<EnumModuleFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  position?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModuleConfigurationUpdateManyMutationInput = {
  availableTo?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  module?: InputMaybe<EnumModuleFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  position?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModuleConfigurationUpdateManyWithWhereWithoutCommunityInput = {
  data: ModuleConfigurationUpdateManyMutationInput;
  where: ModuleConfigurationScalarWhereInput;
};

export type ModuleConfigurationUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<ModuleConfigurationWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleConfigurationCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<ModuleConfigurationCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<ModuleConfigurationCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<ModuleConfigurationWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ModuleConfigurationScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ModuleConfigurationWhereUniqueInput>>;
  set?: InputMaybe<Array<ModuleConfigurationWhereUniqueInput>>;
  update?: InputMaybe<Array<ModuleConfigurationUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<ModuleConfigurationUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<ModuleConfigurationUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type ModuleConfigurationUpdateWithWhereUniqueWithoutCommunityInput = {
  data: ModuleConfigurationUpdateWithoutCommunityInput;
  where: ModuleConfigurationWhereUniqueInput;
};

export type ModuleConfigurationUpdateWithoutCommunityInput = {
  availableTo?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  module?: InputMaybe<EnumModuleFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  position?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type ModuleConfigurationUpsertWithWhereUniqueWithoutCommunityInput = {
  create: ModuleConfigurationCreateWithoutCommunityInput;
  update: ModuleConfigurationUpdateWithoutCommunityInput;
  where: ModuleConfigurationWhereUniqueInput;
};

export type ModuleConfigurationWhereInput = {
  AND?: InputMaybe<Array<ModuleConfigurationWhereInput>>;
  NOT?: InputMaybe<Array<ModuleConfigurationWhereInput>>;
  OR?: InputMaybe<Array<ModuleConfigurationWhereInput>>;
  availableTo?: InputMaybe<StringFilter>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  module?: InputMaybe<EnumModuleFilter>;
  name?: InputMaybe<StringNullableFilter>;
  position?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type ModuleConfigurationWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addToWaitlist?: Maybe<Scalars['Boolean']>;
  adminAddGuests?: Maybe<Scalars['Boolean']>;
  adminCreateOneActivityEvent: ActivityEvent;
  adminCreateOneCatalogItem: CatalogItem;
  adminCreateOneCatalogItemCategory: CatalogItemCategory;
  adminCreateOneCatalogItemSchedule: CatalogItemSchedule;
  adminCreateOneClaimedBadge: ClaimedBadge;
  adminCreateOneCommunity: Community;
  adminCreateOneCommunityMemberInfo: CommunityMemberInfo;
  adminCreateOneConfiguration: Configuration;
  adminCreateOneEvent: Event;
  adminCreateOneEventCategory: EventCategory;
  adminCreateOneFaqCategory: FaqCategory;
  adminCreateOneFaqItem: FaqItem;
  adminCreateOneFeedItem: FeedItem;
  adminCreateOneFeedItemComment: FeedItemComment;
  adminCreateOneFeedItemCommentReport: FeedItemCommentReport;
  adminCreateOneForumComment: ForumComment;
  adminCreateOneForumContentReport: ForumContentReport;
  adminCreateOneForumPost: ForumPost;
  adminCreateOneForumTag: ForumTag;
  adminCreateOneInviteCode: InviteCode;
  adminCreateOneLocation: Location;
  adminCreateOneMassMessage: MassMessage;
  adminCreateOneModuleConfiguration: ModuleConfiguration;
  adminCreateOneOpenCall: OpenCall;
  adminCreateOneOpenCallSubmission: OpenCallSubmission;
  adminCreateOnePayout: Payout;
  adminCreateOneProduct: Product;
  adminCreateOnePromocode: Promocode;
  adminCreateOnePurchase: Purchase;
  adminCreateOnePurchaseTransfer: PurchaseTransfer;
  adminCreateOneTalksMessage: TalksMessage;
  adminCreateOneTemplateBadge: TemplateBadge;
  adminCreateOneTrack: Track;
  adminCreateOneUbiqFeedItem: UbiqFeedItem;
  adminCreateOneUser: User;
  adminCreateOneWaitlistUser: WaitlistUser;
  adminDeleteManyActivityEvent: AffectedRowsOutput;
  adminDeleteManyCatalogItem: AffectedRowsOutput;
  adminDeleteManyCatalogItemCategory: AffectedRowsOutput;
  adminDeleteManyCatalogItemSchedule: AffectedRowsOutput;
  adminDeleteManyClaimedBadge: AffectedRowsOutput;
  adminDeleteManyCommunity: AffectedRowsOutput;
  adminDeleteManyCommunityMemberInfo: AffectedRowsOutput;
  adminDeleteManyConfiguration: AffectedRowsOutput;
  adminDeleteManyEvent: AffectedRowsOutput;
  adminDeleteManyEventCategory: AffectedRowsOutput;
  adminDeleteManyFaqCategory: AffectedRowsOutput;
  adminDeleteManyFaqItem: AffectedRowsOutput;
  adminDeleteManyFeedItem: AffectedRowsOutput;
  adminDeleteManyFeedItemComment: AffectedRowsOutput;
  adminDeleteManyFeedItemCommentReport: AffectedRowsOutput;
  adminDeleteManyForumComment: AffectedRowsOutput;
  adminDeleteManyForumContentReport: AffectedRowsOutput;
  adminDeleteManyForumPost: AffectedRowsOutput;
  adminDeleteManyForumTag: AffectedRowsOutput;
  adminDeleteManyInviteCode: AffectedRowsOutput;
  adminDeleteManyLocation: AffectedRowsOutput;
  adminDeleteManyMassMessage: AffectedRowsOutput;
  adminDeleteManyModuleConfiguration: AffectedRowsOutput;
  adminDeleteManyOpenCall: AffectedRowsOutput;
  adminDeleteManyOpenCallSubmission: AffectedRowsOutput;
  adminDeleteManyPayout: AffectedRowsOutput;
  adminDeleteManyProduct: AffectedRowsOutput;
  adminDeleteManyPromocode: AffectedRowsOutput;
  adminDeleteManyPurchase: AffectedRowsOutput;
  adminDeleteManyPurchaseTransfer: AffectedRowsOutput;
  adminDeleteManyTalksMessage: AffectedRowsOutput;
  adminDeleteManyTemplateBadge: AffectedRowsOutput;
  adminDeleteManyTrack: AffectedRowsOutput;
  adminDeleteManyUbiqFeedItem: AffectedRowsOutput;
  adminDeleteManyUser: AffectedRowsOutput;
  adminDeleteManyWaitlistUser: AffectedRowsOutput;
  adminDeleteOneActivityEvent?: Maybe<ActivityEvent>;
  adminDeleteOneCatalogItem?: Maybe<CatalogItem>;
  adminDeleteOneCatalogItemCategory?: Maybe<CatalogItemCategory>;
  adminDeleteOneCatalogItemSchedule?: Maybe<CatalogItemSchedule>;
  adminDeleteOneClaimedBadge?: Maybe<ClaimedBadge>;
  adminDeleteOneCommunity?: Maybe<Community>;
  adminDeleteOneCommunityMemberInfo?: Maybe<CommunityMemberInfo>;
  adminDeleteOneConfiguration?: Maybe<Configuration>;
  adminDeleteOneEvent?: Maybe<Event>;
  adminDeleteOneEventCategory?: Maybe<EventCategory>;
  adminDeleteOneFaqCategory?: Maybe<FaqCategory>;
  adminDeleteOneFaqItem?: Maybe<FaqItem>;
  adminDeleteOneFeedItem?: Maybe<FeedItem>;
  adminDeleteOneFeedItemComment?: Maybe<FeedItemComment>;
  adminDeleteOneFeedItemCommentReport?: Maybe<FeedItemCommentReport>;
  adminDeleteOneForumComment?: Maybe<ForumComment>;
  adminDeleteOneForumContentReport?: Maybe<ForumContentReport>;
  adminDeleteOneForumPost?: Maybe<ForumPost>;
  adminDeleteOneForumTag?: Maybe<ForumTag>;
  adminDeleteOneInviteCode?: Maybe<InviteCode>;
  adminDeleteOneLocation?: Maybe<Location>;
  adminDeleteOneMassMessage?: Maybe<MassMessage>;
  adminDeleteOneModuleConfiguration?: Maybe<ModuleConfiguration>;
  adminDeleteOneOpenCall?: Maybe<OpenCall>;
  adminDeleteOneOpenCallSubmission?: Maybe<OpenCallSubmission>;
  adminDeleteOnePayout?: Maybe<Payout>;
  adminDeleteOneProduct?: Maybe<Product>;
  adminDeleteOnePromocode?: Maybe<Promocode>;
  adminDeleteOnePurchase?: Maybe<Purchase>;
  adminDeleteOnePurchaseTransfer?: Maybe<PurchaseTransfer>;
  adminDeleteOneTalksMessage?: Maybe<TalksMessage>;
  adminDeleteOneTemplateBadge?: Maybe<TemplateBadge>;
  adminDeleteOneTrack?: Maybe<Track>;
  adminDeleteOneUbiqFeedItem?: Maybe<UbiqFeedItem>;
  adminDeleteOneUser?: Maybe<User>;
  adminDeleteOneWaitlistUser?: Maybe<WaitlistUser>;
  adminUpdateManyActivityEvent: AffectedRowsOutput;
  adminUpdateManyCatalogItem: AffectedRowsOutput;
  adminUpdateManyCatalogItemCategory: AffectedRowsOutput;
  adminUpdateManyCatalogItemSchedule: AffectedRowsOutput;
  adminUpdateManyClaimedBadge: AffectedRowsOutput;
  adminUpdateManyCommunity: AffectedRowsOutput;
  adminUpdateManyCommunityMemberInfo: AffectedRowsOutput;
  adminUpdateManyConfiguration: AffectedRowsOutput;
  adminUpdateManyEvent: AffectedRowsOutput;
  adminUpdateManyEventCategory: AffectedRowsOutput;
  adminUpdateManyFaqCategory: AffectedRowsOutput;
  adminUpdateManyFaqItem: AffectedRowsOutput;
  adminUpdateManyFeedItem: AffectedRowsOutput;
  adminUpdateManyFeedItemComment: AffectedRowsOutput;
  adminUpdateManyFeedItemCommentReport: AffectedRowsOutput;
  adminUpdateManyForumComment: AffectedRowsOutput;
  adminUpdateManyForumContentReport: AffectedRowsOutput;
  adminUpdateManyForumPost: AffectedRowsOutput;
  adminUpdateManyForumTag: AffectedRowsOutput;
  adminUpdateManyInviteCode: AffectedRowsOutput;
  adminUpdateManyLocation: AffectedRowsOutput;
  adminUpdateManyMassMessage: AffectedRowsOutput;
  adminUpdateManyModuleConfiguration: AffectedRowsOutput;
  adminUpdateManyOpenCall: AffectedRowsOutput;
  adminUpdateManyOpenCallSubmission: AffectedRowsOutput;
  adminUpdateManyPayout: AffectedRowsOutput;
  adminUpdateManyProduct: AffectedRowsOutput;
  adminUpdateManyPromocode: AffectedRowsOutput;
  adminUpdateManyPurchase: AffectedRowsOutput;
  adminUpdateManyPurchaseTransfer: AffectedRowsOutput;
  adminUpdateManyTalksMessage: AffectedRowsOutput;
  adminUpdateManyTemplateBadge: AffectedRowsOutput;
  adminUpdateManyTrack: AffectedRowsOutput;
  adminUpdateManyUbiqFeedItem: AffectedRowsOutput;
  adminUpdateManyUser: AffectedRowsOutput;
  adminUpdateManyWaitlistUser: AffectedRowsOutput;
  adminUpdateOneActivityEvent?: Maybe<ActivityEvent>;
  adminUpdateOneCatalogItem?: Maybe<CatalogItem>;
  adminUpdateOneCatalogItemCategory?: Maybe<CatalogItemCategory>;
  adminUpdateOneCatalogItemSchedule?: Maybe<CatalogItemSchedule>;
  adminUpdateOneClaimedBadge?: Maybe<ClaimedBadge>;
  adminUpdateOneCommunity?: Maybe<Community>;
  adminUpdateOneCommunityMemberInfo?: Maybe<CommunityMemberInfo>;
  adminUpdateOneConfiguration?: Maybe<Configuration>;
  adminUpdateOneEvent?: Maybe<Event>;
  adminUpdateOneEventCategory?: Maybe<EventCategory>;
  adminUpdateOneFaqCategory?: Maybe<FaqCategory>;
  adminUpdateOneFaqItem?: Maybe<FaqItem>;
  adminUpdateOneFeedItem?: Maybe<FeedItem>;
  adminUpdateOneFeedItemComment?: Maybe<FeedItemComment>;
  adminUpdateOneFeedItemCommentReport?: Maybe<FeedItemCommentReport>;
  adminUpdateOneForumComment?: Maybe<ForumComment>;
  adminUpdateOneForumContentReport?: Maybe<ForumContentReport>;
  adminUpdateOneForumPost?: Maybe<ForumPost>;
  adminUpdateOneForumTag?: Maybe<ForumTag>;
  adminUpdateOneInviteCode?: Maybe<InviteCode>;
  adminUpdateOneLocation?: Maybe<Location>;
  adminUpdateOneMassMessage?: Maybe<MassMessage>;
  adminUpdateOneModuleConfiguration?: Maybe<ModuleConfiguration>;
  adminUpdateOneOpenCall?: Maybe<OpenCall>;
  adminUpdateOneOpenCallSubmission?: Maybe<OpenCallSubmission>;
  adminUpdateOnePayout?: Maybe<Payout>;
  adminUpdateOneProduct?: Maybe<Product>;
  adminUpdateOnePromocode?: Maybe<Promocode>;
  adminUpdateOnePurchase?: Maybe<Purchase>;
  adminUpdateOnePurchaseTransfer?: Maybe<PurchaseTransfer>;
  adminUpdateOneTalksMessage?: Maybe<TalksMessage>;
  adminUpdateOneTemplateBadge?: Maybe<TemplateBadge>;
  adminUpdateOneTrack?: Maybe<Track>;
  adminUpdateOneUbiqFeedItem?: Maybe<UbiqFeedItem>;
  adminUpdateOneUser?: Maybe<User>;
  adminUpdateOneWaitlistUser?: Maybe<WaitlistUser>;
  adminUpsertOneActivityEvent: ActivityEvent;
  adminUpsertOneCatalogItem: CatalogItem;
  adminUpsertOneCatalogItemCategory: CatalogItemCategory;
  adminUpsertOneCatalogItemSchedule: CatalogItemSchedule;
  adminUpsertOneClaimedBadge: ClaimedBadge;
  adminUpsertOneCommunity: Community;
  adminUpsertOneCommunityMemberInfo: CommunityMemberInfo;
  adminUpsertOneConfiguration: Configuration;
  adminUpsertOneEvent: Event;
  adminUpsertOneEventCategory: EventCategory;
  adminUpsertOneFaqCategory: FaqCategory;
  adminUpsertOneFaqItem: FaqItem;
  adminUpsertOneFeedItem: FeedItem;
  adminUpsertOneFeedItemComment: FeedItemComment;
  adminUpsertOneFeedItemCommentReport: FeedItemCommentReport;
  adminUpsertOneForumComment: ForumComment;
  adminUpsertOneForumContentReport: ForumContentReport;
  adminUpsertOneForumPost: ForumPost;
  adminUpsertOneForumTag: ForumTag;
  adminUpsertOneInviteCode: InviteCode;
  adminUpsertOneLocation: Location;
  adminUpsertOneMassMessage: MassMessage;
  adminUpsertOneModuleConfiguration: ModuleConfiguration;
  adminUpsertOneOpenCall: OpenCall;
  adminUpsertOneOpenCallSubmission: OpenCallSubmission;
  adminUpsertOnePayout: Payout;
  adminUpsertOneProduct: Product;
  adminUpsertOnePromocode: Promocode;
  adminUpsertOnePurchase: Purchase;
  adminUpsertOnePurchaseTransfer: PurchaseTransfer;
  adminUpsertOneTalksMessage: TalksMessage;
  adminUpsertOneTemplateBadge: TemplateBadge;
  adminUpsertOneTrack: Track;
  adminUpsertOneUbiqFeedItem: UbiqFeedItem;
  adminUpsertOneUser: User;
  adminUpsertOneWaitlistUser: WaitlistUser;
  attendeeCheckIn?: Maybe<Event>;
  authV2?: Maybe<AuthV2Response>;
  caAddAdmin?: Maybe<Scalars['Boolean']>;
  caAnnouncementCreateOrUpdate?: Maybe<FeedItem>;
  caCommunityThemeCreateOrUpdate?: Maybe<CommunityTheme>;
  caCommunityThemeDuplicate?: Maybe<CommunityTheme>;
  caCommunityThemeToggleStatus?: Maybe<CommunityTheme>;
  caCreateNewEvent?: Maybe<Event>;
  caCreatePayoutRequest?: Maybe<Payout>;
  caDuplicateAnnouncement?: Maybe<FeedItem>;
  caDuplicateEvent?: Maybe<Event>;
  caDuplicateMassMessage?: Maybe<MassMessage>;
  caDuplicateProduct?: Maybe<Product>;
  caDuplicateScheduleItem?: Maybe<CatalogItemSchedule>;
  caEventCreateOrUpdate?: Maybe<Event>;
  caEventInviteMembers?: Maybe<Scalars['Boolean']>;
  caEventToggleStatus?: Maybe<Event>;
  caLinkStripeAccount?: Maybe<CaLinkStripeAccountPayload>;
  caLogin?: Maybe<CaLoginResponse>;
  caMassMessageUpdate?: Maybe<MassMessage>;
  caModifyProduct?: Maybe<Product>;
  caProcessForumContentReport?: Maybe<Scalars['Boolean']>;
  caProcessForumPost?: Maybe<Scalars['Boolean']>;
  caProductCreateOrUpdate?: Maybe<Product>;
  caPromocodeCreateOrUpdate?: Maybe<Promocode>;
  caPromocodeDuplicate?: Maybe<Promocode>;
  caPromocodeToggleStatus?: Maybe<Promocode>;
  caRadioTrackCreateOrUpdate?: Maybe<Track>;
  caRadioTrackDuplicate?: Maybe<Track>;
  caReadTalksMessages?: Maybe<Scalars['Boolean']>;
  caRemoveAdmin?: Maybe<Scalars['Boolean']>;
  caScheduleItemCreateOrUpdate?: Maybe<CatalogItemSchedule>;
  caSendMessage?: Maybe<TalksMessage>;
  caToggleAnnouncementStatus?: Maybe<FeedItem>;
  caToggleMassMessageStatus?: Maybe<MassMessage>;
  caToggleProductStatus?: Maybe<Product>;
  caToggleRadioTrackStatus?: Maybe<Track>;
  caToggleScheduleItemStatus?: Maybe<CatalogItemSchedule>;
  caUnlinkStripeAccount?: Maybe<CaUnlinkStripeAccountPayload>;
  calculateDiscountAndFees?: Maybe<DiscountAndFees>;
  calendarHideEvent?: Maybe<Scalars['Boolean']>;
  calendarSeenEvents?: Maybe<Scalars['Boolean']>;
  cancelPurchases?: Maybe<Scalars['Boolean']>;
  commentFeedItem?: Maybe<FeedItemComment>;
  commentForumPost?: Maybe<ForumComment>;
  createMedia?: Maybe<CreateMediaResponse>;
  createOneWaitlistUser: WaitlistUser;
  deleteFeedItemComment?: Maybe<Scalars['Boolean']>;
  deleteForumContent?: Maybe<Scalars['Boolean']>;
  eventRegister?: Maybe<Event>;
  getInviteCodeByReservedEmail?: Maybe<Scalars['String']>;
  joinCommunity?: Maybe<CommunityPayload>;
  joinCommunityWithInfoCheck?: Maybe<JoinCommunityReturnType>;
  login?: Maybe<AuthPayload>;
  postCreateUserUpdate?: Maybe<User>;
  reactFeedItem?: Maybe<Scalars['Boolean']>;
  reactFeedItemComment?: Maybe<Scalars['Boolean']>;
  reactForumComment?: Maybe<Scalars['Boolean']>;
  reactForumPost?: Maybe<Scalars['Boolean']>;
  readMessage?: Maybe<ReadMessageResponse>;
  readTalksMessages?: Maybe<Scalars['Boolean']>;
  reportFeedItemComment?: Maybe<FeedItemCommentReport>;
  reportForumContent?: Maybe<ForumContentReport>;
  reprocessPurchases?: Maybe<Scalars['Boolean']>;
  requestAccessFeedItem?: Maybe<Scalars['Boolean']>;
  requestAccountDeletion?: Maybe<Scalars['Boolean']>;
  requestEmailVerification?: Maybe<Scalars['Boolean']>;
  requestHideFeedItem?: Maybe<Scalars['Boolean']>;
  requestPhoneVerification?: Maybe<Scalars['Boolean']>;
  requestProductsPurchase?: Maybe<RequestProductsPurchase>;
  resetPassword?: Maybe<AuthPayload>;
  resetPasswordRequest?: Maybe<PasswordResetRequestPayload>;
  rollbackEmail?: Maybe<Scalars['Boolean']>;
  sendCommunityInvite?: Maybe<InventorySendInvite>;
  sendInviteCode?: Maybe<Scalars['Boolean']>;
  sendMessage?: Maybe<SendMessageResponse>;
  sendTalksMessage?: Maybe<TalksMessage>;
  sendTicket?: Maybe<Scalars['Boolean']>;
  signUp?: Maybe<AuthPayload>;
  submitForumPost?: Maybe<ForumPost>;
  submitOpenCall?: Maybe<OpenCallSubmission>;
  submitSpottedPost?: Maybe<FeedItem>;
  syncPushPlayerIds?: Maybe<SyncPushPlayerIdsResponse>;
  undoCheckIn?: Maybe<Scalars['Boolean']>;
  updateOneUser?: Maybe<User>;
  useTokens?: Maybe<Scalars['Boolean']>;
  userUpdate?: Maybe<User>;
  userUpdateV2?: Maybe<User>;
  validateInviteCode?: Maybe<ValidateInviteCodePayload>;
  validatePasswordResetCode?: Maybe<Scalars['Boolean']>;
  validatePromocode?: Maybe<Promocode>;
  validateUsername?: Maybe<Scalars['Boolean']>;
  verifyEmail?: Maybe<Scalars['Boolean']>;
  verifySwishPayment?: Maybe<Scalars['Boolean']>;
  verifyUser?: Maybe<AuthPayload>;
};


export type MutationAddToWaitlistArgs = {
  email: Scalars['String'];
  source?: InputMaybe<WaitlistSource>;
};


export type MutationAdminAddGuestsArgs = {
  note?: InputMaybe<Scalars['String']>;
  productId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};


export type MutationAdminCreateOneActivityEventArgs = {
  data: ActivityEventCreateInput;
};


export type MutationAdminCreateOneCatalogItemArgs = {
  data: CatalogItemCreateInput;
};


export type MutationAdminCreateOneCatalogItemCategoryArgs = {
  data: CatalogItemCategoryCreateInput;
};


export type MutationAdminCreateOneCatalogItemScheduleArgs = {
  data: CatalogItemScheduleCreateInput;
};


export type MutationAdminCreateOneClaimedBadgeArgs = {
  data: ClaimedBadgeCreateInput;
};


export type MutationAdminCreateOneCommunityArgs = {
  data: CommunityCreateInput;
};


export type MutationAdminCreateOneCommunityMemberInfoArgs = {
  data: CommunityMemberInfoCreateInput;
};


export type MutationAdminCreateOneConfigurationArgs = {
  data: ConfigurationCreateInput;
};


export type MutationAdminCreateOneEventArgs = {
  data: EventCreateInput;
};


export type MutationAdminCreateOneEventCategoryArgs = {
  data: EventCategoryCreateInput;
};


export type MutationAdminCreateOneFaqCategoryArgs = {
  data: FaqCategoryCreateInput;
};


export type MutationAdminCreateOneFaqItemArgs = {
  data: FaqItemCreateInput;
};


export type MutationAdminCreateOneFeedItemArgs = {
  data: FeedItemCreateInput;
};


export type MutationAdminCreateOneFeedItemCommentArgs = {
  data: FeedItemCommentCreateInput;
};


export type MutationAdminCreateOneFeedItemCommentReportArgs = {
  data: FeedItemCommentReportCreateInput;
};


export type MutationAdminCreateOneForumCommentArgs = {
  data: ForumCommentCreateInput;
};


export type MutationAdminCreateOneForumContentReportArgs = {
  data: ForumContentReportCreateInput;
};


export type MutationAdminCreateOneForumPostArgs = {
  data: ForumPostCreateInput;
};


export type MutationAdminCreateOneForumTagArgs = {
  data: ForumTagCreateInput;
};


export type MutationAdminCreateOneInviteCodeArgs = {
  data: InviteCodeCreateInput;
};


export type MutationAdminCreateOneLocationArgs = {
  data: LocationCreateInput;
};


export type MutationAdminCreateOneMassMessageArgs = {
  data: MassMessageCreateInput;
};


export type MutationAdminCreateOneModuleConfigurationArgs = {
  data: ModuleConfigurationCreateInput;
};


export type MutationAdminCreateOneOpenCallArgs = {
  data: OpenCallCreateInput;
};


export type MutationAdminCreateOneOpenCallSubmissionArgs = {
  data: OpenCallSubmissionCreateInput;
};


export type MutationAdminCreateOnePayoutArgs = {
  data: PayoutCreateInput;
};


export type MutationAdminCreateOneProductArgs = {
  data: ProductCreateInput;
};


export type MutationAdminCreateOnePromocodeArgs = {
  data: PromocodeCreateInput;
};


export type MutationAdminCreateOnePurchaseArgs = {
  data: PurchaseCreateInput;
};


export type MutationAdminCreateOnePurchaseTransferArgs = {
  data: PurchaseTransferCreateInput;
};


export type MutationAdminCreateOneTalksMessageArgs = {
  data: TalksMessageCreateInput;
};


export type MutationAdminCreateOneTemplateBadgeArgs = {
  data: TemplateBadgeCreateInput;
};


export type MutationAdminCreateOneTrackArgs = {
  data: TrackCreateInput;
};


export type MutationAdminCreateOneUbiqFeedItemArgs = {
  data: UbiqFeedItemCreateInput;
};


export type MutationAdminCreateOneUserArgs = {
  data: UserCreateInput;
};


export type MutationAdminCreateOneWaitlistUserArgs = {
  data: WaitlistUserCreateInput;
};


export type MutationAdminDeleteManyActivityEventArgs = {
  where?: InputMaybe<ActivityEventWhereInput>;
};


export type MutationAdminDeleteManyCatalogItemArgs = {
  where?: InputMaybe<CatalogItemWhereInput>;
};


export type MutationAdminDeleteManyCatalogItemCategoryArgs = {
  where?: InputMaybe<CatalogItemCategoryWhereInput>;
};


export type MutationAdminDeleteManyCatalogItemScheduleArgs = {
  where?: InputMaybe<CatalogItemScheduleWhereInput>;
};


export type MutationAdminDeleteManyClaimedBadgeArgs = {
  where?: InputMaybe<ClaimedBadgeWhereInput>;
};


export type MutationAdminDeleteManyCommunityArgs = {
  where?: InputMaybe<CommunityWhereInput>;
};


export type MutationAdminDeleteManyCommunityMemberInfoArgs = {
  where?: InputMaybe<CommunityMemberInfoWhereInput>;
};


export type MutationAdminDeleteManyConfigurationArgs = {
  where?: InputMaybe<ConfigurationWhereInput>;
};


export type MutationAdminDeleteManyEventArgs = {
  where?: InputMaybe<EventWhereInput>;
};


export type MutationAdminDeleteManyEventCategoryArgs = {
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type MutationAdminDeleteManyFaqCategoryArgs = {
  where?: InputMaybe<FaqCategoryWhereInput>;
};


export type MutationAdminDeleteManyFaqItemArgs = {
  where?: InputMaybe<FaqItemWhereInput>;
};


export type MutationAdminDeleteManyFeedItemArgs = {
  where?: InputMaybe<FeedItemWhereInput>;
};


export type MutationAdminDeleteManyFeedItemCommentArgs = {
  where?: InputMaybe<FeedItemCommentWhereInput>;
};


export type MutationAdminDeleteManyFeedItemCommentReportArgs = {
  where?: InputMaybe<FeedItemCommentReportWhereInput>;
};


export type MutationAdminDeleteManyForumCommentArgs = {
  where?: InputMaybe<ForumCommentWhereInput>;
};


export type MutationAdminDeleteManyForumContentReportArgs = {
  where?: InputMaybe<ForumContentReportWhereInput>;
};


export type MutationAdminDeleteManyForumPostArgs = {
  where?: InputMaybe<ForumPostWhereInput>;
};


export type MutationAdminDeleteManyForumTagArgs = {
  where?: InputMaybe<ForumTagWhereInput>;
};


export type MutationAdminDeleteManyInviteCodeArgs = {
  where?: InputMaybe<InviteCodeWhereInput>;
};


export type MutationAdminDeleteManyLocationArgs = {
  where?: InputMaybe<LocationWhereInput>;
};


export type MutationAdminDeleteManyMassMessageArgs = {
  where?: InputMaybe<MassMessageWhereInput>;
};


export type MutationAdminDeleteManyModuleConfigurationArgs = {
  where?: InputMaybe<ModuleConfigurationWhereInput>;
};


export type MutationAdminDeleteManyOpenCallArgs = {
  where?: InputMaybe<OpenCallWhereInput>;
};


export type MutationAdminDeleteManyOpenCallSubmissionArgs = {
  where?: InputMaybe<OpenCallSubmissionWhereInput>;
};


export type MutationAdminDeleteManyPayoutArgs = {
  where?: InputMaybe<PayoutWhereInput>;
};


export type MutationAdminDeleteManyProductArgs = {
  where?: InputMaybe<ProductWhereInput>;
};


export type MutationAdminDeleteManyPromocodeArgs = {
  where?: InputMaybe<PromocodeWhereInput>;
};


export type MutationAdminDeleteManyPurchaseArgs = {
  where?: InputMaybe<PurchaseWhereInput>;
};


export type MutationAdminDeleteManyPurchaseTransferArgs = {
  where?: InputMaybe<PurchaseTransferWhereInput>;
};


export type MutationAdminDeleteManyTalksMessageArgs = {
  where?: InputMaybe<TalksMessageWhereInput>;
};


export type MutationAdminDeleteManyTemplateBadgeArgs = {
  where?: InputMaybe<TemplateBadgeWhereInput>;
};


export type MutationAdminDeleteManyTrackArgs = {
  where?: InputMaybe<TrackWhereInput>;
};


export type MutationAdminDeleteManyUbiqFeedItemArgs = {
  where?: InputMaybe<UbiqFeedItemWhereInput>;
};


export type MutationAdminDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationAdminDeleteManyWaitlistUserArgs = {
  where?: InputMaybe<WaitlistUserWhereInput>;
};


export type MutationAdminDeleteOneActivityEventArgs = {
  where: ActivityEventWhereUniqueInput;
};


export type MutationAdminDeleteOneCatalogItemArgs = {
  where: CatalogItemWhereUniqueInput;
};


export type MutationAdminDeleteOneCatalogItemCategoryArgs = {
  where: CatalogItemCategoryWhereUniqueInput;
};


export type MutationAdminDeleteOneCatalogItemScheduleArgs = {
  where: CatalogItemScheduleWhereUniqueInput;
};


export type MutationAdminDeleteOneClaimedBadgeArgs = {
  where: ClaimedBadgeWhereUniqueInput;
};


export type MutationAdminDeleteOneCommunityArgs = {
  where: CommunityWhereUniqueInput;
};


export type MutationAdminDeleteOneCommunityMemberInfoArgs = {
  where: CommunityMemberInfoWhereUniqueInput;
};


export type MutationAdminDeleteOneConfigurationArgs = {
  where: ConfigurationWhereUniqueInput;
};


export type MutationAdminDeleteOneEventArgs = {
  where: EventWhereUniqueInput;
};


export type MutationAdminDeleteOneEventCategoryArgs = {
  where: EventCategoryWhereUniqueInput;
};


export type MutationAdminDeleteOneFaqCategoryArgs = {
  where: FaqCategoryWhereUniqueInput;
};


export type MutationAdminDeleteOneFaqItemArgs = {
  where: FaqItemWhereUniqueInput;
};


export type MutationAdminDeleteOneFeedItemArgs = {
  where: FeedItemWhereUniqueInput;
};


export type MutationAdminDeleteOneFeedItemCommentArgs = {
  where: FeedItemCommentWhereUniqueInput;
};


export type MutationAdminDeleteOneFeedItemCommentReportArgs = {
  where: FeedItemCommentReportWhereUniqueInput;
};


export type MutationAdminDeleteOneForumCommentArgs = {
  where: ForumCommentWhereUniqueInput;
};


export type MutationAdminDeleteOneForumContentReportArgs = {
  where: ForumContentReportWhereUniqueInput;
};


export type MutationAdminDeleteOneForumPostArgs = {
  where: ForumPostWhereUniqueInput;
};


export type MutationAdminDeleteOneForumTagArgs = {
  where: ForumTagWhereUniqueInput;
};


export type MutationAdminDeleteOneInviteCodeArgs = {
  where: InviteCodeWhereUniqueInput;
};


export type MutationAdminDeleteOneLocationArgs = {
  where: LocationWhereUniqueInput;
};


export type MutationAdminDeleteOneMassMessageArgs = {
  where: MassMessageWhereUniqueInput;
};


export type MutationAdminDeleteOneModuleConfigurationArgs = {
  where: ModuleConfigurationWhereUniqueInput;
};


export type MutationAdminDeleteOneOpenCallArgs = {
  where: OpenCallWhereUniqueInput;
};


export type MutationAdminDeleteOneOpenCallSubmissionArgs = {
  where: OpenCallSubmissionWhereUniqueInput;
};


export type MutationAdminDeleteOnePayoutArgs = {
  where: PayoutWhereUniqueInput;
};


export type MutationAdminDeleteOneProductArgs = {
  where: ProductWhereUniqueInput;
};


export type MutationAdminDeleteOnePromocodeArgs = {
  where: PromocodeWhereUniqueInput;
};


export type MutationAdminDeleteOnePurchaseArgs = {
  where: PurchaseWhereUniqueInput;
};


export type MutationAdminDeleteOnePurchaseTransferArgs = {
  where: PurchaseTransferWhereUniqueInput;
};


export type MutationAdminDeleteOneTalksMessageArgs = {
  where: TalksMessageWhereUniqueInput;
};


export type MutationAdminDeleteOneTemplateBadgeArgs = {
  where: TemplateBadgeWhereUniqueInput;
};


export type MutationAdminDeleteOneTrackArgs = {
  where: TrackWhereUniqueInput;
};


export type MutationAdminDeleteOneUbiqFeedItemArgs = {
  where: UbiqFeedItemWhereUniqueInput;
};


export type MutationAdminDeleteOneUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationAdminDeleteOneWaitlistUserArgs = {
  where: WaitlistUserWhereUniqueInput;
};


export type MutationAdminUpdateManyActivityEventArgs = {
  data: ActivityEventUpdateManyMutationInput;
  where?: InputMaybe<ActivityEventWhereInput>;
};


export type MutationAdminUpdateManyCatalogItemArgs = {
  data: CatalogItemUpdateManyMutationInput;
  where?: InputMaybe<CatalogItemWhereInput>;
};


export type MutationAdminUpdateManyCatalogItemCategoryArgs = {
  data: CatalogItemCategoryUpdateManyMutationInput;
  where?: InputMaybe<CatalogItemCategoryWhereInput>;
};


export type MutationAdminUpdateManyCatalogItemScheduleArgs = {
  data: CatalogItemScheduleUpdateManyMutationInput;
  where?: InputMaybe<CatalogItemScheduleWhereInput>;
};


export type MutationAdminUpdateManyClaimedBadgeArgs = {
  data: ClaimedBadgeUpdateManyMutationInput;
  where?: InputMaybe<ClaimedBadgeWhereInput>;
};


export type MutationAdminUpdateManyCommunityArgs = {
  data: CommunityUpdateManyMutationInput;
  where?: InputMaybe<CommunityWhereInput>;
};


export type MutationAdminUpdateManyCommunityMemberInfoArgs = {
  data: CommunityMemberInfoUpdateManyMutationInput;
  where?: InputMaybe<CommunityMemberInfoWhereInput>;
};


export type MutationAdminUpdateManyConfigurationArgs = {
  data: ConfigurationUpdateManyMutationInput;
  where?: InputMaybe<ConfigurationWhereInput>;
};


export type MutationAdminUpdateManyEventArgs = {
  data: EventUpdateManyMutationInput;
  where?: InputMaybe<EventWhereInput>;
};


export type MutationAdminUpdateManyEventCategoryArgs = {
  data: EventCategoryUpdateManyMutationInput;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type MutationAdminUpdateManyFaqCategoryArgs = {
  data: FaqCategoryUpdateManyMutationInput;
  where?: InputMaybe<FaqCategoryWhereInput>;
};


export type MutationAdminUpdateManyFaqItemArgs = {
  data: FaqItemUpdateManyMutationInput;
  where?: InputMaybe<FaqItemWhereInput>;
};


export type MutationAdminUpdateManyFeedItemArgs = {
  data: FeedItemUpdateManyMutationInput;
  where?: InputMaybe<FeedItemWhereInput>;
};


export type MutationAdminUpdateManyFeedItemCommentArgs = {
  data: FeedItemCommentUpdateManyMutationInput;
  where?: InputMaybe<FeedItemCommentWhereInput>;
};


export type MutationAdminUpdateManyFeedItemCommentReportArgs = {
  data: FeedItemCommentReportUpdateManyMutationInput;
  where?: InputMaybe<FeedItemCommentReportWhereInput>;
};


export type MutationAdminUpdateManyForumCommentArgs = {
  data: ForumCommentUpdateManyMutationInput;
  where?: InputMaybe<ForumCommentWhereInput>;
};


export type MutationAdminUpdateManyForumContentReportArgs = {
  data: ForumContentReportUpdateManyMutationInput;
  where?: InputMaybe<ForumContentReportWhereInput>;
};


export type MutationAdminUpdateManyForumPostArgs = {
  data: ForumPostUpdateManyMutationInput;
  where?: InputMaybe<ForumPostWhereInput>;
};


export type MutationAdminUpdateManyForumTagArgs = {
  data: ForumTagUpdateManyMutationInput;
  where?: InputMaybe<ForumTagWhereInput>;
};


export type MutationAdminUpdateManyInviteCodeArgs = {
  data: InviteCodeUpdateManyMutationInput;
  where?: InputMaybe<InviteCodeWhereInput>;
};


export type MutationAdminUpdateManyLocationArgs = {
  data: LocationUpdateManyMutationInput;
  where?: InputMaybe<LocationWhereInput>;
};


export type MutationAdminUpdateManyMassMessageArgs = {
  data: MassMessageUpdateManyMutationInput;
  where?: InputMaybe<MassMessageWhereInput>;
};


export type MutationAdminUpdateManyModuleConfigurationArgs = {
  data: ModuleConfigurationUpdateManyMutationInput;
  where?: InputMaybe<ModuleConfigurationWhereInput>;
};


export type MutationAdminUpdateManyOpenCallArgs = {
  data: OpenCallUpdateManyMutationInput;
  where?: InputMaybe<OpenCallWhereInput>;
};


export type MutationAdminUpdateManyOpenCallSubmissionArgs = {
  data: OpenCallSubmissionUpdateManyMutationInput;
  where?: InputMaybe<OpenCallSubmissionWhereInput>;
};


export type MutationAdminUpdateManyPayoutArgs = {
  data: PayoutUpdateManyMutationInput;
  where?: InputMaybe<PayoutWhereInput>;
};


export type MutationAdminUpdateManyProductArgs = {
  data: ProductUpdateManyMutationInput;
  where?: InputMaybe<ProductWhereInput>;
};


export type MutationAdminUpdateManyPromocodeArgs = {
  data: PromocodeUpdateManyMutationInput;
  where?: InputMaybe<PromocodeWhereInput>;
};


export type MutationAdminUpdateManyPurchaseArgs = {
  data: PurchaseUpdateManyMutationInput;
  where?: InputMaybe<PurchaseWhereInput>;
};


export type MutationAdminUpdateManyPurchaseTransferArgs = {
  data: PurchaseTransferUpdateManyMutationInput;
  where?: InputMaybe<PurchaseTransferWhereInput>;
};


export type MutationAdminUpdateManyTalksMessageArgs = {
  data: TalksMessageUpdateManyMutationInput;
  where?: InputMaybe<TalksMessageWhereInput>;
};


export type MutationAdminUpdateManyTemplateBadgeArgs = {
  data: TemplateBadgeUpdateManyMutationInput;
  where?: InputMaybe<TemplateBadgeWhereInput>;
};


export type MutationAdminUpdateManyTrackArgs = {
  data: TrackUpdateManyMutationInput;
  where?: InputMaybe<TrackWhereInput>;
};


export type MutationAdminUpdateManyUbiqFeedItemArgs = {
  data: UbiqFeedItemUpdateManyMutationInput;
  where?: InputMaybe<UbiqFeedItemWhereInput>;
};


export type MutationAdminUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationAdminUpdateManyWaitlistUserArgs = {
  data: WaitlistUserUpdateManyMutationInput;
  where?: InputMaybe<WaitlistUserWhereInput>;
};


export type MutationAdminUpdateOneActivityEventArgs = {
  data: ActivityEventUpdateInput;
  where: ActivityEventWhereUniqueInput;
};


export type MutationAdminUpdateOneCatalogItemArgs = {
  data: CatalogItemUpdateInput;
  where: CatalogItemWhereUniqueInput;
};


export type MutationAdminUpdateOneCatalogItemCategoryArgs = {
  data: CatalogItemCategoryUpdateInput;
  where: CatalogItemCategoryWhereUniqueInput;
};


export type MutationAdminUpdateOneCatalogItemScheduleArgs = {
  data: CatalogItemScheduleUpdateInput;
  where: CatalogItemScheduleWhereUniqueInput;
};


export type MutationAdminUpdateOneClaimedBadgeArgs = {
  data: ClaimedBadgeUpdateInput;
  where: ClaimedBadgeWhereUniqueInput;
};


export type MutationAdminUpdateOneCommunityArgs = {
  data: CommunityUpdateInput;
  where: CommunityWhereUniqueInput;
};


export type MutationAdminUpdateOneCommunityMemberInfoArgs = {
  data: CommunityMemberInfoUpdateInput;
  where: CommunityMemberInfoWhereUniqueInput;
};


export type MutationAdminUpdateOneConfigurationArgs = {
  data: ConfigurationUpdateInput;
  where: ConfigurationWhereUniqueInput;
};


export type MutationAdminUpdateOneEventArgs = {
  data: EventUpdateInput;
  where: EventWhereUniqueInput;
};


export type MutationAdminUpdateOneEventCategoryArgs = {
  data: EventCategoryUpdateInput;
  where: EventCategoryWhereUniqueInput;
};


export type MutationAdminUpdateOneFaqCategoryArgs = {
  data: FaqCategoryUpdateInput;
  where: FaqCategoryWhereUniqueInput;
};


export type MutationAdminUpdateOneFaqItemArgs = {
  data: FaqItemUpdateInput;
  where: FaqItemWhereUniqueInput;
};


export type MutationAdminUpdateOneFeedItemArgs = {
  data: FeedItemUpdateInput;
  where: FeedItemWhereUniqueInput;
};


export type MutationAdminUpdateOneFeedItemCommentArgs = {
  data: FeedItemCommentUpdateInput;
  where: FeedItemCommentWhereUniqueInput;
};


export type MutationAdminUpdateOneFeedItemCommentReportArgs = {
  data: FeedItemCommentReportUpdateInput;
  where: FeedItemCommentReportWhereUniqueInput;
};


export type MutationAdminUpdateOneForumCommentArgs = {
  data: ForumCommentUpdateInput;
  where: ForumCommentWhereUniqueInput;
};


export type MutationAdminUpdateOneForumContentReportArgs = {
  data: ForumContentReportUpdateInput;
  where: ForumContentReportWhereUniqueInput;
};


export type MutationAdminUpdateOneForumPostArgs = {
  data: ForumPostUpdateInput;
  where: ForumPostWhereUniqueInput;
};


export type MutationAdminUpdateOneForumTagArgs = {
  data: ForumTagUpdateInput;
  where: ForumTagWhereUniqueInput;
};


export type MutationAdminUpdateOneInviteCodeArgs = {
  data: InviteCodeUpdateInput;
  where: InviteCodeWhereUniqueInput;
};


export type MutationAdminUpdateOneLocationArgs = {
  data: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};


export type MutationAdminUpdateOneMassMessageArgs = {
  data: MassMessageUpdateInput;
  where: MassMessageWhereUniqueInput;
};


export type MutationAdminUpdateOneModuleConfigurationArgs = {
  data: ModuleConfigurationUpdateInput;
  where: ModuleConfigurationWhereUniqueInput;
};


export type MutationAdminUpdateOneOpenCallArgs = {
  data: OpenCallUpdateInput;
  where: OpenCallWhereUniqueInput;
};


export type MutationAdminUpdateOneOpenCallSubmissionArgs = {
  data: OpenCallSubmissionUpdateInput;
  where: OpenCallSubmissionWhereUniqueInput;
};


export type MutationAdminUpdateOnePayoutArgs = {
  data: PayoutUpdateInput;
  where: PayoutWhereUniqueInput;
};


export type MutationAdminUpdateOneProductArgs = {
  data: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationAdminUpdateOnePromocodeArgs = {
  data: PromocodeUpdateInput;
  where: PromocodeWhereUniqueInput;
};


export type MutationAdminUpdateOnePurchaseArgs = {
  data: PurchaseUpdateInput;
  where: PurchaseWhereUniqueInput;
};


export type MutationAdminUpdateOnePurchaseTransferArgs = {
  data: PurchaseTransferUpdateInput;
  where: PurchaseTransferWhereUniqueInput;
};


export type MutationAdminUpdateOneTalksMessageArgs = {
  data: TalksMessageUpdateInput;
  where: TalksMessageWhereUniqueInput;
};


export type MutationAdminUpdateOneTemplateBadgeArgs = {
  data: TemplateBadgeUpdateInput;
  where: TemplateBadgeWhereUniqueInput;
};


export type MutationAdminUpdateOneTrackArgs = {
  data: TrackUpdateInput;
  where: TrackWhereUniqueInput;
};


export type MutationAdminUpdateOneUbiqFeedItemArgs = {
  data: UbiqFeedItemUpdateInput;
  where: UbiqFeedItemWhereUniqueInput;
};


export type MutationAdminUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationAdminUpdateOneWaitlistUserArgs = {
  data: WaitlistUserUpdateInput;
  where: WaitlistUserWhereUniqueInput;
};


export type MutationAdminUpsertOneActivityEventArgs = {
  create: ActivityEventCreateInput;
  update: ActivityEventUpdateInput;
  where: ActivityEventWhereUniqueInput;
};


export type MutationAdminUpsertOneCatalogItemArgs = {
  create: CatalogItemCreateInput;
  update: CatalogItemUpdateInput;
  where: CatalogItemWhereUniqueInput;
};


export type MutationAdminUpsertOneCatalogItemCategoryArgs = {
  create: CatalogItemCategoryCreateInput;
  update: CatalogItemCategoryUpdateInput;
  where: CatalogItemCategoryWhereUniqueInput;
};


export type MutationAdminUpsertOneCatalogItemScheduleArgs = {
  create: CatalogItemScheduleCreateInput;
  update: CatalogItemScheduleUpdateInput;
  where: CatalogItemScheduleWhereUniqueInput;
};


export type MutationAdminUpsertOneClaimedBadgeArgs = {
  create: ClaimedBadgeCreateInput;
  update: ClaimedBadgeUpdateInput;
  where: ClaimedBadgeWhereUniqueInput;
};


export type MutationAdminUpsertOneCommunityArgs = {
  create: CommunityCreateInput;
  update: CommunityUpdateInput;
  where: CommunityWhereUniqueInput;
};


export type MutationAdminUpsertOneCommunityMemberInfoArgs = {
  create: CommunityMemberInfoCreateInput;
  update: CommunityMemberInfoUpdateInput;
  where: CommunityMemberInfoWhereUniqueInput;
};


export type MutationAdminUpsertOneConfigurationArgs = {
  create: ConfigurationCreateInput;
  update: ConfigurationUpdateInput;
  where: ConfigurationWhereUniqueInput;
};


export type MutationAdminUpsertOneEventArgs = {
  create: EventCreateInput;
  update: EventUpdateInput;
  where: EventWhereUniqueInput;
};


export type MutationAdminUpsertOneEventCategoryArgs = {
  create: EventCategoryCreateInput;
  update: EventCategoryUpdateInput;
  where: EventCategoryWhereUniqueInput;
};


export type MutationAdminUpsertOneFaqCategoryArgs = {
  create: FaqCategoryCreateInput;
  update: FaqCategoryUpdateInput;
  where: FaqCategoryWhereUniqueInput;
};


export type MutationAdminUpsertOneFaqItemArgs = {
  create: FaqItemCreateInput;
  update: FaqItemUpdateInput;
  where: FaqItemWhereUniqueInput;
};


export type MutationAdminUpsertOneFeedItemArgs = {
  create: FeedItemCreateInput;
  update: FeedItemUpdateInput;
  where: FeedItemWhereUniqueInput;
};


export type MutationAdminUpsertOneFeedItemCommentArgs = {
  create: FeedItemCommentCreateInput;
  update: FeedItemCommentUpdateInput;
  where: FeedItemCommentWhereUniqueInput;
};


export type MutationAdminUpsertOneFeedItemCommentReportArgs = {
  create: FeedItemCommentReportCreateInput;
  update: FeedItemCommentReportUpdateInput;
  where: FeedItemCommentReportWhereUniqueInput;
};


export type MutationAdminUpsertOneForumCommentArgs = {
  create: ForumCommentCreateInput;
  update: ForumCommentUpdateInput;
  where: ForumCommentWhereUniqueInput;
};


export type MutationAdminUpsertOneForumContentReportArgs = {
  create: ForumContentReportCreateInput;
  update: ForumContentReportUpdateInput;
  where: ForumContentReportWhereUniqueInput;
};


export type MutationAdminUpsertOneForumPostArgs = {
  create: ForumPostCreateInput;
  update: ForumPostUpdateInput;
  where: ForumPostWhereUniqueInput;
};


export type MutationAdminUpsertOneForumTagArgs = {
  create: ForumTagCreateInput;
  update: ForumTagUpdateInput;
  where: ForumTagWhereUniqueInput;
};


export type MutationAdminUpsertOneInviteCodeArgs = {
  create: InviteCodeCreateInput;
  update: InviteCodeUpdateInput;
  where: InviteCodeWhereUniqueInput;
};


export type MutationAdminUpsertOneLocationArgs = {
  create: LocationCreateInput;
  update: LocationUpdateInput;
  where: LocationWhereUniqueInput;
};


export type MutationAdminUpsertOneMassMessageArgs = {
  create: MassMessageCreateInput;
  update: MassMessageUpdateInput;
  where: MassMessageWhereUniqueInput;
};


export type MutationAdminUpsertOneModuleConfigurationArgs = {
  create: ModuleConfigurationCreateInput;
  update: ModuleConfigurationUpdateInput;
  where: ModuleConfigurationWhereUniqueInput;
};


export type MutationAdminUpsertOneOpenCallArgs = {
  create: OpenCallCreateInput;
  update: OpenCallUpdateInput;
  where: OpenCallWhereUniqueInput;
};


export type MutationAdminUpsertOneOpenCallSubmissionArgs = {
  create: OpenCallSubmissionCreateInput;
  update: OpenCallSubmissionUpdateInput;
  where: OpenCallSubmissionWhereUniqueInput;
};


export type MutationAdminUpsertOnePayoutArgs = {
  create: PayoutCreateInput;
  update: PayoutUpdateInput;
  where: PayoutWhereUniqueInput;
};


export type MutationAdminUpsertOneProductArgs = {
  create: ProductCreateInput;
  update: ProductUpdateInput;
  where: ProductWhereUniqueInput;
};


export type MutationAdminUpsertOnePromocodeArgs = {
  create: PromocodeCreateInput;
  update: PromocodeUpdateInput;
  where: PromocodeWhereUniqueInput;
};


export type MutationAdminUpsertOnePurchaseArgs = {
  create: PurchaseCreateInput;
  update: PurchaseUpdateInput;
  where: PurchaseWhereUniqueInput;
};


export type MutationAdminUpsertOnePurchaseTransferArgs = {
  create: PurchaseTransferCreateInput;
  update: PurchaseTransferUpdateInput;
  where: PurchaseTransferWhereUniqueInput;
};


export type MutationAdminUpsertOneTalksMessageArgs = {
  create: TalksMessageCreateInput;
  update: TalksMessageUpdateInput;
  where: TalksMessageWhereUniqueInput;
};


export type MutationAdminUpsertOneTemplateBadgeArgs = {
  create: TemplateBadgeCreateInput;
  update: TemplateBadgeUpdateInput;
  where: TemplateBadgeWhereUniqueInput;
};


export type MutationAdminUpsertOneTrackArgs = {
  create: TrackCreateInput;
  update: TrackUpdateInput;
  where: TrackWhereUniqueInput;
};


export type MutationAdminUpsertOneUbiqFeedItemArgs = {
  create: UbiqFeedItemCreateInput;
  update: UbiqFeedItemUpdateInput;
  where: UbiqFeedItemWhereUniqueInput;
};


export type MutationAdminUpsertOneUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationAdminUpsertOneWaitlistUserArgs = {
  create: WaitlistUserCreateInput;
  update: WaitlistUserUpdateInput;
  where: WaitlistUserWhereUniqueInput;
};


export type MutationAttendeeCheckInArgs = {
  eventId: Scalars['String'];
  purchaseIds?: InputMaybe<Array<Scalars['String']>>;
  userId: Scalars['String'];
};


export type MutationAuthV2Args = {
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  phone: Scalars['String'];
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
};


export type MutationCaAddAdminArgs = {
  communityId: Scalars['ID'];
  role: AdminRolesType;
  userId: Scalars['ID'];
};


export type MutationCaAnnouncementCreateOrUpdateArgs = {
  announcementId?: InputMaybe<Scalars['ID']>;
  attemptPublish?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  data: CaAnnouncementCreateOrUpdateInput;
};


export type MutationCaCommunityThemeCreateOrUpdateArgs = {
  attemptPublish?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  communityThemeId?: InputMaybe<Scalars['ID']>;
  data: CaCommunityThemeCreateOrUpdateInput;
};


export type MutationCaCommunityThemeDuplicateArgs = {
  communityId: Scalars['ID'];
  communityThemeId: Scalars['ID'];
};


export type MutationCaCommunityThemeToggleStatusArgs = {
  communityId: Scalars['ID'];
  communityThemeId: Scalars['ID'];
  status: CommunityThemeStatus;
};


export type MutationCaCreateNewEventArgs = {
  communityId: Scalars['ID'];
};


export type MutationCaCreatePayoutRequestArgs = {
  account?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
  communityId: Scalars['ID'];
};


export type MutationCaDuplicateAnnouncementArgs = {
  id: Scalars['ID'];
};


export type MutationCaDuplicateEventArgs = {
  communityId: Scalars['ID'];
  eventId: Scalars['ID'];
};


export type MutationCaDuplicateMassMessageArgs = {
  massMessageId: Scalars['String'];
};


export type MutationCaDuplicateProductArgs = {
  id: Scalars['ID'];
};


export type MutationCaDuplicateScheduleItemArgs = {
  id: Scalars['ID'];
};


export type MutationCaEventCreateOrUpdateArgs = {
  attemptPublish?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  data: CaEventCreateOrUpdateInput;
  eventId?: InputMaybe<Scalars['ID']>;
};


export type MutationCaEventInviteMembersArgs = {
  eventId: Scalars['String'];
};


export type MutationCaEventToggleStatusArgs = {
  communityId: Scalars['ID'];
  eventId: Scalars['ID'];
  status: EventStatus;
};


export type MutationCaLinkStripeAccountArgs = {
  communityId: Scalars['ID'];
};


export type MutationCaLoginArgs = {
  identifier: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCaMassMessageUpdateArgs = {
  attemptPublish?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  data: CaMassMessageUpdateInput;
  massMessageId?: InputMaybe<Scalars['ID']>;
};


export type MutationCaModifyProductArgs = {
  badgeMediaPath?: InputMaybe<Scalars['String']>;
  data: ProductUpdateInput;
  productId: Scalars['ID'];
};


export type MutationCaProcessForumContentReportArgs = {
  action: ProcessForumContentReportActionType;
  deleteReason?: InputMaybe<Scalars['String']>;
  reportId: Scalars['ID'];
};


export type MutationCaProcessForumPostArgs = {
  action: ProcessForumPostActionType;
  deleteReason?: InputMaybe<Scalars['String']>;
  postId: Scalars['ID'];
};


export type MutationCaProductCreateOrUpdateArgs = {
  attemptPublish?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  data: CaProductCreateOrUpdateInput;
  productId?: InputMaybe<Scalars['ID']>;
};


export type MutationCaPromocodeCreateOrUpdateArgs = {
  attemptPublish?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  data: CaPromocodeUpdateInput;
  productsToConnect?: InputMaybe<Array<Scalars['String']>>;
  promocodeId?: InputMaybe<Scalars['ID']>;
};


export type MutationCaPromocodeDuplicateArgs = {
  promocodeId: Scalars['ID'];
};


export type MutationCaPromocodeToggleStatusArgs = {
  code: Scalars['String'];
  communityId: Scalars['ID'];
  promocodeId: Scalars['ID'];
  publishingStatus: PromocodePublishingStatus;
};


export type MutationCaRadioTrackCreateOrUpdateArgs = {
  attemptPublish?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  data: CaRadioTrackCreateOrUpdateInput;
  trackId?: InputMaybe<Scalars['ID']>;
};


export type MutationCaRadioTrackDuplicateArgs = {
  communityId: Scalars['ID'];
  trackId: Scalars['ID'];
};


export type MutationCaReadTalksMessagesArgs = {
  chatId: Scalars['ID'];
  communityId: Scalars['ID'];
};


export type MutationCaRemoveAdminArgs = {
  communityId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationCaScheduleItemCreateOrUpdateArgs = {
  attemptPublish?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  data: CaScheduleItemCreateOrUpdateInput;
  scheduleItemId?: InputMaybe<Scalars['ID']>;
};


export type MutationCaSendMessageArgs = {
  attachmentId?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  recipientId: Scalars['String'];
};


export type MutationCaToggleAnnouncementStatusArgs = {
  announcementId: Scalars['ID'];
  communityId: Scalars['ID'];
  status: FeedItemPublishingStatus;
};


export type MutationCaToggleMassMessageStatusArgs = {
  massMessageId: Scalars['String'];
  status: MassMessageStatus;
};


export type MutationCaToggleProductStatusArgs = {
  communityId: Scalars['ID'];
  productId: Scalars['ID'];
  status: ProductStatus;
};


export type MutationCaToggleRadioTrackStatusArgs = {
  communityId: Scalars['ID'];
  status: TrackPublishingStatus;
  trackId: Scalars['ID'];
};


export type MutationCaToggleScheduleItemStatusArgs = {
  communityId: Scalars['ID'];
  scheduleItemId: Scalars['ID'];
  status: CatalogItemSchedulePublishingStatus;
};


export type MutationCaUnlinkStripeAccountArgs = {
  communityId: Scalars['ID'];
};


export type MutationCalculateDiscountAndFeesArgs = {
  cart: Array<RequestProductsPurchaseInput>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  promocodeId?: InputMaybe<Scalars['String']>;
  sendQR?: Scalars['Boolean'];
};


export type MutationCalendarHideEventArgs = {
  eventId: Scalars['ID'];
};


export type MutationCalendarSeenEventsArgs = {
  eventIds: Scalars['String'];
};


export type MutationCancelPurchasesArgs = {
  purchaseIds: Array<Scalars['String']>;
};


export type MutationCommentFeedItemArgs = {
  commentId?: InputMaybe<Scalars['ID']>;
  feedItemId: Scalars['ID'];
  media?: InputMaybe<Array<Scalars['String']>>;
  text: Scalars['String'];
};


export type MutationCommentForumPostArgs = {
  commentId?: InputMaybe<Scalars['ID']>;
  content: Scalars['String'];
  media?: InputMaybe<Array<Scalars['String']>>;
  postId: Scalars['ID'];
};


export type MutationCreateMediaArgs = {
  filename?: InputMaybe<Scalars['String']>;
  mimeType: Scalars['String'];
  subpath: Scalars['String'];
};


export type MutationCreateOneWaitlistUserArgs = {
  data: WaitlistUserCreateInput;
};


export type MutationDeleteFeedItemCommentArgs = {
  feedItemCommentId: Scalars['ID'];
};


export type MutationDeleteForumContentArgs = {
  commentId?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['ID']>;
};


export type MutationEventRegisterArgs = {
  communityId: Scalars['ID'];
  eventId: Scalars['ID'];
};


export type MutationGetInviteCodeByReservedEmailArgs = {
  email: Scalars['String'];
};


export type MutationJoinCommunityArgs = {
  inviteCode: Scalars['String'];
};


export type MutationJoinCommunityWithInfoCheckArgs = {
  doInfoChecks?: InputMaybe<Scalars['Boolean']>;
  inviteCode: Scalars['String'];
};


export type MutationLoginArgs = {
  identifier?: InputMaybe<Scalars['String']>;
  isAdmin?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  solanaWalletSignature?: InputMaybe<Scalars['String']>;
};


export type MutationPostCreateUserUpdateArgs = {
  password: Scalars['String'];
  username: Scalars['String'];
};


export type MutationReactFeedItemArgs = {
  feedItemId: Scalars['ID'];
};


export type MutationReactFeedItemCommentArgs = {
  feedItemCommentId: Scalars['ID'];
};


export type MutationReactForumCommentArgs = {
  forumCommentId: Scalars['ID'];
};


export type MutationReactForumPostArgs = {
  forumPostId: Scalars['ID'];
};


export type MutationReadMessageArgs = {
  chatId: Scalars['String'];
};


export type MutationReadTalksMessagesArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
};


export type MutationReportFeedItemCommentArgs = {
  feedItemCommentId: Scalars['ID'];
  reason: Scalars['String'];
};


export type MutationReportForumContentArgs = {
  commentId?: InputMaybe<Scalars['ID']>;
  postId?: InputMaybe<Scalars['ID']>;
  reason: Scalars['String'];
};


export type MutationReprocessPurchasesArgs = {
  notifyAdmins?: InputMaybe<Scalars['Boolean']>;
  notifyUser?: InputMaybe<Scalars['Boolean']>;
  purchaseIds: Scalars['String'];
};


export type MutationRequestAccessFeedItemArgs = {
  feedItemId: Scalars['String'];
};


export type MutationRequestAccountDeletionArgs = {
  password: Scalars['String'];
  reason: Scalars['String'];
};


export type MutationRequestEmailVerificationArgs = {
  email?: InputMaybe<Scalars['String']>;
};


export type MutationRequestHideFeedItemArgs = {
  feedItemId: Scalars['String'];
};


export type MutationRequestPhoneVerificationArgs = {
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationRequestProductsPurchaseArgs = {
  cart: Array<RequestProductsPurchaseInput>;
  fromWeb?: Scalars['Boolean'];
  paymentMethod: PurchasePaymentMethod;
  postPaymentReturnUrl?: InputMaybe<Scalars['String']>;
  promocodeId?: InputMaybe<Scalars['String']>;
  sendQR?: Scalars['Boolean'];
  stripePaymentMethodId?: InputMaybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  currentPassword?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  password: Scalars['String'];
  passwordResetCode: Scalars['String'];
};


export type MutationResetPasswordRequestArgs = {
  identifier: Scalars['String'];
};


export type MutationSendCommunityInviteArgs = {
  communityId: Scalars['ID'];
  phone?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationSendInviteCodeArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  identifier: Scalars['String'];
  inviteCode: Scalars['String'];
};


export type MutationSendMessageArgs = {
  attachmentId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  recipientId: Scalars['String'];
};


export type MutationSendTalksMessageArgs = {
  attachmentId?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
};


export type MutationSendTicketArgs = {
  identifier?: InputMaybe<Scalars['String']>;
  purchaseId: Scalars['ID'];
  recipientId?: InputMaybe<Scalars['ID']>;
};


export type MutationSignUpArgs = {
  inviteCode?: InputMaybe<Scalars['String']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  solanaWalletSignature?: InputMaybe<Scalars['String']>;
  user: UserCreateInput;
};


export type MutationSubmitForumPostArgs = {
  communityId: Scalars['ID'];
  content: Scalars['String'];
  headline?: InputMaybe<Scalars['String']>;
  media?: InputMaybe<Array<Scalars['String']>>;
  tags?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSubmitOpenCallArgs = {
  data: Scalars['Json'];
  openCallId: Scalars['ID'];
};


export type MutationSubmitSpottedPostArgs = {
  content: Scalars['String'];
  media?: InputMaybe<Array<Scalars['String']>>;
  title: Scalars['String'];
};


export type MutationSyncPushPlayerIdsArgs = {
  currentPushPlayerId: Scalars['String'];
};


export type MutationUndoCheckInArgs = {
  communityId: Scalars['ID'];
  purchaseIds: Array<Scalars['String']>;
};


export type MutationUpdateOneUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUseTokensArgs = {
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};


export type MutationUserUpdateArgs = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['String']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  currentPassword?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  solanaWalletSignature?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationUserUpdateV2Args = {
  data: UserUpdateV2Input;
};


export type MutationValidateInviteCodeArgs = {
  inviteCode: Scalars['String'];
};


export type MutationValidatePasswordResetCodeArgs = {
  passwordResetCode: Scalars['String'];
};


export type MutationValidatePromocodeArgs = {
  parentCommunityId: Scalars['String'];
  productIds: Array<Scalars['String']>;
  promocode: Scalars['String'];
};


export type MutationValidateUsernameArgs = {
  username: Scalars['String'];
};


export type MutationVerifyEmailArgs = {
  emailVerificationCode: Scalars['String'];
};


export type MutationVerifySwishPaymentArgs = {
  paid: Scalars['Boolean'];
  purchaseId: Scalars['String'];
};


export type MutationVerifyUserArgs = {
  phoneVerificationCode: Scalars['String'];
};

export type MyLatestItem = {
  __typename?: 'MyLatestItem';
  iconUrl: Scalars['String'];
  inAppLink: Scalars['String'];
  mediaUrl: Scalars['String'];
  price: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

export type Nft = {
  __typename?: 'NFT';
  address?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<Maybe<NftAttribute>>>;
  collectionName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
};

export type NftAttribute = {
  __typename?: 'NFTAttribute';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumBackgroundTypeFilter = {
  equals?: InputMaybe<BackgroundType>;
  in?: InputMaybe<Array<BackgroundType>>;
  not?: InputMaybe<NestedEnumBackgroundTypeFilter>;
  notIn?: InputMaybe<Array<BackgroundType>>;
};

export type NestedEnumBackgroundTypeNullableFilter = {
  equals?: InputMaybe<BackgroundType>;
  in?: InputMaybe<Array<BackgroundType>>;
  not?: InputMaybe<NestedEnumBackgroundTypeNullableFilter>;
  notIn?: InputMaybe<Array<BackgroundType>>;
};

export type NestedEnumCatalogItemSchedulePublishingStatusFilter = {
  equals?: InputMaybe<CatalogItemSchedulePublishingStatus>;
  in?: InputMaybe<Array<CatalogItemSchedulePublishingStatus>>;
  not?: InputMaybe<NestedEnumCatalogItemSchedulePublishingStatusFilter>;
  notIn?: InputMaybe<Array<CatalogItemSchedulePublishingStatus>>;
};

export type NestedEnumCommunityThemeStatusFilter = {
  equals?: InputMaybe<CommunityThemeStatus>;
  in?: InputMaybe<Array<CommunityThemeStatus>>;
  not?: InputMaybe<NestedEnumCommunityThemeStatusFilter>;
  notIn?: InputMaybe<Array<CommunityThemeStatus>>;
};

export type NestedEnumConfigurationTypeFilter = {
  equals?: InputMaybe<ConfigurationType>;
  in?: InputMaybe<Array<ConfigurationType>>;
  not?: InputMaybe<NestedEnumConfigurationTypeFilter>;
  notIn?: InputMaybe<Array<ConfigurationType>>;
};

export type NestedEnumEventLocationAvailabilityFilter = {
  equals?: InputMaybe<EventLocationAvailability>;
  in?: InputMaybe<Array<EventLocationAvailability>>;
  not?: InputMaybe<NestedEnumEventLocationAvailabilityFilter>;
  notIn?: InputMaybe<Array<EventLocationAvailability>>;
};

export type NestedEnumEventStatusFilter = {
  equals?: InputMaybe<EventStatus>;
  in?: InputMaybe<Array<EventStatus>>;
  not?: InputMaybe<NestedEnumEventStatusFilter>;
  notIn?: InputMaybe<Array<EventStatus>>;
};

export type NestedEnumFeedItemCommentReportStatusFilter = {
  equals?: InputMaybe<FeedItemCommentReportStatus>;
  in?: InputMaybe<Array<FeedItemCommentReportStatus>>;
  not?: InputMaybe<NestedEnumFeedItemCommentReportStatusFilter>;
  notIn?: InputMaybe<Array<FeedItemCommentReportStatus>>;
};

export type NestedEnumFeedItemCommentStatusFilter = {
  equals?: InputMaybe<FeedItemCommentStatus>;
  in?: InputMaybe<Array<FeedItemCommentStatus>>;
  not?: InputMaybe<NestedEnumFeedItemCommentStatusFilter>;
  notIn?: InputMaybe<Array<FeedItemCommentStatus>>;
};

export type NestedEnumFeedItemPublishingStatusFilter = {
  equals?: InputMaybe<FeedItemPublishingStatus>;
  in?: InputMaybe<Array<FeedItemPublishingStatus>>;
  not?: InputMaybe<NestedEnumFeedItemPublishingStatusFilter>;
  notIn?: InputMaybe<Array<FeedItemPublishingStatus>>;
};

export type NestedEnumForumCommentStatusFilter = {
  equals?: InputMaybe<ForumCommentStatus>;
  in?: InputMaybe<Array<ForumCommentStatus>>;
  not?: InputMaybe<NestedEnumForumCommentStatusFilter>;
  notIn?: InputMaybe<Array<ForumCommentStatus>>;
};

export type NestedEnumForumContentReportStatusFilter = {
  equals?: InputMaybe<ForumContentReportStatus>;
  in?: InputMaybe<Array<ForumContentReportStatus>>;
  not?: InputMaybe<NestedEnumForumContentReportStatusFilter>;
  notIn?: InputMaybe<Array<ForumContentReportStatus>>;
};

export type NestedEnumForumPostPublishingStatusFilter = {
  equals?: InputMaybe<ForumPostPublishingStatus>;
  in?: InputMaybe<Array<ForumPostPublishingStatus>>;
  not?: InputMaybe<NestedEnumForumPostPublishingStatusFilter>;
  notIn?: InputMaybe<Array<ForumPostPublishingStatus>>;
};

export type NestedEnumHeadlineTypeNullableFilter = {
  equals?: InputMaybe<HeadlineType>;
  in?: InputMaybe<Array<HeadlineType>>;
  not?: InputMaybe<NestedEnumHeadlineTypeNullableFilter>;
  notIn?: InputMaybe<Array<HeadlineType>>;
};

export type NestedEnumHighlightTypeFilter = {
  equals?: InputMaybe<HighlightType>;
  in?: InputMaybe<Array<HighlightType>>;
  not?: InputMaybe<NestedEnumHighlightTypeFilter>;
  notIn?: InputMaybe<Array<HighlightType>>;
};

export type NestedEnumInviteCodeTypeFilter = {
  equals?: InputMaybe<InviteCodeType>;
  in?: InputMaybe<Array<InviteCodeType>>;
  not?: InputMaybe<NestedEnumInviteCodeTypeFilter>;
  notIn?: InputMaybe<Array<InviteCodeType>>;
};

export type NestedEnumMassMessageSegmentNullableFilter = {
  equals?: InputMaybe<MassMessageSegment>;
  in?: InputMaybe<Array<MassMessageSegment>>;
  not?: InputMaybe<NestedEnumMassMessageSegmentNullableFilter>;
  notIn?: InputMaybe<Array<MassMessageSegment>>;
};

export type NestedEnumMassMessageStatusFilter = {
  equals?: InputMaybe<MassMessageStatus>;
  in?: InputMaybe<Array<MassMessageStatus>>;
  not?: InputMaybe<NestedEnumMassMessageStatusFilter>;
  notIn?: InputMaybe<Array<MassMessageStatus>>;
};

export type NestedEnumMassMessageTypeFilter = {
  equals?: InputMaybe<MassMessageType>;
  in?: InputMaybe<Array<MassMessageType>>;
  not?: InputMaybe<NestedEnumMassMessageTypeFilter>;
  notIn?: InputMaybe<Array<MassMessageType>>;
};

export type NestedEnumMessageHighlightStatusFilter = {
  equals?: InputMaybe<MessageHighlightStatus>;
  in?: InputMaybe<Array<MessageHighlightStatus>>;
  not?: InputMaybe<NestedEnumMessageHighlightStatusFilter>;
  notIn?: InputMaybe<Array<MessageHighlightStatus>>;
};

export type NestedEnumModuleFilter = {
  equals?: InputMaybe<Module>;
  in?: InputMaybe<Array<Module>>;
  not?: InputMaybe<NestedEnumModuleFilter>;
  notIn?: InputMaybe<Array<Module>>;
};

export type NestedEnumModuleNullableFilter = {
  equals?: InputMaybe<Module>;
  in?: InputMaybe<Array<Module>>;
  not?: InputMaybe<NestedEnumModuleNullableFilter>;
  notIn?: InputMaybe<Array<Module>>;
};

export type NestedEnumOpenCallPublishingStatusFilter = {
  equals?: InputMaybe<OpenCallPublishingStatus>;
  in?: InputMaybe<Array<OpenCallPublishingStatus>>;
  not?: InputMaybe<NestedEnumOpenCallPublishingStatusFilter>;
  notIn?: InputMaybe<Array<OpenCallPublishingStatus>>;
};

export type NestedEnumPayoutStatusFilter = {
  equals?: InputMaybe<PayoutStatus>;
  in?: InputMaybe<Array<PayoutStatus>>;
  not?: InputMaybe<NestedEnumPayoutStatusFilter>;
  notIn?: InputMaybe<Array<PayoutStatus>>;
};

export type NestedEnumProductStatusFilter = {
  equals?: InputMaybe<ProductStatus>;
  in?: InputMaybe<Array<ProductStatus>>;
  not?: InputMaybe<NestedEnumProductStatusFilter>;
  notIn?: InputMaybe<Array<ProductStatus>>;
};

export type NestedEnumProductTypeFilter = {
  equals?: InputMaybe<ProductType>;
  in?: InputMaybe<Array<ProductType>>;
  not?: InputMaybe<NestedEnumProductTypeFilter>;
  notIn?: InputMaybe<Array<ProductType>>;
};

export type NestedEnumPromocodePublishingStatusFilter = {
  equals?: InputMaybe<PromocodePublishingStatus>;
  in?: InputMaybe<Array<PromocodePublishingStatus>>;
  not?: InputMaybe<NestedEnumPromocodePublishingStatusFilter>;
  notIn?: InputMaybe<Array<PromocodePublishingStatus>>;
};

export type NestedEnumPurchasePaymentMethodFilter = {
  equals?: InputMaybe<PurchasePaymentMethod>;
  in?: InputMaybe<Array<PurchasePaymentMethod>>;
  not?: InputMaybe<NestedEnumPurchasePaymentMethodFilter>;
  notIn?: InputMaybe<Array<PurchasePaymentMethod>>;
};

export type NestedEnumPurchaseStatusFilter = {
  equals?: InputMaybe<PurchaseStatus>;
  in?: InputMaybe<Array<PurchaseStatus>>;
  not?: InputMaybe<NestedEnumPurchaseStatusFilter>;
  notIn?: InputMaybe<Array<PurchaseStatus>>;
};

export type NestedEnumSupportedCountriesFilter = {
  equals?: InputMaybe<SupportedCountries>;
  in?: InputMaybe<Array<SupportedCountries>>;
  not?: InputMaybe<NestedEnumSupportedCountriesFilter>;
  notIn?: InputMaybe<Array<SupportedCountries>>;
};

export type NestedEnumSupportedCurrenciesFilter = {
  equals?: InputMaybe<SupportedCurrencies>;
  in?: InputMaybe<Array<SupportedCurrencies>>;
  not?: InputMaybe<NestedEnumSupportedCurrenciesFilter>;
  notIn?: InputMaybe<Array<SupportedCurrencies>>;
};

export type NestedEnumTalksMessageTypeFilter = {
  equals?: InputMaybe<TalksMessageType>;
  in?: InputMaybe<Array<TalksMessageType>>;
  not?: InputMaybe<NestedEnumTalksMessageTypeFilter>;
  notIn?: InputMaybe<Array<TalksMessageType>>;
};

export type NestedEnumTalksMessageTypeNullableFilter = {
  equals?: InputMaybe<TalksMessageType>;
  in?: InputMaybe<Array<TalksMessageType>>;
  not?: InputMaybe<NestedEnumTalksMessageTypeNullableFilter>;
  notIn?: InputMaybe<Array<TalksMessageType>>;
};

export type NestedEnumTrackPublishingStatusFilter = {
  equals?: InputMaybe<TrackPublishingStatus>;
  in?: InputMaybe<Array<TrackPublishingStatus>>;
  not?: InputMaybe<NestedEnumTrackPublishingStatusFilter>;
  notIn?: InputMaybe<Array<TrackPublishingStatus>>;
};

export type NestedEnumTrackTypeFilter = {
  equals?: InputMaybe<TrackType>;
  in?: InputMaybe<Array<TrackType>>;
  not?: InputMaybe<NestedEnumTrackTypeFilter>;
  notIn?: InputMaybe<Array<TrackType>>;
};

export type NestedEnumUbiqFeedItemStatusFilter = {
  equals?: InputMaybe<UbiqFeedItemStatus>;
  in?: InputMaybe<Array<UbiqFeedItemStatus>>;
  not?: InputMaybe<NestedEnumUbiqFeedItemStatusFilter>;
  notIn?: InputMaybe<Array<UbiqFeedItemStatus>>;
};

export type NestedEnumUbiqFeedItemTypeFilter = {
  equals?: InputMaybe<UbiqFeedItemType>;
  in?: InputMaybe<Array<UbiqFeedItemType>>;
  not?: InputMaybe<NestedEnumUbiqFeedItemTypeFilter>;
  notIn?: InputMaybe<Array<UbiqFeedItemType>>;
};

export type NestedEnumUserRoleFilter = {
  equals?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  not?: InputMaybe<NestedEnumUserRoleFilter>;
  notIn?: InputMaybe<Array<UserRole>>;
};

export type NestedEnumUserStatusFilter = {
  equals?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  not?: InputMaybe<NestedEnumUserStatusFilter>;
  notIn?: InputMaybe<Array<UserStatus>>;
};

export type NestedEnumWaitlistSourceFilter = {
  equals?: InputMaybe<WaitlistSource>;
  in?: InputMaybe<Array<WaitlistSource>>;
  not?: InputMaybe<NestedEnumWaitlistSourceFilter>;
  notIn?: InputMaybe<Array<WaitlistSource>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableEnumBackgroundTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<BackgroundType>;
};

export type NullableEnumHeadlineTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<HeadlineType>;
};

export type NullableEnumMassMessageSegmentFieldUpdateOperationsInput = {
  set?: InputMaybe<MassMessageSegment>;
};

export type NullableEnumModuleFieldUpdateOperationsInput = {
  set?: InputMaybe<Module>;
};

export type NullableEnumTalksMessageTypeFieldUpdateOperationsInput = {
  set?: InputMaybe<TalksMessageType>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Float']>;
  divide?: InputMaybe<Scalars['Float']>;
  increment?: InputMaybe<Scalars['Float']>;
  multiply?: InputMaybe<Scalars['Float']>;
  set?: InputMaybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type OpenCall = {
  __typename?: 'OpenCall';
  applied: Scalars['Boolean'];
  community: Community;
  config?: Maybe<Scalars['Json']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: OpenCallPublishingStatus;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type OpenCallCreateInput = {
  community: CommunityCreateNestedOneWithoutOpenCallsInput;
  config?: InputMaybe<Scalars['Json']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<OpenCallPublishingStatus>;
  submissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutOpenCallInput>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OpenCallCreateManyCommunityInput = {
  config?: InputMaybe<Scalars['Json']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<OpenCallPublishingStatus>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OpenCallCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<OpenCallCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OpenCallCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<OpenCallWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OpenCallCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<OpenCallCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<OpenCallCreateManyCommunityInputEnvelope>;
};

export type OpenCallCreateNestedOneWithoutSubmissionsInput = {
  connect?: InputMaybe<OpenCallWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OpenCallCreateOrConnectWithoutSubmissionsInput>;
  create?: InputMaybe<OpenCallCreateWithoutSubmissionsInput>;
};

export type OpenCallCreateOrConnectWithoutCommunityInput = {
  create: OpenCallCreateWithoutCommunityInput;
  where: OpenCallWhereUniqueInput;
};

export type OpenCallCreateOrConnectWithoutSubmissionsInput = {
  create: OpenCallCreateWithoutSubmissionsInput;
  where: OpenCallWhereUniqueInput;
};

export type OpenCallCreateWithoutCommunityInput = {
  config?: InputMaybe<Scalars['Json']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<OpenCallPublishingStatus>;
  submissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutOpenCallInput>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OpenCallCreateWithoutSubmissionsInput = {
  community: CommunityCreateNestedOneWithoutOpenCallsInput;
  config?: InputMaybe<Scalars['Json']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<OpenCallPublishingStatus>;
  title: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OpenCallListRelationFilter = {
  every?: InputMaybe<OpenCallWhereInput>;
  none?: InputMaybe<OpenCallWhereInput>;
  some?: InputMaybe<OpenCallWhereInput>;
};

export type OpenCallOrderByInput = {
  communityId?: InputMaybe<SortOrder>;
  config?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mediaUrl?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum OpenCallPublishingStatus {
  Deleted = 'Deleted',
  Draft = 'Draft',
  Published = 'Published'
}

export type OpenCallScalarWhereInput = {
  AND?: InputMaybe<Array<OpenCallScalarWhereInput>>;
  NOT?: InputMaybe<Array<OpenCallScalarWhereInput>>;
  OR?: InputMaybe<Array<OpenCallScalarWhereInput>>;
  communityId?: InputMaybe<StringFilter>;
  config?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumOpenCallPublishingStatusFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OpenCallSubmission = {
  __typename?: 'OpenCallSubmission';
  createdAt: Scalars['DateTime'];
  data: Scalars['Json'];
  id: Scalars['String'];
  openCall: OpenCall;
  updatedAt: Scalars['DateTime'];
  user: User;
};

export type OpenCallSubmissionCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['String']>;
  openCall: OpenCallCreateNestedOneWithoutSubmissionsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutOpenCallSubmissionsInput;
};

export type OpenCallSubmissionCreateManyOpenCallInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type OpenCallSubmissionCreateManyOpenCallInputEnvelope = {
  data?: InputMaybe<Array<OpenCallSubmissionCreateManyOpenCallInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OpenCallSubmissionCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['String']>;
  openCallId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OpenCallSubmissionCreateManyUserInputEnvelope = {
  data?: InputMaybe<Array<OpenCallSubmissionCreateManyUserInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type OpenCallSubmissionCreateNestedManyWithoutOpenCallInput = {
  connect?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OpenCallSubmissionCreateOrConnectWithoutOpenCallInput>>;
  create?: InputMaybe<Array<OpenCallSubmissionCreateWithoutOpenCallInput>>;
  createMany?: InputMaybe<OpenCallSubmissionCreateManyOpenCallInputEnvelope>;
};

export type OpenCallSubmissionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OpenCallSubmissionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OpenCallSubmissionCreateWithoutUserInput>>;
  createMany?: InputMaybe<OpenCallSubmissionCreateManyUserInputEnvelope>;
};

export type OpenCallSubmissionCreateOrConnectWithoutOpenCallInput = {
  create: OpenCallSubmissionCreateWithoutOpenCallInput;
  where: OpenCallSubmissionWhereUniqueInput;
};

export type OpenCallSubmissionCreateOrConnectWithoutUserInput = {
  create: OpenCallSubmissionCreateWithoutUserInput;
  where: OpenCallSubmissionWhereUniqueInput;
};

export type OpenCallSubmissionCreateWithoutOpenCallInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutOpenCallSubmissionsInput;
};

export type OpenCallSubmissionCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['Json'];
  id?: InputMaybe<Scalars['String']>;
  openCall: OpenCallCreateNestedOneWithoutSubmissionsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type OpenCallSubmissionListRelationFilter = {
  every?: InputMaybe<OpenCallSubmissionWhereInput>;
  none?: InputMaybe<OpenCallSubmissionWhereInput>;
  some?: InputMaybe<OpenCallSubmissionWhereInput>;
};

export type OpenCallSubmissionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  openCallId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type OpenCallSubmissionScalarWhereInput = {
  AND?: InputMaybe<Array<OpenCallSubmissionScalarWhereInput>>;
  NOT?: InputMaybe<Array<OpenCallSubmissionScalarWhereInput>>;
  OR?: InputMaybe<Array<OpenCallSubmissionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  openCallId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type OpenCallSubmissionUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  openCall?: InputMaybe<OpenCallUpdateOneRequiredWithoutSubmissionsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOpenCallSubmissionsInput>;
};

export type OpenCallSubmissionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OpenCallSubmissionUpdateManyWithWhereWithoutOpenCallInput = {
  data: OpenCallSubmissionUpdateManyMutationInput;
  where: OpenCallSubmissionScalarWhereInput;
};

export type OpenCallSubmissionUpdateManyWithWhereWithoutUserInput = {
  data: OpenCallSubmissionUpdateManyMutationInput;
  where: OpenCallSubmissionScalarWhereInput;
};

export type OpenCallSubmissionUpdateManyWithoutOpenCallInput = {
  connect?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OpenCallSubmissionCreateOrConnectWithoutOpenCallInput>>;
  create?: InputMaybe<Array<OpenCallSubmissionCreateWithoutOpenCallInput>>;
  createMany?: InputMaybe<OpenCallSubmissionCreateManyOpenCallInputEnvelope>;
  delete?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OpenCallSubmissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  set?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  update?: InputMaybe<Array<OpenCallSubmissionUpdateWithWhereUniqueWithoutOpenCallInput>>;
  updateMany?: InputMaybe<Array<OpenCallSubmissionUpdateManyWithWhereWithoutOpenCallInput>>;
  upsert?: InputMaybe<Array<OpenCallSubmissionUpsertWithWhereUniqueWithoutOpenCallInput>>;
};

export type OpenCallSubmissionUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OpenCallSubmissionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<OpenCallSubmissionCreateWithoutUserInput>>;
  createMany?: InputMaybe<OpenCallSubmissionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OpenCallSubmissionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  set?: InputMaybe<Array<OpenCallSubmissionWhereUniqueInput>>;
  update?: InputMaybe<Array<OpenCallSubmissionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<OpenCallSubmissionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<OpenCallSubmissionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type OpenCallSubmissionUpdateWithWhereUniqueWithoutOpenCallInput = {
  data: OpenCallSubmissionUpdateWithoutOpenCallInput;
  where: OpenCallSubmissionWhereUniqueInput;
};

export type OpenCallSubmissionUpdateWithWhereUniqueWithoutUserInput = {
  data: OpenCallSubmissionUpdateWithoutUserInput;
  where: OpenCallSubmissionWhereUniqueInput;
};

export type OpenCallSubmissionUpdateWithoutOpenCallInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutOpenCallSubmissionsInput>;
};

export type OpenCallSubmissionUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  openCall?: InputMaybe<OpenCallUpdateOneRequiredWithoutSubmissionsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OpenCallSubmissionUpsertWithWhereUniqueWithoutOpenCallInput = {
  create: OpenCallSubmissionCreateWithoutOpenCallInput;
  update: OpenCallSubmissionUpdateWithoutOpenCallInput;
  where: OpenCallSubmissionWhereUniqueInput;
};

export type OpenCallSubmissionUpsertWithWhereUniqueWithoutUserInput = {
  create: OpenCallSubmissionCreateWithoutUserInput;
  update: OpenCallSubmissionUpdateWithoutUserInput;
  where: OpenCallSubmissionWhereUniqueInput;
};

export type OpenCallSubmissionWhereInput = {
  AND?: InputMaybe<Array<OpenCallSubmissionWhereInput>>;
  NOT?: InputMaybe<Array<OpenCallSubmissionWhereInput>>;
  OR?: InputMaybe<Array<OpenCallSubmissionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  openCall?: InputMaybe<OpenCallWhereInput>;
  openCallId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserWhereInput>;
  userId?: InputMaybe<StringFilter>;
};

export type OpenCallSubmissionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type OpenCallUpdateInput = {
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutOpenCallsInput>;
  config?: InputMaybe<Scalars['Json']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOpenCallPublishingStatusFieldUpdateOperationsInput>;
  submissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutOpenCallInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OpenCallUpdateManyMutationInput = {
  config?: InputMaybe<Scalars['Json']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOpenCallPublishingStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OpenCallUpdateManyWithWhereWithoutCommunityInput = {
  data: OpenCallUpdateManyMutationInput;
  where: OpenCallScalarWhereInput;
};

export type OpenCallUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<OpenCallWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<OpenCallCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<OpenCallCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<OpenCallCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<OpenCallWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<OpenCallScalarWhereInput>>;
  disconnect?: InputMaybe<Array<OpenCallWhereUniqueInput>>;
  set?: InputMaybe<Array<OpenCallWhereUniqueInput>>;
  update?: InputMaybe<Array<OpenCallUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<OpenCallUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<OpenCallUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type OpenCallUpdateOneRequiredWithoutSubmissionsInput = {
  connect?: InputMaybe<OpenCallWhereUniqueInput>;
  connectOrCreate?: InputMaybe<OpenCallCreateOrConnectWithoutSubmissionsInput>;
  create?: InputMaybe<OpenCallCreateWithoutSubmissionsInput>;
  update?: InputMaybe<OpenCallUpdateWithoutSubmissionsInput>;
  upsert?: InputMaybe<OpenCallUpsertWithoutSubmissionsInput>;
};

export type OpenCallUpdateWithWhereUniqueWithoutCommunityInput = {
  data: OpenCallUpdateWithoutCommunityInput;
  where: OpenCallWhereUniqueInput;
};

export type OpenCallUpdateWithoutCommunityInput = {
  config?: InputMaybe<Scalars['Json']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOpenCallPublishingStatusFieldUpdateOperationsInput>;
  submissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutOpenCallInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OpenCallUpdateWithoutSubmissionsInput = {
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutOpenCallsInput>;
  config?: InputMaybe<Scalars['Json']>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<StringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumOpenCallPublishingStatusFieldUpdateOperationsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type OpenCallUpsertWithWhereUniqueWithoutCommunityInput = {
  create: OpenCallCreateWithoutCommunityInput;
  update: OpenCallUpdateWithoutCommunityInput;
  where: OpenCallWhereUniqueInput;
};

export type OpenCallUpsertWithoutSubmissionsInput = {
  create: OpenCallCreateWithoutSubmissionsInput;
  update: OpenCallUpdateWithoutSubmissionsInput;
};

export type OpenCallWhereInput = {
  AND?: InputMaybe<Array<OpenCallWhereInput>>;
  NOT?: InputMaybe<Array<OpenCallWhereInput>>;
  OR?: InputMaybe<Array<OpenCallWhereInput>>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringFilter>;
  config?: InputMaybe<JsonNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumOpenCallPublishingStatusFilter>;
  submissions?: InputMaybe<OpenCallSubmissionListRelationFilter>;
  title?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type OpenCallWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type OpenCallsResponse = {
  __typename?: 'OpenCallsResponse';
  items: Array<OpenCall>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type PasswordResetRequestPayload = {
  __typename?: 'PasswordResetRequestPayload';
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  requested: Scalars['Boolean'];
};

export type Payout = {
  __typename?: 'Payout';
  account?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  communityId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  method: PurchasePaymentMethod;
  purchaseId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type PayoutCreateInput = {
  account?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  method?: InputMaybe<PurchasePaymentMethod>;
  purchaseId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PayoutStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PayoutOrderByInput = {
  account?: InputMaybe<SortOrder>;
  amount?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  method?: InputMaybe<SortOrder>;
  purchaseId?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PayoutStatus {
  Accepted = 'Accepted',
  Declined = 'Declined',
  Processing = 'Processing',
  RequestSent = 'RequestSent'
}

export type PayoutUpdateInput = {
  account?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communityId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  method?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  purchaseId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPayoutStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayoutUpdateManyMutationInput = {
  account?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  communityId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  method?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  purchaseId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPayoutStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PayoutWhereInput = {
  AND?: InputMaybe<Array<PayoutWhereInput>>;
  NOT?: InputMaybe<Array<PayoutWhereInput>>;
  OR?: InputMaybe<Array<PayoutWhereInput>>;
  account?: InputMaybe<StringNullableFilter>;
  amount?: InputMaybe<FloatFilter>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  method?: InputMaybe<EnumPurchasePaymentMethodFilter>;
  purchaseId?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumPayoutStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PayoutWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PopularEvent = {
  __typename?: 'PopularEvent';
  attendeesCount: Scalars['Int'];
  attendeesIcons: Array<Scalars['String']>;
  communityCode: Scalars['String'];
  communityIconUrl: Scalars['String'];
  communityName: Scalars['String'];
  curatedByIcons: Array<Scalars['String']>;
  curatedByUsernames: Scalars['String'];
  date: Scalars['Date'];
  id: Scalars['String'];
  imageUrl: Scalars['String'];
  likesCount: Scalars['Int'];
  location: Scalars['String'];
  title: Scalars['String'];
};

export type PopularEventsResponse = {
  __typename?: 'PopularEventsResponse';
  items: Array<PopularEvent>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export enum ProcessForumContentReportActionType {
  Delete = 'delete',
  Ignore = 'ignore'
}

export enum ProcessForumPostActionType {
  Approve = 'approve',
  Delete = 'delete',
  Ignore = 'ignore'
}

export type Product = {
  __typename?: 'Product';
  additionalInfo?: Maybe<Scalars['String']>;
  badge?: Maybe<TemplateBadge>;
  badgeId?: Maybe<Scalars['String']>;
  bundleProducts: Array<Product>;
  code?: Maybe<Scalars['String']>;
  community: Community;
  communityId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  customReceiptAlias?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  event?: Maybe<Event>;
  fees: ProductFees;
  grossRevenue?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isDisabled: Scalars['Boolean'];
  limit: Scalars['Int'];
  maxAllowedPurchases: Scalars['Int'];
  mediaUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  payees: Scalars['String'];
  pickupInfo?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  productType: ProductType;
  promocodes: Array<Promocode>;
  publishingStatus: ProductStatus;
  purchases: Array<Purchase>;
  quantity: Scalars['Int'];
  requiredAccessCode?: Maybe<Scalars['String']>;
  sharedQuantityProducts: Array<Product>;
  showBeforeStartDate: Scalars['Boolean'];
  soldoutAfter?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  styleOverride?: Maybe<Scalars['Json']>;
  totalProcess?: Maybe<Scalars['Int']>;
  totalSold?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  vat: Scalars['Float'];
};


export type ProductBundleProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type ProductPromocodesArgs = {
  cursor?: InputMaybe<PromocodeWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type ProductPurchasesArgs = {
  cursor?: InputMaybe<PurchaseWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseWhereInput>;
};


export type ProductSharedQuantityProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type ProductCreateInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateManyBadgeInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['String']>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  publishingStatus?: InputMaybe<ProductStatus>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateManyBadgeInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyBadgeInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateManyCommunityInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badgeId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  eventId?: InputMaybe<Scalars['String']>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  publishingStatus?: InputMaybe<ProductStatus>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateManyEventInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badgeId?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  communityId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  publishingStatus?: InputMaybe<ProductStatus>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<ProductCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ProductCreateNestedManyWithoutBadgeInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<ProductCreateManyBadgeInputEnvelope>;
};

export type ProductCreateNestedManyWithoutBundleProductsInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutBundleProductsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutBundleProductsInput>>;
};

export type ProductCreateNestedManyWithoutBundleProductsRelationInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutBundleProductsRelationInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutBundleProductsRelationInput>>;
};

export type ProductCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<ProductCreateManyCommunityInputEnvelope>;
};

export type ProductCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutEventInput>>;
  createMany?: InputMaybe<ProductCreateManyEventInputEnvelope>;
};

export type ProductCreateNestedManyWithoutPromocodesInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutPromocodesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutPromocodesInput>>;
};

export type ProductCreateNestedManyWithoutSharedQuantityProductsInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSharedQuantityProductsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSharedQuantityProductsInput>>;
};

export type ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSharedQuantityProductsRelationInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSharedQuantityProductsRelationInput>>;
};

export type ProductCreateNestedOneWithoutPurchasesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<ProductCreateWithoutPurchasesInput>;
};

export type ProductCreateOrConnectWithoutBadgeInput = {
  create: ProductCreateWithoutBadgeInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutBundleProductsInput = {
  create: ProductCreateWithoutBundleProductsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutBundleProductsRelationInput = {
  create: ProductCreateWithoutBundleProductsRelationInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutCommunityInput = {
  create: ProductCreateWithoutCommunityInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutEventInput = {
  create: ProductCreateWithoutEventInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutPromocodesInput = {
  create: ProductCreateWithoutPromocodesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutPurchasesInput = {
  create: ProductCreateWithoutPurchasesInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutSharedQuantityProductsInput = {
  create: ProductCreateWithoutSharedQuantityProductsInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateOrConnectWithoutSharedQuantityProductsRelationInput = {
  create: ProductCreateWithoutSharedQuantityProductsRelationInput;
  where: ProductWhereUniqueInput;
};

export type ProductCreateWithoutBadgeInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateWithoutBundleProductsInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateWithoutBundleProductsRelationInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateWithoutCommunityInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateWithoutEventInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateWithoutPromocodesInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateWithoutPurchasesInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateWithoutSharedQuantityProductsInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductCreateWithoutSharedQuantityProductsRelationInput = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  badge?: InputMaybe<TemplateBadgeCreateNestedOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductCreateNestedManyWithoutBundleProductsInput>;
  code?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutProductsInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  customReceiptAlias?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  event?: InputMaybe<EventCreateNestedOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  isDisabled?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  maxAllowedPurchases?: InputMaybe<Scalars['Int']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  payees?: InputMaybe<Scalars['String']>;
  pickupInfo?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productType?: InputMaybe<ProductType>;
  promocodes?: InputMaybe<PromocodeCreateNestedManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<ProductStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutProductInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  requiredAccessCode?: InputMaybe<Scalars['String']>;
  sharedQuantityProducts?: InputMaybe<ProductCreateNestedManyWithoutSharedQuantityProductsRelationInput>;
  showBeforeStartDate?: InputMaybe<Scalars['Boolean']>;
  soldoutAfter?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  vat?: InputMaybe<Scalars['Float']>;
};

export type ProductFees = {
  __typename?: 'ProductFees';
  StripeCardFixed?: Maybe<Scalars['Float']>;
  StripeCardMin?: Maybe<Scalars['Float']>;
  StripeCardPercentage?: Maybe<Scalars['Float']>;
  StripeFixed?: Maybe<Scalars['Float']>;
  StripePercentage?: Maybe<Scalars['Float']>;
  StripeSwishFixed?: Maybe<Scalars['Float']>;
  StripeSwishMin?: Maybe<Scalars['Float']>;
  StripeSwishPercentage?: Maybe<Scalars['Float']>;
  SwishFixed?: Maybe<Scalars['Float']>;
  SwishPercentage?: Maybe<Scalars['Float']>;
  WebFixed?: Maybe<Scalars['Float']>;
  WebMin?: Maybe<Scalars['Float']>;
  WebPercentage?: Maybe<Scalars['Float']>;
};

export type ProductListRelationFilter = {
  every?: InputMaybe<ProductWhereInput>;
  none?: InputMaybe<ProductWhereInput>;
  some?: InputMaybe<ProductWhereInput>;
};

export type ProductOrderByInput = {
  additionalInfo?: InputMaybe<SortOrder>;
  badgeId?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  customReceiptAlias?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  fees?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isDisabled?: InputMaybe<SortOrder>;
  limit?: InputMaybe<SortOrder>;
  maxAllowedPurchases?: InputMaybe<SortOrder>;
  mediaUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  payees?: InputMaybe<SortOrder>;
  pickupInfo?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  productType?: InputMaybe<SortOrder>;
  publishingStatus?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  requiredAccessCode?: InputMaybe<SortOrder>;
  showBeforeStartDate?: InputMaybe<SortOrder>;
  soldoutAfter?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  styleOverride?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  vat?: InputMaybe<SortOrder>;
};

export type ProductScalarWhereInput = {
  AND?: InputMaybe<Array<ProductScalarWhereInput>>;
  NOT?: InputMaybe<Array<ProductScalarWhereInput>>;
  OR?: InputMaybe<Array<ProductScalarWhereInput>>;
  additionalInfo?: InputMaybe<StringNullableFilter>;
  badgeId?: InputMaybe<StringNullableFilter>;
  code?: InputMaybe<StringNullableFilter>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customReceiptAlias?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  fees?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  limit?: InputMaybe<IntFilter>;
  maxAllowedPurchases?: InputMaybe<IntNullableFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  payees?: InputMaybe<StringFilter>;
  pickupInfo?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<FloatFilter>;
  productType?: InputMaybe<EnumProductTypeFilter>;
  publishingStatus?: InputMaybe<EnumProductStatusFilter>;
  quantity?: InputMaybe<IntFilter>;
  requiredAccessCode?: InputMaybe<StringNullableFilter>;
  showBeforeStartDate?: InputMaybe<BoolFilter>;
  soldoutAfter?: InputMaybe<DateTimeNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vat?: InputMaybe<FloatFilter>;
};

export enum ProductStatus {
  CompleteAvailable = 'CompleteAvailable',
  CompleteUnavailable = 'CompleteUnavailable',
  Deleted = 'Deleted',
  Draft = 'Draft',
  Hidden = 'Hidden',
  UneditedDraft = 'UneditedDraft'
}

export enum ProductType {
  Accomodation = 'Accomodation',
  Bundle = 'Bundle',
  Clothing = 'Clothing',
  Ticket = 'Ticket',
  Token = 'Token'
}

export type ProductUpdateInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateManyMutationInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateManyWithWhereWithoutBadgeInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutBundleProductsInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutBundleProductsRelationInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutCommunityInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutEventInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutPromocodesInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutSharedQuantityProductsInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithWhereWithoutSharedQuantityProductsRelationInput = {
  data: ProductUpdateManyMutationInput;
  where: ProductScalarWhereInput;
};

export type ProductUpdateManyWithoutBadgeInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutBadgeInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutBadgeInput>>;
  createMany?: InputMaybe<ProductCreateManyBadgeInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutBadgeInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutBadgeInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutBadgeInput>>;
};

export type ProductUpdateManyWithoutBundleProductsInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutBundleProductsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutBundleProductsInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutBundleProductsInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutBundleProductsInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutBundleProductsInput>>;
};

export type ProductUpdateManyWithoutBundleProductsRelationInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutBundleProductsRelationInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutBundleProductsRelationInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutBundleProductsRelationInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutBundleProductsRelationInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutBundleProductsRelationInput>>;
};

export type ProductUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<ProductCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type ProductUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutEventInput>>;
  createMany?: InputMaybe<ProductCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutEventInput>>;
};

export type ProductUpdateManyWithoutPromocodesInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutPromocodesInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutPromocodesInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutPromocodesInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutPromocodesInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutPromocodesInput>>;
};

export type ProductUpdateManyWithoutSharedQuantityProductsInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSharedQuantityProductsInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSharedQuantityProductsInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutSharedQuantityProductsInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutSharedQuantityProductsInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutSharedQuantityProductsInput>>;
};

export type ProductUpdateManyWithoutSharedQuantityProductsRelationInput = {
  connect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ProductCreateOrConnectWithoutSharedQuantityProductsRelationInput>>;
  create?: InputMaybe<Array<ProductCreateWithoutSharedQuantityProductsRelationInput>>;
  delete?: InputMaybe<Array<ProductWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ProductScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ProductWhereUniqueInput>>;
  set?: InputMaybe<Array<ProductWhereUniqueInput>>;
  update?: InputMaybe<Array<ProductUpdateWithWhereUniqueWithoutSharedQuantityProductsRelationInput>>;
  updateMany?: InputMaybe<Array<ProductUpdateManyWithWhereWithoutSharedQuantityProductsRelationInput>>;
  upsert?: InputMaybe<Array<ProductUpsertWithWhereUniqueWithoutSharedQuantityProductsRelationInput>>;
};

export type ProductUpdateOneRequiredWithoutPurchasesInput = {
  connect?: InputMaybe<ProductWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ProductCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<ProductCreateWithoutPurchasesInput>;
  update?: InputMaybe<ProductUpdateWithoutPurchasesInput>;
  upsert?: InputMaybe<ProductUpsertWithoutPurchasesInput>;
};

export type ProductUpdateWithWhereUniqueWithoutBadgeInput = {
  data: ProductUpdateWithoutBadgeInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutBundleProductsInput = {
  data: ProductUpdateWithoutBundleProductsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutBundleProductsRelationInput = {
  data: ProductUpdateWithoutBundleProductsRelationInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutCommunityInput = {
  data: ProductUpdateWithoutCommunityInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutEventInput = {
  data: ProductUpdateWithoutEventInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutPromocodesInput = {
  data: ProductUpdateWithoutPromocodesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutSharedQuantityProductsInput = {
  data: ProductUpdateWithoutSharedQuantityProductsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithWhereUniqueWithoutSharedQuantityProductsRelationInput = {
  data: ProductUpdateWithoutSharedQuantityProductsRelationInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpdateWithoutBadgeInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutBundleProductsInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutBundleProductsRelationInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutCommunityInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutEventInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutPromocodesInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutPurchasesInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutSharedQuantityProductsInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProductsRelation?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpdateWithoutSharedQuantityProductsRelationInput = {
  additionalInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badge?: InputMaybe<TemplateBadgeUpdateOneWithoutProductsInput>;
  bundleProducts?: InputMaybe<ProductUpdateManyWithoutBundleProductsRelationInput>;
  bundleProductsRelation?: InputMaybe<ProductUpdateManyWithoutBundleProductsInput>;
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutProductsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  customReceiptAlias?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  event?: InputMaybe<EventUpdateOneWithoutProductsInput>;
  fees?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isDisabled?: InputMaybe<BoolFieldUpdateOperationsInput>;
  limit?: InputMaybe<IntFieldUpdateOperationsInput>;
  maxAllowedPurchases?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  order?: InputMaybe<IntFieldUpdateOperationsInput>;
  payees?: InputMaybe<StringFieldUpdateOperationsInput>;
  pickupInfo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  productType?: InputMaybe<EnumProductTypeFieldUpdateOperationsInput>;
  promocodes?: InputMaybe<PromocodeUpdateManyWithoutProductsInput>;
  publishingStatus?: InputMaybe<EnumProductStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutProductInput>;
  quantity?: InputMaybe<IntFieldUpdateOperationsInput>;
  requiredAccessCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sharedQuantityProducts?: InputMaybe<ProductUpdateManyWithoutSharedQuantityProductsRelationInput>;
  showBeforeStartDate?: InputMaybe<BoolFieldUpdateOperationsInput>;
  soldoutAfter?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  styleOverride?: InputMaybe<Scalars['Json']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  vat?: InputMaybe<FloatFieldUpdateOperationsInput>;
};

export type ProductUpsertWithWhereUniqueWithoutBadgeInput = {
  create: ProductCreateWithoutBadgeInput;
  update: ProductUpdateWithoutBadgeInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutBundleProductsInput = {
  create: ProductCreateWithoutBundleProductsInput;
  update: ProductUpdateWithoutBundleProductsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutBundleProductsRelationInput = {
  create: ProductCreateWithoutBundleProductsRelationInput;
  update: ProductUpdateWithoutBundleProductsRelationInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutCommunityInput = {
  create: ProductCreateWithoutCommunityInput;
  update: ProductUpdateWithoutCommunityInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutEventInput = {
  create: ProductCreateWithoutEventInput;
  update: ProductUpdateWithoutEventInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutPromocodesInput = {
  create: ProductCreateWithoutPromocodesInput;
  update: ProductUpdateWithoutPromocodesInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutSharedQuantityProductsInput = {
  create: ProductCreateWithoutSharedQuantityProductsInput;
  update: ProductUpdateWithoutSharedQuantityProductsInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithWhereUniqueWithoutSharedQuantityProductsRelationInput = {
  create: ProductCreateWithoutSharedQuantityProductsRelationInput;
  update: ProductUpdateWithoutSharedQuantityProductsRelationInput;
  where: ProductWhereUniqueInput;
};

export type ProductUpsertWithoutPurchasesInput = {
  create: ProductCreateWithoutPurchasesInput;
  update: ProductUpdateWithoutPurchasesInput;
};

export type ProductUsageDailyData = {
  __typename?: 'ProductUsageDailyData';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
};

export type ProductWhereInput = {
  AND?: InputMaybe<Array<ProductWhereInput>>;
  NOT?: InputMaybe<Array<ProductWhereInput>>;
  OR?: InputMaybe<Array<ProductWhereInput>>;
  additionalInfo?: InputMaybe<StringNullableFilter>;
  badge?: InputMaybe<TemplateBadgeWhereInput>;
  badgeId?: InputMaybe<StringNullableFilter>;
  bundleProducts?: InputMaybe<ProductListRelationFilter>;
  bundleProductsRelation?: InputMaybe<ProductListRelationFilter>;
  code?: InputMaybe<StringNullableFilter>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  customReceiptAlias?: InputMaybe<StringNullableFilter>;
  description?: InputMaybe<StringNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringNullableFilter>;
  fees?: InputMaybe<JsonNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isDisabled?: InputMaybe<BoolFilter>;
  limit?: InputMaybe<IntFilter>;
  maxAllowedPurchases?: InputMaybe<IntNullableFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  order?: InputMaybe<IntFilter>;
  payees?: InputMaybe<StringFilter>;
  pickupInfo?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<FloatFilter>;
  productType?: InputMaybe<EnumProductTypeFilter>;
  promocodes?: InputMaybe<PromocodeListRelationFilter>;
  publishingStatus?: InputMaybe<EnumProductStatusFilter>;
  purchases?: InputMaybe<PurchaseListRelationFilter>;
  quantity?: InputMaybe<IntFilter>;
  requiredAccessCode?: InputMaybe<StringNullableFilter>;
  sharedQuantityProducts?: InputMaybe<ProductListRelationFilter>;
  sharedQuantityProductsRelation?: InputMaybe<ProductListRelationFilter>;
  showBeforeStartDate?: InputMaybe<BoolFilter>;
  soldoutAfter?: InputMaybe<DateTimeNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  styleOverride?: InputMaybe<JsonNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  vat?: InputMaybe<FloatFilter>;
};

export type ProductWhereUniqueInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type Promocode = {
  __typename?: 'Promocode';
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discountFixed?: Maybe<Scalars['Int']>;
  discountOverridePrice?: Maybe<Scalars['Int']>;
  discountPercentage?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  limitPerUser?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentCommunity?: Maybe<Community>;
  products: Array<Product>;
  publishingStatus: PromocodePublishingStatus;
  purchases: Array<Purchase>;
  quantity?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  usedCount: Scalars['Int'];
};


export type PromocodeProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type PromocodePurchasesArgs = {
  cursor?: InputMaybe<PurchaseWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type PromocodeCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountFixed?: InputMaybe<Scalars['Int']>;
  discountOverridePrice?: InputMaybe<Scalars['Int']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  limitPerUser?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutPromocodesInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutPromocodesInput>;
  publishingStatus?: InputMaybe<PromocodePublishingStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutPromocodeInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromocodeCreateManyParentCommunityInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountFixed?: InputMaybe<Scalars['Int']>;
  discountOverridePrice?: InputMaybe<Scalars['Int']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  limitPerUser?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  publishingStatus?: InputMaybe<PromocodePublishingStatus>;
  quantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromocodeCreateManyParentCommunityInputEnvelope = {
  data?: InputMaybe<Array<PromocodeCreateManyParentCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PromocodeCreateNestedManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromocodeCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<PromocodeCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<PromocodeCreateManyParentCommunityInputEnvelope>;
};

export type PromocodeCreateNestedManyWithoutProductsInput = {
  connect?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromocodeCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<PromocodeCreateWithoutProductsInput>>;
};

export type PromocodeCreateNestedOneWithoutPurchasesInput = {
  connect?: InputMaybe<PromocodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromocodeCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<PromocodeCreateWithoutPurchasesInput>;
};

export type PromocodeCreateOrConnectWithoutParentCommunityInput = {
  create: PromocodeCreateWithoutParentCommunityInput;
  where: PromocodeWhereUniqueInput;
};

export type PromocodeCreateOrConnectWithoutProductsInput = {
  create: PromocodeCreateWithoutProductsInput;
  where: PromocodeWhereUniqueInput;
};

export type PromocodeCreateOrConnectWithoutPurchasesInput = {
  create: PromocodeCreateWithoutPurchasesInput;
  where: PromocodeWhereUniqueInput;
};

export type PromocodeCreateWithoutParentCommunityInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountFixed?: InputMaybe<Scalars['Int']>;
  discountOverridePrice?: InputMaybe<Scalars['Int']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  limitPerUser?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<ProductCreateNestedManyWithoutPromocodesInput>;
  publishingStatus?: InputMaybe<PromocodePublishingStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutPromocodeInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromocodeCreateWithoutProductsInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountFixed?: InputMaybe<Scalars['Int']>;
  discountOverridePrice?: InputMaybe<Scalars['Int']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  limitPerUser?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutPromocodesInput>;
  publishingStatus?: InputMaybe<PromocodePublishingStatus>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutPromocodeInput>;
  quantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromocodeCreateWithoutPurchasesInput = {
  code?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discountFixed?: InputMaybe<Scalars['Int']>;
  discountOverridePrice?: InputMaybe<Scalars['Int']>;
  discountPercentage?: InputMaybe<Scalars['Int']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  limitPerUser?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  parentCommunity?: InputMaybe<CommunityCreateNestedOneWithoutPromocodesInput>;
  products?: InputMaybe<ProductCreateNestedManyWithoutPromocodesInput>;
  publishingStatus?: InputMaybe<PromocodePublishingStatus>;
  quantity?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PromocodeListRelationFilter = {
  every?: InputMaybe<PromocodeWhereInput>;
  none?: InputMaybe<PromocodeWhereInput>;
  some?: InputMaybe<PromocodeWhereInput>;
};

export type PromocodeOrderByInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  discountFixed?: InputMaybe<SortOrder>;
  discountOverridePrice?: InputMaybe<SortOrder>;
  discountPercentage?: InputMaybe<SortOrder>;
  endDate?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  limitPerUser?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentCommunityId?: InputMaybe<SortOrder>;
  publishingStatus?: InputMaybe<SortOrder>;
  quantity?: InputMaybe<SortOrder>;
  startDate?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum PromocodePublishingStatus {
  Deleted = 'Deleted',
  Draft = 'Draft',
  Published = 'Published',
  UneditedDraft = 'UneditedDraft'
}

export type PromocodeScalarWhereInput = {
  AND?: InputMaybe<Array<PromocodeScalarWhereInput>>;
  NOT?: InputMaybe<Array<PromocodeScalarWhereInput>>;
  OR?: InputMaybe<Array<PromocodeScalarWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  discountFixed?: InputMaybe<IntNullableFilter>;
  discountOverridePrice?: InputMaybe<IntNullableFilter>;
  discountPercentage?: InputMaybe<IntNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  limitPerUser?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  parentCommunityId?: InputMaybe<StringNullableFilter>;
  publishingStatus?: InputMaybe<EnumPromocodePublishingStatusFilter>;
  quantity?: InputMaybe<IntNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PromocodeUpdateInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountFixed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountOverridePrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountPercentage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limitPerUser?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutPromocodesInput>;
  products?: InputMaybe<ProductUpdateManyWithoutPromocodesInput>;
  publishingStatus?: InputMaybe<EnumPromocodePublishingStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutPromocodeInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromocodeUpdateManyMutationInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountFixed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountOverridePrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountPercentage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limitPerUser?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  publishingStatus?: InputMaybe<EnumPromocodePublishingStatusFieldUpdateOperationsInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromocodeUpdateManyWithWhereWithoutParentCommunityInput = {
  data: PromocodeUpdateManyMutationInput;
  where: PromocodeScalarWhereInput;
};

export type PromocodeUpdateManyWithWhereWithoutProductsInput = {
  data: PromocodeUpdateManyMutationInput;
  where: PromocodeScalarWhereInput;
};

export type PromocodeUpdateManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromocodeCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<PromocodeCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<PromocodeCreateManyParentCommunityInputEnvelope>;
  delete?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromocodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  set?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  update?: InputMaybe<Array<PromocodeUpdateWithWhereUniqueWithoutParentCommunityInput>>;
  updateMany?: InputMaybe<Array<PromocodeUpdateManyWithWhereWithoutParentCommunityInput>>;
  upsert?: InputMaybe<Array<PromocodeUpsertWithWhereUniqueWithoutParentCommunityInput>>;
};

export type PromocodeUpdateManyWithoutProductsInput = {
  connect?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PromocodeCreateOrConnectWithoutProductsInput>>;
  create?: InputMaybe<Array<PromocodeCreateWithoutProductsInput>>;
  delete?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PromocodeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  set?: InputMaybe<Array<PromocodeWhereUniqueInput>>;
  update?: InputMaybe<Array<PromocodeUpdateWithWhereUniqueWithoutProductsInput>>;
  updateMany?: InputMaybe<Array<PromocodeUpdateManyWithWhereWithoutProductsInput>>;
  upsert?: InputMaybe<Array<PromocodeUpsertWithWhereUniqueWithoutProductsInput>>;
};

export type PromocodeUpdateOneWithoutPurchasesInput = {
  connect?: InputMaybe<PromocodeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PromocodeCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<PromocodeCreateWithoutPurchasesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PromocodeUpdateWithoutPurchasesInput>;
  upsert?: InputMaybe<PromocodeUpsertWithoutPurchasesInput>;
};

export type PromocodeUpdateWithWhereUniqueWithoutParentCommunityInput = {
  data: PromocodeUpdateWithoutParentCommunityInput;
  where: PromocodeWhereUniqueInput;
};

export type PromocodeUpdateWithWhereUniqueWithoutProductsInput = {
  data: PromocodeUpdateWithoutProductsInput;
  where: PromocodeWhereUniqueInput;
};

export type PromocodeUpdateWithoutParentCommunityInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountFixed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountOverridePrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountPercentage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limitPerUser?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutPromocodesInput>;
  publishingStatus?: InputMaybe<EnumPromocodePublishingStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutPromocodeInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromocodeUpdateWithoutProductsInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountFixed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountOverridePrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountPercentage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limitPerUser?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutPromocodesInput>;
  publishingStatus?: InputMaybe<EnumPromocodePublishingStatusFieldUpdateOperationsInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutPromocodeInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromocodeUpdateWithoutPurchasesInput = {
  code?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  discountFixed?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountOverridePrice?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  discountPercentage?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  endDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  limitPerUser?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneWithoutPromocodesInput>;
  products?: InputMaybe<ProductUpdateManyWithoutPromocodesInput>;
  publishingStatus?: InputMaybe<EnumPromocodePublishingStatusFieldUpdateOperationsInput>;
  quantity?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  startDate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PromocodeUpsertWithWhereUniqueWithoutParentCommunityInput = {
  create: PromocodeCreateWithoutParentCommunityInput;
  update: PromocodeUpdateWithoutParentCommunityInput;
  where: PromocodeWhereUniqueInput;
};

export type PromocodeUpsertWithWhereUniqueWithoutProductsInput = {
  create: PromocodeCreateWithoutProductsInput;
  update: PromocodeUpdateWithoutProductsInput;
  where: PromocodeWhereUniqueInput;
};

export type PromocodeUpsertWithoutPurchasesInput = {
  create: PromocodeCreateWithoutPurchasesInput;
  update: PromocodeUpdateWithoutPurchasesInput;
};

export type PromocodeUsageDailyData = {
  __typename?: 'PromocodeUsageDailyData';
  count?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Date']>;
};

export type PromocodeWhereInput = {
  AND?: InputMaybe<Array<PromocodeWhereInput>>;
  NOT?: InputMaybe<Array<PromocodeWhereInput>>;
  OR?: InputMaybe<Array<PromocodeWhereInput>>;
  code?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  discountFixed?: InputMaybe<IntNullableFilter>;
  discountOverridePrice?: InputMaybe<IntNullableFilter>;
  discountPercentage?: InputMaybe<IntNullableFilter>;
  endDate?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  limitPerUser?: InputMaybe<IntNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  parentCommunity?: InputMaybe<CommunityWhereInput>;
  parentCommunityId?: InputMaybe<StringNullableFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  publishingStatus?: InputMaybe<EnumPromocodePublishingStatusFilter>;
  purchases?: InputMaybe<PurchaseListRelationFilter>;
  quantity?: InputMaybe<IntNullableFilter>;
  startDate?: InputMaybe<DateTimeNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PromocodeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Purchase = {
  __typename?: 'Purchase';
  amount: Scalars['Float'];
  badge?: Maybe<ClaimedBadge>;
  code?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  discount: Scalars['Float'];
  emailSent: Scalars['Boolean'];
  fee: Scalars['Float'];
  fromWeb: Scalars['Boolean'];
  hasBeenUsed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isCheckedIn?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  number: Scalars['Int'];
  owner: User;
  ownerId: Scalars['String'];
  payee?: Maybe<Scalars['String']>;
  paymentMethod: PurchasePaymentMethod;
  price: Scalars['Float'];
  product: Product;
  productId: Scalars['String'];
  promocode?: Maybe<Promocode>;
  purchaserId: Scalars['String'];
  refundedAmount: Scalars['Float'];
  sendQR: Scalars['Boolean'];
  status: PurchaseStatus;
  statusMessage?: Maybe<Scalars['String']>;
  swapPrice?: Maybe<Scalars['Float']>;
  transferHistory: Array<PurchaseTransfer>;
  transferredTo?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  usedAt?: Maybe<Scalars['DateTime']>;
  with3ds: Scalars['Boolean'];
};


export type PurchaseTransferHistoryArgs = {
  cursor?: InputMaybe<PurchaseTransferWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseTransferWhereInput>;
};

export type PurchaseCreateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  badge?: InputMaybe<ClaimedBadgeCreateNestedOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  owner: UserCreateNestedOneWithoutPurchasesInput;
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutPurchasesInput;
  promocode?: InputMaybe<PromocodeCreateNestedOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreaterelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  transferHistory?: InputMaybe<PurchaseTransferCreateNestedManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManyOwnerInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['String'];
  promocodeId?: InputMaybe<Scalars['String']>;
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreateManyrelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManyOwnerInputEnvelope = {
  data?: InputMaybe<Array<PurchaseCreateManyOwnerInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManyProductInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  ownerId: Scalars['String'];
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  promocodeId?: InputMaybe<Scalars['String']>;
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreateManyrelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManyProductInputEnvelope = {
  data?: InputMaybe<Array<PurchaseCreateManyProductInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManyPromocodeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  ownerId: Scalars['String'];
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  productId: Scalars['String'];
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreateManyrelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManyPromocodeInputEnvelope = {
  data?: InputMaybe<Array<PurchaseCreateManyPromocodeInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateManyrelatedPurchasesInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PurchaseCreateNestedManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<PurchaseCreateManyOwnerInputEnvelope>;
};

export type PurchaseCreateNestedManyWithoutProductInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutProductInput>>;
  createMany?: InputMaybe<PurchaseCreateManyProductInputEnvelope>;
};

export type PurchaseCreateNestedManyWithoutPromocodeInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutPromocodeInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutPromocodeInput>>;
  createMany?: InputMaybe<PurchaseCreateManyPromocodeInputEnvelope>;
};

export type PurchaseCreateNestedOneWithoutBadgeInput = {
  connect?: InputMaybe<PurchaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseCreateOrConnectWithoutBadgeInput>;
  create?: InputMaybe<PurchaseCreateWithoutBadgeInput>;
};

export type PurchaseCreateNestedOneWithoutTransferHistoryInput = {
  connect?: InputMaybe<PurchaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseCreateOrConnectWithoutTransferHistoryInput>;
  create?: InputMaybe<PurchaseCreateWithoutTransferHistoryInput>;
};

export type PurchaseCreateOrConnectWithoutBadgeInput = {
  create: PurchaseCreateWithoutBadgeInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseCreateOrConnectWithoutOwnerInput = {
  create: PurchaseCreateWithoutOwnerInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseCreateOrConnectWithoutProductInput = {
  create: PurchaseCreateWithoutProductInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseCreateOrConnectWithoutPromocodeInput = {
  create: PurchaseCreateWithoutPromocodeInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseCreateOrConnectWithoutTransferHistoryInput = {
  create: PurchaseCreateWithoutTransferHistoryInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseCreateWithoutBadgeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  owner: UserCreateNestedOneWithoutPurchasesInput;
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutPurchasesInput;
  promocode?: InputMaybe<PromocodeCreateNestedOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreaterelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  transferHistory?: InputMaybe<PurchaseTransferCreateNestedManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateWithoutOwnerInput = {
  amount?: InputMaybe<Scalars['Float']>;
  badge?: InputMaybe<ClaimedBadgeCreateNestedOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutPurchasesInput;
  promocode?: InputMaybe<PromocodeCreateNestedOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreaterelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  transferHistory?: InputMaybe<PurchaseTransferCreateNestedManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateWithoutProductInput = {
  amount?: InputMaybe<Scalars['Float']>;
  badge?: InputMaybe<ClaimedBadgeCreateNestedOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  owner: UserCreateNestedOneWithoutPurchasesInput;
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  promocode?: InputMaybe<PromocodeCreateNestedOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreaterelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  transferHistory?: InputMaybe<PurchaseTransferCreateNestedManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateWithoutPromocodeInput = {
  amount?: InputMaybe<Scalars['Float']>;
  badge?: InputMaybe<ClaimedBadgeCreateNestedOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  owner: UserCreateNestedOneWithoutPurchasesInput;
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutPurchasesInput;
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreaterelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  transferHistory?: InputMaybe<PurchaseTransferCreateNestedManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreateWithoutTransferHistoryInput = {
  amount?: InputMaybe<Scalars['Float']>;
  badge?: InputMaybe<ClaimedBadgeCreateNestedOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  currency?: InputMaybe<SupportedCurrencies>;
  discount?: InputMaybe<Scalars['Float']>;
  emailSent?: InputMaybe<Scalars['Boolean']>;
  fee?: InputMaybe<Scalars['Float']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['Int']>;
  owner: UserCreateNestedOneWithoutPurchasesInput;
  payee?: InputMaybe<Scalars['String']>;
  paymentMethod?: InputMaybe<PurchasePaymentMethod>;
  paymentMethodChargeId?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  product: ProductCreateNestedOneWithoutPurchasesInput;
  promocode?: InputMaybe<PromocodeCreateNestedOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<Scalars['String']>;
  qrCode?: InputMaybe<Scalars['String']>;
  refundedAmount?: InputMaybe<Scalars['Float']>;
  relatedPurchases?: InputMaybe<PurchaseCreaterelatedPurchasesInput>;
  sendQR?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<PurchaseStatus>;
  statusMessage?: InputMaybe<Scalars['String']>;
  swapPrice?: InputMaybe<Scalars['Float']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  usedAt?: InputMaybe<Scalars['DateTime']>;
  with3ds?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseCreaterelatedPurchasesInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PurchaseListRelationFilter = {
  every?: InputMaybe<PurchaseWhereInput>;
  none?: InputMaybe<PurchaseWhereInput>;
  some?: InputMaybe<PurchaseWhereInput>;
};

export type PurchaseOrderByInput = {
  amount?: InputMaybe<SortOrder>;
  bundleId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  currency?: InputMaybe<SortOrder>;
  discount?: InputMaybe<SortOrder>;
  emailSent?: InputMaybe<SortOrder>;
  fee?: InputMaybe<SortOrder>;
  fromWeb?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  number?: InputMaybe<SortOrder>;
  ownerId?: InputMaybe<SortOrder>;
  payee?: InputMaybe<SortOrder>;
  paymentMethod?: InputMaybe<SortOrder>;
  paymentMethodChargeId?: InputMaybe<SortOrder>;
  price?: InputMaybe<SortOrder>;
  productId?: InputMaybe<SortOrder>;
  promocodeId?: InputMaybe<SortOrder>;
  purchaserId?: InputMaybe<SortOrder>;
  qrCode?: InputMaybe<SortOrder>;
  refundedAmount?: InputMaybe<SortOrder>;
  relatedPurchases?: InputMaybe<SortOrder>;
  sendQR?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  statusMessage?: InputMaybe<SortOrder>;
  swapPrice?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  usedAt?: InputMaybe<SortOrder>;
  with3ds?: InputMaybe<SortOrder>;
};

export enum PurchasePaymentMethod {
  Manual = 'Manual',
  Stripe = 'Stripe',
  StripeCard = 'StripeCard',
  StripeSwish = 'StripeSwish',
  Swish = 'Swish'
}

export type PurchaseScalarWhereInput = {
  AND?: InputMaybe<Array<PurchaseScalarWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseScalarWhereInput>>;
  OR?: InputMaybe<Array<PurchaseScalarWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  bundleId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumSupportedCurrenciesFilter>;
  discount?: InputMaybe<FloatFilter>;
  emailSent?: InputMaybe<BoolFilter>;
  fee?: InputMaybe<FloatFilter>;
  fromWeb?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringNullableFilter>;
  number?: InputMaybe<IntFilter>;
  ownerId?: InputMaybe<StringFilter>;
  payee?: InputMaybe<StringNullableFilter>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFilter>;
  paymentMethodChargeId?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<FloatFilter>;
  productId?: InputMaybe<StringFilter>;
  promocodeId?: InputMaybe<StringNullableFilter>;
  purchaserId?: InputMaybe<StringFilter>;
  qrCode?: InputMaybe<StringNullableFilter>;
  refundedAmount?: InputMaybe<FloatFilter>;
  relatedPurchases?: InputMaybe<StringNullableListFilter>;
  sendQR?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumPurchaseStatusFilter>;
  statusMessage?: InputMaybe<StringNullableFilter>;
  swapPrice?: InputMaybe<FloatNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usedAt?: InputMaybe<DateTimeNullableFilter>;
  with3ds?: InputMaybe<BoolFilter>;
};

export enum PurchaseStatus {
  Failed = 'Failed',
  Paid = 'Paid',
  Processing = 'Processing',
  Refunded = 'Refunded'
}

export type PurchaseTransfer = {
  __typename?: 'PurchaseTransfer';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  purchase?: Maybe<Purchase>;
  recipient?: Maybe<User>;
  sender: User;
  updatedAt: Scalars['DateTime'];
};

export type PurchaseTransferCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  purchase?: InputMaybe<PurchaseCreateNestedOneWithoutTransferHistoryInput>;
  recipient?: InputMaybe<UserCreateNestedOneWithoutPurchaseTransfersWhereRecipientInput>;
  sender: UserCreateNestedOneWithoutPurchaseTransfersWhereSenderInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseTransferCreateManyPurchaseInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  recipientId?: InputMaybe<Scalars['String']>;
  senderId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseTransferCreateManyPurchaseInputEnvelope = {
  data?: InputMaybe<Array<PurchaseTransferCreateManyPurchaseInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseTransferCreateManyRecipientInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  purchaseId?: InputMaybe<Scalars['String']>;
  senderId: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseTransferCreateManyRecipientInputEnvelope = {
  data?: InputMaybe<Array<PurchaseTransferCreateManyRecipientInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseTransferCreateManySenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  purchaseId?: InputMaybe<Scalars['String']>;
  recipientId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseTransferCreateManySenderInputEnvelope = {
  data?: InputMaybe<Array<PurchaseTransferCreateManySenderInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PurchaseTransferCreateNestedManyWithoutPurchaseInput = {
  connect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseTransferCreateOrConnectWithoutPurchaseInput>>;
  create?: InputMaybe<Array<PurchaseTransferCreateWithoutPurchaseInput>>;
  createMany?: InputMaybe<PurchaseTransferCreateManyPurchaseInputEnvelope>;
};

export type PurchaseTransferCreateNestedManyWithoutRecipientInput = {
  connect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseTransferCreateOrConnectWithoutRecipientInput>>;
  create?: InputMaybe<Array<PurchaseTransferCreateWithoutRecipientInput>>;
  createMany?: InputMaybe<PurchaseTransferCreateManyRecipientInputEnvelope>;
};

export type PurchaseTransferCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseTransferCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<PurchaseTransferCreateWithoutSenderInput>>;
  createMany?: InputMaybe<PurchaseTransferCreateManySenderInputEnvelope>;
};

export type PurchaseTransferCreateOrConnectWithoutPurchaseInput = {
  create: PurchaseTransferCreateWithoutPurchaseInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferCreateOrConnectWithoutRecipientInput = {
  create: PurchaseTransferCreateWithoutRecipientInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferCreateOrConnectWithoutSenderInput = {
  create: PurchaseTransferCreateWithoutSenderInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferCreateWithoutPurchaseInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  recipient?: InputMaybe<UserCreateNestedOneWithoutPurchaseTransfersWhereRecipientInput>;
  sender: UserCreateNestedOneWithoutPurchaseTransfersWhereSenderInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseTransferCreateWithoutRecipientInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  purchase?: InputMaybe<PurchaseCreateNestedOneWithoutTransferHistoryInput>;
  sender: UserCreateNestedOneWithoutPurchaseTransfersWhereSenderInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseTransferCreateWithoutSenderInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  purchase?: InputMaybe<PurchaseCreateNestedOneWithoutTransferHistoryInput>;
  recipient?: InputMaybe<UserCreateNestedOneWithoutPurchaseTransfersWhereRecipientInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseTransferListRelationFilter = {
  every?: InputMaybe<PurchaseTransferWhereInput>;
  none?: InputMaybe<PurchaseTransferWhereInput>;
  some?: InputMaybe<PurchaseTransferWhereInput>;
};

export type PurchaseTransferOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  purchaseId?: InputMaybe<SortOrder>;
  recipientId?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PurchaseTransferScalarWhereInput = {
  AND?: InputMaybe<Array<PurchaseTransferScalarWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseTransferScalarWhereInput>>;
  OR?: InputMaybe<Array<PurchaseTransferScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  purchaseId?: InputMaybe<StringNullableFilter>;
  recipientId?: InputMaybe<StringNullableFilter>;
  senderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PurchaseTransferUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  purchase?: InputMaybe<PurchaseUpdateOneWithoutTransferHistoryInput>;
  recipient?: InputMaybe<UserUpdateOneWithoutPurchaseTransfersWhereRecipientInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseTransfersWhereSenderInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PurchaseTransferUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PurchaseTransferUpdateManyWithWhereWithoutPurchaseInput = {
  data: PurchaseTransferUpdateManyMutationInput;
  where: PurchaseTransferScalarWhereInput;
};

export type PurchaseTransferUpdateManyWithWhereWithoutRecipientInput = {
  data: PurchaseTransferUpdateManyMutationInput;
  where: PurchaseTransferScalarWhereInput;
};

export type PurchaseTransferUpdateManyWithWhereWithoutSenderInput = {
  data: PurchaseTransferUpdateManyMutationInput;
  where: PurchaseTransferScalarWhereInput;
};

export type PurchaseTransferUpdateManyWithoutPurchaseInput = {
  connect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseTransferCreateOrConnectWithoutPurchaseInput>>;
  create?: InputMaybe<Array<PurchaseTransferCreateWithoutPurchaseInput>>;
  createMany?: InputMaybe<PurchaseTransferCreateManyPurchaseInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseTransferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseTransferUpdateWithWhereUniqueWithoutPurchaseInput>>;
  updateMany?: InputMaybe<Array<PurchaseTransferUpdateManyWithWhereWithoutPurchaseInput>>;
  upsert?: InputMaybe<Array<PurchaseTransferUpsertWithWhereUniqueWithoutPurchaseInput>>;
};

export type PurchaseTransferUpdateManyWithoutRecipientInput = {
  connect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseTransferCreateOrConnectWithoutRecipientInput>>;
  create?: InputMaybe<Array<PurchaseTransferCreateWithoutRecipientInput>>;
  createMany?: InputMaybe<PurchaseTransferCreateManyRecipientInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseTransferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseTransferUpdateWithWhereUniqueWithoutRecipientInput>>;
  updateMany?: InputMaybe<Array<PurchaseTransferUpdateManyWithWhereWithoutRecipientInput>>;
  upsert?: InputMaybe<Array<PurchaseTransferUpsertWithWhereUniqueWithoutRecipientInput>>;
};

export type PurchaseTransferUpdateManyWithoutSenderInput = {
  connect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseTransferCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<PurchaseTransferCreateWithoutSenderInput>>;
  createMany?: InputMaybe<PurchaseTransferCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseTransferScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseTransferWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseTransferUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<PurchaseTransferUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<PurchaseTransferUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type PurchaseTransferUpdateWithWhereUniqueWithoutPurchaseInput = {
  data: PurchaseTransferUpdateWithoutPurchaseInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferUpdateWithWhereUniqueWithoutRecipientInput = {
  data: PurchaseTransferUpdateWithoutRecipientInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferUpdateWithWhereUniqueWithoutSenderInput = {
  data: PurchaseTransferUpdateWithoutSenderInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferUpdateWithoutPurchaseInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  recipient?: InputMaybe<UserUpdateOneWithoutPurchaseTransfersWhereRecipientInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseTransfersWhereSenderInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PurchaseTransferUpdateWithoutRecipientInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  purchase?: InputMaybe<PurchaseUpdateOneWithoutTransferHistoryInput>;
  sender?: InputMaybe<UserUpdateOneRequiredWithoutPurchaseTransfersWhereSenderInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PurchaseTransferUpdateWithoutSenderInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  purchase?: InputMaybe<PurchaseUpdateOneWithoutTransferHistoryInput>;
  recipient?: InputMaybe<UserUpdateOneWithoutPurchaseTransfersWhereRecipientInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type PurchaseTransferUpsertWithWhereUniqueWithoutPurchaseInput = {
  create: PurchaseTransferCreateWithoutPurchaseInput;
  update: PurchaseTransferUpdateWithoutPurchaseInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferUpsertWithWhereUniqueWithoutRecipientInput = {
  create: PurchaseTransferCreateWithoutRecipientInput;
  update: PurchaseTransferUpdateWithoutRecipientInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferUpsertWithWhereUniqueWithoutSenderInput = {
  create: PurchaseTransferCreateWithoutSenderInput;
  update: PurchaseTransferUpdateWithoutSenderInput;
  where: PurchaseTransferWhereUniqueInput;
};

export type PurchaseTransferWhereInput = {
  AND?: InputMaybe<Array<PurchaseTransferWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseTransferWhereInput>>;
  OR?: InputMaybe<Array<PurchaseTransferWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  purchase?: InputMaybe<PurchaseWhereInput>;
  purchaseId?: InputMaybe<StringNullableFilter>;
  recipient?: InputMaybe<UserWhereInput>;
  recipientId?: InputMaybe<StringNullableFilter>;
  sender?: InputMaybe<UserWhereInput>;
  senderId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PurchaseTransferWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PurchaseUpdateInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  badge?: InputMaybe<ClaimedBadgeUpdateOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  discount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fee?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutPurchasesInput>;
  payee?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodChargeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPurchasesInput>;
  promocode?: InputMaybe<PromocodeUpdateOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  qrCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  relatedPurchases?: InputMaybe<PurchaseUpdaterelatedPurchasesInput>;
  sendQR?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPurchaseStatusFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  swapPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferHistory?: InputMaybe<PurchaseTransferUpdateManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  usedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  with3ds?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PurchaseUpdateManyMutationInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bundleId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  discount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fee?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  payee?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodChargeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  purchaserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  qrCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  relatedPurchases?: InputMaybe<PurchaseUpdaterelatedPurchasesInput>;
  sendQR?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPurchaseStatusFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  swapPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  usedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  with3ds?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PurchaseUpdateManyWithWhereWithoutOwnerInput = {
  data: PurchaseUpdateManyMutationInput;
  where: PurchaseScalarWhereInput;
};

export type PurchaseUpdateManyWithWhereWithoutProductInput = {
  data: PurchaseUpdateManyMutationInput;
  where: PurchaseScalarWhereInput;
};

export type PurchaseUpdateManyWithWhereWithoutPromocodeInput = {
  data: PurchaseUpdateManyMutationInput;
  where: PurchaseScalarWhereInput;
};

export type PurchaseUpdateManyWithoutOwnerInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutOwnerInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutOwnerInput>>;
  createMany?: InputMaybe<PurchaseCreateManyOwnerInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseUpdateWithWhereUniqueWithoutOwnerInput>>;
  updateMany?: InputMaybe<Array<PurchaseUpdateManyWithWhereWithoutOwnerInput>>;
  upsert?: InputMaybe<Array<PurchaseUpsertWithWhereUniqueWithoutOwnerInput>>;
};

export type PurchaseUpdateManyWithoutProductInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutProductInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutProductInput>>;
  createMany?: InputMaybe<PurchaseCreateManyProductInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseUpdateWithWhereUniqueWithoutProductInput>>;
  updateMany?: InputMaybe<Array<PurchaseUpdateManyWithWhereWithoutProductInput>>;
  upsert?: InputMaybe<Array<PurchaseUpsertWithWhereUniqueWithoutProductInput>>;
};

export type PurchaseUpdateManyWithoutPromocodeInput = {
  connect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PurchaseCreateOrConnectWithoutPromocodeInput>>;
  create?: InputMaybe<Array<PurchaseCreateWithoutPromocodeInput>>;
  createMany?: InputMaybe<PurchaseCreateManyPromocodeInputEnvelope>;
  delete?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PurchaseScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  set?: InputMaybe<Array<PurchaseWhereUniqueInput>>;
  update?: InputMaybe<Array<PurchaseUpdateWithWhereUniqueWithoutPromocodeInput>>;
  updateMany?: InputMaybe<Array<PurchaseUpdateManyWithWhereWithoutPromocodeInput>>;
  upsert?: InputMaybe<Array<PurchaseUpsertWithWhereUniqueWithoutPromocodeInput>>;
};

export type PurchaseUpdateOneWithoutBadgeInput = {
  connect?: InputMaybe<PurchaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseCreateOrConnectWithoutBadgeInput>;
  create?: InputMaybe<PurchaseCreateWithoutBadgeInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PurchaseUpdateWithoutBadgeInput>;
  upsert?: InputMaybe<PurchaseUpsertWithoutBadgeInput>;
};

export type PurchaseUpdateOneWithoutTransferHistoryInput = {
  connect?: InputMaybe<PurchaseWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PurchaseCreateOrConnectWithoutTransferHistoryInput>;
  create?: InputMaybe<PurchaseCreateWithoutTransferHistoryInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PurchaseUpdateWithoutTransferHistoryInput>;
  upsert?: InputMaybe<PurchaseUpsertWithoutTransferHistoryInput>;
};

export type PurchaseUpdateWithWhereUniqueWithoutOwnerInput = {
  data: PurchaseUpdateWithoutOwnerInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseUpdateWithWhereUniqueWithoutProductInput = {
  data: PurchaseUpdateWithoutProductInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseUpdateWithWhereUniqueWithoutPromocodeInput = {
  data: PurchaseUpdateWithoutPromocodeInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseUpdateWithoutBadgeInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  bundleId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  discount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fee?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutPurchasesInput>;
  payee?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodChargeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPurchasesInput>;
  promocode?: InputMaybe<PromocodeUpdateOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  qrCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  relatedPurchases?: InputMaybe<PurchaseUpdaterelatedPurchasesInput>;
  sendQR?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPurchaseStatusFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  swapPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferHistory?: InputMaybe<PurchaseTransferUpdateManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  usedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  with3ds?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PurchaseUpdateWithoutOwnerInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  badge?: InputMaybe<ClaimedBadgeUpdateOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  discount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fee?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  payee?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodChargeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPurchasesInput>;
  promocode?: InputMaybe<PromocodeUpdateOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  qrCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  relatedPurchases?: InputMaybe<PurchaseUpdaterelatedPurchasesInput>;
  sendQR?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPurchaseStatusFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  swapPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferHistory?: InputMaybe<PurchaseTransferUpdateManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  usedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  with3ds?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PurchaseUpdateWithoutProductInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  badge?: InputMaybe<ClaimedBadgeUpdateOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  discount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fee?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutPurchasesInput>;
  payee?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodChargeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  promocode?: InputMaybe<PromocodeUpdateOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  qrCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  relatedPurchases?: InputMaybe<PurchaseUpdaterelatedPurchasesInput>;
  sendQR?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPurchaseStatusFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  swapPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferHistory?: InputMaybe<PurchaseTransferUpdateManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  usedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  with3ds?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PurchaseUpdateWithoutPromocodeInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  badge?: InputMaybe<ClaimedBadgeUpdateOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  discount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fee?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutPurchasesInput>;
  payee?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodChargeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPurchasesInput>;
  purchaserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  qrCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  relatedPurchases?: InputMaybe<PurchaseUpdaterelatedPurchasesInput>;
  sendQR?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPurchaseStatusFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  swapPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  transferHistory?: InputMaybe<PurchaseTransferUpdateManyWithoutPurchaseInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  usedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  with3ds?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PurchaseUpdateWithoutTransferHistoryInput = {
  amount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  badge?: InputMaybe<ClaimedBadgeUpdateOneWithoutPurchaseInput>;
  bundleId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  currency?: InputMaybe<EnumSupportedCurrenciesFieldUpdateOperationsInput>;
  discount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  emailSent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fee?: InputMaybe<FloatFieldUpdateOperationsInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  number?: InputMaybe<IntFieldUpdateOperationsInput>;
  owner?: InputMaybe<UserUpdateOneRequiredWithoutPurchasesInput>;
  payee?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFieldUpdateOperationsInput>;
  paymentMethodChargeId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  price?: InputMaybe<FloatFieldUpdateOperationsInput>;
  product?: InputMaybe<ProductUpdateOneRequiredWithoutPurchasesInput>;
  promocode?: InputMaybe<PromocodeUpdateOneWithoutPurchasesInput>;
  purchaserId?: InputMaybe<StringFieldUpdateOperationsInput>;
  qrCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  refundedAmount?: InputMaybe<FloatFieldUpdateOperationsInput>;
  relatedPurchases?: InputMaybe<PurchaseUpdaterelatedPurchasesInput>;
  sendQR?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumPurchaseStatusFieldUpdateOperationsInput>;
  statusMessage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  swapPrice?: InputMaybe<NullableFloatFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  usedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  with3ds?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type PurchaseUpdaterelatedPurchasesInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PurchaseUpsertWithWhereUniqueWithoutOwnerInput = {
  create: PurchaseCreateWithoutOwnerInput;
  update: PurchaseUpdateWithoutOwnerInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseUpsertWithWhereUniqueWithoutProductInput = {
  create: PurchaseCreateWithoutProductInput;
  update: PurchaseUpdateWithoutProductInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseUpsertWithWhereUniqueWithoutPromocodeInput = {
  create: PurchaseCreateWithoutPromocodeInput;
  update: PurchaseUpdateWithoutPromocodeInput;
  where: PurchaseWhereUniqueInput;
};

export type PurchaseUpsertWithoutBadgeInput = {
  create: PurchaseCreateWithoutBadgeInput;
  update: PurchaseUpdateWithoutBadgeInput;
};

export type PurchaseUpsertWithoutTransferHistoryInput = {
  create: PurchaseCreateWithoutTransferHistoryInput;
  update: PurchaseUpdateWithoutTransferHistoryInput;
};

export type PurchaseWhereInput = {
  AND?: InputMaybe<Array<PurchaseWhereInput>>;
  NOT?: InputMaybe<Array<PurchaseWhereInput>>;
  OR?: InputMaybe<Array<PurchaseWhereInput>>;
  amount?: InputMaybe<FloatFilter>;
  badge?: InputMaybe<ClaimedBadgeWhereInput>;
  bundleId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<EnumSupportedCurrenciesFilter>;
  discount?: InputMaybe<FloatFilter>;
  emailSent?: InputMaybe<BoolFilter>;
  fee?: InputMaybe<FloatFilter>;
  fromWeb?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringNullableFilter>;
  number?: InputMaybe<IntFilter>;
  owner?: InputMaybe<UserWhereInput>;
  ownerId?: InputMaybe<StringFilter>;
  payee?: InputMaybe<StringNullableFilter>;
  paymentMethod?: InputMaybe<EnumPurchasePaymentMethodFilter>;
  paymentMethodChargeId?: InputMaybe<StringNullableFilter>;
  price?: InputMaybe<FloatFilter>;
  product?: InputMaybe<ProductWhereInput>;
  productId?: InputMaybe<StringFilter>;
  promocode?: InputMaybe<PromocodeWhereInput>;
  promocodeId?: InputMaybe<StringNullableFilter>;
  purchaserId?: InputMaybe<StringFilter>;
  qrCode?: InputMaybe<StringNullableFilter>;
  refundedAmount?: InputMaybe<FloatFilter>;
  relatedPurchases?: InputMaybe<StringNullableListFilter>;
  sendQR?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumPurchaseStatusFilter>;
  statusMessage?: InputMaybe<StringNullableFilter>;
  swapPrice?: InputMaybe<FloatNullableFilter>;
  transferHistory?: InputMaybe<PurchaseTransferListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  usedAt?: InputMaybe<DateTimeNullableFilter>;
  with3ds?: InputMaybe<BoolFilter>;
};

export type PurchaseWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type PurchasesResponse = {
  __typename?: 'PurchasesResponse';
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  purchases?: Maybe<Array<Maybe<Purchase>>>;
};

export type Query = {
  __typename?: 'Query';
  adminActivityEvent?: Maybe<ActivityEvent>;
  adminActivityEvents: Array<ActivityEvent>;
  adminActivityEventsCount?: Maybe<Scalars['Int']>;
  adminCatalogItem?: Maybe<CatalogItem>;
  adminCatalogItemCategories: Array<CatalogItemCategory>;
  adminCatalogItemCategoriesCount?: Maybe<Scalars['Int']>;
  adminCatalogItemCategory?: Maybe<CatalogItemCategory>;
  adminCatalogItemSchedule?: Maybe<CatalogItemSchedule>;
  adminCatalogItemSchedules: Array<CatalogItemSchedule>;
  adminCatalogItemSchedulesCount?: Maybe<Scalars['Int']>;
  adminCatalogItems: Array<CatalogItem>;
  adminCatalogItemsCount?: Maybe<Scalars['Int']>;
  adminClaimedBadge?: Maybe<ClaimedBadge>;
  adminClaimedBadges: Array<ClaimedBadge>;
  adminClaimedBadgesCount?: Maybe<Scalars['Int']>;
  adminCommunities: Array<Community>;
  adminCommunitiesCount?: Maybe<Scalars['Int']>;
  adminCommunity?: Maybe<Community>;
  adminCommunityMemberInfo?: Maybe<CommunityMemberInfo>;
  adminCommunityMemberInfos: Array<CommunityMemberInfo>;
  adminCommunityMemberInfosCount?: Maybe<Scalars['Int']>;
  adminConfiguration?: Maybe<Configuration>;
  adminConfigurations: Array<Configuration>;
  adminConfigurationsCount?: Maybe<Scalars['Int']>;
  adminEvent?: Maybe<Event>;
  adminEventCategories: Array<EventCategory>;
  adminEventCategoriesCount?: Maybe<Scalars['Int']>;
  adminEventCategory?: Maybe<EventCategory>;
  adminEvents: Array<Event>;
  adminEventsCount?: Maybe<Scalars['Int']>;
  adminFaqCategories: Array<FaqCategory>;
  adminFaqCategoriesCount?: Maybe<Scalars['Int']>;
  adminFaqCategory?: Maybe<FaqCategory>;
  adminFaqItem?: Maybe<FaqItem>;
  adminFaqItems: Array<FaqItem>;
  adminFaqItemsCount?: Maybe<Scalars['Int']>;
  adminFeedItem?: Maybe<FeedItem>;
  adminFeedItemComment?: Maybe<FeedItemComment>;
  adminFeedItemCommentReport?: Maybe<FeedItemCommentReport>;
  adminFeedItemCommentReports: Array<FeedItemCommentReport>;
  adminFeedItemCommentReportsCount?: Maybe<Scalars['Int']>;
  adminFeedItemComments: Array<FeedItemComment>;
  adminFeedItemCommentsCount?: Maybe<Scalars['Int']>;
  adminFeedItems: Array<FeedItem>;
  adminFeedItemsCount?: Maybe<Scalars['Int']>;
  adminForumComment?: Maybe<ForumComment>;
  adminForumComments: Array<ForumComment>;
  adminForumCommentsCount?: Maybe<Scalars['Int']>;
  adminForumContentReport?: Maybe<ForumContentReport>;
  adminForumContentReports: Array<ForumContentReport>;
  adminForumContentReportsCount?: Maybe<Scalars['Int']>;
  adminForumPost?: Maybe<ForumPost>;
  adminForumPosts: Array<ForumPost>;
  adminForumPostsCount?: Maybe<Scalars['Int']>;
  adminForumTag?: Maybe<ForumTag>;
  adminForumTags: Array<ForumTag>;
  adminForumTagsCount?: Maybe<Scalars['Int']>;
  adminGuestListProducts?: Maybe<Array<Product>>;
  adminInviteCode?: Maybe<InviteCode>;
  adminInviteCodes: Array<InviteCode>;
  adminInviteCodesCount?: Maybe<Scalars['Int']>;
  adminListEventAttendee?: Maybe<AdminListEventAttendee>;
  adminListEventAttendees?: Maybe<EventAttendees>;
  adminListEventInfo?: Maybe<Array<Maybe<EventInfoSection>>>;
  adminLocation?: Maybe<Location>;
  adminLocations: Array<Location>;
  adminLocationsCount?: Maybe<Scalars['Int']>;
  adminMassMessage?: Maybe<MassMessage>;
  adminMassMessages: Array<MassMessage>;
  adminMassMessagesCount?: Maybe<Scalars['Int']>;
  adminModuleConfiguration?: Maybe<ModuleConfiguration>;
  adminModuleConfigurations: Array<ModuleConfiguration>;
  adminModuleConfigurationsCount?: Maybe<Scalars['Int']>;
  adminOpenCall?: Maybe<OpenCall>;
  adminOpenCallSubmission?: Maybe<OpenCallSubmission>;
  adminOpenCallSubmissions: Array<OpenCallSubmission>;
  adminOpenCallSubmissionsCount?: Maybe<Scalars['Int']>;
  adminOpenCalls: Array<OpenCall>;
  adminOpenCallsCount?: Maybe<Scalars['Int']>;
  adminPayout?: Maybe<Payout>;
  adminPayouts: Array<Payout>;
  adminPayoutsCount?: Maybe<Scalars['Int']>;
  adminProduct?: Maybe<Product>;
  adminProducts: Array<Product>;
  adminProductsCount?: Maybe<Scalars['Int']>;
  adminPromocode?: Maybe<Promocode>;
  adminPromocodes: Array<Promocode>;
  adminPromocodesCount?: Maybe<Scalars['Int']>;
  adminPurchase?: Maybe<Purchase>;
  adminPurchaseTransfer?: Maybe<PurchaseTransfer>;
  adminPurchaseTransfers: Array<PurchaseTransfer>;
  adminPurchaseTransfersCount?: Maybe<Scalars['Int']>;
  adminPurchases: Array<Purchase>;
  adminPurchasesCount?: Maybe<Scalars['Int']>;
  adminTalksMessage?: Maybe<TalksMessage>;
  adminTalksMessages: Array<TalksMessage>;
  adminTalksMessagesCount?: Maybe<Scalars['Int']>;
  adminTemplateBadge?: Maybe<TemplateBadge>;
  adminTemplateBadges: Array<TemplateBadge>;
  adminTemplateBadgesCount?: Maybe<Scalars['Int']>;
  adminTrack?: Maybe<Track>;
  adminTracks: Array<Track>;
  adminTracksCount?: Maybe<Scalars['Int']>;
  adminUbiqFeedItem?: Maybe<UbiqFeedItem>;
  adminUbiqFeedItems: Array<UbiqFeedItem>;
  adminUbiqFeedItemsCount?: Maybe<Scalars['Int']>;
  adminUser?: Maybe<User>;
  adminUsers: Array<User>;
  adminUsersCount?: Maybe<Scalars['Int']>;
  adminWaitlistUser?: Maybe<WaitlistUser>;
  adminWaitlistUsers: Array<WaitlistUser>;
  adminWaitlistUsersCount?: Maybe<Scalars['Int']>;
  allUserIDsExist?: Maybe<AllUserIDsExistResponse>;
  announcement?: Maybe<FeedItem>;
  announcements?: Maybe<AnnouncementsReturn>;
  caActiveProducts?: Maybe<ActiveProductsReturn>;
  caAdmins?: Maybe<CaAdminsResponse>;
  caAnnouncement?: Maybe<FeedItem>;
  caAnnouncements?: Maybe<CaAnnouncementsResult>;
  caCommunities?: Maybe<Array<Maybe<Event>>>;
  caCommunityMembers?: Maybe<CaCommunityMembersResponse>;
  caCommunityMembersStats?: Maybe<CaCommunityMembersStatsResponse>;
  caCommunityOverviewStats?: Maybe<CaCommunityOverviewStatsResponse>;
  caCommunityRevenueStats?: Maybe<CaCommunityRevenueStatusResponse>;
  caCommunityTheme?: Maybe<CommunityTheme>;
  caCommunityThemes?: Maybe<CaCommunityThemesResult>;
  caDashboard?: Maybe<CaDashboardResponse>;
  caEvent?: Maybe<CaEventResponse>;
  caEventAnalytics?: Maybe<CaEventAnalyticsResult>;
  caEventCategories?: Maybe<CaEventCategoriesResult>;
  caEventCategory?: Maybe<EventCategory>;
  caEventGuests?: Maybe<CaEventGuestsResult>;
  caEventTicketsActivity?: Maybe<CaEventTicketsActivityResponse>;
  caEventTraction?: Maybe<CaEventTraction>;
  caEvents?: Maybe<CaEventsResult>;
  caFinancesOverview?: Maybe<CaFinancesOverviewResponse>;
  caFinancesPayouts?: Maybe<CaFinancesPayoutsResponse>;
  caForumItems?: Maybe<CaForumItemsReturn>;
  caMassMessage?: Maybe<MassMessage>;
  caMassMessages?: Maybe<CaMassMessagesResult>;
  caPotentialAdmins?: Maybe<CaPotentialAdminsResponse>;
  caProduct?: Maybe<Product>;
  caProductUsageStats?: Maybe<CaProductUsageStatsResponse>;
  caProducts?: Maybe<CaProductsReturn>;
  caPromocode?: Maybe<Promocode>;
  caPromocodeUsageStats?: Maybe<CaPromocodeUsageStatsResponse>;
  caPromocodes?: Maybe<CaPromocodesReturn>;
  caPromocodesRecentUsages?: Maybe<CaPromocodesResentUsagesReturn>;
  caPurchases?: Maybe<CaPurchasesResult>;
  caPurchasesFunnel?: Maybe<CaPurchasesFunnelResponse>;
  caRadioTrack?: Maybe<Track>;
  caRadioTracks?: Maybe<CaRadioTracksResult>;
  caScheduleItem?: Maybe<CatalogItemSchedule>;
  caScheduleItems?: Maybe<CaScheduleItemsResult>;
  caStripeAccountInfo?: Maybe<CaStripeAccountInfoPayload>;
  caTalksChat?: Maybe<CaTalksChatResponse>;
  caTalksChats?: Maybe<CaTalksChatsResponse>;
  calendarEvents?: Maybe<CalendarEventsResponse>;
  calendarUnseenEvents?: Maybe<Scalars['Int']>;
  catalogItemCategories: Array<CatalogItemCategory>;
  catalogItemSchedules: Array<CatalogItemSchedule>;
  catalogItems: Array<CatalogItem>;
  checkoutPostReturn?: Maybe<CheckoutPostReturnResult>;
  claimedBadges: Array<ClaimedBadge>;
  communities: Array<Community>;
  community?: Maybe<Community>;
  communityInventory?: Maybe<CommunityInventoryResult>;
  communityInviteCodes?: Maybe<CommunityInviteCodesResult>;
  communityLanding?: Maybe<CommunityLandingResult>;
  event?: Maybe<Event>;
  eventV2?: Maybe<Event>;
  events: Array<Event>;
  eventsV2?: Maybe<EventsV2Response>;
  exploreCommunities?: Maybe<ExploreCommunitiesResponse>;
  faqCategories: Array<FaqCategory>;
  faqItems: Array<FaqItem>;
  feedItem?: Maybe<FeedItem>;
  feedItemComments?: Maybe<FeedItemComments>;
  feedItems: Array<FeedItem>;
  forumPost?: Maybe<ForumPost>;
  forumPostComments?: Maybe<ForumPostCommentsReturn>;
  forumPosts?: Maybe<ForumPostsReturn>;
  getCommunity?: Maybe<Community>;
  inventory?: Maybe<InventoryReturnType>;
  inventoryEvent?: Maybe<InventoryEventResult>;
  inventoryPurchase?: Maybe<Purchase>;
  inventoryV2?: Maybe<InventoryV2Result>;
  inviteCode?: Maybe<InviteCode>;
  inviteCodes: Array<InviteCode>;
  locationSupported?: Maybe<Scalars['Boolean']>;
  me?: Maybe<User>;
  myBadges?: Maybe<Array<Maybe<ClaimedBadge>>>;
  nfts?: Maybe<Array<Maybe<Nft>>>;
  openCall?: Maybe<OpenCall>;
  openCalls?: Maybe<OpenCallsResponse>;
  popularEvents?: Maybe<PopularEventsResponse>;
  products: Array<Product>;
  promocodes: Array<Promocode>;
  purchases?: Maybe<PurchasesResponse>;
  radioTrack?: Maybe<Track>;
  radioTracks?: Maybe<RadioTracksReturn>;
  searchUsers?: Maybe<Array<User>>;
  shopPurchases: Array<Purchase>;
  shopV2?: Maybe<ShopV2Response>;
  shopValidCode?: Maybe<Scalars['Boolean']>;
  swapEvent?: Maybe<SwapEventResponse>;
  swapEvents?: Maybe<SwapEventsResponse>;
  talksChats?: Maybe<TalksChatsResponse>;
  talksMessages?: Maybe<TalksMessagesResponse>;
  talksUnreadMessages?: Maybe<Scalars['Int']>;
  talksV2?: Maybe<TalksV2Response>;
  testInternetConnection?: Maybe<TestInternetConnectionResponse>;
  ubiqFeedItems?: Maybe<UbiqFeedItemsResponse>;
  user?: Maybe<User>;
  userIndicators?: Maybe<UserIndicators>;
  userPopups?: Maybe<Array<Maybe<Modal>>>;
  usernameAvailable?: Maybe<Scalars['Boolean']>;
  users: Array<User>;
  waitlistUsers: Array<WaitlistUser>;
  warmer?: Maybe<Scalars['Boolean']>;
};


export type QueryAdminActivityEventArgs = {
  where: ActivityEventWhereUniqueInput;
};


export type QueryAdminActivityEventsArgs = {
  cursor?: InputMaybe<ActivityEventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ActivityEventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityEventWhereInput>;
};


export type QueryAdminActivityEventsCountArgs = {
  cursor?: InputMaybe<ActivityEventWhereUniqueInput>;
  orderBy?: InputMaybe<ActivityEventOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ActivityEventWhereInput>;
};


export type QueryAdminCatalogItemArgs = {
  where: CatalogItemWhereUniqueInput;
};


export type QueryAdminCatalogItemCategoriesArgs = {
  cursor?: InputMaybe<CatalogItemCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CatalogItemCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemCategoryWhereInput>;
};


export type QueryAdminCatalogItemCategoriesCountArgs = {
  cursor?: InputMaybe<CatalogItemCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<CatalogItemCategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemCategoryWhereInput>;
};


export type QueryAdminCatalogItemCategoryArgs = {
  where: CatalogItemCategoryWhereUniqueInput;
};


export type QueryAdminCatalogItemScheduleArgs = {
  where: CatalogItemScheduleWhereUniqueInput;
};


export type QueryAdminCatalogItemSchedulesArgs = {
  cursor?: InputMaybe<CatalogItemScheduleWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CatalogItemScheduleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemScheduleWhereInput>;
};


export type QueryAdminCatalogItemSchedulesCountArgs = {
  cursor?: InputMaybe<CatalogItemScheduleWhereUniqueInput>;
  orderBy?: InputMaybe<CatalogItemScheduleOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemScheduleWhereInput>;
};


export type QueryAdminCatalogItemsArgs = {
  cursor?: InputMaybe<CatalogItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CatalogItemOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemWhereInput>;
};


export type QueryAdminCatalogItemsCountArgs = {
  cursor?: InputMaybe<CatalogItemWhereUniqueInput>;
  orderBy?: InputMaybe<CatalogItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemWhereInput>;
};


export type QueryAdminClaimedBadgeArgs = {
  where: ClaimedBadgeWhereUniqueInput;
};


export type QueryAdminClaimedBadgesArgs = {
  cursor?: InputMaybe<ClaimedBadgeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ClaimedBadgeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClaimedBadgeWhereInput>;
};


export type QueryAdminClaimedBadgesCountArgs = {
  cursor?: InputMaybe<ClaimedBadgeWhereUniqueInput>;
  orderBy?: InputMaybe<ClaimedBadgeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClaimedBadgeWhereInput>;
};


export type QueryAdminCommunitiesArgs = {
  cursor?: InputMaybe<CommunityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommunityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityWhereInput>;
};


export type QueryAdminCommunitiesCountArgs = {
  cursor?: InputMaybe<CommunityWhereUniqueInput>;
  orderBy?: InputMaybe<CommunityOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityWhereInput>;
};


export type QueryAdminCommunityArgs = {
  where: CommunityWhereUniqueInput;
};


export type QueryAdminCommunityMemberInfoArgs = {
  where: CommunityMemberInfoWhereUniqueInput;
};


export type QueryAdminCommunityMemberInfosArgs = {
  cursor?: InputMaybe<CommunityMemberInfoWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommunityMemberInfoOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityMemberInfoWhereInput>;
};


export type QueryAdminCommunityMemberInfosCountArgs = {
  cursor?: InputMaybe<CommunityMemberInfoWhereUniqueInput>;
  orderBy?: InputMaybe<CommunityMemberInfoOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityMemberInfoWhereInput>;
};


export type QueryAdminConfigurationArgs = {
  where: ConfigurationWhereUniqueInput;
};


export type QueryAdminConfigurationsArgs = {
  cursor?: InputMaybe<ConfigurationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ConfigurationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ConfigurationWhereInput>;
};


export type QueryAdminConfigurationsCountArgs = {
  cursor?: InputMaybe<ConfigurationWhereUniqueInput>;
  orderBy?: InputMaybe<ConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ConfigurationWhereInput>;
};


export type QueryAdminEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryAdminEventCategoriesArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type QueryAdminEventCategoriesCountArgs = {
  cursor?: InputMaybe<EventCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<EventCategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventCategoryWhereInput>;
};


export type QueryAdminEventCategoryArgs = {
  where: EventCategoryWhereUniqueInput;
};


export type QueryAdminEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryAdminEventsCountArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  orderBy?: InputMaybe<EventOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryAdminFaqCategoriesArgs = {
  cursor?: InputMaybe<FaqCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FaqCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqCategoryWhereInput>;
};


export type QueryAdminFaqCategoriesCountArgs = {
  cursor?: InputMaybe<FaqCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<FaqCategoryOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqCategoryWhereInput>;
};


export type QueryAdminFaqCategoryArgs = {
  where: FaqCategoryWhereUniqueInput;
};


export type QueryAdminFaqItemArgs = {
  where: FaqItemWhereUniqueInput;
};


export type QueryAdminFaqItemsArgs = {
  cursor?: InputMaybe<FaqItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FaqItemOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemWhereInput>;
};


export type QueryAdminFaqItemsCountArgs = {
  cursor?: InputMaybe<FaqItemWhereUniqueInput>;
  orderBy?: InputMaybe<FaqItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemWhereInput>;
};


export type QueryAdminFeedItemArgs = {
  where: FeedItemWhereUniqueInput;
};


export type QueryAdminFeedItemCommentArgs = {
  where: FeedItemCommentWhereUniqueInput;
};


export type QueryAdminFeedItemCommentReportArgs = {
  where: FeedItemCommentReportWhereUniqueInput;
};


export type QueryAdminFeedItemCommentReportsArgs = {
  cursor?: InputMaybe<FeedItemCommentReportWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FeedItemCommentReportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedItemCommentReportWhereInput>;
};


export type QueryAdminFeedItemCommentReportsCountArgs = {
  cursor?: InputMaybe<FeedItemCommentReportWhereUniqueInput>;
  orderBy?: InputMaybe<FeedItemCommentReportOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedItemCommentReportWhereInput>;
};


export type QueryAdminFeedItemCommentsArgs = {
  cursor?: InputMaybe<FeedItemCommentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FeedItemCommentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedItemCommentWhereInput>;
};


export type QueryAdminFeedItemCommentsCountArgs = {
  cursor?: InputMaybe<FeedItemCommentWhereUniqueInput>;
  orderBy?: InputMaybe<FeedItemCommentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedItemCommentWhereInput>;
};


export type QueryAdminFeedItemsArgs = {
  cursor?: InputMaybe<FeedItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FeedItemOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedItemWhereInput>;
};


export type QueryAdminFeedItemsCountArgs = {
  cursor?: InputMaybe<FeedItemWhereUniqueInput>;
  orderBy?: InputMaybe<FeedItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedItemWhereInput>;
};


export type QueryAdminForumCommentArgs = {
  where: ForumCommentWhereUniqueInput;
};


export type QueryAdminForumCommentsArgs = {
  cursor?: InputMaybe<ForumCommentWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ForumCommentOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ForumCommentWhereInput>;
};


export type QueryAdminForumCommentsCountArgs = {
  cursor?: InputMaybe<ForumCommentWhereUniqueInput>;
  orderBy?: InputMaybe<ForumCommentOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ForumCommentWhereInput>;
};


export type QueryAdminForumContentReportArgs = {
  where: ForumContentReportWhereUniqueInput;
};


export type QueryAdminForumContentReportsArgs = {
  cursor?: InputMaybe<ForumContentReportWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ForumContentReportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ForumContentReportWhereInput>;
};


export type QueryAdminForumContentReportsCountArgs = {
  cursor?: InputMaybe<ForumContentReportWhereUniqueInput>;
  orderBy?: InputMaybe<ForumContentReportOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ForumContentReportWhereInput>;
};


export type QueryAdminForumPostArgs = {
  where: ForumPostWhereUniqueInput;
};


export type QueryAdminForumPostsArgs = {
  cursor?: InputMaybe<ForumPostWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ForumPostOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ForumPostWhereInput>;
};


export type QueryAdminForumPostsCountArgs = {
  cursor?: InputMaybe<ForumPostWhereUniqueInput>;
  orderBy?: InputMaybe<ForumPostOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ForumPostWhereInput>;
};


export type QueryAdminForumTagArgs = {
  where: ForumTagWhereUniqueInput;
};


export type QueryAdminForumTagsArgs = {
  cursor?: InputMaybe<ForumTagWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ForumTagOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ForumTagWhereInput>;
};


export type QueryAdminForumTagsCountArgs = {
  cursor?: InputMaybe<ForumTagWhereUniqueInput>;
  orderBy?: InputMaybe<ForumTagOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ForumTagWhereInput>;
};


export type QueryAdminGuestListProductsArgs = {
  eventId: Scalars['ID'];
};


export type QueryAdminInviteCodeArgs = {
  where: InviteCodeWhereUniqueInput;
};


export type QueryAdminInviteCodesArgs = {
  cursor?: InputMaybe<InviteCodeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InviteCodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InviteCodeWhereInput>;
};


export type QueryAdminInviteCodesCountArgs = {
  cursor?: InputMaybe<InviteCodeWhereUniqueInput>;
  orderBy?: InputMaybe<InviteCodeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InviteCodeWhereInput>;
};


export type QueryAdminListEventAttendeeArgs = {
  eventId?: InputMaybe<Scalars['ID']>;
  identifier: Scalars['String'];
};


export type QueryAdminListEventAttendeesArgs = {
  eventId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryAdminListEventInfoArgs = {
  eventId: Scalars['String'];
};


export type QueryAdminLocationArgs = {
  where: LocationWhereUniqueInput;
};


export type QueryAdminLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LocationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryAdminLocationsCountArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  orderBy?: InputMaybe<LocationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LocationWhereInput>;
};


export type QueryAdminMassMessageArgs = {
  where: MassMessageWhereUniqueInput;
};


export type QueryAdminMassMessagesArgs = {
  cursor?: InputMaybe<MassMessageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MassMessageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MassMessageWhereInput>;
};


export type QueryAdminMassMessagesCountArgs = {
  cursor?: InputMaybe<MassMessageWhereUniqueInput>;
  orderBy?: InputMaybe<MassMessageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MassMessageWhereInput>;
};


export type QueryAdminModuleConfigurationArgs = {
  where: ModuleConfigurationWhereUniqueInput;
};


export type QueryAdminModuleConfigurationsArgs = {
  cursor?: InputMaybe<ModuleConfigurationWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ModuleConfigurationOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ModuleConfigurationWhereInput>;
};


export type QueryAdminModuleConfigurationsCountArgs = {
  cursor?: InputMaybe<ModuleConfigurationWhereUniqueInput>;
  orderBy?: InputMaybe<ModuleConfigurationOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ModuleConfigurationWhereInput>;
};


export type QueryAdminOpenCallArgs = {
  where: OpenCallWhereUniqueInput;
};


export type QueryAdminOpenCallSubmissionArgs = {
  where: OpenCallSubmissionWhereUniqueInput;
};


export type QueryAdminOpenCallSubmissionsArgs = {
  cursor?: InputMaybe<OpenCallSubmissionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OpenCallSubmissionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OpenCallSubmissionWhereInput>;
};


export type QueryAdminOpenCallSubmissionsCountArgs = {
  cursor?: InputMaybe<OpenCallSubmissionWhereUniqueInput>;
  orderBy?: InputMaybe<OpenCallSubmissionOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OpenCallSubmissionWhereInput>;
};


export type QueryAdminOpenCallsArgs = {
  cursor?: InputMaybe<OpenCallWhereUniqueInput>;
  orderBy?: InputMaybe<Array<OpenCallOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OpenCallWhereInput>;
};


export type QueryAdminOpenCallsCountArgs = {
  cursor?: InputMaybe<OpenCallWhereUniqueInput>;
  orderBy?: InputMaybe<OpenCallOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<OpenCallWhereInput>;
};


export type QueryAdminPayoutArgs = {
  where: PayoutWhereUniqueInput;
};


export type QueryAdminPayoutsArgs = {
  cursor?: InputMaybe<PayoutWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PayoutOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayoutWhereInput>;
};


export type QueryAdminPayoutsCountArgs = {
  cursor?: InputMaybe<PayoutWhereUniqueInput>;
  orderBy?: InputMaybe<PayoutOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PayoutWhereInput>;
};


export type QueryAdminProductArgs = {
  where: ProductWhereUniqueInput;
};


export type QueryAdminProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryAdminProductsCountArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<ProductOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryAdminPromocodeArgs = {
  where: PromocodeWhereUniqueInput;
};


export type QueryAdminPromocodesArgs = {
  cursor?: InputMaybe<PromocodeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PromocodeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromocodeWhereInput>;
};


export type QueryAdminPromocodesCountArgs = {
  cursor?: InputMaybe<PromocodeWhereUniqueInput>;
  orderBy?: InputMaybe<PromocodeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromocodeWhereInput>;
};


export type QueryAdminPurchaseArgs = {
  where: PurchaseWhereUniqueInput;
};


export type QueryAdminPurchaseTransferArgs = {
  where: PurchaseTransferWhereUniqueInput;
};


export type QueryAdminPurchaseTransfersArgs = {
  cursor?: InputMaybe<PurchaseTransferWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PurchaseTransferOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseTransferWhereInput>;
};


export type QueryAdminPurchaseTransfersCountArgs = {
  cursor?: InputMaybe<PurchaseTransferWhereUniqueInput>;
  orderBy?: InputMaybe<PurchaseTransferOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseTransferWhereInput>;
};


export type QueryAdminPurchasesArgs = {
  cursor?: InputMaybe<PurchaseWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PurchaseOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseWhereInput>;
};


export type QueryAdminPurchasesCountArgs = {
  cursor?: InputMaybe<PurchaseWhereUniqueInput>;
  orderBy?: InputMaybe<PurchaseOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseWhereInput>;
};


export type QueryAdminTalksMessageArgs = {
  where: TalksMessageWhereUniqueInput;
};


export type QueryAdminTalksMessagesArgs = {
  cursor?: InputMaybe<TalksMessageWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TalksMessageOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TalksMessageWhereInput>;
};


export type QueryAdminTalksMessagesCountArgs = {
  cursor?: InputMaybe<TalksMessageWhereUniqueInput>;
  orderBy?: InputMaybe<TalksMessageOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TalksMessageWhereInput>;
};


export type QueryAdminTemplateBadgeArgs = {
  where: TemplateBadgeWhereUniqueInput;
};


export type QueryAdminTemplateBadgesArgs = {
  cursor?: InputMaybe<TemplateBadgeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TemplateBadgeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TemplateBadgeWhereInput>;
};


export type QueryAdminTemplateBadgesCountArgs = {
  cursor?: InputMaybe<TemplateBadgeWhereUniqueInput>;
  orderBy?: InputMaybe<TemplateBadgeOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TemplateBadgeWhereInput>;
};


export type QueryAdminTrackArgs = {
  where: TrackWhereUniqueInput;
};


export type QueryAdminTracksArgs = {
  cursor?: InputMaybe<TrackWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TrackOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrackWhereInput>;
};


export type QueryAdminTracksCountArgs = {
  cursor?: InputMaybe<TrackWhereUniqueInput>;
  orderBy?: InputMaybe<TrackOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TrackWhereInput>;
};


export type QueryAdminUbiqFeedItemArgs = {
  where: UbiqFeedItemWhereUniqueInput;
};


export type QueryAdminUbiqFeedItemsArgs = {
  cursor?: InputMaybe<UbiqFeedItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UbiqFeedItemOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UbiqFeedItemWhereInput>;
};


export type QueryAdminUbiqFeedItemsCountArgs = {
  cursor?: InputMaybe<UbiqFeedItemWhereUniqueInput>;
  orderBy?: InputMaybe<UbiqFeedItemOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UbiqFeedItemWhereInput>;
};


export type QueryAdminUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryAdminUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAdminUsersCountArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAdminWaitlistUserArgs = {
  where: WaitlistUserWhereUniqueInput;
};


export type QueryAdminWaitlistUsersArgs = {
  cursor?: InputMaybe<WaitlistUserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<WaitlistUserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WaitlistUserWhereInput>;
};


export type QueryAdminWaitlistUsersCountArgs = {
  cursor?: InputMaybe<WaitlistUserWhereUniqueInput>;
  orderBy?: InputMaybe<WaitlistUserOrderByInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<WaitlistUserWhereInput>;
};


export type QueryAllUserIDsExistArgs = {
  ids: Scalars['String'];
};


export type QueryAnnouncementArgs = {
  announcementId: Scalars['ID'];
  communityId?: InputMaybe<Scalars['ID']>;
};


export type QueryAnnouncementsArgs = {
  communityId: Scalars['ID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaActiveProductsArgs = {
  communityId: Scalars['ID'];
};


export type QueryCaAdminsArgs = {
  communityId: Scalars['ID'];
};


export type QueryCaAnnouncementArgs = {
  announcementId: Scalars['ID'];
  communityId: Scalars['ID'];
};


export type QueryCaAnnouncementsArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaCommunityMembersArgs = {
  communityId: Scalars['String'];
  numberedId?: InputMaybe<Scalars['Int']>;
  orderByJoinDate?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaCommunityMembersStatsArgs = {
  communityId: Scalars['ID'];
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCaCommunityOverviewStatsArgs = {
  communityId: Scalars['ID'];
};


export type QueryCaCommunityRevenueStatsArgs = {
  communityId: Scalars['ID'];
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCaCommunityThemeArgs = {
  communityId: Scalars['ID'];
  communityThemeId?: InputMaybe<Scalars['ID']>;
  communityThemeSlug?: InputMaybe<Scalars['String']>;
};


export type QueryCaCommunityThemesArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaDashboardArgs = {
  communityId: Scalars['String'];
};


export type QueryCaEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryCaEventAnalyticsArgs = {
  eventId: Scalars['ID'];
};


export type QueryCaEventCategoriesArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaEventCategoryArgs = {
  categoryId: Scalars['ID'];
  communityId: Scalars['ID'];
};


export type QueryCaEventGuestsArgs = {
  communityId: Scalars['ID'];
  eventId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaEventTicketsActivityArgs = {
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  eventId: Scalars['String'];
};


export type QueryCaEventTractionArgs = {
  communityId: Scalars['ID'];
  eventId: Scalars['ID'];
};


export type QueryCaEventsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaFinancesOverviewArgs = {
  communityId: Scalars['String'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
  selectedDateFrom?: InputMaybe<Scalars['DateTime']>;
  selectedDateTo?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCaFinancesPayoutsArgs = {
  communityId: Scalars['String'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
};


export type QueryCaForumItemsArgs = {
  communityId: Scalars['ID'];
  pendingOnly?: Scalars['Boolean'];
  skipPosts?: Scalars['Int'];
  skipReported?: Scalars['Int'];
  takePosts?: Scalars['Int'];
  takeReported?: Scalars['Int'];
};


export type QueryCaMassMessageArgs = {
  communityId: Scalars['ID'];
  massMessageId: Scalars['ID'];
};


export type QueryCaMassMessagesArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  sentOnly?: InputMaybe<Scalars['Boolean']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaPotentialAdminsArgs = {
  communityId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaProductArgs = {
  communityId: Scalars['ID'];
  productId: Scalars['ID'];
};


export type QueryCaProductUsageStatsArgs = {
  communityId: Scalars['ID'];
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  productId: Scalars['ID'];
};


export type QueryCaProductsArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaPromocodeArgs = {
  communityId: Scalars['ID'];
  promocodeId: Scalars['ID'];
};


export type QueryCaPromocodeUsageStatsArgs = {
  communityId: Scalars['ID'];
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  promocodeId: Scalars['ID'];
};


export type QueryCaPromocodesArgs = {
  communityId: Scalars['ID'];
  haveBeenUsed?: InputMaybe<Scalars['Boolean']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaPromocodesRecentUsagesArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaPurchasesArgs = {
  activityEnd?: InputMaybe<Scalars['DateTime']>;
  activityStart?: InputMaybe<Scalars['DateTime']>;
  communityId: Scalars['ID'];
  eventId?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaPurchasesFunnelArgs = {
  communityId: Scalars['ID'];
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
};


export type QueryCaRadioTrackArgs = {
  communityId: Scalars['ID'];
  trackId: Scalars['ID'];
};


export type QueryCaRadioTracksArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaScheduleItemArgs = {
  communityId: Scalars['ID'];
  scheduleItemId: Scalars['ID'];
};


export type QueryCaScheduleItemsArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCaStripeAccountInfoArgs = {
  communityId: Scalars['String'];
};


export type QueryCaTalksChatArgs = {
  chatId: Scalars['ID'];
  communityId: Scalars['ID'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCaTalksChatsArgs = {
  communityId: Scalars['ID'];
};


export type QueryCalendarEventsArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryCatalogItemCategoriesArgs = {
  cursor?: InputMaybe<CatalogItemCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CatalogItemCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemCategoryWhereInput>;
};


export type QueryCatalogItemSchedulesArgs = {
  cursor?: InputMaybe<CatalogItemScheduleWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CatalogItemScheduleOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemScheduleWhereInput>;
};


export type QueryCatalogItemsArgs = {
  cursor?: InputMaybe<CatalogItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CatalogItemOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CatalogItemWhereInput>;
};


export type QueryCheckoutPostReturnArgs = {
  purchaseIds: Array<Scalars['ID']>;
};


export type QueryClaimedBadgesArgs = {
  cursor?: InputMaybe<ClaimedBadgeWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ClaimedBadgeOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ClaimedBadgeWhereInput>;
};


export type QueryCommunitiesArgs = {
  cursor?: InputMaybe<CommunityWhereUniqueInput>;
  orderBy?: InputMaybe<Array<CommunityOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityWhereInput>;
};


export type QueryCommunityArgs = {
  where: CommunityWhereUniqueInput;
};


export type QueryCommunityInventoryArgs = {
  communityId: Scalars['ID'];
  type?: InputMaybe<ProductType>;
};


export type QueryCommunityLandingArgs = {
  communityCode: Scalars['String'];
};


export type QueryEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryEventV2Args = {
  communityId?: InputMaybe<Scalars['ID']>;
  eventId: Scalars['ID'];
};


export type QueryEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  orderBy?: InputMaybe<Array<EventOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type QueryEventsV2Args = {
  communityId: Scalars['ID'];
  futureOnly?: Scalars['Boolean'];
  search?: Scalars['ID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryExploreCommunitiesArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryFaqCategoriesArgs = {
  cursor?: InputMaybe<FaqCategoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FaqCategoryOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqCategoryWhereInput>;
};


export type QueryFaqItemsArgs = {
  cursor?: InputMaybe<FaqItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FaqItemOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FaqItemWhereInput>;
};


export type QueryFeedItemArgs = {
  where: FeedItemWhereUniqueInput;
};


export type QueryFeedItemCommentsArgs = {
  feedItemId: Scalars['ID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryFeedItemsArgs = {
  cursor?: InputMaybe<FeedItemWhereUniqueInput>;
  orderBy?: InputMaybe<Array<FeedItemOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<FeedItemWhereInput>;
};


export type QueryForumPostArgs = {
  communityId?: InputMaybe<Scalars['ID']>;
  postId: Scalars['ID'];
};


export type QueryForumPostCommentsArgs = {
  postId: Scalars['ID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryForumPostsArgs = {
  communityId: Scalars['ID'];
  filterTagId?: InputMaybe<Scalars['ID']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryGetCommunityArgs = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryInventoryEventArgs = {
  eventId: Scalars['ID'];
};


export type QueryInventoryPurchaseArgs = {
  purchaseId: Scalars['ID'];
};


export type QueryInviteCodeArgs = {
  where: InviteCodeWhereUniqueInput;
};


export type QueryInviteCodesArgs = {
  cursor?: InputMaybe<InviteCodeWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InviteCodeWhereInput>;
};


export type QueryLocationSupportedArgs = {
  userIP?: InputMaybe<Scalars['String']>;
};


export type QueryMyBadgesArgs = {
  communityId: Scalars['ID'];
};


export type QueryNftsArgs = {
  collectionName?: InputMaybe<Scalars['String']>;
  ignoreCache?: InputMaybe<Scalars['Boolean']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
};


export type QueryOpenCallArgs = {
  communityId: Scalars['ID'];
  openCallId: Scalars['ID'];
};


export type QueryOpenCallsArgs = {
  communityId: Scalars['ID'];
  futureOnly?: Scalars['Boolean'];
  search?: Scalars['ID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryPopularEventsArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  orderBy?: InputMaybe<Array<ProductOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ProductWhereInput>;
};


export type QueryPromocodesArgs = {
  cursor?: InputMaybe<PromocodeWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PromocodeWhereInput>;
};


export type QueryRadioTrackArgs = {
  communityId: Scalars['ID'];
  trackId: Scalars['ID'];
};


export type QueryRadioTracksArgs = {
  communityId: Scalars['ID'];
  search?: InputMaybe<Scalars['String']>;
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QuerySearchUsersArgs = {
  communityId?: InputMaybe<Scalars['String']>;
  query?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryShopPurchasesArgs = {
  cursor?: InputMaybe<PurchaseWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PurchaseOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseWhereInput>;
};


export type QueryShopV2Args = {
  accessCode?: InputMaybe<Scalars['String']>;
  communityId: Scalars['ID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryShopValidCodeArgs = {
  code: Scalars['String'];
  communityId: Scalars['ID'];
};


export type QuerySwapEventArgs = {
  eventId: Scalars['ID'];
};


export type QuerySwapEventsArgs = {
  search?: Scalars['ID'];
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryTalksChatsArgs = {
  chatId?: InputMaybe<Scalars['String']>;
};


export type QueryTalksMessagesArgs = {
  chatId?: InputMaybe<Scalars['String']>;
};


export type QueryTalksV2Args = {
  communityId?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryUbiqFeedItemsArgs = {
  skip?: Scalars['Int'];
  take?: Scalars['Int'];
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsernameAvailableArgs = {
  username: Scalars['String'];
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryWaitlistUsersArgs = {
  cursor?: InputMaybe<WaitlistUserWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type ReadMessageResponse = {
  __typename?: 'ReadMessageResponse';
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type RequestProductsPurchase = {
  __typename?: 'RequestProductsPurchase';
  purchaseIds?: Maybe<Array<Scalars['String']>>;
  stripePaymentIntentClientSecret?: Maybe<Scalars['String']>;
  stripeRedirectUrl?: Maybe<Scalars['String']>;
  stripeSwishQRUrl?: Maybe<Scalars['String']>;
  swishCheckoutDeepLink?: Maybe<Scalars['String']>;
};

export type RequestProductsPurchaseInput = {
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type SalesData = {
  __typename?: 'SalesData';
  endDate?: Maybe<Scalars['Date']>;
  startDate?: Maybe<Scalars['Date']>;
  total?: Maybe<Scalars['Float']>;
};

export type SendMessageResponse = {
  __typename?: 'SendMessageResponse';
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  notification?: Maybe<TalksMessage>;
};

export type ShopV2Response = {
  __typename?: 'ShopV2Response';
  items: Array<Product>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export enum SupportedCountries {
  De = 'DE',
  Dk = 'DK',
  It = 'IT',
  Nl = 'NL',
  Se = 'SE'
}

export enum SupportedCurrencies {
  Dkk = 'DKK',
  Eur = 'EUR',
  Sek = 'SEK'
}

export type SwapEventResponse = {
  __typename?: 'SwapEventResponse';
  event: Event;
  offers: Array<Purchase>;
};

export type SwapEventsResponse = {
  __typename?: 'SwapEventsResponse';
  items: Array<Event>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type SyncPushPlayerIdsResponse = {
  __typename?: 'SyncPushPlayerIdsResponse';
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type TalksChat = {
  __typename?: 'TalksChat';
  communityId: Scalars['String'];
  iconUrl: Scalars['String'];
  latestMessageTime?: Maybe<Scalars['DateTime']>;
  unreadCount: Scalars['Int'];
};

export type TalksChatsResponse = {
  __typename?: 'TalksChatsResponse';
  chats?: Maybe<Array<Maybe<AdminChat>>>;
};

export type TalksMessage = {
  __typename?: 'TalksMessage';
  actionDeepLink?: Maybe<Scalars['String']>;
  attachmentId?: Maybe<Scalars['String']>;
  community?: Maybe<Community>;
  communityId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['Json']>;
  event?: Maybe<Event>;
  id: Scalars['String'];
  inAppLink?: Maybe<Scalars['String']>;
  joinedCommunityAlready: Scalars['Boolean'];
  linkedPurchase?: Maybe<Purchase>;
  message?: Maybe<Scalars['String']>;
  mine: Scalars['Boolean'];
  read: Scalars['Boolean'];
  recipient?: Maybe<User>;
  sender?: Maybe<User>;
  senderId?: Maybe<Scalars['String']>;
  senderTitle?: Maybe<Scalars['String']>;
  status: MessageHighlightStatus;
  type: TalksMessageType;
  updatedAt: Scalars['DateTime'];
};

export type TalksMessageCreateInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  community?: InputMaybe<CommunityCreateNestedOneWithoutRelatedTalksMessagesInput>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  event?: InputMaybe<EventCreateNestedOneWithoutRelatedTalksMessagesInput>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipient?: InputMaybe<UserCreateNestedOneWithoutNotificationsWhereRecipientInput>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  sender?: InputMaybe<UserCreateNestedOneWithoutNotificationsWhereSenderInput>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageCreateManyCommunityInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipientId?: InputMaybe<Scalars['String']>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  senderId?: InputMaybe<Scalars['String']>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<TalksMessageCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TalksMessageCreateManyEventInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipientId?: InputMaybe<Scalars['String']>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  senderId?: InputMaybe<Scalars['String']>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageCreateManyEventInputEnvelope = {
  data?: InputMaybe<Array<TalksMessageCreateManyEventInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TalksMessageCreateManyRecipientInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  senderId?: InputMaybe<Scalars['String']>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageCreateManyRecipientInputEnvelope = {
  data?: InputMaybe<Array<TalksMessageCreateManyRecipientInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TalksMessageCreateManySenderInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  communityId?: InputMaybe<Scalars['String']>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  eventId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipientId?: InputMaybe<Scalars['String']>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageCreateManySenderInputEnvelope = {
  data?: InputMaybe<Array<TalksMessageCreateManySenderInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TalksMessageCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TalksMessageCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<TalksMessageCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<TalksMessageCreateManyCommunityInputEnvelope>;
};

export type TalksMessageCreateNestedManyWithoutEventInput = {
  connect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TalksMessageCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<TalksMessageCreateWithoutEventInput>>;
  createMany?: InputMaybe<TalksMessageCreateManyEventInputEnvelope>;
};

export type TalksMessageCreateNestedManyWithoutRecipientInput = {
  connect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TalksMessageCreateOrConnectWithoutRecipientInput>>;
  create?: InputMaybe<Array<TalksMessageCreateWithoutRecipientInput>>;
  createMany?: InputMaybe<TalksMessageCreateManyRecipientInputEnvelope>;
};

export type TalksMessageCreateNestedManyWithoutSenderInput = {
  connect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TalksMessageCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<TalksMessageCreateWithoutSenderInput>>;
  createMany?: InputMaybe<TalksMessageCreateManySenderInputEnvelope>;
};

export type TalksMessageCreateOrConnectWithoutCommunityInput = {
  create: TalksMessageCreateWithoutCommunityInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageCreateOrConnectWithoutEventInput = {
  create: TalksMessageCreateWithoutEventInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageCreateOrConnectWithoutRecipientInput = {
  create: TalksMessageCreateWithoutRecipientInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageCreateOrConnectWithoutSenderInput = {
  create: TalksMessageCreateWithoutSenderInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageCreateWithoutCommunityInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  event?: InputMaybe<EventCreateNestedOneWithoutRelatedTalksMessagesInput>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipient?: InputMaybe<UserCreateNestedOneWithoutNotificationsWhereRecipientInput>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  sender?: InputMaybe<UserCreateNestedOneWithoutNotificationsWhereSenderInput>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageCreateWithoutEventInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  community?: InputMaybe<CommunityCreateNestedOneWithoutRelatedTalksMessagesInput>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipient?: InputMaybe<UserCreateNestedOneWithoutNotificationsWhereRecipientInput>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  sender?: InputMaybe<UserCreateNestedOneWithoutNotificationsWhereSenderInput>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageCreateWithoutRecipientInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  community?: InputMaybe<CommunityCreateNestedOneWithoutRelatedTalksMessagesInput>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  event?: InputMaybe<EventCreateNestedOneWithoutRelatedTalksMessagesInput>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  sender?: InputMaybe<UserCreateNestedOneWithoutNotificationsWhereSenderInput>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageCreateWithoutSenderInput = {
  actionDeepLink?: InputMaybe<Scalars['String']>;
  attachmentId?: InputMaybe<Scalars['String']>;
  community?: InputMaybe<CommunityCreateNestedOneWithoutRelatedTalksMessagesInput>;
  communitySuggestionId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data?: InputMaybe<Scalars['Json']>;
  event?: InputMaybe<EventCreateNestedOneWithoutRelatedTalksMessagesInput>;
  id?: InputMaybe<Scalars['String']>;
  inAppLink?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  massMessageId?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  pushTrackingId?: InputMaybe<Scalars['String']>;
  read?: InputMaybe<Scalars['Boolean']>;
  recipient?: InputMaybe<UserCreateNestedOneWithoutNotificationsWhereRecipientInput>;
  sendAt?: InputMaybe<Scalars['DateTime']>;
  senderTitle?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<MessageHighlightStatus>;
  type?: InputMaybe<TalksMessageType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TalksMessageListRelationFilter = {
  every?: InputMaybe<TalksMessageWhereInput>;
  none?: InputMaybe<TalksMessageWhereInput>;
  some?: InputMaybe<TalksMessageWhereInput>;
};

export type TalksMessageOrderByInput = {
  actionDeepLink?: InputMaybe<SortOrder>;
  attachmentId?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  communitySuggestionId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  eventId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inAppLink?: InputMaybe<SortOrder>;
  key?: InputMaybe<SortOrder>;
  massMessageId?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  pushTrackingId?: InputMaybe<SortOrder>;
  read?: InputMaybe<SortOrder>;
  recipientId?: InputMaybe<SortOrder>;
  sendAt?: InputMaybe<SortOrder>;
  senderId?: InputMaybe<SortOrder>;
  senderTitle?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TalksMessageScalarWhereInput = {
  AND?: InputMaybe<Array<TalksMessageScalarWhereInput>>;
  NOT?: InputMaybe<Array<TalksMessageScalarWhereInput>>;
  OR?: InputMaybe<Array<TalksMessageScalarWhereInput>>;
  actionDeepLink?: InputMaybe<StringNullableFilter>;
  attachmentId?: InputMaybe<StringNullableFilter>;
  communityId?: InputMaybe<StringNullableFilter>;
  communitySuggestionId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inAppLink?: InputMaybe<StringNullableFilter>;
  key?: InputMaybe<StringNullableFilter>;
  massMessageId?: InputMaybe<StringNullableFilter>;
  message?: InputMaybe<StringNullableFilter>;
  pushTrackingId?: InputMaybe<StringNullableFilter>;
  read?: InputMaybe<BoolFilter>;
  recipientId?: InputMaybe<StringNullableFilter>;
  sendAt?: InputMaybe<DateTimeNullableFilter>;
  senderId?: InputMaybe<StringNullableFilter>;
  senderTitle?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumMessageHighlightStatusFilter>;
  type?: InputMaybe<EnumTalksMessageTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum TalksMessageType {
  AccountDeleted = 'AccountDeleted',
  CommentLiked = 'CommentLiked',
  CommentReplied = 'CommentReplied',
  CommunityInvite = 'CommunityInvite',
  Direct = 'Direct',
  InvitedPrivateEvent = 'InvitedPrivateEvent',
  JoinedWithInviteCode = 'JoinedWithInviteCode',
  LocationDropped = 'LocationDropped',
  NewEventDetails = 'NewEventDetails',
  SharedInventory = 'SharedInventory',
  UserSuggestionAdmin = 'UserSuggestionAdmin',
  WelcomeCommunity = 'WelcomeCommunity'
}

export type TalksMessageUpdateInput = {
  actionDeepLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachmentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneWithoutRelatedTalksMessagesInput>;
  communitySuggestionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  event?: InputMaybe<EventUpdateOneWithoutRelatedTalksMessagesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inAppLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  massMessageId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTrackingId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  recipient?: InputMaybe<UserUpdateOneWithoutNotificationsWhereRecipientInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneWithoutNotificationsWhereSenderInput>;
  senderTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTalksMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TalksMessageUpdateManyMutationInput = {
  actionDeepLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachmentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  communitySuggestionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inAppLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  massMessageId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTrackingId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  senderTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTalksMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TalksMessageUpdateManyWithWhereWithoutCommunityInput = {
  data: TalksMessageUpdateManyMutationInput;
  where: TalksMessageScalarWhereInput;
};

export type TalksMessageUpdateManyWithWhereWithoutEventInput = {
  data: TalksMessageUpdateManyMutationInput;
  where: TalksMessageScalarWhereInput;
};

export type TalksMessageUpdateManyWithWhereWithoutRecipientInput = {
  data: TalksMessageUpdateManyMutationInput;
  where: TalksMessageScalarWhereInput;
};

export type TalksMessageUpdateManyWithWhereWithoutSenderInput = {
  data: TalksMessageUpdateManyMutationInput;
  where: TalksMessageScalarWhereInput;
};

export type TalksMessageUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TalksMessageCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<TalksMessageCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<TalksMessageCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TalksMessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  set?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  update?: InputMaybe<Array<TalksMessageUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<TalksMessageUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<TalksMessageUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type TalksMessageUpdateManyWithoutEventInput = {
  connect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TalksMessageCreateOrConnectWithoutEventInput>>;
  create?: InputMaybe<Array<TalksMessageCreateWithoutEventInput>>;
  createMany?: InputMaybe<TalksMessageCreateManyEventInputEnvelope>;
  delete?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TalksMessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  set?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  update?: InputMaybe<Array<TalksMessageUpdateWithWhereUniqueWithoutEventInput>>;
  updateMany?: InputMaybe<Array<TalksMessageUpdateManyWithWhereWithoutEventInput>>;
  upsert?: InputMaybe<Array<TalksMessageUpsertWithWhereUniqueWithoutEventInput>>;
};

export type TalksMessageUpdateManyWithoutRecipientInput = {
  connect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TalksMessageCreateOrConnectWithoutRecipientInput>>;
  create?: InputMaybe<Array<TalksMessageCreateWithoutRecipientInput>>;
  createMany?: InputMaybe<TalksMessageCreateManyRecipientInputEnvelope>;
  delete?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TalksMessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  set?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  update?: InputMaybe<Array<TalksMessageUpdateWithWhereUniqueWithoutRecipientInput>>;
  updateMany?: InputMaybe<Array<TalksMessageUpdateManyWithWhereWithoutRecipientInput>>;
  upsert?: InputMaybe<Array<TalksMessageUpsertWithWhereUniqueWithoutRecipientInput>>;
};

export type TalksMessageUpdateManyWithoutSenderInput = {
  connect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TalksMessageCreateOrConnectWithoutSenderInput>>;
  create?: InputMaybe<Array<TalksMessageCreateWithoutSenderInput>>;
  createMany?: InputMaybe<TalksMessageCreateManySenderInputEnvelope>;
  delete?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TalksMessageScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  set?: InputMaybe<Array<TalksMessageWhereUniqueInput>>;
  update?: InputMaybe<Array<TalksMessageUpdateWithWhereUniqueWithoutSenderInput>>;
  updateMany?: InputMaybe<Array<TalksMessageUpdateManyWithWhereWithoutSenderInput>>;
  upsert?: InputMaybe<Array<TalksMessageUpsertWithWhereUniqueWithoutSenderInput>>;
};

export type TalksMessageUpdateWithWhereUniqueWithoutCommunityInput = {
  data: TalksMessageUpdateWithoutCommunityInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageUpdateWithWhereUniqueWithoutEventInput = {
  data: TalksMessageUpdateWithoutEventInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageUpdateWithWhereUniqueWithoutRecipientInput = {
  data: TalksMessageUpdateWithoutRecipientInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageUpdateWithWhereUniqueWithoutSenderInput = {
  data: TalksMessageUpdateWithoutSenderInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageUpdateWithoutCommunityInput = {
  actionDeepLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachmentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  communitySuggestionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  event?: InputMaybe<EventUpdateOneWithoutRelatedTalksMessagesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inAppLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  massMessageId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTrackingId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  recipient?: InputMaybe<UserUpdateOneWithoutNotificationsWhereRecipientInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneWithoutNotificationsWhereSenderInput>;
  senderTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTalksMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TalksMessageUpdateWithoutEventInput = {
  actionDeepLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachmentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneWithoutRelatedTalksMessagesInput>;
  communitySuggestionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inAppLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  massMessageId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTrackingId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  recipient?: InputMaybe<UserUpdateOneWithoutNotificationsWhereRecipientInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneWithoutNotificationsWhereSenderInput>;
  senderTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTalksMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TalksMessageUpdateWithoutRecipientInput = {
  actionDeepLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachmentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneWithoutRelatedTalksMessagesInput>;
  communitySuggestionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  event?: InputMaybe<EventUpdateOneWithoutRelatedTalksMessagesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inAppLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  massMessageId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTrackingId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  sender?: InputMaybe<UserUpdateOneWithoutNotificationsWhereSenderInput>;
  senderTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTalksMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TalksMessageUpdateWithoutSenderInput = {
  actionDeepLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  attachmentId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneWithoutRelatedTalksMessagesInput>;
  communitySuggestionId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['Json']>;
  event?: InputMaybe<EventUpdateOneWithoutRelatedTalksMessagesInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  inAppLink?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  key?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  massMessageId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  message?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  pushTrackingId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  read?: InputMaybe<BoolFieldUpdateOperationsInput>;
  recipient?: InputMaybe<UserUpdateOneWithoutNotificationsWhereRecipientInput>;
  sendAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  senderTitle?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumMessageHighlightStatusFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTalksMessageTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TalksMessageUpsertWithWhereUniqueWithoutCommunityInput = {
  create: TalksMessageCreateWithoutCommunityInput;
  update: TalksMessageUpdateWithoutCommunityInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageUpsertWithWhereUniqueWithoutEventInput = {
  create: TalksMessageCreateWithoutEventInput;
  update: TalksMessageUpdateWithoutEventInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageUpsertWithWhereUniqueWithoutRecipientInput = {
  create: TalksMessageCreateWithoutRecipientInput;
  update: TalksMessageUpdateWithoutRecipientInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageUpsertWithWhereUniqueWithoutSenderInput = {
  create: TalksMessageCreateWithoutSenderInput;
  update: TalksMessageUpdateWithoutSenderInput;
  where: TalksMessageWhereUniqueInput;
};

export type TalksMessageWhereInput = {
  AND?: InputMaybe<Array<TalksMessageWhereInput>>;
  NOT?: InputMaybe<Array<TalksMessageWhereInput>>;
  OR?: InputMaybe<Array<TalksMessageWhereInput>>;
  actionDeepLink?: InputMaybe<StringNullableFilter>;
  attachmentId?: InputMaybe<StringNullableFilter>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringNullableFilter>;
  communitySuggestionId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonNullableFilter>;
  event?: InputMaybe<EventWhereInput>;
  eventId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  inAppLink?: InputMaybe<StringNullableFilter>;
  key?: InputMaybe<StringNullableFilter>;
  massMessageId?: InputMaybe<StringNullableFilter>;
  message?: InputMaybe<StringNullableFilter>;
  pushTrackingId?: InputMaybe<StringNullableFilter>;
  read?: InputMaybe<BoolFilter>;
  recipient?: InputMaybe<UserWhereInput>;
  recipientId?: InputMaybe<StringNullableFilter>;
  sendAt?: InputMaybe<DateTimeNullableFilter>;
  sender?: InputMaybe<UserWhereInput>;
  senderId?: InputMaybe<StringNullableFilter>;
  senderTitle?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumMessageHighlightStatusFilter>;
  type?: InputMaybe<EnumTalksMessageTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TalksMessageWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TalksMessagesResponse = {
  __typename?: 'TalksMessagesResponse';
  adminId?: Maybe<Scalars['String']>;
  chats?: Maybe<Array<Maybe<Chat>>>;
  talksMessages?: Maybe<Array<Maybe<TalksMessage>>>;
};

export type TalksV2Response = {
  __typename?: 'TalksV2Response';
  chats: Array<TalksChat>;
  items: Array<TalksMessage>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type TemplateBadge = {
  __typename?: 'TemplateBadge';
  claimedBadges: Array<ClaimedBadge>;
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: Community;
  parentCommunityId: Scalars['String'];
  products: Array<Product>;
  updatedAt: Scalars['DateTime'];
};


export type TemplateBadgeClaimedBadgesArgs = {
  cursor?: InputMaybe<ClaimedBadgeWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type TemplateBadgeProductsArgs = {
  cursor?: InputMaybe<ProductWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type TemplateBadgeCreateInput = {
  claimedBadges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutTemplateInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutBadgesInput;
  products?: InputMaybe<ProductCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TemplateBadgeCreateManyParentCommunityInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TemplateBadgeCreateManyParentCommunityInputEnvelope = {
  data?: InputMaybe<Array<TemplateBadgeCreateManyParentCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TemplateBadgeCreateNestedManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<TemplateBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateBadgeCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<TemplateBadgeCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<TemplateBadgeCreateManyParentCommunityInputEnvelope>;
};

export type TemplateBadgeCreateNestedOneWithoutClaimedBadgesInput = {
  connect?: InputMaybe<TemplateBadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateBadgeCreateOrConnectWithoutClaimedBadgesInput>;
  create?: InputMaybe<TemplateBadgeCreateWithoutClaimedBadgesInput>;
};

export type TemplateBadgeCreateNestedOneWithoutProductsInput = {
  connect?: InputMaybe<TemplateBadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateBadgeCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<TemplateBadgeCreateWithoutProductsInput>;
};

export type TemplateBadgeCreateOrConnectWithoutClaimedBadgesInput = {
  create: TemplateBadgeCreateWithoutClaimedBadgesInput;
  where: TemplateBadgeWhereUniqueInput;
};

export type TemplateBadgeCreateOrConnectWithoutParentCommunityInput = {
  create: TemplateBadgeCreateWithoutParentCommunityInput;
  where: TemplateBadgeWhereUniqueInput;
};

export type TemplateBadgeCreateOrConnectWithoutProductsInput = {
  create: TemplateBadgeCreateWithoutProductsInput;
  where: TemplateBadgeWhereUniqueInput;
};

export type TemplateBadgeCreateWithoutClaimedBadgesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutBadgesInput;
  products?: InputMaybe<ProductCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TemplateBadgeCreateWithoutParentCommunityInput = {
  claimedBadges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutTemplateInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  products?: InputMaybe<ProductCreateNestedManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TemplateBadgeCreateWithoutProductsInput = {
  claimedBadges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutTemplateInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentCommunity: CommunityCreateNestedOneWithoutBadgesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TemplateBadgeListRelationFilter = {
  every?: InputMaybe<TemplateBadgeWhereInput>;
  none?: InputMaybe<TemplateBadgeWhereInput>;
  some?: InputMaybe<TemplateBadgeWhereInput>;
};

export type TemplateBadgeOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mediaUrl?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  parentCommunityId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type TemplateBadgeScalarWhereInput = {
  AND?: InputMaybe<Array<TemplateBadgeScalarWhereInput>>;
  NOT?: InputMaybe<Array<TemplateBadgeScalarWhereInput>>;
  OR?: InputMaybe<Array<TemplateBadgeScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCommunityId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TemplateBadgeUpdateInput = {
  claimedBadges?: InputMaybe<ClaimedBadgeUpdateManyWithoutTemplateInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutBadgesInput>;
  products?: InputMaybe<ProductUpdateManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateBadgeUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateBadgeUpdateManyWithWhereWithoutParentCommunityInput = {
  data: TemplateBadgeUpdateManyMutationInput;
  where: TemplateBadgeScalarWhereInput;
};

export type TemplateBadgeUpdateManyWithoutParentCommunityInput = {
  connect?: InputMaybe<Array<TemplateBadgeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TemplateBadgeCreateOrConnectWithoutParentCommunityInput>>;
  create?: InputMaybe<Array<TemplateBadgeCreateWithoutParentCommunityInput>>;
  createMany?: InputMaybe<TemplateBadgeCreateManyParentCommunityInputEnvelope>;
  delete?: InputMaybe<Array<TemplateBadgeWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TemplateBadgeScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TemplateBadgeWhereUniqueInput>>;
  set?: InputMaybe<Array<TemplateBadgeWhereUniqueInput>>;
  update?: InputMaybe<Array<TemplateBadgeUpdateWithWhereUniqueWithoutParentCommunityInput>>;
  updateMany?: InputMaybe<Array<TemplateBadgeUpdateManyWithWhereWithoutParentCommunityInput>>;
  upsert?: InputMaybe<Array<TemplateBadgeUpsertWithWhereUniqueWithoutParentCommunityInput>>;
};

export type TemplateBadgeUpdateOneRequiredWithoutClaimedBadgesInput = {
  connect?: InputMaybe<TemplateBadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateBadgeCreateOrConnectWithoutClaimedBadgesInput>;
  create?: InputMaybe<TemplateBadgeCreateWithoutClaimedBadgesInput>;
  update?: InputMaybe<TemplateBadgeUpdateWithoutClaimedBadgesInput>;
  upsert?: InputMaybe<TemplateBadgeUpsertWithoutClaimedBadgesInput>;
};

export type TemplateBadgeUpdateOneWithoutProductsInput = {
  connect?: InputMaybe<TemplateBadgeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TemplateBadgeCreateOrConnectWithoutProductsInput>;
  create?: InputMaybe<TemplateBadgeCreateWithoutProductsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TemplateBadgeUpdateWithoutProductsInput>;
  upsert?: InputMaybe<TemplateBadgeUpsertWithoutProductsInput>;
};

export type TemplateBadgeUpdateWithWhereUniqueWithoutParentCommunityInput = {
  data: TemplateBadgeUpdateWithoutParentCommunityInput;
  where: TemplateBadgeWhereUniqueInput;
};

export type TemplateBadgeUpdateWithoutClaimedBadgesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutBadgesInput>;
  products?: InputMaybe<ProductUpdateManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateBadgeUpdateWithoutParentCommunityInput = {
  claimedBadges?: InputMaybe<ClaimedBadgeUpdateManyWithoutTemplateInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  products?: InputMaybe<ProductUpdateManyWithoutBadgeInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateBadgeUpdateWithoutProductsInput = {
  claimedBadges?: InputMaybe<ClaimedBadgeUpdateManyWithoutTemplateInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  parentCommunity?: InputMaybe<CommunityUpdateOneRequiredWithoutBadgesInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TemplateBadgeUpsertWithWhereUniqueWithoutParentCommunityInput = {
  create: TemplateBadgeCreateWithoutParentCommunityInput;
  update: TemplateBadgeUpdateWithoutParentCommunityInput;
  where: TemplateBadgeWhereUniqueInput;
};

export type TemplateBadgeUpsertWithoutClaimedBadgesInput = {
  create: TemplateBadgeCreateWithoutClaimedBadgesInput;
  update: TemplateBadgeUpdateWithoutClaimedBadgesInput;
};

export type TemplateBadgeUpsertWithoutProductsInput = {
  create: TemplateBadgeCreateWithoutProductsInput;
  update: TemplateBadgeUpdateWithoutProductsInput;
};

export type TemplateBadgeWhereInput = {
  AND?: InputMaybe<Array<TemplateBadgeWhereInput>>;
  NOT?: InputMaybe<Array<TemplateBadgeWhereInput>>;
  OR?: InputMaybe<Array<TemplateBadgeWhereInput>>;
  claimedBadges?: InputMaybe<ClaimedBadgeListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringFilter>;
  parentCommunity?: InputMaybe<CommunityWhereInput>;
  parentCommunityId?: InputMaybe<StringFilter>;
  products?: InputMaybe<ProductListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TemplateBadgeWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TestInternetConnectionResponse = {
  __typename?: 'TestInternetConnectionResponse';
  errors?: Maybe<Array<Maybe<TranslatableError>>>;
  output?: Maybe<Scalars['String']>;
};

export type Token = {
  __typename?: 'Token';
  communityCode: Scalars['String'];
  communityName: Scalars['String'];
  tokenIds: Array<Maybe<Scalars['String']>>;
  tokenImageUrl?: Maybe<Scalars['String']>;
  tokenName: Scalars['String'];
  tokensAvailable: Scalars['Int'];
};

export type Tools = {
  __typename?: 'Tools';
  calendarEvents: Array<Maybe<Scalars['String']>>;
  inventory: Scalars['Boolean'];
  settings: Scalars['Boolean'];
};

export type Track = {
  __typename?: 'Track';
  artist?: Maybe<Scalars['String']>;
  artistDetails?: Maybe<Scalars['String']>;
  community: Community;
  coverUrl?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  end?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  mediaUrl?: Maybe<Scalars['String']>;
  socialMediaLinks: Array<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  status: TrackPublishingStatus;
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  type: TrackType;
};

export type TrackCreateInput = {
  artist?: InputMaybe<Scalars['String']>;
  artistDetails?: InputMaybe<Scalars['String']>;
  community: CommunityCreateNestedOneWithoutTrackInput;
  coverUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  socialMediaLinks?: InputMaybe<TrackCreatesocialMediaLinksInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<TrackPublishingStatus>;
  tags?: InputMaybe<TrackCreatetagsInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TrackType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TrackCreateManyCommunityInput = {
  artist?: InputMaybe<Scalars['String']>;
  artistDetails?: InputMaybe<Scalars['String']>;
  coverUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  socialMediaLinks?: InputMaybe<TrackCreateManysocialMediaLinksInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<TrackPublishingStatus>;
  tags?: InputMaybe<TrackCreateManytagsInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TrackType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TrackCreateManyCommunityInputEnvelope = {
  data?: InputMaybe<Array<TrackCreateManyCommunityInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TrackCreateManysocialMediaLinksInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackCreateManytagsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackCreateNestedManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<TrackWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TrackCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<TrackCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<TrackCreateManyCommunityInputEnvelope>;
};

export type TrackCreateOrConnectWithoutCommunityInput = {
  create: TrackCreateWithoutCommunityInput;
  where: TrackWhereUniqueInput;
};

export type TrackCreateWithoutCommunityInput = {
  artist?: InputMaybe<Scalars['String']>;
  artistDetails?: InputMaybe<Scalars['String']>;
  coverUrl?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  socialMediaLinks?: InputMaybe<TrackCreatesocialMediaLinksInput>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<TrackPublishingStatus>;
  tags?: InputMaybe<TrackCreatetagsInput>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TrackType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type TrackCreatesocialMediaLinksInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackCreatetagsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackListRelationFilter = {
  every?: InputMaybe<TrackWhereInput>;
  none?: InputMaybe<TrackWhereInput>;
  some?: InputMaybe<TrackWhereInput>;
};

export type TrackOrderByInput = {
  artist?: InputMaybe<SortOrder>;
  artistDetails?: InputMaybe<SortOrder>;
  communityId?: InputMaybe<SortOrder>;
  coverUrl?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrder>;
  duration?: InputMaybe<SortOrder>;
  end?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mediaUrl?: InputMaybe<SortOrder>;
  socialMediaLinks?: InputMaybe<SortOrder>;
  start?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  tags?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum TrackPublishingStatus {
  Deleted = 'Deleted',
  Draft = 'Draft',
  Published = 'Published',
  UneditedDraft = 'UneditedDraft'
}

export type TrackScalarWhereInput = {
  AND?: InputMaybe<Array<TrackScalarWhereInput>>;
  NOT?: InputMaybe<Array<TrackScalarWhereInput>>;
  OR?: InputMaybe<Array<TrackScalarWhereInput>>;
  artist?: InputMaybe<StringNullableFilter>;
  artistDetails?: InputMaybe<StringNullableFilter>;
  communityId?: InputMaybe<StringFilter>;
  coverUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  socialMediaLinks?: InputMaybe<StringNullableListFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumTrackPublishingStatusFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumTrackTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum TrackType {
  AudioLive = 'AudioLive',
  AudioRecorded = 'AudioRecorded'
}

export type TrackUpdateInput = {
  artist?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  artistDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  community?: InputMaybe<CommunityUpdateOneRequiredWithoutTrackInput>;
  coverUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  socialMediaLinks?: InputMaybe<TrackUpdatesocialMediaLinksInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTrackPublishingStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<TrackUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTrackTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TrackUpdateManyMutationInput = {
  artist?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  artistDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coverUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  socialMediaLinks?: InputMaybe<TrackUpdatesocialMediaLinksInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTrackPublishingStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<TrackUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTrackTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TrackUpdateManyWithWhereWithoutCommunityInput = {
  data: TrackUpdateManyMutationInput;
  where: TrackScalarWhereInput;
};

export type TrackUpdateManyWithoutCommunityInput = {
  connect?: InputMaybe<Array<TrackWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TrackCreateOrConnectWithoutCommunityInput>>;
  create?: InputMaybe<Array<TrackCreateWithoutCommunityInput>>;
  createMany?: InputMaybe<TrackCreateManyCommunityInputEnvelope>;
  delete?: InputMaybe<Array<TrackWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TrackScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TrackWhereUniqueInput>>;
  set?: InputMaybe<Array<TrackWhereUniqueInput>>;
  update?: InputMaybe<Array<TrackUpdateWithWhereUniqueWithoutCommunityInput>>;
  updateMany?: InputMaybe<Array<TrackUpdateManyWithWhereWithoutCommunityInput>>;
  upsert?: InputMaybe<Array<TrackUpsertWithWhereUniqueWithoutCommunityInput>>;
};

export type TrackUpdateWithWhereUniqueWithoutCommunityInput = {
  data: TrackUpdateWithoutCommunityInput;
  where: TrackWhereUniqueInput;
};

export type TrackUpdateWithoutCommunityInput = {
  artist?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  artistDetails?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  coverUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  description?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  duration?: InputMaybe<IntFieldUpdateOperationsInput>;
  end?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mediaUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  socialMediaLinks?: InputMaybe<TrackUpdatesocialMediaLinksInput>;
  start?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumTrackPublishingStatusFieldUpdateOperationsInput>;
  tags?: InputMaybe<TrackUpdatetagsInput>;
  title?: InputMaybe<StringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumTrackTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type TrackUpdatesocialMediaLinksInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackUpdatetagsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type TrackUpsertWithWhereUniqueWithoutCommunityInput = {
  create: TrackCreateWithoutCommunityInput;
  update: TrackUpdateWithoutCommunityInput;
  where: TrackWhereUniqueInput;
};

export type TrackWhereInput = {
  AND?: InputMaybe<Array<TrackWhereInput>>;
  NOT?: InputMaybe<Array<TrackWhereInput>>;
  OR?: InputMaybe<Array<TrackWhereInput>>;
  artist?: InputMaybe<StringNullableFilter>;
  artistDetails?: InputMaybe<StringNullableFilter>;
  community?: InputMaybe<CommunityWhereInput>;
  communityId?: InputMaybe<StringFilter>;
  coverUrl?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringNullableFilter>;
  duration?: InputMaybe<IntFilter>;
  end?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  mediaUrl?: InputMaybe<StringNullableFilter>;
  socialMediaLinks?: InputMaybe<StringNullableListFilter>;
  start?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumTrackPublishingStatusFilter>;
  tags?: InputMaybe<StringNullableListFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumTrackTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type TrackWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type TranslatableError = {
  __typename?: 'TranslatableError';
  errorCode?: Maybe<TranslatableErrorCode>;
  path?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum TranslatableErrorCode {
  AlreadyBought = 'AlreadyBought',
  AlreadyInUse = 'AlreadyInUse',
  InternalServerError = 'InternalServerError',
  InvalidInviteCode = 'InvalidInviteCode',
  InvalidPassword = 'InvalidPassword',
  InvalidPasswordResetCode = 'InvalidPasswordResetCode',
  InvalidVerificationCode = 'InvalidVerificationCode',
  InvalidWalletSignature = 'InvalidWalletSignature',
  NotAllowedToMutatePost = 'NotAllowedToMutatePost',
  NotAllowedToMutateUser = 'NotAllowedToMutateUser',
  ProductNotFound = 'ProductNotFound',
  SpecifyIdentifier = 'SpecifyIdentifier',
  SpecifyUsernameOrPhone = 'SpecifyUsernameOrPhone',
  UserNotFound = 'UserNotFound',
  UserNotFoundOAuth = 'UserNotFoundOAuth'
}

export type UbiqFeedItem = {
  __typename?: 'UbiqFeedItem';
  alreadyJoinedLinkedCommunity: Scalars['Boolean'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  headerCTAText: Scalars['String'];
  headerCTAUrl: Scalars['String'];
  headerIconUrl?: Maybe<Scalars['String']>;
  headerSecondaryText?: Maybe<Scalars['String']>;
  headerText: Scalars['String'];
  hideFromUserIds?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isSmallMedia: Scalars['Boolean'];
  linkedCommunity?: Maybe<Community>;
  linkedCommunityCode?: Maybe<Scalars['String']>;
  linkedItemId?: Maybe<Scalars['String']>;
  linkedModule?: Maybe<Module>;
  media: Array<Scalars['String']>;
  needToRequestAccess: Scalars['Boolean'];
  order?: Maybe<Scalars['Int']>;
  restrictToCommunities: Array<Community>;
  restrictToLocations: Array<Location>;
  restrictToUserIds?: Maybe<Scalars['String']>;
  showReactions: Scalars['Boolean'];
  status: UbiqFeedItemStatus;
  suggestJoin: Scalars['Boolean'];
  suggestionText: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  totalRequestedAccess?: Maybe<Scalars['Int']>;
  type: UbiqFeedItemType;
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};


export type UbiqFeedItemRestrictToCommunitiesArgs = {
  cursor?: InputMaybe<CommunityWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UbiqFeedItemRestrictToLocationsArgs = {
  cursor?: InputMaybe<LocationWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type UbiqFeedItemCreateInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  headerCTAText?: InputMaybe<Scalars['String']>;
  headerCTAUrl?: InputMaybe<Scalars['String']>;
  headerIconUrl?: InputMaybe<Scalars['String']>;
  headerSecondaryText?: InputMaybe<Scalars['String']>;
  headerText: Scalars['String'];
  hideFromUserIds?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isSmallMedia?: InputMaybe<Scalars['Boolean']>;
  linkedCommunityCode?: InputMaybe<Scalars['String']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  media?: InputMaybe<UbiqFeedItemCreatemediaInput>;
  order?: InputMaybe<Scalars['Int']>;
  requestedAccessUserIds?: InputMaybe<UbiqFeedItemCreaterequestedAccessUserIdsInput>;
  restrictToCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedFeedItemsInput>;
  restrictToLocations?: InputMaybe<LocationCreateNestedManyWithoutUbiqFeedItemsInput>;
  restrictToUserIds?: InputMaybe<Scalars['String']>;
  showReactions?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<UbiqFeedItemStatus>;
  suggestJoin?: InputMaybe<Scalars['Boolean']>;
  suggestionText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UbiqFeedItemType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UbiqFeedItemCreateNestedManyWithoutRestrictToCommunitiesInput = {
  connect?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UbiqFeedItemCreateOrConnectWithoutRestrictToCommunitiesInput>>;
  create?: InputMaybe<Array<UbiqFeedItemCreateWithoutRestrictToCommunitiesInput>>;
};

export type UbiqFeedItemCreateNestedManyWithoutRestrictToLocationsInput = {
  connect?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UbiqFeedItemCreateOrConnectWithoutRestrictToLocationsInput>>;
  create?: InputMaybe<Array<UbiqFeedItemCreateWithoutRestrictToLocationsInput>>;
};

export type UbiqFeedItemCreateOrConnectWithoutRestrictToCommunitiesInput = {
  create: UbiqFeedItemCreateWithoutRestrictToCommunitiesInput;
  where: UbiqFeedItemWhereUniqueInput;
};

export type UbiqFeedItemCreateOrConnectWithoutRestrictToLocationsInput = {
  create: UbiqFeedItemCreateWithoutRestrictToLocationsInput;
  where: UbiqFeedItemWhereUniqueInput;
};

export type UbiqFeedItemCreateWithoutRestrictToCommunitiesInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  headerCTAText?: InputMaybe<Scalars['String']>;
  headerCTAUrl?: InputMaybe<Scalars['String']>;
  headerIconUrl?: InputMaybe<Scalars['String']>;
  headerSecondaryText?: InputMaybe<Scalars['String']>;
  headerText: Scalars['String'];
  hideFromUserIds?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isSmallMedia?: InputMaybe<Scalars['Boolean']>;
  linkedCommunityCode?: InputMaybe<Scalars['String']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  media?: InputMaybe<UbiqFeedItemCreatemediaInput>;
  order?: InputMaybe<Scalars['Int']>;
  requestedAccessUserIds?: InputMaybe<UbiqFeedItemCreaterequestedAccessUserIdsInput>;
  restrictToLocations?: InputMaybe<LocationCreateNestedManyWithoutUbiqFeedItemsInput>;
  restrictToUserIds?: InputMaybe<Scalars['String']>;
  showReactions?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<UbiqFeedItemStatus>;
  suggestJoin?: InputMaybe<Scalars['Boolean']>;
  suggestionText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UbiqFeedItemType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UbiqFeedItemCreateWithoutRestrictToLocationsInput = {
  content: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  headerCTAText?: InputMaybe<Scalars['String']>;
  headerCTAUrl?: InputMaybe<Scalars['String']>;
  headerIconUrl?: InputMaybe<Scalars['String']>;
  headerSecondaryText?: InputMaybe<Scalars['String']>;
  headerText: Scalars['String'];
  hideFromUserIds?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isSmallMedia?: InputMaybe<Scalars['Boolean']>;
  linkedCommunityCode?: InputMaybe<Scalars['String']>;
  linkedItemId?: InputMaybe<Scalars['String']>;
  linkedModule?: InputMaybe<Module>;
  media?: InputMaybe<UbiqFeedItemCreatemediaInput>;
  order?: InputMaybe<Scalars['Int']>;
  requestedAccessUserIds?: InputMaybe<UbiqFeedItemCreaterequestedAccessUserIdsInput>;
  restrictToCommunities?: InputMaybe<CommunityCreateNestedManyWithoutRelatedFeedItemsInput>;
  restrictToUserIds?: InputMaybe<Scalars['String']>;
  showReactions?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<UbiqFeedItemStatus>;
  suggestJoin?: InputMaybe<Scalars['Boolean']>;
  suggestionText?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<UbiqFeedItemType>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UbiqFeedItemCreatemediaInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UbiqFeedItemCreaterequestedAccessUserIdsInput = {
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UbiqFeedItemListRelationFilter = {
  every?: InputMaybe<UbiqFeedItemWhereInput>;
  none?: InputMaybe<UbiqFeedItemWhereInput>;
  some?: InputMaybe<UbiqFeedItemWhereInput>;
};

export type UbiqFeedItemOrderByInput = {
  content?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  headerCTAText?: InputMaybe<SortOrder>;
  headerCTAUrl?: InputMaybe<SortOrder>;
  headerIconUrl?: InputMaybe<SortOrder>;
  headerSecondaryText?: InputMaybe<SortOrder>;
  headerText?: InputMaybe<SortOrder>;
  hideFromUserIds?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  isSmallMedia?: InputMaybe<SortOrder>;
  linkedCommunityCode?: InputMaybe<SortOrder>;
  linkedItemId?: InputMaybe<SortOrder>;
  linkedModule?: InputMaybe<SortOrder>;
  media?: InputMaybe<SortOrder>;
  order?: InputMaybe<SortOrder>;
  requestedAccessUserIds?: InputMaybe<SortOrder>;
  restrictToUserIds?: InputMaybe<SortOrder>;
  showReactions?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  suggestJoin?: InputMaybe<SortOrder>;
  suggestionText?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  url?: InputMaybe<SortOrder>;
};

export type UbiqFeedItemScalarWhereInput = {
  AND?: InputMaybe<Array<UbiqFeedItemScalarWhereInput>>;
  NOT?: InputMaybe<Array<UbiqFeedItemScalarWhereInput>>;
  OR?: InputMaybe<Array<UbiqFeedItemScalarWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  headerCTAText?: InputMaybe<StringNullableFilter>;
  headerCTAUrl?: InputMaybe<StringNullableFilter>;
  headerIconUrl?: InputMaybe<StringNullableFilter>;
  headerSecondaryText?: InputMaybe<StringNullableFilter>;
  headerText?: InputMaybe<StringFilter>;
  hideFromUserIds?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isSmallMedia?: InputMaybe<BoolFilter>;
  linkedCommunityCode?: InputMaybe<StringNullableFilter>;
  linkedItemId?: InputMaybe<StringNullableFilter>;
  linkedModule?: InputMaybe<EnumModuleNullableFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  order?: InputMaybe<IntNullableFilter>;
  requestedAccessUserIds?: InputMaybe<StringNullableListFilter>;
  restrictToUserIds?: InputMaybe<StringNullableFilter>;
  showReactions?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumUbiqFeedItemStatusFilter>;
  suggestJoin?: InputMaybe<BoolFilter>;
  suggestionText?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumUbiqFeedItemTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export enum UbiqFeedItemStatus {
  Active = 'Active',
  Hidden = 'Hidden'
}

export enum UbiqFeedItemType {
  Custom = 'Custom',
  EventDatesUpdated = 'EventDatesUpdated',
  EventDescriptionUpdated = 'EventDescriptionUpdated',
  EventLocationDropped = 'EventLocationDropped',
  EventLocationDroppingSoon = 'EventLocationDroppingSoon',
  EventPublished = 'EventPublished',
  NewsItemPublished = 'NewsItemPublished',
  ShopItemAlmostSoldOut = 'ShopItemAlmostSoldOut',
  ShopItemAvailable = 'ShopItemAvailable',
  ShopItemSoldout = 'ShopItemSoldout'
}

export type UbiqFeedItemUpdateInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  headerCTAText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerCTAUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerIconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerSecondaryText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<StringFieldUpdateOperationsInput>;
  hideFromUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSmallMedia?: InputMaybe<BoolFieldUpdateOperationsInput>;
  linkedCommunityCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  media?: InputMaybe<UbiqFeedItemUpdatemediaInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  requestedAccessUserIds?: InputMaybe<UbiqFeedItemUpdaterequestedAccessUserIdsInput>;
  restrictToCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedFeedItemsInput>;
  restrictToLocations?: InputMaybe<LocationUpdateManyWithoutUbiqFeedItemsInput>;
  restrictToUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  showReactions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUbiqFeedItemStatusFieldUpdateOperationsInput>;
  suggestJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  suggestionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUbiqFeedItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UbiqFeedItemUpdateManyMutationInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  headerCTAText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerCTAUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerIconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerSecondaryText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<StringFieldUpdateOperationsInput>;
  hideFromUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSmallMedia?: InputMaybe<BoolFieldUpdateOperationsInput>;
  linkedCommunityCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  media?: InputMaybe<UbiqFeedItemUpdatemediaInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  requestedAccessUserIds?: InputMaybe<UbiqFeedItemUpdaterequestedAccessUserIdsInput>;
  restrictToUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  showReactions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUbiqFeedItemStatusFieldUpdateOperationsInput>;
  suggestJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  suggestionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUbiqFeedItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UbiqFeedItemUpdateManyWithWhereWithoutRestrictToCommunitiesInput = {
  data: UbiqFeedItemUpdateManyMutationInput;
  where: UbiqFeedItemScalarWhereInput;
};

export type UbiqFeedItemUpdateManyWithWhereWithoutRestrictToLocationsInput = {
  data: UbiqFeedItemUpdateManyMutationInput;
  where: UbiqFeedItemScalarWhereInput;
};

export type UbiqFeedItemUpdateManyWithoutRestrictToCommunitiesInput = {
  connect?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UbiqFeedItemCreateOrConnectWithoutRestrictToCommunitiesInput>>;
  create?: InputMaybe<Array<UbiqFeedItemCreateWithoutRestrictToCommunitiesInput>>;
  delete?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UbiqFeedItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  set?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  update?: InputMaybe<Array<UbiqFeedItemUpdateWithWhereUniqueWithoutRestrictToCommunitiesInput>>;
  updateMany?: InputMaybe<Array<UbiqFeedItemUpdateManyWithWhereWithoutRestrictToCommunitiesInput>>;
  upsert?: InputMaybe<Array<UbiqFeedItemUpsertWithWhereUniqueWithoutRestrictToCommunitiesInput>>;
};

export type UbiqFeedItemUpdateManyWithoutRestrictToLocationsInput = {
  connect?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UbiqFeedItemCreateOrConnectWithoutRestrictToLocationsInput>>;
  create?: InputMaybe<Array<UbiqFeedItemCreateWithoutRestrictToLocationsInput>>;
  delete?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UbiqFeedItemScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  set?: InputMaybe<Array<UbiqFeedItemWhereUniqueInput>>;
  update?: InputMaybe<Array<UbiqFeedItemUpdateWithWhereUniqueWithoutRestrictToLocationsInput>>;
  updateMany?: InputMaybe<Array<UbiqFeedItemUpdateManyWithWhereWithoutRestrictToLocationsInput>>;
  upsert?: InputMaybe<Array<UbiqFeedItemUpsertWithWhereUniqueWithoutRestrictToLocationsInput>>;
};

export type UbiqFeedItemUpdateWithWhereUniqueWithoutRestrictToCommunitiesInput = {
  data: UbiqFeedItemUpdateWithoutRestrictToCommunitiesInput;
  where: UbiqFeedItemWhereUniqueInput;
};

export type UbiqFeedItemUpdateWithWhereUniqueWithoutRestrictToLocationsInput = {
  data: UbiqFeedItemUpdateWithoutRestrictToLocationsInput;
  where: UbiqFeedItemWhereUniqueInput;
};

export type UbiqFeedItemUpdateWithoutRestrictToCommunitiesInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  headerCTAText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerCTAUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerIconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerSecondaryText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<StringFieldUpdateOperationsInput>;
  hideFromUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSmallMedia?: InputMaybe<BoolFieldUpdateOperationsInput>;
  linkedCommunityCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  media?: InputMaybe<UbiqFeedItemUpdatemediaInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  requestedAccessUserIds?: InputMaybe<UbiqFeedItemUpdaterequestedAccessUserIdsInput>;
  restrictToLocations?: InputMaybe<LocationUpdateManyWithoutUbiqFeedItemsInput>;
  restrictToUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  showReactions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUbiqFeedItemStatusFieldUpdateOperationsInput>;
  suggestJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  suggestionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUbiqFeedItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UbiqFeedItemUpdateWithoutRestrictToLocationsInput = {
  content?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  headerCTAText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerCTAUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerIconUrl?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerSecondaryText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  headerText?: InputMaybe<StringFieldUpdateOperationsInput>;
  hideFromUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  isSmallMedia?: InputMaybe<BoolFieldUpdateOperationsInput>;
  linkedCommunityCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedItemId?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  linkedModule?: InputMaybe<NullableEnumModuleFieldUpdateOperationsInput>;
  media?: InputMaybe<UbiqFeedItemUpdatemediaInput>;
  order?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  requestedAccessUserIds?: InputMaybe<UbiqFeedItemUpdaterequestedAccessUserIdsInput>;
  restrictToCommunities?: InputMaybe<CommunityUpdateManyWithoutRelatedFeedItemsInput>;
  restrictToUserIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  showReactions?: InputMaybe<BoolFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUbiqFeedItemStatusFieldUpdateOperationsInput>;
  suggestJoin?: InputMaybe<BoolFieldUpdateOperationsInput>;
  suggestionText?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  title?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<EnumUbiqFeedItemTypeFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  url?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UbiqFeedItemUpdatemediaInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UbiqFeedItemUpdaterequestedAccessUserIdsInput = {
  push?: InputMaybe<Scalars['String']>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type UbiqFeedItemUpsertWithWhereUniqueWithoutRestrictToCommunitiesInput = {
  create: UbiqFeedItemCreateWithoutRestrictToCommunitiesInput;
  update: UbiqFeedItemUpdateWithoutRestrictToCommunitiesInput;
  where: UbiqFeedItemWhereUniqueInput;
};

export type UbiqFeedItemUpsertWithWhereUniqueWithoutRestrictToLocationsInput = {
  create: UbiqFeedItemCreateWithoutRestrictToLocationsInput;
  update: UbiqFeedItemUpdateWithoutRestrictToLocationsInput;
  where: UbiqFeedItemWhereUniqueInput;
};

export type UbiqFeedItemWhereInput = {
  AND?: InputMaybe<Array<UbiqFeedItemWhereInput>>;
  NOT?: InputMaybe<Array<UbiqFeedItemWhereInput>>;
  OR?: InputMaybe<Array<UbiqFeedItemWhereInput>>;
  content?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  headerCTAText?: InputMaybe<StringNullableFilter>;
  headerCTAUrl?: InputMaybe<StringNullableFilter>;
  headerIconUrl?: InputMaybe<StringNullableFilter>;
  headerSecondaryText?: InputMaybe<StringNullableFilter>;
  headerText?: InputMaybe<StringFilter>;
  hideFromUserIds?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isSmallMedia?: InputMaybe<BoolFilter>;
  linkedCommunityCode?: InputMaybe<StringNullableFilter>;
  linkedItemId?: InputMaybe<StringNullableFilter>;
  linkedModule?: InputMaybe<EnumModuleNullableFilter>;
  media?: InputMaybe<StringNullableListFilter>;
  order?: InputMaybe<IntNullableFilter>;
  requestedAccessUserIds?: InputMaybe<StringNullableListFilter>;
  restrictToCommunities?: InputMaybe<CommunityListRelationFilter>;
  restrictToLocations?: InputMaybe<LocationListRelationFilter>;
  restrictToUserIds?: InputMaybe<StringNullableFilter>;
  showReactions?: InputMaybe<BoolFilter>;
  status?: InputMaybe<EnumUbiqFeedItemStatusFilter>;
  suggestJoin?: InputMaybe<BoolFilter>;
  suggestionText?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<EnumUbiqFeedItemTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  url?: InputMaybe<StringNullableFilter>;
};

export type UbiqFeedItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type UbiqFeedItemsResponse = {
  __typename?: 'UbiqFeedItemsResponse';
  items: Array<UbiqFeedItem>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  avatar?: Maybe<Scalars['String']>;
  badges: Array<ClaimedBadge>;
  bio?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['DateTime']>;
  checkedInEvents: Array<Event>;
  communitiesWhereAdmin: Array<Maybe<Community>>;
  communitiesWhereMember: Array<Community>;
  communityMemberSince?: Maybe<Scalars['Date']>;
  communityOnboardingInfo?: Maybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding: Scalars['Boolean'];
  completedDiscoverOnboarding: Scalars['Boolean'];
  completedIDCardOnboarding: Scalars['Boolean'];
  completedInventoryOnboarding: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailVerificationCode?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  firstName?: Maybe<Scalars['String']>;
  fromWeb: Scalars['Boolean'];
  fullName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  invitedEvents: Array<Event>;
  lastName?: Maybe<Scalars['String']>;
  location?: Maybe<Location>;
  mcaVersion?: Maybe<Scalars['String']>;
  notificationsEnabled?: Maybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: Maybe<Scalars['DateTime']>;
  numberedId: Scalars['Int'];
  ownedInviteCodes: Array<InviteCode>;
  phone?: Maybe<Scalars['String']>;
  phoneVerified: Scalars['Boolean'];
  postalCode?: Maybe<Scalars['String']>;
  purchases: Array<Purchase>;
  pushPlayerIds: Scalars['String'];
  registeredEvents: Array<Event>;
  requirePasswordChange: Scalars['Boolean'];
  role: UserRole;
  socialLinks?: Maybe<Scalars['Json']>;
  solanaWalletAddress?: Maybe<Scalars['String']>;
  ticketsEvents: Array<Event>;
  updatedAt: Scalars['DateTime'];
  username?: Maybe<Scalars['String']>;
  versionOfWerz?: Maybe<Scalars['String']>;
};


export type UserBadgesArgs = {
  cursor?: InputMaybe<ClaimedBadgeWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type UserCheckedInEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type UserCommunitiesWhereMemberArgs = {
  cursor?: InputMaybe<CommunityWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<CommunityWhereInput>;
};


export type UserCommunityMemberSinceArgs = {
  communityId?: InputMaybe<Scalars['String']>;
};


export type UserInvitedEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type UserOwnedInviteCodesArgs = {
  cursor?: InputMaybe<InviteCodeWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<InviteCodeWhereInput>;
};


export type UserPurchasesArgs = {
  cursor?: InputMaybe<PurchaseWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PurchaseWhereInput>;
};


export type UserRegisteredEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};


export type UserTicketsEventsArgs = {
  cursor?: InputMaybe<EventWhereUniqueInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<EventWhereInput>;
};

export type UserCreateInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type UserCreateManyLocationInput = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  numberedId?: InputMaybe<Scalars['Int']>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateManyLocationInputEnvelope = {
  data?: InputMaybe<Array<UserCreateManyLocationInput>>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutCheckedInEventsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCheckedInEventsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCheckedInEventsInput>>;
};

export type UserCreateNestedManyWithoutCommunitiesWhereAdminInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCommunitiesWhereAdminInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCommunitiesWhereAdminInput>>;
};

export type UserCreateNestedManyWithoutCommunitiesWhereMemberInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCommunitiesWhereMemberInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCommunitiesWhereMemberInput>>;
};

export type UserCreateNestedManyWithoutCommunitiesWhereRequestedMemberInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCommunitiesWhereRequestedMemberInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCommunitiesWhereRequestedMemberInput>>;
};

export type UserCreateNestedManyWithoutFeedItemCommentLikesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFeedItemCommentLikesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFeedItemCommentLikesInput>>;
};

export type UserCreateNestedManyWithoutInvitedEventsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutInvitedEventsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutInvitedEventsInput>>;
};

export type UserCreateNestedManyWithoutLikedFeedItemsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLikedFeedItemsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLikedFeedItemsInput>>;
};

export type UserCreateNestedManyWithoutLikedForumCommentsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLikedForumCommentsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLikedForumCommentsInput>>;
};

export type UserCreateNestedManyWithoutLikedForumPostsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLikedForumPostsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLikedForumPostsInput>>;
};

export type UserCreateNestedManyWithoutLocationInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLocationInput>>;
  createMany?: InputMaybe<UserCreateManyLocationInputEnvelope>;
};

export type UserCreateNestedManyWithoutRegisteredEventsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutRegisteredEventsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutRegisteredEventsInput>>;
};

export type UserCreateNestedManyWithoutTicketsEventsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutTicketsEventsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutTicketsEventsInput>>;
};

export type UserCreateNestedOneWithoutActivityEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivityEventsInput>;
  create?: InputMaybe<UserCreateWithoutActivityEventsInput>;
};

export type UserCreateNestedOneWithoutBadgesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBadgesInput>;
  create?: InputMaybe<UserCreateWithoutBadgesInput>;
};

export type UserCreateNestedOneWithoutClaimedInviteCodeInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClaimedInviteCodeInput>;
  create?: InputMaybe<UserCreateWithoutClaimedInviteCodeInput>;
};

export type UserCreateNestedOneWithoutCommunitiesMembershipInfoInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommunitiesMembershipInfoInput>;
  create?: InputMaybe<UserCreateWithoutCommunitiesMembershipInfoInput>;
};

export type UserCreateNestedOneWithoutCreatedEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedEventsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedEventsInput>;
};

export type UserCreateNestedOneWithoutFeedItemCommentReportsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFeedItemCommentReportsInput>;
  create?: InputMaybe<UserCreateWithoutFeedItemCommentReportsInput>;
};

export type UserCreateNestedOneWithoutFeedItemCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFeedItemCommentsInput>;
  create?: InputMaybe<UserCreateWithoutFeedItemCommentsInput>;
};

export type UserCreateNestedOneWithoutForumCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutForumCommentsInput>;
  create?: InputMaybe<UserCreateWithoutForumCommentsInput>;
};

export type UserCreateNestedOneWithoutForumPostsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutForumPostsInput>;
  create?: InputMaybe<UserCreateWithoutForumPostsInput>;
};

export type UserCreateNestedOneWithoutNotificationsWhereRecipientInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsWhereRecipientInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsWhereRecipientInput>;
};

export type UserCreateNestedOneWithoutNotificationsWhereSenderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsWhereSenderInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsWhereSenderInput>;
};

export type UserCreateNestedOneWithoutOpenCallSubmissionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOpenCallSubmissionsInput>;
  create?: InputMaybe<UserCreateWithoutOpenCallSubmissionsInput>;
};

export type UserCreateNestedOneWithoutOwnedInviteCodesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOwnedInviteCodesInput>;
  create?: InputMaybe<UserCreateWithoutOwnedInviteCodesInput>;
};

export type UserCreateNestedOneWithoutPurchaseTransfersWhereRecipientInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchaseTransfersWhereRecipientInput>;
  create?: InputMaybe<UserCreateWithoutPurchaseTransfersWhereRecipientInput>;
};

export type UserCreateNestedOneWithoutPurchaseTransfersWhereSenderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchaseTransfersWhereSenderInput>;
  create?: InputMaybe<UserCreateWithoutPurchaseTransfersWhereSenderInput>;
};

export type UserCreateNestedOneWithoutPurchasesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<UserCreateWithoutPurchasesInput>;
};

export type UserCreateOrConnectWithoutActivityEventsInput = {
  create: UserCreateWithoutActivityEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutBadgesInput = {
  create: UserCreateWithoutBadgesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCheckedInEventsInput = {
  create: UserCreateWithoutCheckedInEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutClaimedInviteCodeInput = {
  create: UserCreateWithoutClaimedInviteCodeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommunitiesMembershipInfoInput = {
  create: UserCreateWithoutCommunitiesMembershipInfoInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommunitiesWhereAdminInput = {
  create: UserCreateWithoutCommunitiesWhereAdminInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommunitiesWhereMemberInput = {
  create: UserCreateWithoutCommunitiesWhereMemberInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCommunitiesWhereRequestedMemberInput = {
  create: UserCreateWithoutCommunitiesWhereRequestedMemberInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutCreatedEventsInput = {
  create: UserCreateWithoutCreatedEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFeedItemCommentLikesInput = {
  create: UserCreateWithoutFeedItemCommentLikesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFeedItemCommentReportsInput = {
  create: UserCreateWithoutFeedItemCommentReportsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFeedItemCommentsInput = {
  create: UserCreateWithoutFeedItemCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutForumCommentsInput = {
  create: UserCreateWithoutForumCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutForumPostsInput = {
  create: UserCreateWithoutForumPostsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutInvitedEventsInput = {
  create: UserCreateWithoutInvitedEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLikedFeedItemsInput = {
  create: UserCreateWithoutLikedFeedItemsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLikedForumCommentsInput = {
  create: UserCreateWithoutLikedForumCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLikedForumPostsInput = {
  create: UserCreateWithoutLikedForumPostsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutLocationInput = {
  create: UserCreateWithoutLocationInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNotificationsWhereRecipientInput = {
  create: UserCreateWithoutNotificationsWhereRecipientInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutNotificationsWhereSenderInput = {
  create: UserCreateWithoutNotificationsWhereSenderInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOpenCallSubmissionsInput = {
  create: UserCreateWithoutOpenCallSubmissionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutOwnedInviteCodesInput = {
  create: UserCreateWithoutOwnedInviteCodesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPurchaseTransfersWhereRecipientInput = {
  create: UserCreateWithoutPurchaseTransfersWhereRecipientInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPurchaseTransfersWhereSenderInput = {
  create: UserCreateWithoutPurchaseTransfersWhereSenderInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutPurchasesInput = {
  create: UserCreateWithoutPurchasesInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutRegisteredEventsInput = {
  create: UserCreateWithoutRegisteredEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutTicketsEventsInput = {
  create: UserCreateWithoutTicketsEventsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutActivityEventsInput = {
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutBadgesInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCheckedInEventsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutClaimedInviteCodeInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCommunitiesMembershipInfoInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCommunitiesWhereAdminInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCommunitiesWhereMemberInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCommunitiesWhereRequestedMemberInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutCreatedEventsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFeedItemCommentLikesInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFeedItemCommentReportsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutFeedItemCommentsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutForumCommentsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutForumPostsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutInvitedEventsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutLikedFeedItemsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutLikedForumCommentsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutLikedForumPostsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutLocationInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutNotificationsWhereRecipientInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutNotificationsWhereSenderInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutOpenCallSubmissionsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutOwnedInviteCodesInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutPurchaseTransfersWhereRecipientInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutPurchaseTransfersWhereSenderInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutPurchasesInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutRegisteredEventsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  ticketsEvents?: InputMaybe<EventCreateNestedManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserCreateWithoutTicketsEventsInput = {
  activityEvents?: InputMaybe<ActivityEventCreateNestedManyWithoutUserInput>;
  avatar?: InputMaybe<Scalars['String']>;
  badges?: InputMaybe<ClaimedBadgeCreateNestedManyWithoutOwnerInput>;
  bio?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  checkedInEvents?: InputMaybe<EventCreateNestedManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeCreateNestedManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoCreateNestedManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityCreateNestedManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityCreateNestedManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityCreateNestedManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedDiscoverOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedIDCardOnboarding?: InputMaybe<Scalars['Boolean']>;
  completedInventoryOnboarding?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdEvents?: InputMaybe<EventCreateNestedManyWithoutCreatorInput>;
  email?: InputMaybe<Scalars['String']>;
  emailVerificationCode?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentCreateNestedManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportCreateNestedManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentCreateNestedManyWithoutAuthorInput>;
  firstName?: InputMaybe<Scalars['String']>;
  forumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<Scalars['Boolean']>;
  fullName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  invitedEvents?: InputMaybe<EventCreateNestedManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<Scalars['String']>;
  likedFeedItems?: InputMaybe<FeedItemCreateNestedManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentCreateNestedManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostCreateNestedManyWithoutLikesInput>;
  location?: InputMaybe<LocationCreateNestedOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<Scalars['String']>;
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  notificationsLastPromptedAt?: InputMaybe<Scalars['DateTime']>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageCreateNestedManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageCreateNestedManyWithoutSenderInput>;
  numberedId?: InputMaybe<Scalars['Int']>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionCreateNestedManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeCreateNestedManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  passwordSalt?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  phoneVerificationCode?: InputMaybe<Scalars['String']>;
  phoneVerified?: InputMaybe<Scalars['Boolean']>;
  postalCode?: InputMaybe<Scalars['String']>;
  previousEmail?: InputMaybe<Scalars['String']>;
  previousEmailVerified?: InputMaybe<Scalars['Boolean']>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferCreateNestedManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferCreateNestedManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseCreateNestedManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<Scalars['String']>;
  registeredEvents?: InputMaybe<EventCreateNestedManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<UserRole>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  username?: InputMaybe<Scalars['String']>;
  versionOfWerz?: InputMaybe<Scalars['String']>;
};

export type UserIndicators = {
  __typename?: 'UserIndicators';
  feedOnHome: Scalars['Boolean'];
  inventory: Scalars['Boolean'];
  settings: Scalars['Boolean'];
  tools: Tools;
  unreadMessages: Scalars['Int'];
};

export enum UserInfoField {
  Dob = 'DOB',
  Email = 'Email',
  FirstName = 'FirstName',
  LastName = 'LastName',
  ProfilePicture = 'ProfilePicture'
}

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserOrderByInput = {
  avatar?: InputMaybe<SortOrder>;
  bio?: InputMaybe<SortOrder>;
  birthday?: InputMaybe<SortOrder>;
  communityOnboardingInfo?: InputMaybe<SortOrder>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<SortOrder>;
  completedDiscoverOnboarding?: InputMaybe<SortOrder>;
  completedIDCardOnboarding?: InputMaybe<SortOrder>;
  completedInventoryOnboarding?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerificationCode?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  fromWeb?: InputMaybe<SortOrder>;
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  locationId?: InputMaybe<SortOrder>;
  mcaVersion?: InputMaybe<SortOrder>;
  notificationsEnabled?: InputMaybe<SortOrder>;
  notificationsLastPromptedAt?: InputMaybe<SortOrder>;
  numberedId?: InputMaybe<SortOrder>;
  passwordHash?: InputMaybe<SortOrder>;
  passwordResetCode?: InputMaybe<SortOrder>;
  passwordSalt?: InputMaybe<SortOrder>;
  phone?: InputMaybe<SortOrder>;
  phoneVerificationCode?: InputMaybe<SortOrder>;
  phoneVerified?: InputMaybe<SortOrder>;
  postalCode?: InputMaybe<SortOrder>;
  previousEmail?: InputMaybe<SortOrder>;
  previousEmailVerified?: InputMaybe<SortOrder>;
  pushPlayerIds?: InputMaybe<SortOrder>;
  requirePasswordChange?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  socialLinks?: InputMaybe<SortOrder>;
  solanaWalletAddress?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
  versionOfWerz?: InputMaybe<SortOrder>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  birthday?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Administrator = 'Administrator',
  Intern = 'Intern',
  User = 'User'
}

export type UserScalarWhereInput = {
  AND?: InputMaybe<Array<UserScalarWhereInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereInput>>;
  OR?: InputMaybe<Array<UserScalarWhereInput>>;
  avatar?: InputMaybe<StringNullableFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  birthday?: InputMaybe<DateTimeNullableFilter>;
  communityOnboardingInfo?: InputMaybe<JsonNullableFilter>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFilter>;
  completedDiscoverOnboarding?: InputMaybe<BoolFilter>;
  completedIDCardOnboarding?: InputMaybe<BoolFilter>;
  completedInventoryOnboarding?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  emailVerificationCode?: InputMaybe<StringNullableFilter>;
  emailVerified?: InputMaybe<BoolFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  fromWeb?: InputMaybe<BoolFilter>;
  fullName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  locationId?: InputMaybe<StringNullableFilter>;
  mcaVersion?: InputMaybe<StringNullableFilter>;
  notificationsEnabled?: InputMaybe<BoolNullableFilter>;
  notificationsLastPromptedAt?: InputMaybe<DateTimeNullableFilter>;
  numberedId?: InputMaybe<IntFilter>;
  passwordHash?: InputMaybe<StringNullableFilter>;
  passwordResetCode?: InputMaybe<StringNullableFilter>;
  passwordSalt?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  phoneVerificationCode?: InputMaybe<StringNullableFilter>;
  phoneVerified?: InputMaybe<BoolFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  previousEmail?: InputMaybe<StringNullableFilter>;
  previousEmailVerified?: InputMaybe<BoolFilter>;
  pushPlayerIds?: InputMaybe<StringFilter>;
  requirePasswordChange?: InputMaybe<BoolFilter>;
  role?: InputMaybe<EnumUserRoleFilter>;
  socialLinks?: InputMaybe<JsonNullableFilter>;
  solanaWalletAddress?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumUserStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringNullableFilter>;
  versionOfWerz?: InputMaybe<StringNullableFilter>;
};

export enum UserStatus {
  Active = 'Active',
  DeleteRequested = 'DeleteRequested',
  Deleted = 'Deleted'
}

export type UserUpdateInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutCheckedInEventsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutCommunitiesWhereAdminInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutCommunitiesWhereMemberInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutCommunitiesWhereRequestedMemberInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutFeedItemCommentLikesInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutInvitedEventsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutLikedFeedItemsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutLikedForumCommentsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutLikedForumPostsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutLocationInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutRegisteredEventsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithWhereWithoutTicketsEventsInput = {
  data: UserUpdateManyMutationInput;
  where: UserScalarWhereInput;
};

export type UserUpdateManyWithoutCheckedInEventsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCheckedInEventsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCheckedInEventsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutCheckedInEventsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutCheckedInEventsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutCheckedInEventsInput>>;
};

export type UserUpdateManyWithoutCommunitiesWhereAdminInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCommunitiesWhereAdminInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCommunitiesWhereAdminInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutCommunitiesWhereAdminInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutCommunitiesWhereAdminInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutCommunitiesWhereAdminInput>>;
};

export type UserUpdateManyWithoutCommunitiesWhereMemberInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCommunitiesWhereMemberInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCommunitiesWhereMemberInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutCommunitiesWhereMemberInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutCommunitiesWhereMemberInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutCommunitiesWhereMemberInput>>;
};

export type UserUpdateManyWithoutCommunitiesWhereRequestedMemberInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutCommunitiesWhereRequestedMemberInput>>;
  create?: InputMaybe<Array<UserCreateWithoutCommunitiesWhereRequestedMemberInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutCommunitiesWhereRequestedMemberInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutCommunitiesWhereRequestedMemberInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutCommunitiesWhereRequestedMemberInput>>;
};

export type UserUpdateManyWithoutFeedItemCommentLikesInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFeedItemCommentLikesInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFeedItemCommentLikesInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutFeedItemCommentLikesInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutFeedItemCommentLikesInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutFeedItemCommentLikesInput>>;
};

export type UserUpdateManyWithoutInvitedEventsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutInvitedEventsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutInvitedEventsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutInvitedEventsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutInvitedEventsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutInvitedEventsInput>>;
};

export type UserUpdateManyWithoutLikedFeedItemsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLikedFeedItemsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLikedFeedItemsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutLikedFeedItemsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutLikedFeedItemsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutLikedFeedItemsInput>>;
};

export type UserUpdateManyWithoutLikedForumCommentsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLikedForumCommentsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLikedForumCommentsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutLikedForumCommentsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutLikedForumCommentsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutLikedForumCommentsInput>>;
};

export type UserUpdateManyWithoutLikedForumPostsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLikedForumPostsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLikedForumPostsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutLikedForumPostsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutLikedForumPostsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutLikedForumPostsInput>>;
};

export type UserUpdateManyWithoutLocationInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutLocationInput>>;
  create?: InputMaybe<Array<UserCreateWithoutLocationInput>>;
  createMany?: InputMaybe<UserCreateManyLocationInputEnvelope>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutLocationInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutLocationInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutLocationInput>>;
};

export type UserUpdateManyWithoutRegisteredEventsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutRegisteredEventsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutRegisteredEventsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutRegisteredEventsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutRegisteredEventsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutRegisteredEventsInput>>;
};

export type UserUpdateManyWithoutTicketsEventsInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutTicketsEventsInput>>;
  create?: InputMaybe<Array<UserCreateWithoutTicketsEventsInput>>;
  delete?: InputMaybe<Array<UserWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<UserScalarWhereInput>>;
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
  update?: InputMaybe<Array<UserUpdateWithWhereUniqueWithoutTicketsEventsInput>>;
  updateMany?: InputMaybe<Array<UserUpdateManyWithWhereWithoutTicketsEventsInput>>;
  upsert?: InputMaybe<Array<UserUpsertWithWhereUniqueWithoutTicketsEventsInput>>;
};

export type UserUpdateOneRequiredWithoutActivityEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutActivityEventsInput>;
  create?: InputMaybe<UserCreateWithoutActivityEventsInput>;
  update?: InputMaybe<UserUpdateWithoutActivityEventsInput>;
  upsert?: InputMaybe<UserUpsertWithoutActivityEventsInput>;
};

export type UserUpdateOneRequiredWithoutBadgesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutBadgesInput>;
  create?: InputMaybe<UserCreateWithoutBadgesInput>;
  update?: InputMaybe<UserUpdateWithoutBadgesInput>;
  upsert?: InputMaybe<UserUpsertWithoutBadgesInput>;
};

export type UserUpdateOneRequiredWithoutCommunitiesMembershipInfoInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCommunitiesMembershipInfoInput>;
  create?: InputMaybe<UserCreateWithoutCommunitiesMembershipInfoInput>;
  update?: InputMaybe<UserUpdateWithoutCommunitiesMembershipInfoInput>;
  upsert?: InputMaybe<UserUpsertWithoutCommunitiesMembershipInfoInput>;
};

export type UserUpdateOneRequiredWithoutFeedItemCommentReportsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFeedItemCommentReportsInput>;
  create?: InputMaybe<UserCreateWithoutFeedItemCommentReportsInput>;
  update?: InputMaybe<UserUpdateWithoutFeedItemCommentReportsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFeedItemCommentReportsInput>;
};

export type UserUpdateOneRequiredWithoutFeedItemCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutFeedItemCommentsInput>;
  create?: InputMaybe<UserCreateWithoutFeedItemCommentsInput>;
  update?: InputMaybe<UserUpdateWithoutFeedItemCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutFeedItemCommentsInput>;
};

export type UserUpdateOneRequiredWithoutForumCommentsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutForumCommentsInput>;
  create?: InputMaybe<UserCreateWithoutForumCommentsInput>;
  update?: InputMaybe<UserUpdateWithoutForumCommentsInput>;
  upsert?: InputMaybe<UserUpsertWithoutForumCommentsInput>;
};

export type UserUpdateOneRequiredWithoutForumPostsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutForumPostsInput>;
  create?: InputMaybe<UserCreateWithoutForumPostsInput>;
  update?: InputMaybe<UserUpdateWithoutForumPostsInput>;
  upsert?: InputMaybe<UserUpsertWithoutForumPostsInput>;
};

export type UserUpdateOneRequiredWithoutOpenCallSubmissionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOpenCallSubmissionsInput>;
  create?: InputMaybe<UserCreateWithoutOpenCallSubmissionsInput>;
  update?: InputMaybe<UserUpdateWithoutOpenCallSubmissionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutOpenCallSubmissionsInput>;
};

export type UserUpdateOneRequiredWithoutPurchaseTransfersWhereSenderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchaseTransfersWhereSenderInput>;
  create?: InputMaybe<UserCreateWithoutPurchaseTransfersWhereSenderInput>;
  update?: InputMaybe<UserUpdateWithoutPurchaseTransfersWhereSenderInput>;
  upsert?: InputMaybe<UserUpsertWithoutPurchaseTransfersWhereSenderInput>;
};

export type UserUpdateOneRequiredWithoutPurchasesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchasesInput>;
  create?: InputMaybe<UserCreateWithoutPurchasesInput>;
  update?: InputMaybe<UserUpdateWithoutPurchasesInput>;
  upsert?: InputMaybe<UserUpsertWithoutPurchasesInput>;
};

export type UserUpdateOneWithoutClaimedInviteCodeInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutClaimedInviteCodeInput>;
  create?: InputMaybe<UserCreateWithoutClaimedInviteCodeInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutClaimedInviteCodeInput>;
  upsert?: InputMaybe<UserUpsertWithoutClaimedInviteCodeInput>;
};

export type UserUpdateOneWithoutCreatedEventsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutCreatedEventsInput>;
  create?: InputMaybe<UserCreateWithoutCreatedEventsInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutCreatedEventsInput>;
  upsert?: InputMaybe<UserUpsertWithoutCreatedEventsInput>;
};

export type UserUpdateOneWithoutNotificationsWhereRecipientInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsWhereRecipientInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsWhereRecipientInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutNotificationsWhereRecipientInput>;
  upsert?: InputMaybe<UserUpsertWithoutNotificationsWhereRecipientInput>;
};

export type UserUpdateOneWithoutNotificationsWhereSenderInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutNotificationsWhereSenderInput>;
  create?: InputMaybe<UserCreateWithoutNotificationsWhereSenderInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutNotificationsWhereSenderInput>;
  upsert?: InputMaybe<UserUpsertWithoutNotificationsWhereSenderInput>;
};

export type UserUpdateOneWithoutOwnedInviteCodesInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutOwnedInviteCodesInput>;
  create?: InputMaybe<UserCreateWithoutOwnedInviteCodesInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutOwnedInviteCodesInput>;
  upsert?: InputMaybe<UserUpsertWithoutOwnedInviteCodesInput>;
};

export type UserUpdateOneWithoutPurchaseTransfersWhereRecipientInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutPurchaseTransfersWhereRecipientInput>;
  create?: InputMaybe<UserCreateWithoutPurchaseTransfersWhereRecipientInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<UserUpdateWithoutPurchaseTransfersWhereRecipientInput>;
  upsert?: InputMaybe<UserUpsertWithoutPurchaseTransfersWhereRecipientInput>;
};

export type UserUpdateV2Input = {
  communityOnboardingInfo?: InputMaybe<Scalars['JSON']>;
  mcaVersion?: InputMaybe<Scalars['String']>;
};

export type UserUpdateWithWhereUniqueWithoutCheckedInEventsInput = {
  data: UserUpdateWithoutCheckedInEventsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutCommunitiesWhereAdminInput = {
  data: UserUpdateWithoutCommunitiesWhereAdminInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutCommunitiesWhereMemberInput = {
  data: UserUpdateWithoutCommunitiesWhereMemberInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutCommunitiesWhereRequestedMemberInput = {
  data: UserUpdateWithoutCommunitiesWhereRequestedMemberInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutFeedItemCommentLikesInput = {
  data: UserUpdateWithoutFeedItemCommentLikesInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutInvitedEventsInput = {
  data: UserUpdateWithoutInvitedEventsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutLikedFeedItemsInput = {
  data: UserUpdateWithoutLikedFeedItemsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutLikedForumCommentsInput = {
  data: UserUpdateWithoutLikedForumCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutLikedForumPostsInput = {
  data: UserUpdateWithoutLikedForumPostsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutLocationInput = {
  data: UserUpdateWithoutLocationInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutRegisteredEventsInput = {
  data: UserUpdateWithoutRegisteredEventsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithWhereUniqueWithoutTicketsEventsInput = {
  data: UserUpdateWithoutTicketsEventsInput;
  where: UserWhereUniqueInput;
};

export type UserUpdateWithoutActivityEventsInput = {
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutBadgesInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCheckedInEventsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutClaimedInviteCodeInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCommunitiesMembershipInfoInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCommunitiesWhereAdminInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCommunitiesWhereMemberInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCommunitiesWhereRequestedMemberInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutCreatedEventsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFeedItemCommentLikesInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFeedItemCommentReportsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutFeedItemCommentsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutForumCommentsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutForumPostsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutInvitedEventsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLikedFeedItemsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLikedForumCommentsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLikedForumPostsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutLocationInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutNotificationsWhereRecipientInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutNotificationsWhereSenderInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutOpenCallSubmissionsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutOwnedInviteCodesInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPurchaseTransfersWhereRecipientInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPurchaseTransfersWhereSenderInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutPurchasesInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutRegisteredEventsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  ticketsEvents?: InputMaybe<EventUpdateManyWithoutTicketedUsersInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutTicketsEventsInput = {
  activityEvents?: InputMaybe<ActivityEventUpdateManyWithoutUserInput>;
  avatar?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  badges?: InputMaybe<ClaimedBadgeUpdateManyWithoutOwnerInput>;
  bio?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  birthday?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  checkedInEvents?: InputMaybe<EventUpdateManyWithoutCheckedInUsersInput>;
  claimedInviteCode?: InputMaybe<InviteCodeUpdateManyWithoutClaimerInput>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoUpdateManyWithoutUserInput>;
  communitiesWhereAdmin?: InputMaybe<CommunityUpdateManyWithoutAdminsInput>;
  communitiesWhereMember?: InputMaybe<CommunityUpdateManyWithoutMembersInput>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityUpdateManyWithoutRequestedMembersInput>;
  communityOnboardingInfo?: InputMaybe<Scalars['Json']>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedDiscoverOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedIDCardOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  completedInventoryOnboarding?: InputMaybe<BoolFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdEvents?: InputMaybe<EventUpdateManyWithoutCreatorInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentUpdateManyWithoutLikesInput>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportUpdateManyWithoutAuthorInput>;
  feedItemComments?: InputMaybe<FeedItemCommentUpdateManyWithoutAuthorInput>;
  firstName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  forumComments?: InputMaybe<ForumCommentUpdateManyWithoutAuthorInput>;
  forumPosts?: InputMaybe<ForumPostUpdateManyWithoutAuthorInput>;
  fromWeb?: InputMaybe<BoolFieldUpdateOperationsInput>;
  fullName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  invitedEvents?: InputMaybe<EventUpdateManyWithoutInvitedUsersInput>;
  lastName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  likedFeedItems?: InputMaybe<FeedItemUpdateManyWithoutLikesInput>;
  likedForumComments?: InputMaybe<ForumCommentUpdateManyWithoutLikesInput>;
  likedForumPosts?: InputMaybe<ForumPostUpdateManyWithoutLikesInput>;
  location?: InputMaybe<LocationUpdateOneWithoutUsersInput>;
  mcaVersion?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  notificationsEnabled?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  notificationsLastPromptedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageUpdateManyWithoutRecipientInput>;
  notificationsWhereSender?: InputMaybe<TalksMessageUpdateManyWithoutSenderInput>;
  numberedId?: InputMaybe<IntFieldUpdateOperationsInput>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionUpdateManyWithoutUserInput>;
  ownedInviteCodes?: InputMaybe<InviteCodeUpdateManyWithoutOwnerInput>;
  passwordHash?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordResetCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  passwordSalt?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phone?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerificationCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  phoneVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  postalCode?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmail?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  previousEmailVerified?: InputMaybe<BoolFieldUpdateOperationsInput>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferUpdateManyWithoutRecipientInput>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferUpdateManyWithoutSenderInput>;
  purchases?: InputMaybe<PurchaseUpdateManyWithoutOwnerInput>;
  pushPlayerIds?: InputMaybe<StringFieldUpdateOperationsInput>;
  registeredEvents?: InputMaybe<EventUpdateManyWithoutRegisteredUsersInput>;
  requirePasswordChange?: InputMaybe<BoolFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumUserRoleFieldUpdateOperationsInput>;
  socialLinks?: InputMaybe<Scalars['Json']>;
  solanaWalletAddress?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  status?: InputMaybe<EnumUserStatusFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  username?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  versionOfWerz?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type UserUpsertWithWhereUniqueWithoutCheckedInEventsInput = {
  create: UserCreateWithoutCheckedInEventsInput;
  update: UserUpdateWithoutCheckedInEventsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutCommunitiesWhereAdminInput = {
  create: UserCreateWithoutCommunitiesWhereAdminInput;
  update: UserUpdateWithoutCommunitiesWhereAdminInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutCommunitiesWhereMemberInput = {
  create: UserCreateWithoutCommunitiesWhereMemberInput;
  update: UserUpdateWithoutCommunitiesWhereMemberInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutCommunitiesWhereRequestedMemberInput = {
  create: UserCreateWithoutCommunitiesWhereRequestedMemberInput;
  update: UserUpdateWithoutCommunitiesWhereRequestedMemberInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutFeedItemCommentLikesInput = {
  create: UserCreateWithoutFeedItemCommentLikesInput;
  update: UserUpdateWithoutFeedItemCommentLikesInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutInvitedEventsInput = {
  create: UserCreateWithoutInvitedEventsInput;
  update: UserUpdateWithoutInvitedEventsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutLikedFeedItemsInput = {
  create: UserCreateWithoutLikedFeedItemsInput;
  update: UserUpdateWithoutLikedFeedItemsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutLikedForumCommentsInput = {
  create: UserCreateWithoutLikedForumCommentsInput;
  update: UserUpdateWithoutLikedForumCommentsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutLikedForumPostsInput = {
  create: UserCreateWithoutLikedForumPostsInput;
  update: UserUpdateWithoutLikedForumPostsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutLocationInput = {
  create: UserCreateWithoutLocationInput;
  update: UserUpdateWithoutLocationInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutRegisteredEventsInput = {
  create: UserCreateWithoutRegisteredEventsInput;
  update: UserUpdateWithoutRegisteredEventsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithWhereUniqueWithoutTicketsEventsInput = {
  create: UserCreateWithoutTicketsEventsInput;
  update: UserUpdateWithoutTicketsEventsInput;
  where: UserWhereUniqueInput;
};

export type UserUpsertWithoutActivityEventsInput = {
  create: UserCreateWithoutActivityEventsInput;
  update: UserUpdateWithoutActivityEventsInput;
};

export type UserUpsertWithoutBadgesInput = {
  create: UserCreateWithoutBadgesInput;
  update: UserUpdateWithoutBadgesInput;
};

export type UserUpsertWithoutClaimedInviteCodeInput = {
  create: UserCreateWithoutClaimedInviteCodeInput;
  update: UserUpdateWithoutClaimedInviteCodeInput;
};

export type UserUpsertWithoutCommunitiesMembershipInfoInput = {
  create: UserCreateWithoutCommunitiesMembershipInfoInput;
  update: UserUpdateWithoutCommunitiesMembershipInfoInput;
};

export type UserUpsertWithoutCreatedEventsInput = {
  create: UserCreateWithoutCreatedEventsInput;
  update: UserUpdateWithoutCreatedEventsInput;
};

export type UserUpsertWithoutFeedItemCommentReportsInput = {
  create: UserCreateWithoutFeedItemCommentReportsInput;
  update: UserUpdateWithoutFeedItemCommentReportsInput;
};

export type UserUpsertWithoutFeedItemCommentsInput = {
  create: UserCreateWithoutFeedItemCommentsInput;
  update: UserUpdateWithoutFeedItemCommentsInput;
};

export type UserUpsertWithoutForumCommentsInput = {
  create: UserCreateWithoutForumCommentsInput;
  update: UserUpdateWithoutForumCommentsInput;
};

export type UserUpsertWithoutForumPostsInput = {
  create: UserCreateWithoutForumPostsInput;
  update: UserUpdateWithoutForumPostsInput;
};

export type UserUpsertWithoutNotificationsWhereRecipientInput = {
  create: UserCreateWithoutNotificationsWhereRecipientInput;
  update: UserUpdateWithoutNotificationsWhereRecipientInput;
};

export type UserUpsertWithoutNotificationsWhereSenderInput = {
  create: UserCreateWithoutNotificationsWhereSenderInput;
  update: UserUpdateWithoutNotificationsWhereSenderInput;
};

export type UserUpsertWithoutOpenCallSubmissionsInput = {
  create: UserCreateWithoutOpenCallSubmissionsInput;
  update: UserUpdateWithoutOpenCallSubmissionsInput;
};

export type UserUpsertWithoutOwnedInviteCodesInput = {
  create: UserCreateWithoutOwnedInviteCodesInput;
  update: UserUpdateWithoutOwnedInviteCodesInput;
};

export type UserUpsertWithoutPurchaseTransfersWhereRecipientInput = {
  create: UserCreateWithoutPurchaseTransfersWhereRecipientInput;
  update: UserUpdateWithoutPurchaseTransfersWhereRecipientInput;
};

export type UserUpsertWithoutPurchaseTransfersWhereSenderInput = {
  create: UserCreateWithoutPurchaseTransfersWhereSenderInput;
  update: UserUpdateWithoutPurchaseTransfersWhereSenderInput;
};

export type UserUpsertWithoutPurchasesInput = {
  create: UserCreateWithoutPurchasesInput;
  update: UserUpdateWithoutPurchasesInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activityEvents?: InputMaybe<ActivityEventListRelationFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  badges?: InputMaybe<ClaimedBadgeListRelationFilter>;
  bio?: InputMaybe<StringNullableFilter>;
  birthday?: InputMaybe<DateTimeNullableFilter>;
  checkedInEvents?: InputMaybe<EventListRelationFilter>;
  claimedInviteCode?: InputMaybe<InviteCodeListRelationFilter>;
  communitiesMembershipInfo?: InputMaybe<CommunityMemberInfoListRelationFilter>;
  communitiesWhereAdmin?: InputMaybe<CommunityListRelationFilter>;
  communitiesWhereMember?: InputMaybe<CommunityListRelationFilter>;
  communitiesWhereRequestedMember?: InputMaybe<CommunityListRelationFilter>;
  communityOnboardingInfo?: InputMaybe<JsonNullableFilter>;
  completedDiscoverCollectionsOnboarding?: InputMaybe<BoolFilter>;
  completedDiscoverOnboarding?: InputMaybe<BoolFilter>;
  completedIDCardOnboarding?: InputMaybe<BoolFilter>;
  completedInventoryOnboarding?: InputMaybe<BoolFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdEvents?: InputMaybe<EventListRelationFilter>;
  email?: InputMaybe<StringNullableFilter>;
  emailVerificationCode?: InputMaybe<StringNullableFilter>;
  emailVerified?: InputMaybe<BoolFilter>;
  feedItemCommentLikes?: InputMaybe<FeedItemCommentListRelationFilter>;
  feedItemCommentReports?: InputMaybe<FeedItemCommentReportListRelationFilter>;
  feedItemComments?: InputMaybe<FeedItemCommentListRelationFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  forumComments?: InputMaybe<ForumCommentListRelationFilter>;
  forumPosts?: InputMaybe<ForumPostListRelationFilter>;
  fromWeb?: InputMaybe<BoolFilter>;
  fullName?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invitedEvents?: InputMaybe<EventListRelationFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  likedFeedItems?: InputMaybe<FeedItemListRelationFilter>;
  likedForumComments?: InputMaybe<ForumCommentListRelationFilter>;
  likedForumPosts?: InputMaybe<ForumPostListRelationFilter>;
  location?: InputMaybe<LocationWhereInput>;
  locationId?: InputMaybe<StringNullableFilter>;
  mcaVersion?: InputMaybe<StringNullableFilter>;
  notificationsEnabled?: InputMaybe<BoolNullableFilter>;
  notificationsLastPromptedAt?: InputMaybe<DateTimeNullableFilter>;
  notificationsWhereRecipient?: InputMaybe<TalksMessageListRelationFilter>;
  notificationsWhereSender?: InputMaybe<TalksMessageListRelationFilter>;
  numberedId?: InputMaybe<IntFilter>;
  openCallSubmissions?: InputMaybe<OpenCallSubmissionListRelationFilter>;
  ownedInviteCodes?: InputMaybe<InviteCodeListRelationFilter>;
  passwordHash?: InputMaybe<StringNullableFilter>;
  passwordResetCode?: InputMaybe<StringNullableFilter>;
  passwordSalt?: InputMaybe<StringNullableFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  phoneVerificationCode?: InputMaybe<StringNullableFilter>;
  phoneVerified?: InputMaybe<BoolFilter>;
  postalCode?: InputMaybe<StringNullableFilter>;
  previousEmail?: InputMaybe<StringNullableFilter>;
  previousEmailVerified?: InputMaybe<BoolFilter>;
  purchaseTransfersWhereRecipient?: InputMaybe<PurchaseTransferListRelationFilter>;
  purchaseTransfersWhereSender?: InputMaybe<PurchaseTransferListRelationFilter>;
  purchases?: InputMaybe<PurchaseListRelationFilter>;
  pushPlayerIds?: InputMaybe<StringFilter>;
  registeredEvents?: InputMaybe<EventListRelationFilter>;
  requirePasswordChange?: InputMaybe<BoolFilter>;
  role?: InputMaybe<EnumUserRoleFilter>;
  socialLinks?: InputMaybe<JsonNullableFilter>;
  solanaWalletAddress?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumUserStatusFilter>;
  ticketsEvents?: InputMaybe<EventListRelationFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  username?: InputMaybe<StringNullableFilter>;
  versionOfWerz?: InputMaybe<StringNullableFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  passwordResetCode?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type ValidateInviteCodePayload = {
  __typename?: 'ValidateInviteCodePayload';
  community?: Maybe<Community>;
  inviteCode?: Maybe<InviteCode>;
  type?: Maybe<InviteCodeType>;
};

export enum WaitlistSource {
  Mobile = 'Mobile',
  Website = 'Website'
}

export type WaitlistUser = {
  __typename?: 'WaitlistUser';
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  source: WaitlistSource;
  updatedAt: Scalars['DateTime'];
};

export type WaitlistUserCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<WaitlistSource>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type WaitlistUserOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  source?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type WaitlistUserUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  source?: InputMaybe<EnumWaitlistSourceFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WaitlistUserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  source?: InputMaybe<EnumWaitlistSourceFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type WaitlistUserWhereInput = {
  AND?: InputMaybe<Array<WaitlistUserWhereInput>>;
  NOT?: InputMaybe<Array<WaitlistUserWhereInput>>;
  OR?: InputMaybe<Array<WaitlistUserWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  source?: InputMaybe<EnumWaitlistSourceFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type WaitlistUserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type CaAnnouncementsResult = {
  __typename?: 'caAnnouncementsResult';
  items: Array<FeedItem>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaCommunityMembersResponse = {
  __typename?: 'caCommunityMembersResponse';
  members: Array<User>;
  next: Scalars['Int'];
  totalMembers: Scalars['Int'];
};

export type CaCommunityMembersStatsResponse = {
  __typename?: 'caCommunityMembersStatsResponse';
  breakdown?: Maybe<Array<Maybe<DailyMembersData>>>;
  newMembers?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type CaCommunityOverviewStatsResponse = {
  __typename?: 'caCommunityOverviewStatsResponse';
  newMembers?: Maybe<Scalars['Int']>;
  newPurchases?: Maybe<Scalars['Int']>;
  newRevenue?: Maybe<Scalars['Float']>;
  totalMembers?: Maybe<Scalars['Int']>;
  totalPurchases?: Maybe<Scalars['Int']>;
  totalRevenue?: Maybe<Scalars['Float']>;
};

export type CaCommunityRevenueStatusResponse = {
  __typename?: 'caCommunityRevenueStatusResponse';
  breakdown?: Maybe<Array<Maybe<CommunityDailyRevenueData>>>;
  total?: Maybe<Scalars['Float']>;
  totalPurchases?: Maybe<Scalars['Float']>;
};

export type CaCommunityThemesResult = {
  __typename?: 'caCommunityThemesResult';
  items: Array<CommunityTheme>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaEventAnalyticsResult = {
  __typename?: 'caEventAnalyticsResult';
  boughtTickets: Scalars['Int'];
  checkedIn: Scalars['Int'];
  members: Scalars['Int'];
  registered: Scalars['Int'];
  ticketsSold: Scalars['Int'];
};

export type CaEventCategoriesResult = {
  __typename?: 'caEventCategoriesResult';
  items: Array<EventCategory>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaEventCreateOrUpdateInput = {
  additionalDetails?: InputMaybe<Scalars['String']>;
  allowFreeGuestList?: InputMaybe<Scalars['Boolean']>;
  buyTicketsLink?: InputMaybe<Scalars['String']>;
  categories?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  end?: InputMaybe<Scalars['DateTime']>;
  invitedUsers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isPrivate?: InputMaybe<Scalars['Boolean']>;
  latitude?: InputMaybe<Scalars['Float']>;
  location?: InputMaybe<Scalars['String']>;
  locationAvailability?: InputMaybe<Scalars['String']>;
  locationDescription?: InputMaybe<Scalars['String']>;
  locationDropTime?: InputMaybe<Scalars['DateTime']>;
  longitude?: InputMaybe<Scalars['Float']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  registrationRequired?: InputMaybe<Scalars['Boolean']>;
  shouldInviteCommunityMembersAutomatically?: InputMaybe<Scalars['Boolean']>;
  start?: InputMaybe<Scalars['DateTime']>;
  venueName?: InputMaybe<Scalars['String']>;
};

export type CaEventGuestsResult = {
  __typename?: 'caEventGuestsResult';
  guests: Array<CommunityMemberInfo>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaEventsResult = {
  __typename?: 'caEventsResult';
  items: Array<Event>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaMassMessagesResult = {
  __typename?: 'caMassMessagesResult';
  items: Array<MassMessage>;
  next: Scalars['Int'];
  notifsThisMonth: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaPotentialAdminsResponse = {
  __typename?: 'caPotentialAdminsResponse';
  next: Scalars['Int'];
  total: Scalars['Int'];
  users: Array<User>;
};

export type CaProductUsageStatsResponse = {
  __typename?: 'caProductUsageStatsResponse';
  breakdown?: Maybe<Array<Maybe<ProductUsageDailyData>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CaPromocodeUsageStatsResponse = {
  __typename?: 'caPromocodeUsageStatsResponse';
  breakdown?: Maybe<Array<Maybe<PromocodeUsageDailyData>>>;
  total?: Maybe<Scalars['Int']>;
};

export type CaPromocodesResentUsagesReturn = {
  __typename?: 'caPromocodesResentUsagesReturn';
  items: Array<Purchase>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaPromocodesReturn = {
  __typename?: 'caPromocodesReturn';
  items: Array<Promocode>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaPurchasesFunnelResponse = {
  __typename?: 'caPurchasesFunnelResponse';
  keyMetrics?: Maybe<KeyMetricsData>;
};

export type CaPurchasesResult = {
  __typename?: 'caPurchasesResult';
  items: Array<Purchase>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaRadioTrackCreateOrUpdateInput = {
  artist?: InputMaybe<Scalars['String']>;
  artistDetails?: InputMaybe<Scalars['String']>;
  coverUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['Int']>;
  end?: InputMaybe<Scalars['DateTime']>;
  mediaUrl?: InputMaybe<Scalars['String']>;
  socialMediaLinks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  start?: InputMaybe<Scalars['DateTime']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TrackType>;
};

export type CaRadioTracksResult = {
  __typename?: 'caRadioTracksResult';
  items: Array<Track>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CaScheduleItemsResult = {
  __typename?: 'caScheduleItemsResult';
  items: Array<CatalogItemSchedule>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type CheckoutPostReturnResult = {
  __typename?: 'checkoutPostReturnResult';
  byEmail: Scalars['Boolean'];
  currency: Scalars['String'];
  discount: Scalars['Float'];
  fees: Scalars['Float'];
  purchases: Array<Purchase>;
  status: Scalars['String'];
  vat: Scalars['Float'];
};

export type CommunityInventoryResult = {
  __typename?: 'communityInventoryResult';
  availableTypes?: Maybe<Array<Maybe<ProductType>>>;
  purchases?: Maybe<Array<Maybe<Purchase>>>;
};

export type CommunityInviteCodesResult = {
  __typename?: 'communityInviteCodesResult';
  codes: Array<InviteCode>;
  count: Scalars['Int'];
};

export type CommunityLandingResult = {
  __typename?: 'communityLandingResult';
  community: CommunityResult;
  latestNews?: Maybe<Array<LatestNewsResult>>;
  otherLinks?: Maybe<Array<OtherLinksResult>>;
  upcomingEvents?: Maybe<Array<UpcomingEventsResult>>;
};

export type CommunityResult = {
  __typename?: 'communityResult';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  socialLinks?: Maybe<Array<SocialLinksResult>>;
};

export type EventResult = {
  __typename?: 'eventResult';
  event?: Maybe<Event>;
  numberOfTicketsAvailableSoon?: Maybe<Scalars['Float']>;
  numberOfTicketsAvailableToCheckIn?: Maybe<Scalars['Float']>;
  numberOfTicketsChecked?: Maybe<Scalars['Float']>;
  numberOfTicketsToTransfer?: Maybe<Scalars['Float']>;
  totalTickets?: Maybe<Scalars['Float']>;
};

export type InventoryEventResult = {
  __typename?: 'inventoryEventResult';
  event?: Maybe<Event>;
  purchases?: Maybe<Array<Maybe<Purchase>>>;
};

export type InventoryV2Result = {
  __typename?: 'inventoryV2Result';
  communitiesWithPurchases?: Maybe<Array<Maybe<Community>>>;
  communityShops?: Maybe<Array<CommunityShop>>;
  eventsWithPurchases?: Maybe<Array<EventResult>>;
  featured?: Maybe<Array<FeaturedItem>>;
  myLatest?: Maybe<Array<MyLatestItem>>;
  productsForSale: Array<Maybe<Product>>;
  purchases?: Maybe<Array<Maybe<Purchase>>>;
  tokens: Array<Token>;
};

export type LatestNewsResult = {
  __typename?: 'latestNewsResult';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type OtherLinksResult = {
  __typename?: 'otherLinksResult';
  icon?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ProductsResult = {
  __typename?: 'productsResult';
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
};

export type RadioTracksReturn = {
  __typename?: 'radioTracksReturn';
  items: Array<Track>;
  next: Scalars['Int'];
  total: Scalars['Int'];
};

export type SocialLinksResult = {
  __typename?: 'socialLinksResult';
  platform?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UpcomingEventsResult = {
  __typename?: 'upcomingEventsResult';
  buyTicketsLink?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  locationAvailable?: Maybe<Scalars['Boolean']>;
  mediaUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<Array<ProductsResult>>;
  start?: Maybe<Scalars['DateTime']>;
};

export type LoginMutationVariables = Exact<{
  identifier: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login?: { __typename?: 'AuthPayload', token?: string | null, errors?: Array<{ __typename?: 'TranslatableError', errorCode?: TranslatableErrorCode | null, path?: Array<string | null> | null } | null> | null, user?: { __typename?: 'User', email?: string | null, firstName?: string | null, id: string, lastName?: string | null, username?: string | null } | null } | null };


export const LoginDocument = gql`
    mutation Login($identifier: String!, $password: String!) {
  login(identifier: $identifier, password: $password) {
    errors {
      ... on TranslatableError {
        errorCode
        path
      }
    }
    token
    user {
      email
      firstName
      id
      lastName
      username
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      identifier: // value for 'identifier'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;