import React, { FC } from "react"
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateTimeInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { EditToolbar } from "./utils"

export const HighlightCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="message" />
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput reference="Event" source="event">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const HighlightEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <TextInput source="title" />
      <TextInput source="message" />
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="mediaUrl" />
      <MediaWithUpload
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        filename={props["id"] as string}
        label="Media URL"
        path="highlight/media"
        source="mediaUrl"
      />
      <TextInput source="mediaPreviewUrl" />
      <MediaWithUpload
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        filename={props["id"] as string}
        label="Media URL"
        path="highlight/preview"
        source="mediaPreviewUrl"
      />
      <DateTimeInput source="createdAt" />
    </SimpleForm>
  </Edit>
)

export const HighlightList: FC<any> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="message" />
      <ReferenceField reference="Community" source="community">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)
