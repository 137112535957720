import { FC } from "react"
import {
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextField,
  TextInput,
} from "react-admin"
import { JsonInput } from "react-admin-json-view"

import { ConfigurationType } from "./generated/graphql"
import { CustomSegmentUserIdsInput } from "./mass-message"

const configurationTypes = [
  ...Object.values(ConfigurationType).map((k) => ({
    id: k,
    name: k,
  })),
]

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" />
      <SelectInput choices={configurationTypes} source="type" />
      <CustomSegmentUserIdsInput
        required
        source="userIds"
        style={{
          width: 900,
        }}
      />
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: true,
          name: null,
          quotesOnKeys: false,
        }}
        source="value"
      />
      <DateTimeInput source="createdAt" />
      {props.children}
    </SimpleForm>
  )
}

export const ConfigurationCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const ConfigurationEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm />
    </Edit>
  )
}

export const ConfigurationList: FC<any> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ChipField source="type" />
      <DateField showTime source="createdAt" />
      <DateField showTime source="updatedAt" />
    </Datagrid>
  </List>
)
