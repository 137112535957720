import { gql, GraphQLClient } from "graphql-request"

export const client = new GraphQLClient(
  (process.env.REACT_APP_API_URL ?? "/") + "?client=werz-admin",
)

export const login = (
  identifier: string,
  password: string,
): Promise<{ login?: { token?: string } }> => {
  return client.request(
    gql`
      mutation Login($identifier: String!, $password: String!) {
        login(identifier: $identifier, password: $password) {
          token
        }
      }
    `,
    {
      identifier,
      password,
    },
  )
}
