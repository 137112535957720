import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  ArrayInput,
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextField,
  TextInput,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { TruncatedTextField } from "./components/TruncatedTextField"
import { FeedItemPublishingStatus } from "./generated/graphql"
import { SearchFilter } from "./utils"

const feedItemStatusChoices = [
  ...Object.keys(FeedItemPublishingStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

export const FeedItemCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput source="title" />
      <MarkdownInput source="description" />
      <TextInput label="Read More Link" source="watchLink" />
    </SimpleForm>
  </Create>
)

export const FeedItemEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceInput reference="Community" source="parentCommunity">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <TextInput source="title" />
        <MarkdownInput source="description" />
        <SelectInput choices={feedItemStatusChoices} source="publishingStatus" />
        <NumberField source="likesCount" />
        <NumberInput source="likesBoost" />
        <TextInput label="Read More Link" source="watchLink" />
        <ArrayInput source="media">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, scopedFormData }) => {
                const { media, id: feedItemId } = formData as {
                  media: string[]
                  id: string
                }
                const url = scopedFormData as string
                let index = media.findIndex((m) => m === url)
                if (index === -1) index = media.length
                return (
                  <MediaWithUpload
                    path={`feedItem/media/${feedItemId}_${index}`}
                    source={`media.${index}`}
                  />
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <DateTimeInput source="createdAt" />
      </SimpleForm>
    </Edit>
  )
}

export const FeedItemList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)} perPage={25}>
    <Datagrid rowClick="edit">
      <ReferenceField label="Community" reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="title" />
      <TextField source="publishingStatus" />
      <TruncatedTextField source="description" />
      <NumberField source="likesCount" />
      <DateField label="Date" source="createdAt" />
    </Datagrid>
  </List>
)
