import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  Create,
  Datagrid,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"
import { JsonField, JsonInput } from "react-admin-json-view"

import { SearchFilter } from "./utils"

export const FaqCategoryCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <NumberInput source="order" />
      <TextInput source="name" />
      <ReferenceArrayInput reference="FaqItem" source="faqItems">
        <AutocompleteArrayInput optionText="question" />
      </ReferenceArrayInput>
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const FaqCategoryEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <NumberInput source="order" />
      <TextInput source="name" />
      <ReferenceArrayInput reference="FaqItem" source="faqItems">
        <AutocompleteArrayInput optionText="question" />
      </ReferenceArrayInput>
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Edit>
)

export const FaqCategoryList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
)

export const FaqCategoryShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextInput source="name" />
      <NumberField source="order" />
      <JsonField
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleShowLayout>
  </Show>
)
