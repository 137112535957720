import React, { FC } from "react"
import {
  AutocompleteInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"

import { SearchFilter } from "./utils"

export const InviteCodeCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="code" />
      <ReferenceInput label="Community" reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Owner" reference="User" source="owner">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const InviteCodeEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="code" />
      <ReferenceInput label="Community" reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput label="Owner" reference="User" source="owner">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput label="Claimer" reference="User" source="claimer">
        <AutocompleteInput allowEmpty optionText="username" />
      </ReferenceInput>
      <TextField source="type" />
      <DateField showTime={true} source="createdAt" />
      <DateField showTime={true} source="claimedAt" />
    </SimpleForm>
  </Edit>
)

export const InviteCodeList: FC<any> = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="Community" source="community.name" />
        <TextInput label="Owner" source="owner.username" />
        <TextInput label="Claimer" source="claimer.username" />
      </SearchFilter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="code" />
      <ReferenceField label="Community" reference="Community" source="community">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Owner" reference="User" source="owner">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Claimer" reference="User" source="claimer">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="type" />
    </Datagrid>
  </List>
)

export const InviteCodeShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="code" />
      <ReferenceField label="Community" reference="Community" source="community">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Owner" reference="User" source="owner">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Claimer" reference="User" source="claimer">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="type" />
      <DateField showTime={true} source="createdAt" />
      <DateField showTime={true} source="claimedAt" />
    </SimpleShowLayout>
  </Show>
)
