/* eslint-disable @typescript-eslint/no-unused-vars */
import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  List,
  ReferenceField,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleFormProps,
  TextField,
  TextInput,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { TrackPublishingStatus } from "./generated/graphql"
import { SearchFilter } from "./utils"

const trackStatusChoices = [
  ...Object.keys(TrackPublishingStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

type STEP_CONFIG = {
  type: "input" | "multipleInput" | "selection" | "mediaSelection" | "addFile"
  title: string
  inputLabel: string
  required?: boolean
  subtype?: "short" | "long" | "single" | "multiple"
  characterLimit?: number
  options?: (string | { uri: string; title: string })[]
  inputs?: string[]
}

interface FormDataConsumerProps {
  formData: any // You can further type this based on your form structure
  scopedFormData: STEP_CONFIG
  getSource: (name: string) => string
}

interface ConfigInputProps {
  source: string
}

const ConfigInput: FC<ConfigInputProps> = ({ source }) => (
  <ArrayInput source={source}>
    <SimpleFormIterator>
      <FormDataConsumer>
        {({ formData, scopedFormData, getSource }: FormDataConsumerProps) => {
          return (
            scopedFormData &&
            scopedFormData.type && (
              <div>
                <SelectInput
                  choices={[
                    { id: "input", name: "Input" },
                    { id: "multipleInput", name: "Multiple Input" },
                    { id: "selection", name: "Selection" },
                    { id: "media", name: "Media" },
                    { id: "addFile", name: "Add File" },
                  ]}
                  source={getSource("type")}
                  validate={required()}
                />
                <br />
                <TextInput source={getSource("title")} validate={required()} />
                <br />
                <TextInput source={getSource("inputLabel")} validate={required()} />
                <br />
                <BooleanInput source={getSource("required")} />
                {["input", "media", "selection"].includes(scopedFormData.type) && (
                  <SelectInput
                    choices={[
                      ...(scopedFormData.type === "input"
                        ? [
                            { id: "short", name: "Short" },
                            { id: "long", name: "Long" },
                          ]
                        : []),
                      ...(scopedFormData.type === "mediaSelection" ||
                      scopedFormData.type === "selection"
                        ? [
                            { id: "single", name: "Single" },
                            { id: "multiple", name: "Multiple" },
                          ]
                        : []),
                    ]}
                    source={getSource("subtype")}
                  />
                )}
                <br />
                {scopedFormData.type === "input" && scopedFormData.subtype === "long" && (
                  <TextInput source={getSource("characterLimit")} />
                )}
                <br />
                {scopedFormData.type === "selection" && (
                  <ArrayInput source={getSource("options")}>
                    <SimpleFormIterator>
                      <TextInput label="Option" source="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                )}
                {scopedFormData.type === "mediaSelection" && (
                  <ArrayInput source={getSource("options")}>
                    <SimpleFormIterator>
                      <FormDataConsumer>
                        {({ scopedFormData: optionData, getSource: getOptionSource }) => (
                          <div>
                            <TextInput label="URI" source={getOptionSource("uri")} />
                            <br />
                            <TextInput label="Title" source={getOptionSource("title")} />
                          </div>
                        )}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                )}

                {scopedFormData.type === "multipleInput" && (
                  <ArrayInput source={getSource("inputs")}>
                    <SimpleFormIterator>
                      <TextInput source="" />
                    </SimpleFormIterator>
                  </ArrayInput>
                )}
              </div>
            )
          )
        }}
      </FormDataConsumer>
    </SimpleFormIterator>
  </ArrayInput>
)

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  const id = props["id"] as string | undefined
  return (
    <SimpleForm {...props}>
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput isRequired source="title" />
      <MarkdownInput source="description" />
      <SelectInput choices={trackStatusChoices} source="status" />
      <MediaWithUpload filename={id} path="openCall/media" source="mediaUrl" />
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <ConfigInput source="config" />
      {props.children}
    </SimpleForm>
  )
}

export const OpenCallCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const OpenCallEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm />
    </Edit>
  )
}

export const OpenCallList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <ReferenceField reference="Community" source="community">
        <ChipField source="name" />
      </ReferenceField>
      <ChipField source="status" />
      <DateField source="startDate" />
      <DateField source="endDate" />
    </Datagrid>
  </List>
)
