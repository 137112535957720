import React from "react"
import { useInput } from "react-admin"
import Dropzone from "react-dropzone"

import { useUploader } from "../hooks/useUploader"

interface MediaWithUploadProps {
  path: string
  filename?: string
  label?: string
  source?: string
}

const MediaWithUpload: React.FC<MediaWithUploadProps> = ({
  label,
  source = "mediaUrl",
  path,
  filename,
}) => {
  const { input } = useInput({ source })
  const { uploading, onDrop } = useUploader({
    filename,
    onChange: input.onChange,
    path,
  })
  const displayUrl = (input.value as string) ?? ""
  let displayType = "image"
  if (displayUrl.includes("type=video")) {
    displayType = "video"
  }
  if (displayUrl.includes("type=audio")) {
    displayType = "audio"
  }
  return (
    <div style={{ flex: 1, flexDirection: "column", marginBottom: 32 }}>
      {label && (
        <p className="MuiFormLabel-root" style={{ fontSize: "0.8rem" }}>
          {label}
        </p>
      )}
      {displayType === "image" ? (
        <img src={displayUrl} style={{ maxHeight: 500, maxWidth: 500 }} />
      ) : null}
      {displayType === "video" ? (
        <video controls src={displayUrl} style={{ maxHeight: 500, maxWidth: 500 }} />
      ) : null}
      {displayType === "audio" ? (
        <audio controls>
          <source src={displayUrl} />
        </audio>
      ) : null}
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section
            style={{
              backgroundColor: "lightgray",
              cursor: "pointer",
              marginTop: 8,
              padding: 32,
              width: "20%",
            }}
          >
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p className="MuiTypography-body1">
                {uploading === "uploading"
                  ? "Uploading..."
                  : "Drag 'n' drop some files here, or click to select files"}
              </p>
            </div>
          </section>
        )}
      </Dropzone>
    </div>
  )
}

export default MediaWithUpload
