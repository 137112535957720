import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceArrayInput,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { SearchFilter } from "./utils"

export const TemplateBadgeCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const TemplateBadgeEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput reference="Community" source="parentCommunity">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <MediaWithUpload
          filename={
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            props["id"] as string
          }
          label="Icon"
          path="badge/icon/"
          source="mediaUrl"
        />
        <ReferenceArrayInput reference="Product" source="products">
          <AutocompleteArrayInput optionText="code" />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="ClaimedBadge" source="claimedBadges">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export const TemplateBadgeList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
)

export const TemplateBadgeShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
)
