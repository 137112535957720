import React, { ReactElement } from "react"
import { CreateButton, ExportButton, List, ListProps, ToolbarProps, TopToolbar } from "react-admin"

export const ListActions: React.FC<ToolbarProps> = (props) => (
  <TopToolbar {...props}>
    <CreateButton />
    <ExportButton maxResults={10000} />
  </TopToolbar>
)

export const BulkList: React.FC<ListProps & { children: ReactElement }> = ({
  children,
  ...props
}) => (
  <List {...props} actions={<ListActions />}>
    {children}
  </List>
)

export default BulkList
