import React, { FC } from "react"
import {
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"
import { JsonField, JsonInput } from "react-admin-json-view"

import MediaWithUpload from "./components/MediaWithUpload"
import { CatalogItemSchedulePublishingStatus } from "./generated/graphql"
import { SearchFilter } from "./utils"

const statusChoices = [
  ...Object.keys(CatalogItemSchedulePublishingStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

export const CatalogItemScheduleCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="stageName" />
      <ReferenceInput allowEmpty label="Artist Name" reference="CatalogItem" source="artist">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput choices={statusChoices} source="publishingStatus" />
      <DateTimeInput source="start" />
      <DateTimeInput source="end" />
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const CatalogItemScheduleEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <TextInput source="stageName" />
      <SelectInput choices={statusChoices} source="publishingStatus" />
      <ReferenceInput allowEmpty label="Artist Name" reference="CatalogItem" source="artist">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <MediaWithUpload
        filename={
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          props["id"] as string
        }
        label="Stage Photo"
        path="module/schedule"
      />
      <DateTimeInput source="start" />
      <DateTimeInput source="end" />
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Edit>
)

export const CatalogItemScheduleList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="stageName" />
      <ReferenceField reference="CatalogItem" source="artist">
        <ChipField source="name" />
      </ReferenceField>
      <DateField source="start" />
      <DateField source="end" />
    </Datagrid>
  </List>
)

export const CatalogItemScheduleShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="stageName" />
      <JsonField
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleShowLayout>
  </Show>
)
