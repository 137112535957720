import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"
import { JsonInput } from "react-admin-json-view"

import MediaWithUpload from "./components/MediaWithUpload"
import { EventLocationAvailability, EventStatus } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"

const eventStatusChoices = [
  ...Object.keys(EventStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

const eventLocationAvailabilityChoices = [
  ...Object.keys(EventLocationAvailability).map((k) => ({
    id: k,
    name: k,
  })),
]

export const EventCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <ReferenceArrayInput reference="Community" source="relatedCommunities">
        <AutocompleteArrayInput source="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="EventCategory" source="categories">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput source="name" />
      <MarkdownInput source="additionalDetails" />
      <DateTimeInput source="start" />
      <DateTimeInput source="end" />
      <MediaWithUpload
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        filename={props["id"] as string}
        label="Additional Details Image"
        path="event/additionalDetails"
        source="mediaUrl"
      />
      <TextInput label="Register / Read more link" source="registerLink" />
      <TextInput source="buyTicketsLink" />
      <BooleanInput source="allowFreeGuestList" />
      <SelectInput choices={eventStatusChoices} source="publishingStatus" />
      <br />
      <BooleanInput source="registrationRequired" />
      <SelectInput choices={eventLocationAvailabilityChoices} source="locationAvailability" />
      <TextInput source="venueName" />
      <TextInput source="location" />
      <DateTimeInput source="locationDropTime" />
      <MarkdownInput source="locationDescription" />
      <NumberInput source="latitude" />
      <NumberInput source="longitude" />
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const EventEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source="id" />
        <ReferenceInput reference="Community" source="parentCommunity">
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceArrayInput reference="Community" source="relatedCommunities">
          <AutocompleteArrayInput source="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="EventCategory" source="categories">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <TextInput source="name" />
        <MarkdownInput source="additionalDetails" />
        <DateTimeInput source="start" />
        <DateTimeInput source="end" />
        <MediaWithUpload
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          filename={props["id"] as string}
          label="Additional Details Image"
          path="event/additionalDetails"
          source="mediaUrl"
        />
        <ReferenceArrayInput reference="User" source="recommendedBy">
          <AutocompleteArrayInput source="username" />
        </ReferenceArrayInput>
        <TextInput source="buyTicketsLink" />
        <BooleanInput source="allowFreeGuestList" />
        <ReferenceArrayInput reference="Event" source="connectedEvents">
          <AutocompleteArrayInput source="name" />
        </ReferenceArrayInput>
        <br />
        <BooleanInput source="registrationRequired" />
        <SelectInput choices={eventLocationAvailabilityChoices} source="locationAvailability" />
        <TextInput source="venueName" />
        <TextInput source="location" />
        <DateTimeInput source="locationDropTime" />
        <BooleanInput source="locationDropNotificationSent" />
        <MarkdownInput source="locationDescription" />
        <NumberInput source="latitude" />
        <NumberInput source="longitude" />
        <SelectInput choices={eventStatusChoices} source="publishingStatus" />
        <br />
        <NumberField source="ticketsSold" />
        <NumberField source="ticketsTotal" />
        <NumberField source="grossRevenue" />
      </SimpleForm>
    </Edit>
  )
}

export const EventList: FC<any> = (props) => (
  <List
    {...props}
    filter={{
      publishingStatus: {
        in: ["Draft", "Active", "CompletedDraft"],
      },
    }}
    filters={SearchFilter(props)}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="publishingStatus" />
      <DateField source="start" />
      <DateField source="end" />
      <TextField source="locationAvailability" />
      <TextField source="location" />
      <DateField source="locationDropTime" />
      <NumberField source="ticketsSold" />
      <NumberField source="ticketsTotal" />
      <NumberField source="grossRevenue" />
    </Datagrid>
  </List>
)
