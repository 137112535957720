import React, { FC } from "react"
import { ChipField, Datagrid, DateField, List, ReferenceField, TextInput } from "react-admin"

import { SearchFilter } from "./utils"

export const PurchaseTransferList: FC<any> = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="PurchaseId" source="purchaseId" />
      </SearchFilter>
    }
  >
    <Datagrid rowClick="edit">
      <ReferenceField reference="User" source="sender">
        <ChipField source="username" />
      </ReferenceField>
      <ReferenceField reference="User" source="recipient">
        <ChipField source="username" />
      </ReferenceField>
      <ReferenceField reference="Purchase" source="purchase">
        <ChipField source="id" />
      </ReferenceField>
      <DateField showTime source="createdAt" />
    </Datagrid>
  </List>
)
