import React, { FC } from "react"
import {
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  SimpleFormProps,
  TextField,
  TextInput,
} from "react-admin"

import { SearchFilter } from "./utils"

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <TextInput isRequired source="name" />
      {props.children}
    </SimpleForm>
  )
}

export const ForumTagCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const ForumTagEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm>
        <DateField showTime source="updatedAt" />
        <DateField showTime source="createdAt" />
      </CreateEditForm>
    </Edit>
  )
}

export const ForumTagList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceField reference="Community" source="community">
        <ChipField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)
