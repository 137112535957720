import React, { FC } from "react"
import {
  ChipField,
  Datagrid,
  DateField,
  List,
  ReferenceField,
  Show,
  SimpleShowLayout,
  useRecordContext,
} from "react-admin"

import { SearchFilter } from "./utils"

const JsonField: React.FC<{ source: string }> = ({ source }) => {
  const record = useRecordContext()
  if (!record) return null

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const jsonData = record[source]
  const jsonString = JSON.stringify(jsonData, null, 2) // Pretty print JSON

  return <pre style={{ background: "#f6f8fa", padding: "10px" }}>{jsonString}</pre>
}

export const OpenCallSubmissionShow: FC<any> = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <ReferenceField reference="OpenCall" source="openCall">
          <ChipField source="title" />
        </ReferenceField>
        <ReferenceField reference="User" source="user">
          <ChipField source="username" />
        </ReferenceField>
        <DateField showTime source="createdAt" />
        <JsonField source="data" />
      </SimpleShowLayout>
    </Show>
  )
}

export const OpenCallSubmissionList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="show">
      <ReferenceField reference="OpenCall" source="openCall">
        <ChipField source="title" />
      </ReferenceField>
      <ReferenceField reference="User" source="user">
        <ChipField source="username" />
      </ReferenceField>
      <DateField showTime source="createdAt" />
    </Datagrid>
  </List>
)
