import { createId } from "@paralleldrive/cuid2"
import imageCompression from "browser-image-compression"
import { gql } from "graphql-request"
import { useState } from "react"

import { client as graphQLClient } from "../utils/api"

interface UploaderProps {
  path: string
  filename?: string
  onChange?: (ns: string) => void
}

interface UploaderReturn {
  onDrop: (files: File[]) => void
  uploading: string
}

export const useUploader: (props: UploaderProps) => UploaderReturn = ({
  filename = createId(),
  path,
  onChange,
}) => {
  const [uploading, setUploading] = useState("none")
  const mutation = gql`
    mutation CreateMedia($filename: String, $mimeType: String!, $subpath: String!) {
      createMedia(filename: $filename, mimeType: $mimeType, subpath: $subpath) {
        uploadURL
      }
    }
  `
  const onDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()

    try {
      let compressedFile = file
      console.log(file.type)
      if (file.type.includes("image")) {
        if (file.type.includes("gif")) {
          compressedFile = file
        } else {
          compressedFile = await imageCompression(file, {
            maxSizeMB: 1,
            maxWidthOrHeight: 1280,
            useWebWorker: true,
          })
        }
      }

      reader.onabort = () => console.error("File reading aborted. Please try again")
      reader.onerror = () => console.error("File reading failed. Please try again", "error")
      reader.onload = async () => {
        try {
          setUploading("uploading")
          const binaryStr = reader.result
          let type = "image"
          if (compressedFile.type.includes("video")) {
            type = "video"
          }
          if (compressedFile.type.includes("audio")) {
            type = "audio"
          }
          const hash = Date.now()
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const mediaData = await graphQLClient.request(mutation, {
            filename: filename,
            mimeType: compressedFile.type, // Use compressed file type
            subpath: path,
          })
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          const uploadURL = mediaData["createMedia"]["uploadURL"] as string
          await fetch(uploadURL, {
            body: binaryStr,
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": compressedFile.type, // Use compressed file type
            },
            method: "PUT",
          })
          setUploading("uploaded")
          const fileUrl = `${uploadURL.split("?")[0]}?uploadTime=${hash}&type=${type}`
          onChange && onChange(fileUrl)
        } catch (err) {
          alert(err)
          setUploading("error")
          console.error(err)
        }
      }
      reader.readAsArrayBuffer(compressedFile) // Use compressed file
    } catch (err) {
      alert(err)
      setUploading("error")
      console.error(err)
    }
  }

  return { onDrop, uploading }
}
