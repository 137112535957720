import { FC } from "react"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"

import { SearchFilter } from "./utils"

export const WaitlistUserCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" />
    </SimpleForm>
  </Create>
)

export const WaitlistUserEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="email" />
      <TextField source="source" />
      <DateField showTime={true} source="createdAt" />
    </SimpleForm>
  </Edit>
)

export const WaitlistUserList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="email" />
      <TextField source="source" />
      <DateField showTime={true} source="createdAt" />
    </Datagrid>
  </List>
)

export const WaitlistUserShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="email" />
      <TextField source="source" />
      <DateField showTime={true} source="createdAt" />
    </SimpleShowLayout>
  </Show>
)
