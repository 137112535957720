import { gql } from "graphql-request"
import React, { FC, useEffect } from "react"
import {
  ArrayField,
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextField,
  TextInput,
  TextInputProps,
  useInput,
} from "react-admin"
import { useFormState } from "react-final-form"

import {
  MassMessageSegment,
  MassMessageStatus,
  Module,
  TalksMessageType,
} from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"
import { client as graphQLClient } from "./utils/api"

const massMessageSegmentChoices = [
  ...Object.keys(MassMessageSegment).map((k) => ({
    id: k,
    name: k,
  })),
]

const massMessageStatusChoices = [
  ...Object.keys(MassMessageStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

const talksMessageTypeChoices = [
  ...Object.keys(TalksMessageType).map((k) => ({
    id: k,
    name: k,
  })),
]

const availableModules = [
  ...Object.values(Module).map((k) => ({
    id: k,
    name: k,
  })),
]

const scheme = process.env.REACT_APP_DEEPLINK_SCHEME || "werz"

const mapModuleToDeeplinkPath = (module: Module, communityCode) => {
  switch (module) {
    case Module.EventList:
      if (communityCode === "ug" || communityCode === "eventssthlm") {
        return "events-calendar"
      }
      return "events"
    case Module.Feed:
      return "news"
    case Module.Shop:
      return "shop"
    default:
      return "unknown"
  }
}

const constructDeeplink = (communityCode: string, module: Module, itemId?: string) => {
  const moduleMapped = mapModuleToDeeplinkPath(module, communityCode) as Module
  let newDeeplink = `${scheme}://c/${communityCode}&module=${moduleMapped as string}`
  if (itemId) {
    newDeeplink += `?itemIdToOpen=${itemId}`
  }
  return newDeeplink
}

const DeeplinkInput = (props: TextInputProps) => {
  const { input } = useInput({ source: props.source })
  const { values } = useFormState()
  useEffect(() => {
    if (!values.actionDeepLink && values.linkedModule && values.community) {
      input.onChange(constructDeeplink(values.community, values.linkedModule, values.linkedItemId))
    }
  }, [values.linkedModule, values.linkedItemId])
  return <TextInput {...props} style={{ width: 900 }} />
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomSegmentUserIdsInput = (props: TextInputProps) => {
  const token = localStorage.getItem("token")
  const query = gql`
    query CheckUserIDs($ids: String!) {
      allUserIDsExist(ids: $ids) {
        allExist
        missingIDs
      }
    }
  `
  const { values } = useFormState()
  useEffect(() => {
    if (values.customSegmentUserIds && !token) {
      alert("No token found in local storage, won't be able to validate IDs")
    }
    if (values.customSegmentUserIds && token) {
      // check if ids are valid
      graphQLClient
        .setHeader("Authorization", token)
        .request(query, {
          ids: values.customSegmentUserIds as string,
        })
        .then((data) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (!data.allUserIDsExist.allExist) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            alert(
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access
              "Invalid Custom Segment UserIDs: " + (data.allUserIDsExist.missingIDs as string),
            )
          }
        })
        .catch((err) => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          alert("Error while validating IDs: " + err)
        })
    }
  }, [values.customSegmentUserIds])
  return <TextInput {...props} multiline style={{ width: 900 }} />
}

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <TextInput helperText="Internal labeling" required source="name" />
      <TextInput source="title" />
      <TextInput required source="message" style={{ width: 900 }} />

      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="immediateSend" />
      <DateTimeInput source="sendAt" />

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const { input: linkedItemIdInput } = useInput({ source: "linkedItemId" })
          return (
            <>
              <SelectInput
                choices={availableModules}
                onChange={() => {
                  linkedItemIdInput.onChange("")
                }}
                source="linkedModule"
              />
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                formData.linkedModule === Module.EventList && (
                  <ReferenceInput reference="Event" required source="linkedItemId" {...rest}>
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                )
              }
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                formData.linkedModule === Module.Feed && (
                  <ReferenceInput reference="FeedItem" required source="linkedItemId" {...rest}>
                    <AutocompleteInput optionText="title" />
                  </ReferenceInput>
                )
              }
            </>
          )
        }}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            <SelectInput choices={massMessageSegmentChoices} required source="segment" />
            <br />
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              formData.segment && formData.segment.startsWith("Event") && (
                <ReferenceInput reference="Event" required source="event" {...rest}>
                  <AutocompleteInput optionText="name" />
                </ReferenceInput>
              )
            }
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
              formData.segment && formData.segment.startsWith("Custom") && (
                <CustomSegmentUserIdsInput
                  required
                  source="customSegmentUserIds"
                  {...rest}
                  style={{
                    width: 900,
                  }}
                />
              )
            }
          </>
        )}
      </FormDataConsumer>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const { input: talksMessageTextInput } = useInput({ source: "talksMessageText" })
          return (
            <>
              <SelectInput
                allowEmpty
                choices={talksMessageTypeChoices}
                helperText="If empty, talks message will not be generated"
                nullable
                onChange={(e) => {
                  const val = e.target.value as TalksMessageType
                  if (val === "Direct") {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    talksMessageTextInput.onChange(formData.message)
                  }
                }}
                source="talksMessageType"
              />
              <br />
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                formData.talksMessageType && formData.talksMessageType === "Direct" && (
                  <TextInput
                    required
                    source="talksMessageText"
                    style={{ width: 900 }}
                    {...rest}
                    multiline
                  />
                )
              }
            </>
          )
        }}
      </FormDataConsumer>

      <DeeplinkInput source="actionDeepLink" />

      <NumberInput
        defaultValue={0}
        helperText="in minutes (0 if to send all in one)"
        label="Chunk Interval"
        source="chunkInterval"
      />
      <NumberInput
        helperText="Ignored if chunk interval is 0"
        label="Chunk Size"
        source="chunkSize"
      />

      <SelectInput
        choices={massMessageStatusChoices}
        defaultValue={MassMessageStatus.Draft as string}
        required
        source="status"
      />
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <>
            {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              formData.status === MassMessageStatus.Rejected && (
                <TextInput required source="rejectionReason" {...rest} />
              )
            }
          </>
        )}
      </FormDataConsumer>
      {props.children}
    </SimpleForm>
  )
}

export const MassMessageCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

const URLs: React.FC<{ ids?: string[] }> = (props) => {
  if (!props["ids"]) return null
  return (
    <ul>
      {props["ids"].map((link, key) => (
        <li key={key}>
          <a href={JSON.parse(link) as string} rel="noopener noreferrer" target="_blank">
            Notification {key + 1}
          </a>
        </li>
      ))}
    </ul>
  )
}

export const MassMessageEdit: FC<any> = (props) => (
  <Edit {...props}>
    <CreateEditForm toolbar={<EditToolbar />}>
      <h3>Info</h3>
      <TextField label="Target audience" source="totalUsers" />
      <TextField label="Receivers (notifs enabled)" source="totalReceivers" />
      <TextField label="Clicked" source="totalClicks" />
      <ArrayField source="onesignalUrls">
        <URLs />
      </ArrayField>
    </CreateEditForm>
  </Edit>
)

export const MassMessageList: FC<any> = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="Community" source="community.name" />
      </SearchFilter>
    }
  >
    <Datagrid rowClick="edit">
      <ReferenceField reference="Community" source="community">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <TextField source="message" />
      <DateField showTime={true} source="sendAt" />
      <ChipField source="status" />
    </Datagrid>
  </List>
)
