import { FC } from "react"
import { CloneButton, Filter, FilterProps, SaveButton, TextInput, Toolbar } from "react-admin"

export const SearchFilter = ({ children, ...props }: FilterProps): React.ReactElement => (
  <Filter {...props}>
    <TextInput alwaysOn label="Search" source="q" />
    {children}
  </Filter>
)

export const EditToolbar: FC<any> = (props) => (
  <Toolbar {...props}>
    <SaveButton />
    <CloneButton />
  </Toolbar>
)
