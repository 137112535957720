import React, { FC } from "react"
import {
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"

import { SearchFilter } from "./utils"

export const ClaimedBadgeCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <ReferenceInput reference="User" source="owner">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput reference="TemplateBadge" source="template">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const ClaimedBadgeEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
        <ReferenceInput reference="User" source="owner">
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput reference="TemplateBadge" source="template">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput reference="Purchase" source="purchase">
          <AutocompleteInput optionText="message" />
        </ReferenceInput>
        <ReferenceField reference="Purchase" source="purchase">
          <ChipField source="id" />
        </ReferenceField>
      </SimpleForm>
    </Edit>
  )
}

export const ClaimedBadgeList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceField reference="Purchase" source="purchase">
        <ChipField source="id" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export const ClaimedBadgeShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
    </SimpleShowLayout>
  </Show>
)
