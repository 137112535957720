import React from "react"
import { Identifier, TextField, TextFieldProps } from "react-admin"

interface TruncatedTextFieldProps extends TextFieldProps {
  maxLines?: number
}
export const TruncatedTextField: React.FC<TruncatedTextFieldProps> = ({
  source,
  record,
  maxLines = 3,
  ...rest
}) => {
  const text = (record && source ? record[source] : "") as string

  const truncateText = (text: string): string => {
    const lines = text?.split("\n") ?? []
    if (lines.length <= maxLines) {
      return text
    }
    const truncatedLines = lines.slice(0, maxLines)
    return `${truncatedLines.join("\n")}...`
  }

  const updatedRecord: { id: Identifier } & Record<string, any> = {
    ...record,
    id: record?.id ?? "",
    [source || "a"]: truncateText(text),
  }

  return <TextField record={updatedRecord} source={source} {...rest} />
}
