import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  RadioButtonGroupInput,
  ReferenceField,
  SimpleForm,
  TextField,
} from "react-admin"

import { TruncatedTextField } from "./components/TruncatedTextField"
import { SearchFilter } from "./utils"

export const FeedItemCommentReportCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceField label="Comment" reference="FeedItemComment" source="comment">
        <TruncatedTextField maxLines={1} source="text" />
      </ReferenceField>
      <ReferenceField label="Author" reference="User" source="author">
        <TextField source="username" />
      </ReferenceField>
      <RadioButtonGroupInput
        choices={[
          { id: "Submitted", name: "Submitted" },
          { id: "Approved", name: "Approved" },
          { id: "Declined", name: "Declined" },
        ]}
        source="status"
      />
      <MarkdownInput source="reason" />
    </SimpleForm>
  </Create>
)

export const FeedItemCommentReportEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField label="Comment" reference="FeedItemComment" source="comment">
          <TruncatedTextField maxLines={1} source="text" />
        </ReferenceField>
        <ReferenceField label="Author" reference="User" source="author">
          <TextField source="username" />
        </ReferenceField>
        <RadioButtonGroupInput
          choices={[
            { id: "Submitted", name: "Submitted" },
            { id: "Approved", name: "Approved" },
            { id: "Declined", name: "Declined" },
          ]}
          source="status"
        />
        <MarkdownInput source="reason" />
      </SimpleForm>
    </Edit>
  )
}

export const FeedItemCommentReportList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)} perPage={25}>
    <Datagrid rowClick="edit">
      <ReferenceField label="Comment" reference="FeedItemComment" source="comment">
        <TruncatedTextField maxLines={1} source="text" />
      </ReferenceField>
      <ReferenceField label="Reporter" reference="User" source="author">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="status" />
      <TruncatedTextField maxLines={1} source="reason" />
      <DateField label="Date" source="createdAt" />
    </Datagrid>
  </List>
)
