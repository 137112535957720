import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin"

import { BulkList } from "./components/BulkList"
import { PromocodePublishingStatus } from "./generated/graphql"
import { SearchFilter } from "./utils"

const promocodeStatusChoices = [
  ...Object.keys(PromocodePublishingStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

export const PromocodeCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextField source="prefix" />
      <TextField source="code" />
      <NumberInput source="discountPercentage" />
      <NumberInput source="discountFixed" />
      <NumberInput source="discountOverridePrice" />
      <NumberInput helperText="for infinite/unlimited use -1" source="quantity" />
      <NumberInput helperText="for infinite/unlimited use -1" source="limitPerUser" />
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput reference="Product" source="products">
        <AutocompleteArrayInput optionText="code" />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="Purchase" source="purchases">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
    </SimpleForm>
  </Create>
)

export const PromocodeEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
      <NumberInput source="discountPercentage" />
      <NumberInput source="discountFixed" />
      <NumberInput source="discountOverridePrice" />
      <NumberInput helperText="for infinite/unlimited use -1" source="quantity" />
      <NumberInput helperText="for infinite/unlimited use -1" source="limitPerUser" />
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <SelectInput choices={promocodeStatusChoices} source="publishingStatus" />
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput reference="Product" source="products">
        <AutocompleteArrayInput optionText="code" />
      </ReferenceArrayInput>
      <ReferenceArrayField filter={{ status: "Paid" }} reference="Purchase" source="purchases">
        <SingleFieldList>
          <ChipField source="message" />
        </SingleFieldList>
      </ReferenceArrayField>
    </SimpleForm>
  </Edit>
)

export const PromocodeList: FC<any> = (props) => (
  <BulkList {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="code" />
    </Datagrid>
  </BulkList>
)

export const PromocodeShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="prefix" />
      <TextField source="code" />
      <BooleanField source="isReusable" />
      <NumberField source="discount" />
      <DateField source="startDate" />
      <DateField source="endDate" />
      <ReferenceArrayField reference="Product" source="products">
        <ChipField source="code" />
      </ReferenceArrayField>
      <ReferenceField reference="Purchase" source="purchase">
        <ChipField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
)
