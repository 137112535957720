import { FC } from "react"
import {
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  Edit,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
  TextField,
  TextInput,
} from "react-admin"
import { JsonInput } from "react-admin-json-view"

import { Module } from "./generated/graphql"
import { CustomSegmentUserIdsInput } from "./mass-message"

const availableModules = [
  ...Object.values(Module).map((k) => ({
    id: k,
    name: k,
  })),
]

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="name" />
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <SelectInput choices={availableModules} source="module" />
      <NumberInput source="position" />
      <CustomSegmentUserIdsInput
        required
        source="availableTo"
        style={{
          width: 900,
        }}
      />
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: true,
          name: null,
          quotesOnKeys: false,
        }}
        source="styleOverride"
      />
      {props.children}
    </SimpleForm>
  )
}

export const ModuleConfigurationCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const ModuleConfigurationEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm />
    </Edit>
  )
}

export const ModuleConfigurationList: FC<any> = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceField reference="Community" source="community">
        <ChipField source="name" />
      </ReferenceField>
      <ChipField source="module" />
      <DateField showTime source="createdAt" />
    </Datagrid>
  </List>
)
