import { gql } from "graphql-request"
import React, { FC, useEffect } from "react"
import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  SimpleFormProps,
  TextField,
  TextInput,
  TextInputProps,
  useInput,
} from "react-admin"
import { useFormState } from "react-final-form"

import MediaWithUpload from "./components/MediaWithUpload"
import { Module } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"
import { client as graphQLClient } from "./utils/api"

const availableModules = [
  ...Object.values(Module).map((k) => ({
    id: k,
    name: k,
  })),
]

const mapModuleToDeeplinkPath = (module: Module) => {
  switch (module) {
    case Module.EventList:
      return "events"
    case Module.Feed:
      return "news"
    case Module.Shop:
      return "shop"
    default:
      return "unknown"
  }
}

const DeeplinkInput = (props: TextInputProps) => {
  const { input } = useInput({ source: props.source })
  const { values } = useFormState()
  useEffect(() => {
    if (!values.actionDeepLink && values.linkedModule && values.community) {
      let newDeeplink = `${process.env.REACT_APP_DEEPLINK_SCHEME || "werz"}://c/${
        values.community as string
      }&module=${values.linkedModule as string}`
      if (values.linkedItemId) {
        newDeeplink = `${process.env.REACT_APP_DEEPLINK_SCHEME || "werz"}://c/${
          values.community as string
        }/${mapModuleToDeeplinkPath(values.linkedModule as Module)}/${
          values.linkedItemId as string
        }`
      }
      input.onChange(newDeeplink)
    }
  }, [values.linkedModule, values.linkedItemId])
  return <TextInput {...props} style={{ width: 900 }} />
}

const CustomSegmentUserIdsInput = (props: TextInputProps) => {
  const token = localStorage.getItem("token")
  const query = gql`
    query CheckUserIDs($ids: String!) {
      allUserIDsExist(ids: $ids) {
        allExist
        missingIDs
      }
    }
  `
  const { values } = useFormState()
  useEffect(() => {
    const IDs = values[props["source"]] as string
    if (IDs && !token) {
      alert("No token found in local storage, won't be able to validate IDs")
    }
    if (IDs && token) {
      // check if ids are valid
      graphQLClient
        .setHeader("Authorization", token)
        .request(query, {
          ids: IDs,
        })
        .then((data) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          if (!data.allUserIDsExist.allExist) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            alert(
              // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/no-unsafe-member-access
              "Invalid UserIDs: " + (data.allUserIDsExist.missingIDs as string),
            )
          }
        })
        .catch((err) => {
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          alert("Error while validating IDs: " + err)
        })
    }
  }, [values.customSegmentUserIds])
  return <TextInput {...props} multiline style={{ width: 900 }} />
}

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <TextField source="type" />
      <NumberInput source="order" />
      <FormDataConsumer>
        {(props) => (
          <CustomSegmentUserIdsInput
            source="restrictToUserIds"
            {...props}
            style={{
              width: 900,
            }}
          />
        )}
      </FormDataConsumer>
      <FormDataConsumer>
        {(props) => (
          <CustomSegmentUserIdsInput
            source="hideFromUserIds"
            {...props}
            style={{
              width: 900,
            }}
          />
        )}
      </FormDataConsumer>
      <ReferenceArrayInput reference="Community" source="restrictToCommunities">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="Location" source="restrictToLocations">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>

      <br />
      <TextInput source="linkedCommunityCode" />
      <BooleanInput source="suggestJoin" />
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const { input: linkedItemIdInput } = useInput({ source: "linkedItemId" })
          return (
            <>
              <SelectInput
                choices={availableModules}
                onChange={() => {
                  linkedItemIdInput.onChange("")
                }}
                source="linkedModule"
              />
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                formData.linkedModule === Module.EventList && (
                  <ReferenceInput reference="Event" required source="linkedItemId" {...rest}>
                    <AutocompleteInput optionText="name" />
                  </ReferenceInput>
                )
              }
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                formData.linkedModule === Module.Feed && (
                  <ReferenceInput reference="FeedItem" required source="linkedItemId" {...rest}>
                    <AutocompleteInput optionText="title" />
                  </ReferenceInput>
                )
              }
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                formData.linkedModule === Module.Forum && (
                  <ReferenceInput reference="ForumPost" required source="linkedItemId" {...rest}>
                    <AutocompleteInput optionText="content" />
                  </ReferenceInput>
                )
              }
              {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                formData.linkedModule === Module.Radio && (
                  <ReferenceInput reference="Track" required source="linkedItemId" {...rest}>
                    <AutocompleteInput optionText="title" />
                  </ReferenceInput>
                )
              }
            </>
          )
        }}
      </FormDataConsumer>
      <DeeplinkInput source="url" />

      {/* // data section */}
      <TextInput source="suggestionText" />
      <FormDataConsumer>
        {({ formData }) => {
          const { id: feedItemId } = formData as {
            media: string[]
            id: string
          }
          return (
            <MediaWithUpload
              path={`ubiqFeedItem/media/${feedItemId ?? Math.random() * 100000}`}
              source={`headerIconUrl`}
            />
          )
        }}
      </FormDataConsumer>
      <TextInput source="headerText" />
      <TextInput source="headerSecondaryText" />
      <TextInput source="headerCTAText" />
      <TextInput source="headerCTAUrl" />
      <TextInput source="title" />
      <TextInput source="content" />
      <ArrayInput source="media">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ formData, scopedFormData }) => {
              const { media, id: feedItemId } = formData as {
                media: string[]
                id: string
              }
              const url = scopedFormData as string
              let index = media.findIndex((m) => m === url)
              if (index === -1) index = media.length
              return (
                <MediaWithUpload
                  path={`ubiqFeedItem/media/${feedItemId}_${index}`}
                  source={`media.${index}`}
                />
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput source="isSmallMedia" />
      <BooleanInput source="showReactions" />
      {props.children}
    </SimpleForm>
  )
}

export const UbiqFeedItemCreate: FC<any> = (props) => (
  <Create {...props}>
    <CreateEditForm />
  </Create>
)

export const UbiqFeedItemEdit: FC<any> = (props) => (
  <Edit {...props}>
    <CreateEditForm toolbar={<EditToolbar />}>
      <DateTimeInput source="createdAt" />
    </CreateEditForm>
  </Edit>
)

export const UbiqFeedItemList: FC<any> = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="Community" source="community.name" />
      </SearchFilter>
    }
  >
    <Datagrid rowClick="edit">
      <TextField source="title" />
      <TextField source="content" />
      <ChipField source="status" />
      <NumberField source="totalRequestedAccess" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
)
