import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"
import { JsonInput } from "react-admin-json-view"

import MediaWithUpload from "./components/MediaWithUpload"
import { EditToolbar, SearchFilter } from "./utils"

export const CatalogItemCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="name" />
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <MarkdownInput source="description" />
      <ReferenceArrayInput reference="CatalogItemCategory" source="categories">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="CatalogItemSchedule" source="schedule">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <TextInput label="Link for Youtube" source="youtubeUrl" />
      <TextInput label="Link for Instagram" source="instagramUrl" />
      <TextInput label="Link for SoundCloud" source="soundcloudUrl" />
      <TextInput label="Link for Spotify" source="spotifyUrl" />
      <TextInput label="Link for Twitter" source="twitterUrl" />
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const CatalogItemEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source="id" />
        <TextInput source="name" />
        <ReferenceInput reference="Community" source="parentCommunity">
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <MarkdownInput source="description" />
        <ReferenceArrayInput reference="CatalogItemCategory" source="categories">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="CatalogItemSchedule" source="schedule">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <MediaWithUpload
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          filename={props["id"] as string}
          label="Artist Photo"
          path="catalog/avatar"
          source="photoUrl"
        />
        <MediaWithUpload
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          filename={props["id"] as string}
          label="Artist Video"
          path="catalog/video"
          source="videoUrl"
        />
        <TextInput label="Link for Youtube" source="youtubeUrl" />
        <TextInput label="Link for Instagram" source="instagramUrl" />
        <TextInput label="Link for SoundCloud" source="soundcloudUrl" />
        <TextInput label="Link for Spotify" source="spotifyUrl" />
        <TextInput label="Link for Twitter" source="twitterUrl" />
        <JsonInput
          jsonString={false}
          reactJsonOptions={{
            collapsed: true,
            displayDataTypes: false,
            enableClipboard: false,
            name: null,
          }}
          source="styleOverride"
        />
      </SimpleForm>
    </Edit>
  )
}

export const CatalogItemList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="description" />
    </Datagrid>
  </List>
)

export const CatalogItemShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="description" />
      <TextField label="Link for Youtube" source="youtubeUrl" />
      <TextField label="Link for Instagram" source="instagramUrl" />
      <TextField label="Link for SoundCloud" source="soundcloudUrl" />
      <TextField label="Link for Spotify" source="spotifyUrl" />
      <TextField label="Link for Twitter" source="twitterUrl" />
    </SimpleShowLayout>
  </Show>
)
