import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  ChipField,
  Datagrid,
  DateField,
  Edit,
  List,
  ReferenceField,
  SelectInput,
  SimpleForm,
  SimpleFormProps,
} from "react-admin"

import { TruncatedTextField } from "./components/TruncatedTextField"
import { ForumContentReportStatus } from "./generated/graphql"
import { SearchFilter } from "./utils"

const forumContentReportStatusChoices = [
  ...Object.keys(ForumContentReportStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

const CreateEditForm = (props: Omit<SimpleFormProps, "children">) => {
  return (
    <SimpleForm {...props}>
      <ReferenceField reference="ForumPost" source="postId">
        <ChipField source="id" />
      </ReferenceField>
      <ReferenceField reference="ForumComment" source="commentId">
        <ChipField source="id" />
      </ReferenceField>
      <ReferenceField reference="User" source="authorId">
        <ChipField source="username" />
      </ReferenceField>
      <SelectInput choices={forumContentReportStatusChoices} source="status" />
      <MarkdownInput source="reason" />
      {props.children}
    </SimpleForm>
  )
}

export const ForumContentReportEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <CreateEditForm>
        <DateField showTime source="updatedAt" />
        <DateField showTime source="createdAt" />
      </CreateEditForm>
    </Edit>
  )
}

export const ForumContentReportList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <ReferenceField reference="ForumPost" source="postId">
        <ChipField source="id" />
      </ReferenceField>
      <ReferenceField reference="ForumComment" source="commentId">
        <ChipField source="id" />
      </ReferenceField>
      <ReferenceField reference="User" source="authorId">
        <ChipField source="username" />
      </ReferenceField>
      <ChipField source="status" />
      <TruncatedTextField source="reason" />
      <DateField showTime source="createdAt" />
    </Datagrid>
  </List>
)
