import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  ArrayInput,
  Create,
  Datagrid,
  DateField,
  Edit,
  FormDataConsumer,
  List,
  NumberField,
  RadioButtonGroupInput,
  ReferenceField,
  SimpleForm,
  SimpleFormIterator,
  TextField,
} from "react-admin"

import MediaWithUpload from "./components/MediaWithUpload"
import { TruncatedTextField } from "./components/TruncatedTextField"
import { SearchFilter } from "./utils"

export const FeedItemCommentCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceField label="Announcement" reference="FeedItem" source="feedItem">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Author" reference="User" source="author">
        <TextField source="username" />
      </ReferenceField>
      <RadioButtonGroupInput
        choices={[
          { id: "Published", name: "Published" },
          { id: "Deleted", name: "Deleted" },
          { id: "Hidden", name: "Hidden" },
        ]}
        source="status"
      />
      <MarkdownInput source="text" />
      <NumberField source="likesCount" />
      <ArrayInput source="media">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ formData, scopedFormData }) => {
              const { media, id: feedItemId } = formData as {
                media: string[]
                id: string
              }
              const url = scopedFormData as string
              let index = media.findIndex((m) => m === url)
              if (index === -1) index = media.length
              return (
                <MediaWithUpload
                  path={`feedItem/media/${feedItemId}_${index}`}
                  source={`media.${index}`}
                />
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create>
)

export const FeedItemCommentEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextField source="id" />
        <ReferenceField label="Announcement" reference="FeedItem" source="feedItem">
          <TextField source="title" />
        </ReferenceField>
        <ReferenceField label="Author" reference="User" source="author">
          <TextField source="username" />
        </ReferenceField>
        <RadioButtonGroupInput
          choices={[
            { id: "Published", name: "Published" },
            { id: "Deleted", name: "Deleted" },
            { id: "Hidden", name: "Hidden" },
          ]}
          source="status"
        />
        <MarkdownInput source="text" />
        <NumberField source="likesCount" />
        <ArrayInput source="media">
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, scopedFormData }) => {
                const { media, id: feedItemId } = formData as {
                  media: string[]
                  id: string
                }
                const url = scopedFormData as string
                let index = media.findIndex((m) => m === url)
                if (index === -1) index = media.length
                return (
                  <MediaWithUpload
                    path={`feedItem/media/${feedItemId}_${index}`}
                    source={`media.${index}`}
                  />
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export const FeedItemCommentList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)} perPage={25}>
    <Datagrid rowClick="edit">
      <ReferenceField label="Announcement" reference="FeedItem" source="feedItem">
        <TextField source="title" />
      </ReferenceField>
      <ReferenceField label="Author" reference="User" source="author">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Reply To" reference="FeedItemComment" source="parentCommentId">
        <TruncatedTextField source="text" />
      </ReferenceField>
      <TextField source="status" />
      <TruncatedTextField source="text" />
      <NumberField source="likesCount" />
      <DateField label="Date" source="createdAt" />
    </Datagrid>
  </List>
)
